import React from "react";
import  { Table } from "../../Componets/Basic/Table/Table";
import { Layout } from "../../Componets/General/Layout/Layout";
import { objectKV,action } from "../../utils/interface";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { queryClients as GET_CLIENTS , fieldsTable } from "./Client.conf";
import { factory, factoryFilters } from "./Client.factory"
import { ButtonAdd } from "../../Componets/General/FormsUtils/Buttons.form";
import { createFilters, FiltersOptions, parceOrders } from "../../Componets/General/UtilsView/Utils-view";
import { ModalOptionsClient } from "./Client.options";


/**
 * pagination-fix-1 corresponde a machetazo para la paginacion
 */ 
export const Client = () => {

    const tableRef =  React.useRef(null);

    let [id, setId] = React.useState<number>();
    const [visible, setVisible] = React.useState<boolean>(false);

    // const [data, setData] = React.useState<Array<any>>();

    const  [loadData, { called, loading, data:dataRequest, refetch,fetchMore }] = useLazyQueryToSever({query:GET_CLIENTS});

    let [hiddenFilters, sethiddenFilters] = React.useState<boolean>();
    let [idSelected, setIdSelected] = React.useState<Array<number | string>>([]);

    const initial = {
        /**
         * estos valores tambien estan en el state pero no tiene un valor inicial
         */
        //data: es la info que se odtine al dar click en una columna de la tabla ,
        //filters: aqui se guarda los filtros,
        //orders: se guardan los valores del orden

        currentPage:1,
        numReg:30,
    };

    const reducer = (state:objectKV, action:action) => {

        switch(action.type) {
            case "CLICK_ROW":
                if(action.value?.id) {
                    setId(action.value?.id);
                    setVisible(true);
                }
                return Object.assign({},state,{data:action.value});
            case "ADD_FILTERS":
                let filters = createFilters(action.value);
                if(JSON.stringify(filters) === JSON.stringify(state.filters)) return state;
                return Object.assign({}, state, {filters, currentPage:1});
            case "ADD_CURRENT_PAGE":
                return Object.assign({}, state, {currentPage:action.value});
            case "SET_ORDER":
                return Object.assign({}, state, {orders:action.value});
            case "SET_NUM_REG":
                return Object.assign({}, state, {numReg:+action.value ?+action.value:10, currentPage:1});
            default: 
                return state;
        }
    };

    const [ state, dispatch ] = React.useReducer(reducer,initial);

    React.useEffect(() => {
        //esto es algo asi como un machetazo
        setIdSelected([]);
        let { filters, numReg, currentPage, orders } = state;
        //Se parcean los campos de orden que traen una estructura diferente de el state
        orders = parceOrders(orders); 
        orders = orders?[orders]:undefined;

        if(filters && numReg && currentPage){
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            loadData({variables:{filters, page: currentPage, fields: numReg, orders }})
        }
    }, [state.filters, state.numReg, state.currentPage, JSON.stringify(state.orders), fieldsTable]);

    const resetFilters = ()=>{
        let el:any = tableRef?.current;
        if(el?.resetFilters){
            el?.resetFilters()
        }
    };

    const _close = () => {
        setVisible(false);
    };

    const _onClickAdd = () => {
        setVisible(visible => {
            setId(undefined);
            return !visible;
        });
    };

    const refresh = () => {
        if(refetch)refetch();
        setIdSelected([]);
    };

    const _getIsSelectable = (data:objectKV) => {
        return data?.indicadorfisico;
    };

    return (
        <React.Fragment>
             <ModalOptionsClient visible={visible} id={id} close={_close} refresh={refresh}/>
            <Layout
            title="Clientes"
            goToPage={dispatch}
            right={<FiltersOptions
                filters={state.filters}
                reset={resetFilters} 
                hiddenFilters={sethiddenFilters}
                hidden={!!hiddenFilters} />}
            pageCurrent={state.currentPage}
            //pagination-fix-1
            total={dataRequest?.clientesempresastransportadoras?.totalreg}
            numReg={state.numReg}>
                <Table
                ref={tableRef}
                factoryFilters={factoryFilters}
                getIsSelectable={_getIsSelectable}
                factory={factory}
                getFilters={dispatch} 
                hiddenFilters={hiddenFilters}
                data={dataRequest?.clientesempresastransportadoras?.data} 
                clickRow={dispatch}
                loading={(loading || !called )}
                orderClick={dispatch} 
                orderableField={state.orders} 
                fields={fieldsTable}/>
            </Layout>
        </React.Fragment>
    );   
};