
import { confTable } from "./../../utils/interface";


const viajeMin = `    
id
idempresa
indicadoralerta
idempresatransportadora
idagencia
numero
nombreusuarioultimoreporte
fechahoraproximogps
fechahoraproximallamada
fechahoraproximopuestocontrol
fechahoraultimallamada
fecha
alerta
placa
indicadortransitosolar
retenerviaje
idproximosysevento
idsysestado
estado
idsysmodalidad
idplanviaje
idvehiculo
idconductor
idcargainternacional
idagencia
idembarcador
idruta
lugar
fechahoraultimoevento
ultimoevento
controlador
cliente
estado
mercancias
modalidadnombre
documentocliente
indicadoraltoriesgo
destino
devolviendocontenedor
origen
modalidad
proximopuestocontrol
nombreconductor
telefonoconductor
idclientes
indicadorvialibre
idproximopuestocontrol
trailerplaca
sincronizado
ruta
nombreconductor
numerodocumentoconductor
empresatransportadora
numerocontenedor
sysevento
novedad
evento
idorigen
iddestino
tipovehiculo
comercial
indicadornocoincide
geolocalizacion {
  latitud
  longitud
}
`;

export const getSysModalidad = `
query getSysModality{
  sysModalidadesInfo {
    data {
      id
      descripcion
      nombre
    }
  }
}
`;

export const querySysEventos = `
query sysevents{
  sysEventos @client{
    data {
      id
      iconname
      descripcion
    }
  }
}
`;

export const getSysEstados = `
query getSysEstados {
    sysEstados{
        data{
          id
          iconname
          descripcion
        }
      }
}
`;

export const fields : Array <confTable> = [
  {
    label:"",
    field:"Alertas",
    filterable:false,
    stopClick:true,
    width:30
  },
  {
    label:"C.GPS",
    field:"indicadornocoincide",
    filterable:true,
    orderable:true,
    width:50,
    tooltip:"Coincide el gps con la información reportada en la llamada",
  },
  {
    label:"Llamada",
    field:"fechahoraproximallamada",
    filterable:false,
    tooltip:"Retraso llamada",
    orderable:true,
    width:100,
    textAling:"RIGHT"
  },
  {
    label:"GPS",
    field:"fechahoraproximogps",
    filterable:false,
    tooltip:" Retraso GPS",
    orderable:true,
    width:70,
    textAling:"RIGHT"
  },
  {
    label:"Otros",
    tooltip:"Otros retrasos",
    field:"fechahoraproximopuestocontrol",
    filterable:false,
    orderable:true,
    width:90,
    textAling:"RIGHT"
  },
  {
    label:"Alerta",
    field:"indicadoralerta",
    textAling:"CENTER",
    stopClick:true,
    width:50,
    filterable:true,
    orderable:true,
  },
  {
    label:"Placa",
    field:"placa",
    width:70,
    orderable:true,
    filterable:true
  },
  {
    label:"Tipo",
    field:"tipovehiculo",
    path:"tipovehiculo",
    type:"TEXT",
    width:80,
    useTooltip:true,
    orderable:true,
    filterable:true
  },
  {
    label:"No. manifiesto",
    field: "numero",
    path:"numero",
    width:100,
    orderable:true,
    filterable:true
  },
  {
    label:"No. cont.",
    field: "numerocontenedor",
    path:"numerocontenedor",
    type: "TEXT",
    width:100,
    orderable:true,
    voidValue:" ",
    filterable:true
  },
  {
    label:"Doc. cliente",
    field: "documentocliente",
    path:"documentocliente",
    type: "TEXT",
    width:100,
    useTooltip:true,
    orderable:true,
    voidValue:" ",
    filterable:true
  },
  {
    label:"Estado",
    field:"idsysestado",
    orderable:true,
    filterable:true,
    textAling:"CENTER",
    // valueFiltered:"PENDIENTE"
  },
  {
    label:"Generador de carga",
    field:"cliente",
    path:"cliente",
    orderable:true,
    width:200,
    filterable:true,
    type:"TEXT",
    // valueFiltered:"241"
  },
  {
    label:"Tel. Cond",
    field:"telefonoconductor",
    width:100,
    orderable:true,
    filterable:true,
    stopClick:true,
    textAling:"CENTER"
  },
  {
    label:"Merc.",
    field:"indicadoraltoriesgo",
    orderable:true,
    filterable:true,
    textAling:"CENTER"
  },
  {
    label:"Origen",
    field:"idorigen",
    orderable:true,
    filterable:true,
    width:80,
    useTooltip:true,
    path:"origen",
    type:"TEXT"
  },
  {
    label:"Destino",
    field:"iddestino",
    orderable:true,
    width:80,
    useTooltip:true,
    filterable:true,
    path:"destino",
    type:"TEXT"
  },
  {
    label:"Ultimo reporte",
    field:"novedad",
    orderable:true,
    filterable:true,
    width:310
  },
  {
    label:"Fecha-hora",
    field:"fechahoraultimoevento",
    path:"fechahoraultimoevento",
    orderable:true,
    width:100,
    filterable:false,
    type:"DATE-TIME",
    textAling:"RIGHT"
  },
  {
    label:"Usuario ultimo reporte",
    field:"nombreusuarioultimoreporte",
    path:"nombreusuarioultimoreporte",
    orderable:true,
    filterable:true,
    type:"TEXT"
  },
  
  {
    label:"Modal",
    field:"modalidad",
    orderable:true,
    filterable:true,
    width:60,
    multiPath:["modalidad","modalidadnombre"],
    concatToken:" - ",
    useTooltip:true,
    type:"TEXT",
    textAling:"CENTER"
  },
  {
    label:"Lugar",
    field:"lugar",
    path:"lugar",
    orderable:true,
    filterable:true,
    width:200,
    type:"TEXT",
  },
  {
    label:"Prox. puesto",
    field:"proximopuestocontrol",
    filterable:true,
    path:"proximopuestocontrol",
    type:"TEXT",
    width:200
  },
  {
    label:"Conductor",
    field: "nombreconductor",
    path:"nombreconductor",
    type: "TEXT",
    orderable:true,
    filterable:true
  },
  {
    label:"Cédula",
    field: "numerodocumentoconductor",
    path:"numerodocumentoconductor",
    type: "TEXT",
    orderable:true,
    filterable:true
  },
  {
    label:"Comercial",
    field: "comercial",
    path:"comercial",
    type: "TEXT",
    orderable:true,
    filterable:true
  },
  {
    label:"Empresa transportadora",
    field: "empresatransportadora",
    path:"empresatransportadora",
    type: "TEXT",
    orderable:true,
    filterable:true
  }
];

export const queryViajes = `
query viajes( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
  viajes(
    filters: $filters,
    orders: $orders,
    pagination: { fields:$fields, page: $page}
  ) {
      data {
        ${viajeMin}
      }
      totalreg
    }
}`;

//Estos valores son para el select por lo que se encuentran quemados 
export const queryClientesViajes = `
query clienteViajesInfo{
  clienteViajesInfo(
    orders:[
    {field:"nombre",type:ASC}
    ],
    filters:[
    {field:"idsysestado",value:"1"},  	
    {field:"idsysestado",value:"2"},
  	{field:"idsysestado",value:"3"},
  	{field:"idsysestado",value:"5"},
  	{field:"idsysestado",value:"6"},
  	{field:"idsysestado",value:"8"},
  	{field:"idsysestado",value:"9"},
  	{field:"idsysestado",value:"10"},
  	{field:"idsysestado",value:"11"},
  	{field:"idsysestado",value:"12"}
    ]
  ){
    data{
      id
      idempresa
      nombre
    }
  }
}`;


export const poblacionDQuery = `{
  poblacionD{
    data{
      id
      destino
    }
    totalreg
  }
}`;

export const poblacionOQuery = `{
poblacionO{
    data{
      id
      origen
    }
      totalreg
  }
}`

export const queryViaje = ``;