import React from 'react'
import {
  InputSelect,
  InputSelect2
} from './../../Componets/Basic/Input/InputSelect'
import {
  factorFilter,
  confTable,
  objectKV,
  confMap,
  action
} from './../../utils/interface'
import { destructor } from './../../utils/utils'
import Button from './../../Componets/Basic/Button/Button'
import Icon from './../../Componets/Basic/Icon/Icon.tooltip'
import { colorAlerts } from './../../utils/conf'
import {
  useLazyQueryToSever,
  useQueryToSever
} from '../../Componets/hook/query-hook'
import './Alert.scss'
import './FactoryHome.scss'
import {
  querySysEventos,
  getSysEstados as GET_SYS_STATUS,
  getSysModalidad,
  queryClientesViajes,
  poblacionOQuery,
  poblacionDQuery
} from './Home.config'
import TextView from '../../Componets/Basic/Text/TextView'
import { NotificationElement } from './../../Componets/General/Notification/Notification'
import ToolTip from '../../Componets/Basic/ToolTip/ToolTip'
import { ClientFilter } from '../../Componets/General/UtilsView/Utils-view'
import { AlertModal } from './Forms/Alert'
import { ButtonsReportsAlerts, GpsReport, PhoneReport } from './Travel/Travel'

const GET_TIME_COUNTER = `{
    timeCounter    
}`

interface propsAlertsElements {
  timeGps?: number
  timeCall?: number
  timeReport?: number
  nextSysEvento?: number
  id?: number
  data?: any
  utils?: any
}

interface propsButtonAdapter {
  icon: string
  eventClick?: (v?: any) => any
  time?: number
  className?: string
  itsLate?: (v: action) => any
  name?: string
  disabled?: boolean
}

/**
 * @description esta funcion se encarga de encontarar la diferencia entre dos fechas las actual y un time
 * si devuelve mayor a 0 es que la fecha pasada es menor a la actual y viceversa
 * @param time
 */
const getTime = (time?: number) => {
  if (!time || !+time) return null
  let _time = new Date().getTime() - +time
  _time = _time / 60000
  return _time
}

const AlertsElements = React.memo((props: propsAlertsElements) => {
  const [hover, setHover] = React.useState<boolean>()
  const [lock, setLock] = React.useState<boolean>(false)

  const reducer = (state: objectKV, action: action) => {
    switch (action.type) {
      case 'SET_IST_LATE':
        return Object.assign({}, state, action.value)
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = React.useReducer(reducer, {
    //icon este es el icono
    //itslate es un boolean que indica retraso
  })

  const _onMouseEnter = () => {
    setHover(lock ? hover : true)
  }

  const _onMouseLeave = (e: any) => {
    setHover(false)
  }

  const _updateAlert = () => {
    if (typeof props?.utils?.refetch == 'function') props?.utils?.refetch()
  }

  const _click = () => {
    setLock(true)
    _onMouseLeave(null)
  }

  const isVisible = (evt: any) => {
    setTimeout(() => {
      setLock(evt)
    }, 500)
  }

  return (
    <div
      className='alert-info'
      onMouseOver={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      onClick={_click}
    >
      <div style={{ width: hover ? 130 : 20 }} className='alert-info__view'>
        <div
          className='alert-info__view__button'
          style={{ display: hover ? 'flex' : 'none' }}
        >
          <ButtonsReportsAlerts isVisible={isVisible} data={{ viaje: props.data }} id={props.id}>
            <ButtonAdapterCheckPoint
              data={{ viaje: props.data }}
              id={props.id}
              itsLate={dispatch}
              timeReport={props.timeReport}
              nextSysEvent={props.nextSysEvento}
            />
          </ButtonsReportsAlerts>
          <div style={{height:0,width:0}}>
          <ButtonAdapterCheckPoint
              data={{ viaje: props.data }}
              id={props.id}
              itsLate={dispatch}
              timeReport={props.timeReport}
              nextSysEvent={props.nextSysEvento}
            />
          </div>
          <PhoneReport
            id={props.id}
            isVisible={isVisible}
            time={props.timeCall}
          />
          {/* <ButtonAdapter name="Reporte de llamada" icon="icon-telefono" eventClick={() => console.log("click call")} time={props.timeCall} /> */}
          <GpsReport id={props.id} isVisible={isVisible} time={props.timeGps} />
          <AlertModal
            {...(props.data ?? {})}
            isVisible={isVisible}
            style={{ height: 25, width: 25 }}
            update={_updateAlert}
          />
          {/* <ButtonAdapter name="Reporte de gps" icon="icon-gps" eventClick={() => console.log("click gps")} time={props.timeGps} /> */}
        </div>
        <div
          className='alert-info__square'
          style={{ display: !hover ? 'flex' : 'none' }}
        ></div>
        <div
          className='alert-info__triangle'
          style={{ borderLeftColor: state.color ?? '#c8c9d6' }}
        />
      </div>
    </div>
  )
})

export const ButtonAdapterCheckPoint = (props: {
  data?: any
  id?: any
  disabled?: boolean
  timeReport?: number
  itsLate?: (v: action) => any
  nextCheckPointId?: number
  className?: string
  nextSysEvent?: number
  getSysEvent?: (v: action) => any
  eventClick?: (v: any) => any
}) => {
  const [loadData, { data, error }] = useLazyQueryToSever({
    query: querySysEventos,
    fetchPolicy: 'cache-first'
  })
  const [sysEvent, setSysEvent] = React.useState<objectKV>()

  React.useEffect(() => {
    loadData()
  }, [])

  React.useEffect(() => {
    if (data?.sysEventos?.data?.length) {
      let _sysEvent = data.sysEventos.data.find(
        (item: objectKV) => item.id === props.nextSysEvent
      )
      setSysEvent(_sysEvent)
      if (props.getSysEvent)
        props.getSysEvent({ type: 'SET_DATA', value: _sysEvent })
    }
  }, [data, props.nextSysEvent])

  const _eventClick = () => {
    if (props.eventClick && sysEvent) props.eventClick(null)
  }

  return (
    <ButtonAdapter
      itsLate={props.itsLate}
      className={props.className}
      name={sysEvent?.descripcion}
      icon={sysEvent?.iconname ?? ''}
      eventClick={_eventClick}
      disabled={props.disabled}
      time={props.timeReport}
    />
  )
}

export const NotificationReport = (props: { id: number }) => {
  return (
    <NotificationElement>
      <div className='notification-report'>
        <div className='notification-report__icon'>
          <i className='icon-check ' />
        </div>
        <div>
          <label className='notification-report__text'>
            Se realizo un nuevo reporte al viaje
            <label className='notification-report__id'>{`  ${props.id}`}</label>
          </label>
        </div>
      </div>
    </NotificationElement>
  )
}

export const ButtonAdapter = React.memo((props: propsButtonAdapter) => {
  const { data, refetch } = useQueryToSever({
    query: GET_TIME_COUNTER,
    fetchPolicy: 'cache-only'
  })
  const [time, setTime] = React.useState<number | null>()
  const [color, setColor] = React.useState<
    { time: number; label: string; color: string; pulse: string } | undefined
  >(colorAlerts[0])

  React.useEffect(() => {
    let time = getTime(props.time)
    setTime(time)
    let _color = colorAlerts
      .sort((a: objectKV, b: objectKV) => a.time - b.time)
      .find((item: objectKV) => item.time >= (time !== null && time))
    let color = _color?.color ?? 'gray'
    setColor(_color)
    if (props.itsLate)
      props.itsLate({
        type: 'SET_IST_LATE',
        value: { itsLate: time !== null && time, color, icon: props.icon }
      })
  }, [data, props.time])

  return (
    <Button
      onClick={props.eventClick}
      disabled={props.disabled}
      style={{
        background: props.disabled ? 'gray' : color?.color ?? 'gray',
        animation: `${color?.pulse} ${!props.disabled ? '1s' : ''} infinite`
      }}
      className={`button-alerts ${props.className ?? ''}`}
    >
      <Icon className={props.icon} stabilizer={50}>
        {time !== null ? (
          <InfoTooltip time={time} name={props.name} color={color?.color} />
        ) : null}
      </Icon>
    </Button>
  )
})

export const getDHM = (time?: number | null) => {
  if (!time && time !== 0) return null
  let _time = Math.abs(time)
  let day = (_time / (60 * 24)).toString().split('.')
  let _day = +(day[0] ?? 0)
  let hrs = +(+`0.${day[1] ?? 0}` * 24).toString().split('.')[0]

  let mnts = _time - (_day * 24 + hrs) * 60
  // let mnts = _time % (((_day*24)+hrs)*60);
  return { day: _day, hours: hrs, minutes: Math.round(mnts) || 0 }
}

export const InfoTooltip = React.memo(
  (props: { name?: string; color?: string; time?: number }) => {
    const [time, setTime] = React.useState<Array<number>>([])

    React.useEffect(() => {
      let _time = getDHM(props.time)
      if (_time) setTime([_time.day, _time.hours, _time.minutes])
      else setTime([])
    }, [props.time])

    return (
      <React.Fragment>
        <div className='tool-tip-name'>{props.name ?? 'Reporte'}</div>
        <div className='tool-tip-info'>
          {(props.time ?? 0) < 0
            ? 'Se debe verificar en:'
            : 'Se debio verificar en:'}
        </div>
        <div className='tool-tip-time' style={{ color: props.color }}>
          <table>
            <thead>
              <tr>
                <th>Dias</th>
                <th>Hrs</th>
                <th>Min</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{time[0]}</td>
                <td>{time[1]}</td>
                <td>{time[2]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }
)

const Alerts = React.memo(
  (props: { field: confTable; data: objectKV; utils?: any }) => {
    return (
      <div className='container-alerts'>
        <div
          className='alert'
          style={{ backgroundColor: props?.data?.retenerviaje ? 'red' : '' }}
        >
          <Icon
            message={props?.data?.retenerviaje ? 'Retener viaje' : ''}
            className={`${
              props?.data?.retenerviaje ? 'icon-alerta' : 'icon-gps-lugar'
            } alert__icon`}
          />
        </div>
        <AlertsElements
          id={props.data.id}
          data={props.data}
          utils={props.utils}
          nextSysEvento={props.data.idproximosysevento}
          timeGps={props.data.fechahoraproximogps}
          timeCall={props.data.fechahoraproximallamada}
          timeReport={props.data.fechahoraproximopuestocontrol}
        />
      </div>
    )
  }
)

export const useSysState = (idsRemoved?: Array<number>) => {
  const { data } = useQueryToSever({
    query: GET_SYS_STATUS,
    fetchPolicy: 'cache-only'
  })
  const [sys, setSys] = React.useState<Array<confMap>>()
  React.useEffect(() => {
    let _sys = data?.sysEstados?.data
    if (_sys) {
      if (!_sys?.length) setSys([])
      else {
        let temp = _sys
          .filter((item: objectKV) => {
            return !(idsRemoved ?? []).find(
              (item_2: number) => item_2 === +item.id
            )
          })
          .map((item: objectKV) => {
            let { id, iconname: className, descripcion: description } = item
            return { id, className, description }
          })
        setSys(temp)
      }
    }
  }, [data])

  const getSysState = React.useCallback(() => {
    return sys
  }, [sys])

  return React.useMemo(() => ({ sys, getSysState }), [sys, getSysState])
}

export const Modality = (props: objectKV) => {
  const [dataModality, setModality] = React.useState<Array<confMap>>()

  const [loadData, { data }] = useLazyQueryToSever({
    query: getSysModalidad,
    fetchPolicy: 'cache-first'
  })

  React.useEffect(() => {
    loadData()
  }, [])

  React.useEffect(() => {
    setModality(data?.sysModalidadesInfo?.data ?? [])
  }, [data])

  return (
    <InputSelect
      {...props}
      data={dataModality}
      confSelect={{
        pathEmit: 'descripcion',
        tokenJoin: ' - ',
        multiPathOption: ['descripcion', 'nombre']
      }}
    />
  )
}

export const SysStatusFilter = (props: objectKV) => {
  const { sys } = useSysState(props.idsRemoved)
  return (
    <InputSelect
      {...props}
      data={sys?.map((item: any) => ({ ...item, id: item.id + '' }))}
      confSelect={{ pathEmit: 'id', pathOption: 'description' }}
    />
  )
}

export const FilterClient = React.memo((props: factorFilter) => {
  const { width, field, filterable } = props?.confField
  const [getData, { data, loading, error }] = useLazyQueryToSever({
    query: queryClientesViajes
  })
  React.useEffect(() => {
    getData()
  }, [])
  // React.useEffect(() => {
  //     console.log(data);
  // },[data]);
  return (
    <InputSelect2
      style={{ width }}
      name={field}
      {...props.bind}
      disabled={!filterable || loading || !!error}
      data={data?.clienteViajesInfo?.data}
      confSelect={{ pathEmit: 'id', pathOption: 'nombre' }}
    />
  )
})

export const FactoryFilters = (props: factorFilter) => {
  //NOTA: para este caso seria mejor un switch, si decea cambiarlo bn, hay varios iguales
  if (props.confField.field === 'idsysestado') {
    return (
      <SysStatusFilter
        idsRemoved={[4, 7, 12, 14, 13, 15, 8, 17, 16]}
        style={{ width: 100 }}
        name={props.confField.field}
        {...props.bind}
        disabled={!props.confField.filterable}
      />
    )
  }

  //se usa indicador de alto riesgo para referenciar las mercancias ya que este es el campo que lo representa en GRAPHQL
  if (props.confField.field === 'modalidad') {
    return (
      <Modality
        style={{ width: 60 }}
        name={props.confField.field}
        {...props.bind}
        disabled={!props.confField.filterable}
      />
    )
  }

  if (props.confField.field === 'idorigen') {
    return (
      <RouteOD
        {...props.confField}
        bind={props.bind}
        query={poblacionOQuery}
        path='poblacionO'
        pathOption='origen'
      />
    )
  }

  if (props.confField.field === 'iddestino') {
    return (
      <RouteOD
        {...props.confField}
        bind={props.bind}
        query={poblacionDQuery}
        path='poblacionD'
        pathOption='destino'
      />
    )
  }

  if (props.confField.field === 'cliente') {
    return (
      <ClientFilter
        idsysevents={[1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 13, 14]}
        style={{ width: props.confField.width }}
        name={props.confField.field}
        {...props.bind}
        disabled={!props.confField.filterable}
      />
    )
  }

  if (props.confField.field === 'indicadoraltoriesgo') {
    return (
      <InputSelect
        style={{ width: 50 }}
        name={props.confField.field}
        {...props.bind}
        disabled={!props.confField.filterable}
        data={[
          { value: 'false', className: 'icon-icons8-box box-icon' },
          { value: 'true', className: 'icon-warning warning-icon' }
        ]}
        confSelect={{ pathEmit: 'value', textAling: 'CENTER' }}
      />
    )
  }

  if (props.confField.field === 'indicadoralerta') {
    return (
      <InputSelect
        style={{ width: 50 }}
        name={props.confField.field}
        {...props.bind}
        disabled={!props.confField.filterable}
        data={[
          { value: 'false', option: 'NO' },
          { value: 'true', option: 'SÍ' }
        ]}
        confSelect={{
          pathEmit: 'value',
          pathOption: 'option',
          textAling: 'CENTER'
        }}
      />
    )
  }

  if (props.confField.field === 'indicadornocoincide') {
    return (
      <InputSelect
        style={{ width: 50 }}
        name={props.confField.field}
        {...props.bind}
        disabled={!props.confField.filterable}
        data={[
          { value: 'true', option: 'NO' },
          { value: 'false', option: 'SÍ' }
        ]}
        confSelect={{
          pathEmit: 'value',
          pathOption: 'option',
          textAling: 'CENTER'
        }}
      />
    )
  }

  return null
}

const Merchandise = (props: { data: objectKV }) => {
  const [message, setMessage] = React.useState<string>()
  const [highRisk, setHighRisk] = React.useState<boolean>()

  React.useEffect(() => {
    setHighRisk(!!props.data.indicadoraltoriesgo)
    setMessage(props.data.mercancias)
  }, [props.data.remesas])
  return (
    <Icon
      className={`${
        highRisk ? 'icon-warning warning-icon' : 'icon-icons8-box box-icon'
      } `}
      message={message ?? ''}
    />
  )
}

export const NotMatch = React.memo((props: { indicadornocoincide?: any }) => {
  return (
    <div
      className='not-match-factory'
      style={props.indicadornocoincide ? { color: 'red' } : {}}
    >
      {props.indicadornocoincide ? 'X' : ''}
    </div>
  )
})

const DelayReport = (props: { timeReport?: number; nextSysEvent?: any }) => {
  const { data, refetch } = useQueryToSever({
    query: GET_TIME_COUNTER,
    fetchPolicy: 'cache-only'
  })

  const [time, setTime] = React.useState<string>('00:00')

  /**Posicion del tooltip verticalmente */
  const [top, setTop] = React.useState<number>(0)

  /**Posicion del tooltip horizontalmente */
  const [left, setLeft] = React.useState<number>(0)

  /**Este valor es true cuando el tooltip es visible*/
  const [visible, setVisible] = React.useState<boolean>(false)

  const [loadData, { data: datasysevents, error }] = useLazyQueryToSever({
    query: querySysEventos,
    fetchPolicy: 'cache-first'
  })
  const [sysEvent, setSysEvent] = React.useState<objectKV>()

  React.useEffect(() => {
    loadData()
  }, [])

  React.useEffect(() => {
    if (datasysevents?.sysEventos?.data?.length) {
      let _sysEvent = datasysevents.sysEventos.data.find(
        (item: objectKV) => item.id === props.nextSysEvent
      )
      setSysEvent(_sysEvent)
    }
  }, [datasysevents, props.nextSysEvent])

  React.useEffect(() => {
    let time = getTime(props.timeReport)

    if (time && time > 0) {
      let _time = (time / 60).toString().split('.')
      let hrs = +_time[0] < 10 ? `0${_time[0]}` : _time[0]
      let mnts = _time[1]
        ? (60 * +`0.${_time[1]}`).toString().split('.')[0]
        : '0'
      let _mnts = +mnts < 10 ? `0${mnts}` : mnts
      setTime(`${hrs}:${_mnts}`)
    }
  }, [data, props.timeReport])

  /**  */
  const _onMouseMoveCapture = (e: any) => {
    if (e) {
      let _top = 0,
        _left = 0
      if (+e.clientY) {
        _top = e.clientY
        // este elemento cambia cuando se esta cerca de la posicion final de la pagina
        // para permitir renderizar el tool tip sobre el mouse
        if (window.innerHeight - e.clientY < 50) _top = e.clientY - 50
      }
      if (+e.clientX) {
        _left = +e.clientX
      }
      setTop(_top)
      setLeft(_left)
    }
    if (!visible) setVisible(true)
  }

  const _onMouseLeave = () => {
    setVisible(false)
    setTop(0)
    setLeft(0)
  }

  return (
    <React.Fragment>
      {sysEvent?.id ? (
        <ToolTip visible={visible} top={top} left={left}>
          <label className='text-tooltip'>
            Retraso para: {sysEvent?.descripcion}{' '}
          </label>
        </ToolTip>
      ) : null}
      <label
        onMouseMoveCapture={_onMouseMoveCapture}
        onMouseLeave={_onMouseLeave}
        className='delay'
      >
        {time}
      </label>
    </React.Fragment>
  )
}

const RouteOD = React.memo((props: objectKV) => {
  const { width, field, filterable, query, pathOption, path } = props ?? {}
  const [getData, { data, loading, error }] = useLazyQueryToSever({
    query,
    fetchPolicy: 'cache-first'
  })
  React.useEffect(() => {
    getData()
  }, [])
  return (
    <InputSelect2
      style={{ width }}
      name={field}
      {...props.bind}
      disabled={!filterable || loading || !!error}
      data={
        data &&
        data[path] &&
        data[path].data?.map &&
        data[path].data?.map((item: any) => ({ ...item, id: `${item.id}` }))
      }
      confSelect={{ pathEmit: 'id', pathOption }}
    />
  )
})

const Plate = (props: {
  plate?: string
  indicadorvialibre?: boolean
  devolvercontenedor?: boolean
}) => {
  return (
    <label
      style={{
        color: props.devolvercontenedor
          ? '#FF0000'
          : !props.indicadorvialibre
          ? '#888888'
          : '#60e245'
      }}
      className='plate'
    >
      {props.plate ?? 'NA'}
    </label>
  )
}

const Manifest = (props: { indicadortransitosolar: boolean; numero: any }) => {
  return (
    <label
      style={{
        color: props.indicadortransitosolar ? 'rgb(255, 147, 52)' : '#888888'
      }}
      className='plate'
    >
      {props.numero ?? ''}
    </label>
  )
}

const DriverContact = React.memo((props: any) => {
  let data = props?.data?.telefonoconductor
  return (
    <Icon
      className=''
      message={props?.data?.nombreconductor}
      content={
        data ? (
          <label>
            {`${data}`.split(',').map((i: any) => (
              <a href={`tel:${`${i}`.split('-').reverse()[0]}`}>
                {`${i}`.split('-').reverse()[0]}
              </a>
            ))}
          </label>
        ) : (
          <label className='text-info text-table'>NA</label>
        )
      }
    />
  )
})

const SysStatus = (props: { data: objectKV }) => {
  const { data } = useQueryToSever({
    query: GET_SYS_STATUS,
    fetchPolicy: 'cache-only'
  })
  const [sys, setSys] = React.useState<confMap>()

  React.useEffect(() => {
    let _sys = data?.sysEstados?.data
    if (_sys?.length) {
      let temp = _sys
        .map((item: objectKV) => {
          let { id, iconname: className, descripcion: description } = item
          return { id, className, description }
        })
        .find((item: confMap) => props.data?.idsysestado === item.id)
      setSys(temp)
    }
  }, [data])

  return (
    <Icon
      className={`icon-status ${sys?.className}`}
      message={sys?.description ?? ''}
    />
  )
}

const Place = (props: { field: confTable; data: objectKV }) => {
  // let population = "";

  let value: string | undefined = undefined

  // if(props.field?.multiPath) value = props.field?.multiPath.map((item: string | Array <string>) => destructor(item,props.data) );

  if (props.field?.path) value = destructor(props.field.path, props.data)
  // population = `${value[0]} (${value[1]})`;

  return value !== undefined ? (
    <div className='text-place'>
      <TextView text={value} />
    </div>
  ) : null
}

export const Factory = (props: {
  field: confTable
  data: objectKV
  utils?: any
}) => {
  if (props.field.field === 'Alertas') return <Alerts {...props} />

  if (props.field.field === 'indicadoralerta')
    return <div className='content-center'><AlertModal {...(props.data ?? {})} disableModal /></div>

  if (props.field.field === 'novedad')
    return (
      <div>
        {props.data?.sysevento ?? props.data?.evento ?? ''}{' '}
        {props.data?.novedad ?? ''}
      </div>
    )

  if (props.field.field === 'fechahoraproximopuestocontrol')
    return (
      <DelayReport
        timeReport={props.data.fechahoraproximopuestocontrol}
        nextSysEvent={props?.data?.idproximosysevento}
      />
    )

  if (props.field.field === 'fechahoraproximallamada')
    return <DelayReport timeReport={props.data.fechahoraproximallamada} />

  if (props.field.field === 'fechahoraproximogps')
    return <DelayReport timeReport={props.data.fechahoraproximogps} />

  if (props.field.field === 'indicadornocoincide')
    return <NotMatch indicadornocoincide={props.data.indicadornocoincide} />

  if (props.field.field === 'placa')
    return (
      <Plate
        indicadorvialibre={props.data.indicadorvialibre}
        devolvercontenedor={!!props.data?.devolviendocontenedor}
        plate={props.data.placa}
      />
    )

  if (props.field.field === 'numero')
    return (
      <Manifest
        indicadortransitosolar={props.data.indicadortransitosolar}
        numero={props.data.numero}
      />
    )

  //se usa indicador de alto riesgo para referenciar las mercancias ya que este es el campo que lo representa en GRAPHQL
  if (props.field.field === 'indicadoraltoriesgo')
    return <Merchandise data={props.data} />

  if (props.field.field === 'idsysestado' || props.field.field === 'estado')
    return <SysStatus data={props.data} />

  if (props.field.field === 'telefonoconductor')
    return <DriverContact data={props.data} />

  return null
}
