
export const sysStado = () => {
    return [
        {
            id:1,
            description:"EN TRÁNSITO",
            className:"icon-transito"
        },
        {
            id:2,
            description:"PAUSADO",
            className:"icon-pause"
        },
        {
            id:3,
            description:"CARGANDO",
            className:"icon-cargue"
        },
        {
            id:4,
            description:"CANCELADO",
            className:"icon-cancel"
        },
        {
            id:5,
            description:"ANULADO",
            className:"icon-slash"
        },
        {
            id:6,
            description:"PENDIENTE",
            className:"icon-pendiente"
        },
        {
            id:7,
            description:"TERMINADO",
            className:"icon-check"
        },
        {
            id:8,
            description:"DESCARGANDO",
            className:"icon-descargue"
        },
        {
            id:9,
            description:"DESCARGADO",
            className:"icon-llegada"
        },
    ];
}

/**
 * @description esto se usa para mapperar los valores reales se obtienen de la base de datos.
 * se usa la descripcion para encontarr relacion
 */
export const sysEventos = [
    {
        id: 1, 
        descripcion: "Salida de cargue"
    }
]

export const colorAlerts = [
    {
        time: 0,
        label:"OK",
        color:"#60e245",
        pulse:"none"
    },
    {
        time:15,
        label:"0-15",
        color:"#f8d800",
        pulse:"pulseYellow"
    },
    {
        time:30,
        label:"15-30",
        color:"#ff8900",
        pulse:"pulseOrange"
    },
    {
        time:Infinity,
        label:">30",
        color:"#ff0000",
        pulse:"pulseRed"
    }
    // ,
    // {
    //     time:Infinity,
    //     label:">45",
    //     color:"#8222f1",
    //     pulse:"pulsePurple"
    // }
];