import { confTable } from "./../../../../utils/interface";

export const fieldsTrazability : Array <confTable> = [
    {
        label:"#",
        field:"orden",
        orderable:false,
        filterable:false,
        textAling: "CENTER",
        type: "TEXT",
        path:"orden",
        stopClick: true,
        width:20
    },
    {
        label:" ",
        field:"indicadornocoincide",
        filterable:false,
        orderable:false,
        width:30,
        tooltip:"Coincide el gps con la información reportada en la llamada",
      },
    {
        label:"Fotos",
        field:"fotos",
        orderable:false,
        filterable:false,
        textAling:"CENTER",
        stopClick: true,
        width:50
    },
    {
        label:"Tipo",
        orderable:false,
        filterable:false,
        field:"cump",
        textAling: "CENTER",
        width:50
    },
    {
        label:"Remesas",
        orderable:false,
        filterable:false,
        field: "remittances",
        width:120
    },
    {
        label:"Lugar reportado",
        orderable:false,
        filterable:false,
        field:"lugar",
        width:200,
        path:"lugar",
    },
    {
        label:"Reportes",
        orderable:false,
        filterable:false,
        field:"reportes",
        type:"TEXT",
        concatToken:" - ",
        multiPath:["puestocontrol","evento","novedad"]
    }, 
    {
        label:"Fecha hora   ",
        orderable:false,
        filterable:false,
        field:"fechaHora",
        type:"DATE-TIME",
        textAling:"RIGHT",
        path:"fechahora"
    },
    {
        label:"Reportado por",
        field:"reportadopor",
        orderable:false,
        filterable:false,
        type:"TEXT",
        path:"usuario",
    },
    {
        label:"Fecha creación",
        orderable:false,
        filterable:false,
        field:"fechareporte",
        type:"DATE-TIME",
        textAling:"RIGHT",
        path:"fechareporte"
    }
];


export const fieldsPlan : Array <confTable> = [
    {
        label:"Orden",
        orderable:false,
        filterable:false,
        field:"Orden",
        type:"TEXT",
        path:"orden",
        textAling:"RIGHT",
    },
    {
        label:"Nombre",
        orderable:false,
        filterable:false,
        field:"puestocontrol",
        type:"TEXT",
        textAling:"LEFT",
        path:"puestocontrol",
    },
    {
        label:"Distancia (Km)",
        orderable:false,
        filterable:false,
        field:"Distancia",
        type:"TEXT",
        path:"distancia",
        textAling:"RIGHT",
    },
    {
        label:"Duración",
        orderable:false,
        filterable:false,
        field:"Duracion",
        type:"TEXT",
        path:"duracion",
        textAling:"RIGHT",
    },
    {
        label:"Tipo",
        orderable:false,
        filterable:false,
        field:"pernotar",
        type:"BOOLEAN",
        path:"indicadorfisico",
        booleanValues:{isFalse:"Virtual", isTrue:"Físico"},
        textAling:"RIGHT",
    },
    {
        label:"Alto Riesgo",
        orderable:false,
        filterable:false,
        field:"pernotar",
        type:"BOOLEAN",
        path:"indicadoraltoriesgo",
        textAling:"RIGHT",
    },
    {
        label:"Pernotar",
        orderable:false,
        filterable:false,
        field:"pernotar",
        type:"BOOLEAN",
        path:"indicadorpernoctar",
        textAling:"RIGHT",
    },
];


export const fieldsRemitances : Array <confTable> = [
    {
        label:"Estado",
        orderable:false,
        filterable:false,
        field:"estado",
        type: "TEXT",
        word: true,
        path:"estado",
    },
    {
        label:"No. remesa",
        orderable:false,
        filterable:false,
        field:"Número",
        type:"TEXT",
        path:"numeroremesa",
        word:true
    },
    {
        label:"Destinatario",
        orderable:false,
        filterable:false,
        field:"Destinatario",
        type:"TEXT",
        word:true,
        path:"destinatario",
    },
    {
        label:"Dirección",
        orderable:false,
        filterable:false,
        field:"direcciondestinatario",
        type:"TEXT",
        word:true,
        path:"direcciondestinatario",
    },
    {
        label:"Lugar",
        orderable:false,
        filterable:false,
        word:true,
        field:"destino",
        type:"TEXT",
        path:"destino",
    },
    {
        label:"Doc.cte.",
        orderable:false,
        filterable:false,
        field:"Documentocliente",
        type:"TEXT",
        path:"documentocliente",
    },
    {
        label:"Contenido",
        orderable:false,
        filterable:false,
        field:"Contenido",
        type:"TEXT",
        word:true,
        path:"contenido",
    },
    {
        label:"Cant.",
        orderable:false,
        filterable:false,
        field:"numerounidades",
        type:"TEXT",
        textAling:"RIGHT",
        path:"numerounidades",
    },
    {
        label:"Peso(TN)",
        orderable:false,
        filterable:false,
        field:"pesomercancia",
        type:"TEXT",
        textAling:"RIGHT",
        path:"pesomercancia",
    },
    {
        label:"Tipo Emp.",
        orderable:false,
        filterable:false,
        field:"empaque",
        word:true,
        type:"TEXT",
        path:"empaque",
    },
    {
        label:"No. cont.",
        orderable:false,
        filterable:false,
        word:true,
        field:"numerocontenedor",
        type:"TEXT",
        path:"numerocontenedor",//contenedor
    },
    {
        label:"Devol",
        orderable:false,
        filterable:false,
        field:"devolvercontenedor",
        type:"BOOLEAN",
        textAling:"CENTER",
        path:"devolvercontenedor",//devcontenedor 
    },
    {
        label:"Generador",
        orderable:false,
        filterable: false,
        useTooltip: true,
        word: true,
        width:100,
        field:"clienteempresatransportadora",
        type:"TEXT",
        path:"clienteempresatransportadora", 
    },
    {
        label:"Observaciones",
        orderable:false,
        filterable:false,
        field:"Cantidad",
        type:"TEXT",
        width:300,
        path:"observaciones",
    }
];

export const getSignedImage = `
query getSignedImage($id:Int){
    getSignedURLReport(idreporte: $id) {
        id
        key
        url
      }
}
`;