import React from 'react'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import TextView from './../../../Componets/Basic/Text/TextView'
import {
  useQueryToSever,
  useLazyQueryToSever
} from './../../../Componets/hook/query-hook'
import LoadContainer from './../../../Componets/Basic/LoadIndicator/LoadContainer'
import { Distance } from './../../../Componets/General/Map/Distance'
import {
  ButtonResize,
  CtnResize
} from './../../../Componets/General/UtilsView/Utils-view'
import { sysEventos } from './../../../utils/conf'
import { useInput } from './../../../Componets/hook/input-hook'

import { colorAlerts } from './../../../utils/conf'
import { objectKV, position, action, latlng } from './../../../utils/interface'
import Details from './Details/Details'
import {
  queryVehicle as GET_VEHICLE_INFO,
  queryGenralInfo as GET_GENERAL_INFO,
  queryTravelFragment as GET_TRAVELS_LOCAL,
  queryDriver as GET_DRIVER_INFO,
  queryRoute as GET_ROUTE,
  queryLocationsLocal as GET_LOCATION_LOCAL,
  queryLocalTrazability as GET_TRAZABILITY_LOCAL,
  querySysEventos as GET_SYSEVENTOS,
  queryTravelRequest as GET_TRAVEL_REQUEST,
  queryStatus as GET_SYS_STADOS,
  GET_TIME_REPORTS,
  queryTablesPlan,
  queryTablesTrazability,
  queryTablesRemitances
} from './Travel.config'
import './Travel.scss'
import {
  ButtonAdapter,
  ButtonAdapterCheckPoint,
  NotificationReport
} from './../FactoryHome'
import Button from './../../../Componets/Basic/Button/Button'
import { Modal } from './../../../Componets/Basic/Modal/Modal'
import { FormCall } from './../Forms/Form.report-call'
import { FormCheck } from './../Forms/Form.report-check-point'
import { FormGps } from './../Forms/Form.report-gps'
import { FormBascula } from './../Forms/Form.report-bascula'
import { FormDocuments } from './../Forms/Form.report-documents'
import { FormEvents } from './../Forms/Form.report-events'
import { OptionsButton } from './../../../Componets/General/Layout/Layout'
import { useSocket } from '../../../Componets/hook/socket-hook'
import { useNotification } from '../../../Componets/hook/notification-hook'
import { ImageViwerByKeys } from '../../../Componets/Basic/Image/ImageViwer'
import Icon from '../../../Componets/Basic/Icon/Icon.tooltip'
import usePortal from '../../../Componets/hook/portal-hook'
import ReactDOM from 'react-dom'
import { FormBasicProcces } from '../Forms/Form.procces-basic'
import { ClickAwayListener } from '../../../Componets/Basic/ClickAwayListener/Click.away-listener'
import { BooleanView } from '../../../Componets/Basic/Text/BooleanView'
import { useUser } from '../../../Componets/hook/user-hook'
import { MapGranada, markerInterface } from '../../../Componets/General/Map/Map'

import iconCheckPoint from '../../../utils/css/icons/common/pin_yellow.png'
import iconMarkerVehicle from '../../../utils/css/icons/common/pin_red.png'
import { FormRem } from '../Forms/Form.report-rem'
import { useClientApolloGranada } from '../../../Componets/hook/client-apollo-hook'
import { createReportMail } from '../../../utils/utils'
import {
  NotificationError,
  NotificationSucces
} from '../../../Componets/General/FormsUtils/Notification.fom'
import { InstructionsForm } from '../Forms/InstructionsForm'
import { AlertModal } from '../Forms/Alert'

const ID_COMPANY_CUSTOM = [3, 22, 4]
const PREVENT_REPORT: any = {
  0: {},
  3: {
    drop: [3, 4, 5, 7, 8, 10]
  }
  // 4:{
  //     drop:[3,4,5,7,8,10,19]
  // },
}

const GET_TIME_COUNTER = `{
    timeCounter @client    
}`

const parceNumber = (v?: string | number | null) => {
  if (typeof v === 'string' && (+v === 0 || +v)) return +v
  return undefined
}

const substractString = (a: any, b: any) => {
  let tempA = +a
  let tempB = +b
  tempA = tempA ? tempA : 0
  tempB = tempB ? tempB : 0
  return a - b
}

/**
 * @description es un Adaptador que trae los valores del store de origen destino y posicion-actual y se los pasa al componente de Distance
 * @param props
 */
export const Distance_ = React.memo(
  (props: { id: number | string; idTripPlan?: number | string }) => {
    let { id } = props

    const [
      loadDataTripPlan,
      {
        called: calledTripPlan,
        loading: loadingTripPlan,
        data: dataTripPlan,
        refetch: refetchTripPlan
      }
    ] = useLazyQueryToSever({
      query: queryTablesPlan,
      fetchPolicy: 'cache-only'
    })

    const [loadData, { called, loading, data, refetch }] = useLazyQueryToSever({
      query: GET_LOCATION_LOCAL,
      fetchPolicy: 'cache-first'
    })
    const { data: status, error } = useQueryToSever({
      query: GET_SYS_STADOS,
      fetchPolicy: 'cache-only'
    })
    const [completed, setCompleted] = React.useState<boolean>(false)

    const [origin, setOrigin] = React.useState<position>()
    const [destination, setDestination] = React.useState<position>()

    // const { idReport, idviaje} = useSocket();

    // React.useEffect(() => {
    //     if(!idviaje || !props.id) return;
    //     if(idviaje === props.id && refetch) {
    //         refetch();
    //     }
    // }, [idReport, idviaje]);

    React.useEffect(() => {
      if (props.id) loadData({ variables: { id: props.id } })
      if (!calledTripPlan && +(props.id ?? 0))
        loadDataTripPlan({ variables: { idviaje: +(props.id ?? 0) } })
    }, [props.id])

    React.useEffect(() => {
      if (data?.viaje?.idsysestado && status?.sysEstados?.data?.length) {
        let sys = status?.sysEstados?.data.find(
          (item: any) => item.id === data?.viaje?.idsysestado
        )
        setCompleted(sys?.descripcion === 'TERMINADO')
      }
    }, [status, data])

    React.useEffect(() => {
      let pladetalles: any = dataTripPlan?.planviajePorViaje?.data?.length
        ? dataTripPlan?.planviajePorViaje?.data[0]
        : null
      if (!pladetalles?.planviajedetalles?.length) return
      let checkPoint = [...(pladetalles?.planviajedetalles ?? [])][0]
      const { latitud: lat, longitud: lng } = checkPoint?.geolocalizacion ?? {}
      let _origin = checkPoint?.geolocalizacion
        ? { position: { lat, lng } }
        : undefined
      checkPoint = [...(pladetalles?.planviajedetalles ?? [])].reverse()[0]
      const { latitud, longitud } = checkPoint?.geolocalizacion ?? {}
      let _destination = checkPoint?.geolocalizacion
        ? { position: { lat: latitud, lng: longitud } }
        : undefined
      setOrigin(_origin)
      setDestination(_destination)
    }, [JSON.stringify(dataTripPlan?.planviajePorViaje?.data)])

    return (
      <Distance
        origin={origin}
        destination={destination}
        currentPosition={
          completed
            ? dataTripPlan?.planviajePorViaje?.data?.length &&
              dataTripPlan?.planviajePorViaje?.data?.planviajedetalles?.length
              ? {
                  position: [
                    ...(dataTripPlan?.planviajePorViaje?.data[0]
                      ?.planviajedetalles ?? [])
                  ].reverse()[0].geolocalizacion
                }
              : undefined
            : data?.viaje?.geolocalizacion
            ? {
                position: {
                  lat: data.viaje.geolocalizacion.latitud,
                  lng: data.viaje.geolocalizacion.longitud
                }
              }
            : undefined
        }
      />
    )
  }
)

/**
 * @description Calcula el tiempo recorrido
 * @param props
 */
export const TimeTravel = React.memo((props: { id: number | string }) => {
  let { id } = props

  let [time, setTime] = React.useState<string>('')

  const [
    loadDataTrazability,
    {
      called: calledTrazability,
      loading: loadingTrazability,
      data: dataTrazability,
      refetch: refetchTrazability
    }
  ] = useLazyQueryToSever({
    query: queryTablesTrazability,
    fetchPolicy: 'cache-only'
  })

  const [loadTrazability, { data: trazability }] = useLazyQueryToSever({
    query: GET_TRAZABILITY_LOCAL,
    fetchPolicy: 'cache-only'
  })
  const [loadSysEvt, { data: sysEvt }] = useLazyQueryToSever({
    query: GET_SYSEVENTOS,
    fetchPolicy: 'cache-only'
  })
  React.useEffect(() => {
    if (!calledTrazability && +(props.id ?? 0))
      loadDataTrazability({ variables: { id: +(props.id ?? 0) } })
    if (props.id) loadTrazability({ variables: { id: props.id } })
    loadSysEvt()
  }, [props.id])

  React.useEffect(() => {
    //
    let _sysEventos = sysEventos.find
      ? sysEventos.find((item: objectKV) => item.id === 1)
      : undefined
    if (
      dataTrazability?.trazabilidadInfo?.data?.find &&
      sysEvt?.sysEventos?.data.find
    ) {
      let _sysEvt = sysEvt?.sysEventos?.data.find(
        (item: objectKV) => item.descripcion === _sysEventos?.descripcion
      )
      let _trazability = dataTrazability?.trazabilidadInfo?.data?.find(
        (item: objectKV) => item.idsysevento === _sysEvt?.id
      )
      setTime(getTime(_trazability?.fechahora))
    }
  }, [
    trazability,
    sysEvt,
    JSON.stringify(dataTrazability?.trazabilidadInfo?.data)
  ])

  const getTime = (date?: number) => {
    if (!date) return 'NA'
    let _date = new Date().getTime() - date
    let hours = _date / (1000 * 60 * 60)
    let minutes = (+('0.' + (hours.toString().split('.')[1] ?? 0)) * 60)
      .toString()
      .split('.')[0]
    hours = +hours.toString().split('.')[0]
    return `${hours ? hours : '00'}:${minutes ? minutes : '00'}`
  }
  return <TextView label='Tiempo recor.' text={time} />
})

/**
 * @description consulta informacion del vehiculo y muestra en la vista
 * @param props
 */
export const InfoVehicle = React.memo(
  (props: {
    id?: string | number
    style?: any
    onClick?: (v?: any) => any
    emitInfo?: (v: objectKV | string | number) => any
  }) => {
    const [loadData, { called, loading, data }] = useLazyQueryToSever({
      query: GET_VEHICLE_INFO(),
      fetchPolicy: 'cache-first'
    })

    React.useEffect(() => {
      if (props.id && !called) loadData({ variables: { id: props.id } })
    }, [props.id])

    React.useEffect(() => {
      if (props.emitInfo) props.emitInfo(data?.vehiculo)
    }, [data?.vehiculo])

    const _onClick = () => {
      if (props.onClick) props.onClick()
    }

    return (
      <div
        className='info-vehicle info-vehicle-container'
        onClick={_onClick}
        style={props.style}
      >
        {/* con estos dos valores le induco sei ya fue llamdo y no esta cragando muestra valores de otro modo 
            solo muestra la imagen de carga  */}
        {!called || loading ? (
          <LoadContainer />
        ) : (
          <React.Fragment>
            <div className='plate-vehicle'>
              <TextView label='Placa' width={100}>
                <label>{data?.vehiculo?.placa ?? ''}</label>
              </TextView>
            </div>
            <div className='type-vehicle'>
              <TextView label='Tipo' text={data?.vehiculo?.tipo} />
            </div>
            <div className='mark-vehicle'>
              <TextView
                width={100}
                label='Marca'
                text={data?.vehiculo?.marca}
              />
            </div>
            <div className='model-vehicle'>
              <TextView
                width={100}
                label='Modelo'
                text={data?.vehiculo?.modelo}
              />
            </div>
            <div className='color-vehicle'>
              <TextView
                width={200}
                label='Color'
                text={data?.vehiculo?.color}
              />
            </div>
            <div className='owner-vehicle'>
              <TextView
                label='Propietario'
                text={data?.vehiculo?.nombrepropietario}
              />
            </div>
            <div className='owner-phone-vehicle'>
              <TextView
                width={100}
                label='Telefono'
                text={data?.vehiculo?.telefonopropietario}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
)
interface contact {
  name: string
  phone: string
}

const Phones = React.memo((props: { phone?: Array<any> }) => {
  const { phone } = props
  return (
    <React.Fragment>
      {phone?.map((item: string, index: number) => (
        <li key={index}>
          <a href={`tel:${item}`}>{item}</a>
        </li>
      ))}
    </React.Fragment>
  )
})

const Contacts = React.memo((props: { contacts?: Array<any> }) => {
  const { contacts } = props
  return (
    <React.Fragment>
      {contacts?.map((item: contact, index: number) => (
        <li key={`${index}-contacts`}>
          {item.name}
          <a href={`tel:${item.phone}`}>{item.phone}</a>
        </li>
      ))}
    </React.Fragment>
  )
})

export const ModalDriver = React.memo(
  (props: {
    data?: objectKV
    visible?: boolean
    clickOutside: (v?: any) => any
  }) => {
    const [phone, setPhone] = React.useState<Array<string>>()
    const [contacts, setContacts] = React.useState<Array<contact>>()

    React.useEffect(() => {
      let contacts: Array<string> = (props?.data?.contactos ?? '').split(',')
      let tempContacts: Array<contact> = []
      contacts.forEach((item: string) => {
        let tempItem = item.split(':')
        if (
          !tempItem[0] ||
          !tempItem[1] ||
          typeof tempItem[1] !== 'string' ||
          typeof tempItem[0] !== 'string'
        )
          return
        tempContacts.push({ name: tempItem[0], phone: tempItem[1] })
      })
      setContacts([...tempContacts])
      let _phones = (props?.data?.telefono ?? '').split(',')
      setPhone([..._phones])
    }, [props.data])

    return (
      <Modal
        visible={!!props.visible}
        clickOutside={props.clickOutside}
        backGroundColor='#3838383b'
      >
        <div className='driver-modal-container'>
          <div className='driver-modal-container__image'>
            <ImageViwerByKeys
              uri={props?.data?.foto}
              className={'image-driver'}
            />
          </div>
          <div className='driver-modal-container__info'>
            <div>
              <TextView
                label='Nombre Conductor'
                text={props?.data?.nombre ?? 'NA'}
              />
            </div>
            <div>
              <TextView
                label='Documento'
                text={props?.data?.numerodocumento ?? 'NA'}
              />
            </div>
            <div>
              <TextView
                label='Teléfonos'
                height={
                  25 + ((phone?.length ?? 0) + (contacts?.length ?? 0)) * 20
                }
              >
                <ul>
                  <Phones phone={phone} />
                  <Contacts contacts={contacts} />
                </ul>
              </TextView>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
)

export const ModalVehicle = React.memo(
  (props: {
    id?: string | number
    visible?: boolean
    clickOutside: (v?: any) => any
  }) => {
    const [data, setData] = React.useState<{ picture: string }>()

    const getInfo = (v: any) => {
      if (v) {
        setData({
          picture: v.foto ?? undefined
        })
      }
    }

    return (
      <Modal
        visible={!!props.visible}
        clickOutside={props.clickOutside}
        backGroundColor='#3838383b'
      >
        <div className='modal-vehicle'>
          <ImageViwerByKeys
            className='modal-vehicle__image'
            uri={data?.picture}
          />
          <InfoVehicle id={props.id} emitInfo={getInfo} />
        </div>
      </Modal>
    )
  }
)
/**
 * @description consulta la informacion general del viaje y las muetra en pantalla
 * @param props
 */
export const InfoGeneral = React.memo((props: { id?: string | number }) => {
  const [loadData, { called, loading, data }] = useLazyQueryToSever({
    query: GET_GENERAL_INFO,
    fetchPolicy: 'cache-first'
  })
  const [
    loadDataRemitances,
    {
      called: calledRemitances,
      loading: loadingRemitances,
      data: dataRemitances,
      refetch: refetchRemitances
    }
  ] = useLazyQueryToSever({
    query: queryTablesRemitances,
    fetchPolicy: 'cache-only'
  })

  React.useEffect(() => {
    if (props.id && !called) loadData({ variables: { id: props.id } })
    if (!calledRemitances && +(props.id ?? 0))
      loadDataRemitances({ variables: { id: +(props.id ?? 0) } })
  }, [props.id])

  const observation = React.useMemo(() => {
    return (
      (dataRemitances?.remesasInfo?.data ?? [])
        ?.filter((item: objectKV) => item.observaciones)
        ?.map((item: objectKV) => `${item.numeroremesa}:${item.observaciones}`)
        ?.join('  • ') ?? ''
    )
  }, [dataRemitances?.remesasInfo?.data])

  return (
    <div className='info-general' style={{ height: 134 }}>
      {!called || loading ? (
        <LoadContainer />
      ) : (
        <React.Fragment>
          <div className='trailer over-flow-hidden'>
            {/* replazar */}
            <TextView
              label='Trailer'
              text={data?.viaje?.trailerplaca ?? 'NA'}
            />
          </div>
          <div className='n-travel over-flow-hidden'>
            <TextView label='N° Viaje' text={data?.viaje?.id ?? 'NA'} />
            {/* <TextView label="N° Viaje" text={data?.viaje?.idsysestado ?? "NA"}/> */}
          </div>
          <div className='generator-carga over-flow-hidden'>
            <TextView
              showCopy
              label='Generador'
              text={data?.viaje?.cliente ?? 'NA'}
            />
          </div>
          <div className='modality over-flow-hidden'>
            <TextView
              label='Modalidad'
              text={data?.viaje?.modalidadnombre ?? 'NA'}
            />
          </div>
          <div className='transportation-company over-flow-hidden'>
            <TextView
              label='Transportadora'
              text={data?.viaje?.empresatransportadora ?? 'NA'}
            />
          </div>
          <div className='n-manifest over-flow-hidden'>
            <TextView
              label='N° Manifiesto'
              text={data?.viaje?.numero ?? 'NA'}
            />
          </div>
          <div className='route over-flow-hidden'>
            <TextView label='Ruta' text={data?.viaje?.ruta ?? 'NA'} />
          </div>
          {/* 
                remplazar
                <div className="insurance over-flow-hidden">
                    <TextView label="Aseguradora" text={data?.viaje?.ase ?? "NA"} />
                </div> */}
          <div className='merchandise over-flow-hidden'>
            <TextView
              label='Contenedor(s)'
              text={data?.viaje?.numerocontenedor ?? 'NA'}
            />
          </div>
          <div className='risk over-flow-hidden'>
            {/* {data?.viaje?.devolviendocontenedor?<BooleanView label="Pend. devol. cont." messageFalse="Pendiente por devolver contenedor" iconFalse="icon-check" value={!data?.viaje?.devolviendocontenedor} />:null}
                    <BooleanView label="Vía libre" value={data?.viaje?.indicadorvialibre} />
                    <TextView label="Alto Riesgo">
                        <i className={` icon-alerta ${data?.viaje?.indicadoraltoriesgo?"high-risk":"low-risk"}`} />
                    </TextView>
                    <BooleanView label="Trans. solar" messageTrue="Con tránsito solar" messageFalse="Sin tránsito solar" value={data?.viaje?.indicadortransitosolar} /> */}
          </div>
          <div className='travel-percentage over-flow-hidden'>
            <TextView height={40} label='% Recorrido'>
              {![4, 7].includes(data?.viaje?.idsysestado) ? (
                <Distance_
                  id={data?.viaje?.id}
                  idTripPlan={data?.viaje?.idplanviaje}
                />
              ) : (
                <div></div>
              )}
            </TextView>
          </div>
          <div className='time-trip over-flow-hidden'>
            {![4, 7].includes(data?.viaje?.idsysestado) ? (
              <TimeTravel
                key={`${data?.viaje?.id}-map-distance`}
                id={data?.viaje?.id}
              />
            ) : (
              <div></div>
            )}
          </div>
          <div className='restrictions over-flow-hidden'>
            {/* remplazar */}
            <TextView
              height={50}
              showCopy
              numLines={2}
              label='Observaciones'
              text={observation}
            />
            <Instructions id={data?.viaje?.id} />
          </div>
          {/* <div className="back-container over-flow-hidden">
                    <TextView label="Devolver Cont" text={"NA"} />
                </div> */}
          <div style={{ gridColumn: '1/-1' }}></div>
        </React.Fragment>
      )}
    </div>
  )
})

/**
 * @description Es un componente adaptador crea un circulo de un color y muetra un label
 * @param props
 */
export const ColorInfo = React.memo(
  (props: { color: string; label: string }) => {
    return (
      <div className='color-info'>
        <div className='color-info-color' style={{ background: props.color }}>
          {props.label}
        </div>
      </div>
    )
  }
)

/**
 * @description renderiza los componente de informacion y botones de reportes
 */
export const InfoReports = React.memo((props: { id?: number | string }) => {
  const [loadData, { called, loading, data }] = useLazyQueryToSever({
    query: GET_LOCATION_LOCAL,
    fetchPolicy: 'cache-only'
  })

  let history = useHistory()
  const goToGallery = () => {
    let _id = +(props.id ?? 0)
    if (_id) history.push(`/Home/Gallery/${props.id}`)
  }

  React.useEffect(() => {
    let _id = +(props.id ?? 0)
    if (_id) loadData({ variables: { id: _id } })
  }, [props.id])

  const _openAlert = () => {}

  return (
    <div className='info-report'>
      <div className='info-report__report-button'>
        {data?.viaje?.idproximosysevento ? (
          <Button
            className='button-report'
            style={{ backgroundColor: data?.viaje?.retenerviaje ? 'red' : '' }}
          >
            <Icon
              message={
                data?.viaje?.retenerviaje ? 'Hay que retener el viaje' : ''
              }
              className='icon-alerta'
            />
          </Button>
        ) : null}
        <Button className='button-report' onClick={goToGallery}>
          <Icon message={'Ir a galeria'} className='icon-fotos-cargadas' />
        </Button>
      </div>
      <div className='info-report__colors'>
        {colorAlerts.map((item: objectKV) => (
          <ColorInfo key={item.time} color={item.color} label={item.label} />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          gap: 10,
          width: '100%',
          gridColumn: '5/8',
          marginTop: 4
        }}
      >
        {data?.viaje?.devolviendocontenedor ? (
          <BooleanView
            label='Pend. devol. cont.'
            messageFalse='Pendiente por devolver contenedor'
            iconFalse='icon-check'
            value={!data?.viaje?.devolviendocontenedor}
          />
        ) : null}
        <BooleanView label='Vía libre' value={data?.viaje?.indicadorvialibre} />
        <BooleanView
          label='Alto Riesgo'
          iconTrue='icon-alerta'
          iconFalse='icon-alerta'
          messageFalse='Mercancía de alto riesgo'
          messageTrue='Mercancía normal'
          value={!data?.viaje?.indicadoraltoriesgo}
        />
        <BooleanView
          label='Trans. solar'
          messageTrue='Con tránsito solar'
          messageFalse='Sin tránsito solar'
          value={data?.viaje?.indicadortransitosolar}
        />
      </div>
      <div className='info-report__buttons'>
        <ButtonsReports key='reports' {...props} />
      </div>
    </div>
  )
})

/**
 * etse componente renderiza los botones
 * @param props
 */
export const ButtonsReportsAlerts = React.memo((props: any) => {
  const { user } = useUser()
  const [times, setTimes] = React.useState<Array<number | undefined>>([
    //0 tiempo de reporte de puesto de control
    //1 tiempo de reporte de llamada
    //2 tiempo de reporte de gps
  ])
  const { data } = props

  React.useEffect(() => {
    let _value = data?.viaje
    if (_value)
      setTimes([
        parceNumber(_value?.fechahoraproximopuestocontrol),
        parceNumber(_value?.fechahoraproximallamada),
        parceNumber(_value?.fechahoraproximogps)
      ])
  }, [data])

  const _idcompany = React.useMemo(() => {
    return user?.idcompany ?? 0
  }, [user?.idcompany])

  const showChildren = React.useMemo(() => {
    return (
      PREVENT_REPORT[`${_idcompany}`]?.drop?.includes(3) ||
      PREVENT_REPORT[`${_idcompany}`]?.drop?.includes(4) ||
      PREVENT_REPORT[`${_idcompany}`]?.drop?.includes(5) ||
      PREVENT_REPORT[`${_idcompany}`]?.drop?.includes(7) ||
      PREVENT_REPORT[`${_idcompany}`]?.drop?.includes(8) ||
      PREVENT_REPORT[`${_idcompany}`]?.drop?.includes(10) 
    )
  }, [PREVENT_REPORT, _idcompany, JSON.stringify(data)])

  if (showChildren) return props.children
  return (
    <>
      <InitTravelRem
        {...props}
        hiddenEvent
        key='ButtonsReportsAlerts1'
        time={times[0]}
        nextSysEvent={data?.viaje?.idproximosysevento}
      />

      <CheckPointReport
        {...props}
        hiddenEvent
        key='ButtonsReportsAlerts2'
        time={times[0]}
        nextSysEvent={data?.viaje?.idproximosysevento}
      />

      <EndTravelRem
        {...props}
        hiddenEvent
        key='ButtonsReportsAlerts3'
        time={times[0]}
        nextSysEvent={data?.viaje?.idproximosysevento}
      />

      {/* <CheckPointReport
        {...props}
        endTravel
        key='ButtonsReportsAlerts4'
        hiddenEvent
        time={0}
        nextSysEvent={19}
      /> */}
    </>
  )
})

/**
 * etse componente renderiza los botones
 * @param props
 */
export const ButtonsReports = React.memo((props: { id?: number | string }) => {
  const [times, setTimes] = React.useState<Array<number | undefined>>([
    //0 tiempo de reporte de puesto de control
    //1 tiempo de reporte de llamada
    //2 tiempo de reporte de gps
  ])
  const [loadData, { data, loading, error, refetch }] = useLazyQueryToSever({
    query: GET_TIME_REPORTS,
    fetchPolicy: 'cache-first'
  })
  const [loadDataServer, {}] = useLazyQueryToSever({
    query: GET_TIME_REPORTS,
    fetchPolicy: 'network-only'
  })

  const { user } = useUser()

  React.useEffect(() => {
    if (props.id) loadData({ variables: { id: props.id } })
  }, [props.id])

  React.useEffect(() => {
    let _value = data?.viaje
    if (_value)
      setTimes([
        parceNumber(_value?.fechahoraproximopuestocontrol),
        parceNumber(_value?.fechahoraproximallamada),
        parceNumber(_value?.fechahoraproximogps)
      ])
  }, [data])

  const updateTravel = () => {
    let _id = +(props.id ?? 0)
    if (_id) loadDataServer({ variables: { id: _id } })
  }

  console.log(9999990, PREVENT_REPORT, user?.idcompany)

  /**
   * Sysevetos
   * 4 cancelado
   * 7 terminado
   */
  return !data?.viaje?.idsysestado ? null : ![4, 7].includes(
      data?.viaje?.idsysestado
    ) ? (
    <React.Fragment>
      {PREVENT_REPORT[`${user?.idcompany ?? 0}`]?.drop?.includes(3) ||
      PREVENT_REPORT[`${user?.idcompany ?? 0}`]?.drop?.includes(4) ||
      PREVENT_REPORT[`${user?.idcompany ?? 0}`]?.drop?.includes(5) ? null : (
        <InitTravelRem
          {...props}
          key='ButtonsReports1'
          time={times[0]}
          nextSysEvent={data?.viaje?.idproximosysevento}
        />
      )}

      {(PREVENT_REPORT[`${user?.idcompany ?? 0}`]?.drop?.includes(10) ||
        data?.viaje?.idproximosysevento == 19) &&
      ![18].includes(data?.viaje?.idproximosysevento) ? null : (
        <CheckPointReport
          {...props}
          key='ButtonsReports2'
          time={times[0]}
          nextSysEvent={data?.viaje?.idproximosysevento}
        />
      )}

      {PREVENT_REPORT[`${user?.idcompany ?? 0}`]?.drop?.includes(7) ||
      PREVENT_REPORT[`${user?.idcompany ?? 0}`]?.drop?.includes(8) ? null : (
        <EndTravelRem
          {...props}
          key='ButtonsReports3'
          time={times[0]}
          nextSysEvent={data?.viaje?.idproximosysevento}
        />
      )}

      <PhoneReport {...props} time={times[1]} />
      <GpsReport {...props} time={times[2]} />
      {PREVENT_REPORT[`${user?.idcompany ?? 0}`]?.drop?.includes(19) ? null : (
        <CheckPointReport
          {...props}
          key='ButtonsReports4'
          time={0}
          nextSysEvent={19}
        />
      )}
      <EventReport {...props} />
      <BasculaReport {...props} />
      <DocumentReport {...props} />
      <AlertModal {...(data?.viaje ?? {})} update={updateTravel} />
      <ButtonProcces {...props} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      {user?.roll === 3 ? (
        <ButtonProccesAdapter
          id={props.id}
          sysEvent={
            data?.viaje?.idsysestado === 7
              ? 30
              : data?.viaje?.idsysestado === 4
              ? 31
              : 0
          }
          label=''
          className='return-procces-button'
          title={
            data?.viaje?.idsysestado === 7
              ? 'Devolver terminar viaje'
              : data?.viaje?.idsysestado === 4
              ? 'Devolver cancelar'
              : ''
          }
        >
          <div
            className={`buttons-report buttons-report-static content-center`}
          >
            <Icon
              className='icon-baseline-reply-24px'
              message={
                data?.viaje?.idsysestado === 7
                  ? 'Devolver terminar'
                  : data?.viaje?.idsysestado === 4
                  ? 'Devolver cancelar'
                  : ''
              }
            />
          </div>
        </ButtonProccesAdapter>
      ) : null}
      <ExcelDownload showButton {...props} />
    </React.Fragment>
  )
})

/**
 * bOTON DE REPORTE DE LLAMADA
 * @param props
 */
export const PhoneReport = React.memo(
  (props: { id?: number | string; time?: number; isVisible?: any }) => {
    const [visible, setVisible] = React.useState<boolean>(false)

    const close = () => {
      if (typeof props.isVisible == 'function') props.isVisible(false)
      setVisible(false)
    }
    const open = () => {
      if (typeof props.isVisible == 'function') props.isVisible(true)
      setVisible(true)
    }

    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <FormCall {...props} close={close} />
        </Modal>
        <ButtonAdapter
          name='Reporte llmada'
          className='buttons-report'
          icon='icon-telefono'
          eventClick={open}
          time={props.time}
        />
      </React.Fragment>
    )
  }
)

/**
 * BOTON PARA LAS INSTRUCIONES
 * @param props
 */
export const Instructions = React.memo(
  (props: { id?: number | string; time?: number }) => {
    const [visible, setVisible] = React.useState<boolean>(false)
    const [opened, setOpened] = React.useState<boolean>(false)

    const [
      loadData,
      { data: instructionsRes, refetch, loading, called }
    ] = useLazyQueryToSever({
      query: `
    query obs($filters:[FilterInput]){
        observacionesviaje(filters:$filters,utilsValue:"0",orders:[{field:"id",type:DESC}],pagination:{fields:1,page:1}){
          data{
            id
            idusuario
            idviaje
            usuarionombre
            ultimovisto
            observacion
            fecha
          }
        }
      }
    `,
      fetchPolicy: 'network-only'
    })

    React.useEffect(() => {
      if (props.id) {
        loadData({
          variables: { filters: [{ field: 'idviaje', value: props.id + '' }] }
        })
      }
    }, [props.id])

    const areThereNew = React.useMemo(() => {
      if (!instructionsRes?.observacionesviaje?.data?.length) return false
      let temp = instructionsRes?.observacionesviaje?.data[0]
      return temp.ultimovisto < temp.id
    }, [instructionsRes])

    const close = () => {
      setVisible(false)
      setOpened(true)
    }
    const open = () => setVisible(true)
    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <InstructionsForm data={[]} {...props} close={close} />
        </Modal>
        <Button
          className={
            areThereNew && !opened
              ? 'botton-info-instructions'
              : 'botton-info-instructions-gray'
          }
          onClick={open}
        >
          Inst.comercial
        </Button>
      </React.Fragment>
    )
  }
)

/**
 * BOTON DE REPORTE  de documentos
 * @param props
 */
export const DocumentReport = React.memo((props: { id?: number | string }) => {
  const [visible, setVisible] = React.useState<boolean>(false)

  const close = () => setVisible(false)
  const open = () => setVisible(true)
  return (
    <React.Fragment>
      <Modal visible={visible} backGroundColor='#00000000' clickOutside={close}>
        <FormDocuments {...props} close={close} />
      </Modal>
      <Button className={`buttons-report-static buttons-report`} onClick={open}>
        <i className='icon-documento' />
      </Button>
    </React.Fragment>
  )
})

export const ButtonProcces = React.memo((props: { id?: number | string }) => {
  const [_open, setOpen] = React.useState<boolean>(false)
  const [top, setTop] = React.useState<number>(0)
  const [boundClientRect, setBoundClientRect] = React.useState<objectKV | null>(
    null
  )

  const el: any = React.useRef(null)

  React.useEffect(() => {
    setBoundClientRect(
      el?.current?.getBoundingClientRect
        ? el?.current?.getBoundingClientRect()
        : null
    )
  }, [el?.current])

  React.useEffect(() => {
    let _newTop = (boundClientRect?.top ?? 0) + (boundClientRect?.height ?? 0)
    if (top !== _newTop) setTop(_newTop)
  }, [boundClientRect])

  const open = React.useCallback(() => {
    _assingOpen(true)
  }, [_open])

  const _assingOpen = React.useCallback(
    (v: boolean) => {
      setOpen(v)
    },
    [_open, setOpen]
  )

  return (
    <React.Fragment>
      <Button
        ref={el}
        className={`buttons-procces buttons-report`}
        onClickCapture={open}
      >
        <i className='icon-menu' />
      </Button>
      <OptionsProcces
        {...props}
        hover={_open}
        setHover={_assingOpen}
        top={top}
      />
    </React.Fragment>
  )
})

export const OptionsProcces = React.memo(
  (props: {
    id?: number | string
    setHover: (v: boolean) => any
    hover: boolean
    top: number
  }) => {
    const el = usePortal('select-root')

    const { user } = useUser()

    const [loadData, { called, loading, data }] = useLazyQueryToSever({
      query: GET_LOCATION_LOCAL,
      fetchPolicy: 'cache-only'
    })

    React.useEffect(() => {
      let _id = +(props.id ?? 0)
      if (_id) loadData({ variables: { id: _id } })
    }, [])

    const close = React.useCallback(() => {
      props.setHover(false)
    }, [props.hover])

    return ReactDOM.createPortal(
      <div
        className='options-button-procces'
        style={{ height: !props.hover ? 0 : '', top: props.top - 5 }}
      >
        <ClickAwayListener clickOutside={close}>
          {data?.viaje?.idsysestado === 2 ? (
            <div />
          ) : (
            <ButtonProccesAdapter
              key='Pausar'
              close={close}
              id={props.id}
              sysEvent={14}
              label='Pausar'
              title='Pausar viaje'
            />
          )}
          <React.Fragment>
            {user?.roll === 3
              ? [
                  <ButtonProccesAdapter
                    key='Cancelar'
                    close={close}
                    id={props.id}
                    sysEvent={13}
                    label='Cancelar'
                    title='Cancelar viaje'
                  />,
                  data?.viaje?.indicadorvialibre ? (
                    <ButtonProccesAdapter
                      key='ViaLibreReport'
                      close={close}
                      id={props.id}
                      sysEvent={29}
                      label='Quitar vía libre'
                      title='Quitar vía libre'
                    />
                  ) : (
                    <ButtonProccesAdapter
                      key='ViaLibreReport'
                      close={close}
                      id={props.id}
                      sysEvent={28}
                      label='Vía libre'
                      title='Vía libre'
                    />
                  )
                ]
              : []}

            {data?.viaje?.indicadortransitosolar ? (
              <ButtonProccesAdapter
                key='transitosolarReport1'
                close={close}
                id={props.id}
                sysEvent={35}
                label='Quitar tránsito solar'
                title='Quitar tránsito solar'
              />
            ) : (
              <ButtonProccesAdapter
                key='transitosolarReport2'
                close={close}
                id={props.id}
                sysEvent={34}
                label='Tránsito solar'
                title='Tránsito solar'
              />
            )}

            <CheckRem
              key='transitosolarReport3'
              // close={close}
              id={props.id}
              // sysEvent={36}
              label='Cumplir remesa'
              // title="Cumplir remesa"
            />
          </React.Fragment>
          {data?.viaje?.retenerviaje ? (
            <ButtonProccesAdapter
              key='ViaLibre'
              close={close}
              id={props.id}
              sysEvent={21}
              label='Continuar viaje'
              title='Continuar viaje'
            />
          ) : (
            <ButtonProccesAdapter
              key='Detener'
              close={close}
              id={props.id}
              sysEvent={20}
              label='Detener vehículo'
              title='Detener Viaje'
            />
          )}
          <ExcelDownload id={props.id} />
          <SendMailToClient id={props.id} />
          <ButtonProccesAdapter
            key='Devolviendo'
            close={close}
            id={props.id}
            sysEvent={33}
            label='Pend. devol. cont.'
            title='Pend. devol. cont.'
          />
          {/* <GoToGallery id={props.id}/> */}
        </ClickAwayListener>
      </div>,
      el
    )
  }
)

const filterTraza = (traza: Array<any>, rem: any) => {
  return traza.filter((item: any) => {
    if ([3, 4, 5, 7, 8].includes(item.idsysevento ?? 0)) {
      if (
        (rem.syseventosmail ?? []).includes(item.idsysevento) &&
        (item.idsremesas ?? []).includes(rem.id ?? 0)
      )
        return true
      return false
    } else if (item.idsysevento) {
      if ((rem.syseventosmail ?? []).includes(item.idsysevento)) return true
      return false
    } else if (item.idevento) {
      if ((rem.eventosmail ?? []).includes(item.idevento)) return true
      return false
    } else return false
  })
}

const _fields = [
  { field: 'origin', path: 'origin', label: 'Origen: ', drop: [] },
  { field: 'destination', path: 'destination', label: 'Destino: ', drop: [] },
  {
    field: 'docClient',
    path: 'docClient',
    label: 'Documento cliente: ',
    drop: []
  },
  { field: 'travelId', path: 'travelId', label: 'Manifiesto: ', drop: [] },
  {
    field: 'travelState',
    path: 'travelState',
    label: 'Estado de viaje: ',
    drop: []
  },
  { field: 'container', path: 'container', label: 'Contenedor: ', drop: [] },
  {
    field: 'remittancesId',
    path: 'remittancesId',
    label: 'Remesa: ',
    drop: []
  },
  {
    field: 'remittancesState',
    path: 'remittancesState',
    label: 'Estado remesa: ',
    drop: []
  },
  { field: 'nameDriver', path: 'nameDriver', label: 'Nombre :', drop: [] },
  { field: 'idDriver', path: 'idDriver', label: 'Cedula :', drop: [] }
]

const SendMailToClient = React.memo((props: { id?: number | string }) => {
  const [
    loadData,
    { called, error, loading, data: dataRequest, fetchMore: refetchTravel }
  ] = useLazyQueryToSever({
    query: GET_TRAVEL_REQUEST,
    fetchPolicy: 'cache-only'
  })
  const [
    loadDataTrazability,
    {
      called: calledTrazability,
      loading: loadingTrazability,
      data: dataTrazability,
      refetch: refetchTrazability
    }
  ] = useLazyQueryToSever({
    query: queryTablesTrazability,
    fetchPolicy: 'cache-only'
  })
  const [
    loadDataRem,
    {
      called: calledRem,
      loading: loadingRem,
      data: dataRem,
      refetch: refetchRem
    }
  ] = useLazyQueryToSever({
    query: queryTablesRemitances,
    fetchPolicy: 'cache-only'
  })
  const { user } = useUser()
  const [sending, setSending] = React.useState<boolean>(false)
  const { addNotification } = useNotification()

  React.useEffect(() => {
    if (+(props.id ?? 0)) loadData({ variables: { id: +(props.id ?? 0) } })
  }, [props.id])

  React.useEffect(() => {
    let _dataRequest = dataRequest
    if (_dataRequest?.viaje && !error) {
      if (!calledTrazability && +(_dataRequest?.viaje?.id ?? 0)) {
        loadDataRem({ variables: { id: +(_dataRequest.viaje.id ?? 0) } })
        loadDataTrazability({
          variables: { id: +(_dataRequest.viaje.id ?? 0) }
        })
      }
    }
  }, [dataRequest, error])

  const sendMail = React.useCallback(() => {
    let data = dataRequest?.viaje
    setSending(true)
    Promise.all(
      (dataRem?.remesasInfo?.data ?? []).map(async (item: any) => {
        let traza = null
        console.log(item)
        if (!(item?.eventosmail?.length || item?.syseventosmail?.length)) {
          // return addNotification({ element: <NotificationError message={`No se encontro trazabilidad para mostrar al cliente, recuerde registrar los eventos`} />, delay: 3000 });
          let temptraza = JSON.parse(
            JSON.stringify(dataTrazability?.trazabilidadInfo?.data ?? [])
          )
          console.log(7777, temptraza.reverse()[0])
          traza = [temptraza.reverse()[0]]
        } else {
          traza = filterTraza(
            dataTrazability?.trazabilidadInfo?.data ?? [],
            item
          )
        }
        if (!traza?.length)
          return addNotification({
            element: (
              <NotificationError
                message={`No se encontro trazabilidad para mostrar al cliente`}
              />
            ),
            delay: 3000
          })
        let report = createReportMail(
          {
            trazability: traza,
            route: data?.ruta,
            origin: data?.origen,
            destination: data?.destino,
            carId: data?.placa,
            company: data?.empresatransportadora,
            client: item?.clienteempresatransportadora,
            travelId: data?.numero,
            remittancesId: item?.numeroremesa,
            travelState: data?.estado,
            remittancesState: item?.estado,
            container: item?.numerocontenedor,
            docClient: item?.documentocliente,
            nameDriver: data.nombreconductor,
            idDriver: data.numerodocumentoconductor
          },
          _fields.filter((item: any) => !item.drop.includes(user.idcompany))
        )
        if (!user.token || !user.idcompany || !process.env.REACT_APP_ID)
          return new Error('No se pudo extraer la url')

        let adressMail = item.correoreporte?.split(',') ?? []
        adressMail = adressMail.filter((item: any) => !!item)
        // adressMail.push("juanbrand@erpsolucionesweb.com");
        if (!adressMail?.length)
          return addNotification({
            element: (
              <NotificationError
                message={`El correo no puede ser enviado, recuerde registrar los correos para el cliente`}
              />
            ),
            delay: 3000
          })

        let headers = new Headers()

        headers.append('Authorization', user.token)
        headers.append('idaplicacion', process.env.REACT_APP_ID + '')
        headers.append('idempresa', user.idcompany.toString())
        headers.append('Content-Type', 'application/json')
        headers.append('Access-Control-Allow-Origin', '*')
        // headers.append("Accept", "application/json, text/plain, */*");

        await fetch(
          'https://jh1tljbjzk.execute-api.us-west-1.amazonaws.com/dev/sendmail',
          {
            method: 'POST',
            headers,
            body: JSON.stringify({
              sender: 'trazabilidad@erpsolucionesweb.com',
              recipient: [...adressMail],
              subject: `Trazabilidad de viaje ${item?.documentocliente}`,
              bHtml: report
            })
          }
        )
          .then((res: any) => {
            // console.log("::::::::::::::::",res)
            if (addNotification) {
              addNotification({
                element: (
                  <NotificationSucces
                    message={`Se envio correo a ${item?.numeroremesa} ${item?.clienteempresatransportadora}`}
                  />
                ),
                delay: 3000
              })
            }
          })
          .catch((err: any) => {
            // console.log("::::::::::::::::",err)
            // if(addNotification) addNotification({element:<NotificationError message={`ERROR: al enviar correo a ${item?.numeroremesa} ${item?.clienteempresatransportadora}`} />,delay:3000});
          })
      })
    ).finally(() => {
      setSending(false)
    })
  }, [
    JSON.stringify(dataRequest),
    JSON.stringify(dataTrazability),
    JSON.stringify(dataRem),
    user
  ])
  return (
    <div onClickCapture={sendMail} className='options-button-procces__item'>
      {sending ? 'Enviando' : 'Enviar trazabilidad mail'}
    </div>
  )
})

const GoToGallery = React.memo((props: { id?: number | string }) => {
  let history = useHistory()
  const goToGallery = () => {
    history.push(`/Home/Gallery/${props.id}`)
  }
  return (
    <div onClickCapture={goToGallery} className='options-button-procces__item'>
      {'Ir a galeria'}
    </div>
  )
})

const ExcelDownload = React.memo(
  (props: { id?: number | string; showButton?: boolean }) => {
    const { user } = useUser()

    const [uploading, setUploading] = React.useState<boolean>(false)

    const _open = React.useCallback(() => {
      if (!props.id) return
      setUploading(true)
      let headers = new Headers()
      headers.append('Authorization', user?.token ?? '')
      headers.append('idapp', '12')
      headers.append('idempresa', '' + (user.idcompany ?? ''))
      headers.append('Content-Type', 'application/json')
      headers.append('Access-Control-Allow-Origin', '*')
      let body = JSON.stringify({
        filters: [{ field: 'idviaje', value: props.id }],
        fields: [
          'lugar',
          'fecha',
          'remesas',
          'usuario',
          'evento',
          'orden',
          'sysevento',
          'puestocontrol',
          'novedad'
        ],
        idquery: 1
      })
      let uri = `${process.env.REACT_APP_URL}/api/v1/filesDoc`
      fetch(uri, { method: 'POST', headers, body })
        .then((res: any) => res.blob())
        .then(res => res)
        .then(blob => URL.createObjectURL(blob))
        .then(href => {
          Object.assign(document.createElement('a'), {
            href,
            download: `trazabilidad_${new Date()
              .toLocaleString()
              .replace(/(\s)|(,)|(:)/gi, '_')
              .split('/')
              .join('_')}.xlsx`
          }).click()
        })
        .catch(console.log)
        .finally(() => {
          setUploading(false)
        })
    }, [props.id])

    return props.showButton ? (
      <Button
        className={`buttons-report-static buttons-report`}
        onClick={!uploading ? _open : console.log}
      >
        <Icon
          message='Descargar la trazabilidad como excel'
          className='icon-Excel'
        />
      </Button>
    ) : (
      <div
        onClickCapture={!uploading ? _open : console.log}
        className='options-button-procces__item'
      >
        {uploading ? 'Descargando...' : 'exportar trazabilidad excel'}
      </div>
    )
  }
)

export const ButtonProccesAdapter = React.memo(
  (props: {
    close?: (v?: any) => any
    sysEvent: number
    title: string
    label: string
    className?: string
    children?: JSX.Element[] | JSX.Element
    id?: number | string
  }) => {
    const { close: closeOptions, sysEvent, title, label, children } = props

    const [visible, setVisible] = React.useState<boolean>(false)

    const close = React.useCallback(() => {
      setVisible(false)
    }, [])

    const open = React.useCallback(() => {
      if (closeOptions) closeOptions()
      setVisible(true)
    }, [closeOptions])

    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <FormBasicProcces
            sysEvent={sysEvent}
            title={title}
            close={close}
            id={props.id}
          />
        </Modal>
        {children ? (
          <div className={props.className} onClickCapture={open}>
            {children}
          </div>
        ) : (
          <div onClickCapture={open} className='options-button-procces__item'>
            {label}
          </div>
        )}
      </React.Fragment>
    )
  }
)

/**
 * boton de reporte de eventos
 * @param props
 */
export const EventReport = React.memo((props: { id?: number | string }) => {
  const [visible, setVisible] = React.useState<boolean>(false)

  const close = () => setVisible(false)
  const open = () => setVisible(true)
  return (
    <React.Fragment>
      <Modal visible={visible} backGroundColor='#00000000' clickOutside={close}>
        <FormEvents {...props} close={close} />
      </Modal>
      <Button className={`buttons-report-static buttons-report`} onClick={open}>
        <i className='icon-tabla-archivo' />
      </Button>
    </React.Fragment>
  )
})

/**
 * boton de reporte de cumplido
 * @param props
 */
export const CheckRem = React.memo(
  (props: {
    id?: number | string
    nextSysEvent?: number
    time?: number
    label?: string
  }) => {
    const [visible, setVisible] = React.useState<boolean>(false)
    const [
      loadDataRemittances,
      {
        data: dataRemittances,
        loading: loadinRemittances,
        error: errorRemittances
      }
    ] = useLazyQueryToSever({
      query: queryTablesRemitances,
      fetchPolicy: 'cache-only'
    })

    const init = {}

    const reducer = (state: objectKV, action: action) => {
      switch (action.type) {
        case 'SET_DATA':
          return Object.assign({}, state, action.value)
        default:
          throw new Error()
      }
    }

    const [state, dispatch] = React.useReducer(reducer, init)

    React.useEffect(() => {
      if (props.id) {
        loadDataRemittances({ variables: { id: props.id } })
      }
    }, [props.id])

    const close = () => setVisible(false)
    const open = () => setVisible(true)
    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <FormRem
            {...props}
            sysEvent={{ id: 36, descripcion: 'Cumplir remesa' }}
            close={close}
          />
        </Modal>
        {
          <div onClickCapture={open} className='options-button-procces__item'>
            {props.label}
          </div>
        }
      </React.Fragment>
    )
  }
)

/**
 * boton de reporte de cargue
 * @param props
 */
export const InitTravelRem = React.memo(
  (props: {
    id?: number | string
    nextSysEvent?: number
    time?: number
    hiddenEvent?: any
  }) => {
    const [visible, setVisible] = React.useState<boolean>(false)
    const [
      loadDataRemittances,
      {
        data: dataRemittances,
        loading: loadinRemittances,
        error: errorRemittances
      }
    ] = useLazyQueryToSever({
      query: queryTablesRemitances,
      fetchPolicy: 'cache-only'
    })

    const init = {}

    const reducer = (state: objectKV, action: action) => {
      switch (action.type) {
        case 'SET_DATA':
          return Object.assign({}, state, action.value)
        default:
          throw new Error()
      }
    }

    const [state, dispatch] = React.useReducer(reducer, init)

    const disabled = React.useMemo(() => {
      if ([7, 8, 18].includes(props.nextSysEvent ?? 0)) return true
      let value = dataRemittances?.remesasInfo?.data
      if (Array.isArray(value))
        return !value.filter((item: objectKV) =>
          [6, 3].includes(item.idsysestado ?? 0)
        ).length
      return false
    }, [JSON.stringify(dataRemittances), props.nextSysEvent])

    const time = React.useMemo(() => {
      if (![3, 5].includes(props.nextSysEvent ?? 0)) return undefined
      let value = dataRemittances?.remesasInfo?.data
      if (Array.isArray(value)) {
        if (props.nextSysEvent === 5) return undefined
        let tempValue: any = value
          .filter((item: objectKV) => [6, 3].includes(item.idsysestado ?? 0))
          .sort((a: any, b: any) =>
            substractString(a?.fechahoracitacargue, b?.fechahoracitacargue)
          )
        tempValue = tempValue?.length ? tempValue[0] : null
        tempValue = +(tempValue?.fechahoracitacargue ?? 0)
        return tempValue ? tempValue : undefined
      }
      return undefined
    }, [JSON.stringify(dataRemittances), props.nextSysEvent])

    React.useEffect(() => {
      if (props.id) {
        loadDataRemittances({ variables: { id: props.id } })
      }
    }, [props.id])

    const close = () => setVisible(false)
    const open = () => setVisible(true)
    if (![3, 4, 5].includes(props.nextSysEvent ?? 0) && props.hiddenEvent)
      return null
    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <FormRem {...props} close={close} sysEvent={state} />
        </Modal>
        <ButtonAdapterCheckPoint
          disabled={disabled}
          getSysEvent={dispatch}
          nextSysEvent={
            disabled ? 3 : props.nextSysEvent === 10 ? 3 : props.nextSysEvent
          }
          className='buttons-report'
          eventClick={open}
          timeReport={disabled ? undefined : time}
        />
      </React.Fragment>
    )
  }
)

/**
 * boton de reporte de descargue
 * @param props
 */
export const EndTravelRem = React.memo(
  (props: {
    id?: number | string
    nextSysEvent?: number
    time?: number
    hiddenEvent?: any
    isVisible?: any
  }) => {
    const [visible, setVisible] = React.useState<boolean>(false)
    const [
      loadDataRemittances,
      {
        data: dataRemittances,
        loading: loadinRemittances,
        error: errorRemittances
      }
    ] = useLazyQueryToSever({
      query: queryTablesRemitances,
      fetchPolicy: 'cache-only'
    })

    const init = {}

    const reducer = (state: objectKV, action: action) => {
      switch (action.type) {
        case 'SET_DATA':
          return Object.assign({}, state, action.value)
        default:
          throw new Error()
      }
    }

    const [state, dispatch] = React.useReducer(reducer, init)

    const disabled = React.useMemo(() => {
      if ([3, 4, 5, 18].includes(props.nextSysEvent ?? 0)) return true
      let value = dataRemittances?.remesasInfo?.data
      if (Array.isArray(value))
        return !value.filter((item: objectKV) =>
          [13, 8].includes(item.idsysestado ?? 0)
        ).length
      return false
    }, [JSON.stringify(dataRemittances), props.nextSysEvent])

    const time = React.useMemo(() => {
      if (![7, 8].includes(props.nextSysEvent ?? 0)) return undefined
      let value = dataRemittances?.remesasInfo?.data
      if (Array.isArray(value)) {
        if (props.nextSysEvent === 8) return undefined
        let tempValue: any = value
          .filter((item: objectKV) => [13, 8].includes(item.idsysestado ?? 0))
          .sort((a: any, b: any) =>
            substractString(
              a?.fechahoracitadescargue,
              b?.fechahoracitadescargue
            )
          )
        tempValue = tempValue?.length ? tempValue[0] : null
        tempValue = +(tempValue?.fechahoracitadescargue ?? 0)
        return tempValue ? tempValue : undefined
      }
      return undefined
    }, [JSON.stringify(dataRemittances), props.nextSysEvent])

    React.useEffect(() => {
      if (props.id) {
        loadDataRemittances({ variables: { id: props.id } })
      }
    }, [props.id])

    const close = () => {
      if (typeof props.isVisible == 'function') props.isVisible(false)
      setVisible(false)
    }
    const open = () => {
      if (typeof props.isVisible == 'function') props.isVisible(true)
      setVisible(true)
    }

    if (![7, 8].includes(props.nextSysEvent ?? 0) && props.hiddenEvent)
      return null

    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <FormRem {...props} close={close} sysEvent={state} />
        </Modal>
        <ButtonAdapterCheckPoint
          disabled={disabled}
          getSysEvent={dispatch}
          nextSysEvent={
            disabled ? 7 : props.nextSysEvent === 10 ? 7 : props.nextSysEvent
          }
          className='buttons-report'
          eventClick={open}
          timeReport={disabled ? undefined : time}
        />
      </React.Fragment>
    )
  }
)

/**
 * boton de reporte de puesto de control
 */
export const CheckPointReport = React.memo(
  (props: {
    id?: number | string
    time?: number
    nextSysEvent?: number
    hiddenEvent?: any
    isVisible?: any
    endTravel?: any
  }) => {
    const [visible, setVisible] = React.useState<boolean>(false)

    const init = {}

    const reducer = (state: objectKV, action: action) => {
      switch (action.type) {
        case 'SET_DATA':
          return Object.assign({}, state, action.value)
        default:
          throw new Error()
      }
    }

    const [state, dispatch] = React.useReducer(reducer, init)

    const disabled = React.useMemo(() => {
      if ([7, 8, 3, 4, 5].includes(props.nextSysEvent ?? 0)) return true
      return false
    }, [props.nextSysEvent])

    const close = () => {
      if (typeof props.isVisible == 'function') props.isVisible(false)
      setVisible(false)
    }

    const open = () => {
      if (typeof props.isVisible == 'function') props.isVisible(true)
      setVisible(true)
    }
    if (![10, 18, 19].includes(props.nextSysEvent ?? 0) && props.hiddenEvent)
      return null

    // if(props.endTravel&&props.nextSysEvent != 19) return null;

    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <FormCheck {...props} close={close} sysEvent={state} />
        </Modal>
        <ButtonAdapterCheckPoint
          disabled={disabled}
          getSysEvent={dispatch}
          nextSysEvent={disabled ? 10 : props.nextSysEvent}
          className='buttons-report'
          eventClick={open}
          timeReport={disabled ? undefined : props.time}
        />
      </React.Fragment>
    )
  }
)

/**
 * boton de reporte de bascula
 * @param props
 */
export const BasculaReport = React.memo((props: { id?: number | string }) => {
  const [visible, setVisible] = React.useState<boolean>(false)

  const close = () => setVisible(false)
  const open = () => setVisible(true)

  return (
    <React.Fragment>
      <Modal visible={visible} backGroundColor='#00000000' clickOutside={close}>
        <FormBascula {...props} close={close} />
      </Modal>
      <Button className={`buttons-report-static buttons-report`} onClick={open}>
        <i className='icon-bascula' />
      </Button>
    </React.Fragment>
  )
})

/**
 * boton de reporte gps
 */
export const GpsReport = React.memo(
  (props: { id?: number | string; time?: number; isVisible?: any }) => {
    const [visible, setVisible] = React.useState<boolean>(false)

    const close = () => {
      if (typeof props.isVisible == 'function') props.isVisible(false)
      setVisible(false)
    }
    const open = () => {
      if (typeof props.isVisible == 'function') props.isVisible(true)
      setVisible(true)
    }

    return (
      <React.Fragment>
        <Modal
          visible={visible}
          backGroundColor='#00000000'
          clickOutside={close}
        >
          <FormGps {...props} close={close} />
        </Modal>
        <ButtonAdapter
          name='Reporte GPS'
          className='buttons-report'
          icon='icon-gps'
          eventClick={open}
          time={props.time}
        />
      </React.Fragment>
    )
  }
)

export const getWayPoints = (
  waypoints?: Array<position>,
  idproximopuestocontrol?: number
) => {
  let _waypoints =
    waypoints?.sort &&
    waypoints.sort((a, b) => {
      return (a.order ?? 0) - (b.order ?? 0)
    })
  let waypointsOC: Array<position> = []
  let waypointsCD: Array<position> = []
  //esta es una bandera
  let isPoint: boolean = false
  _waypoints?.forEach((item: position) => {
    if (isPoint || item.id === idproximopuestocontrol) {
      isPoint = true
      waypointsCD.push(item)
    } else {
      waypointsOC.push(item)
    }
  })
  return { waypointsCD, waypointsOC }
}

/**
 * @description odtiene la informacion necesaria para mostrar el mapa y lo renderiza
 * @param props
 */
export const InfoMaps = React.memo(
  (props: {
    id?: string | number
    idTripPlan?: string | number
    style?: any
  }) => {
    const [loadData, { called, loading, data, refetch }] = useLazyQueryToSever({
      query: GET_ROUTE,
      fetchPolicy: 'cache-only'
    })
    const [
      loadDataTripPlan,
      {
        called: calledTripPlan,
        loading: loadingTripPlan,
        data: dataTripPlan,
        refetch: refetchTripPlan
      }
    ] = useLazyQueryToSever({
      query: queryTablesPlan,
      fetchPolicy: 'cache-only'
    })

    React.useEffect(() => {
      if (!called && +(props.id ?? 0))
        loadData({ variables: { id: +(props.id ?? 0) } })
      if (!calledTripPlan && +(props.id ?? 0))
        loadDataTripPlan({ variables: { idviaje: +(props.id ?? 0) } })
    }, [props.id, props.idTripPlan])

    // let [destination, setDestination] = React.useState<position>();
    // let [origin, setOrigin] = React.useState<position>();
    let [otherMarkers, setOtherMarkers] = React.useState<
      Array<markerInterface>
    >([])
    let [waypoints, setWayPoints] = React.useState<Array<markerInterface>>()

    const { data: status, error } = useQueryToSever({
      query: GET_SYS_STADOS,
      fetchPolicy: 'cache-only'
    })
    // const [completed, setCompleted] = React.useState<boolean>(false);

    // const {waypointsCD, waypointsOC} = React.useMemo(() => getWayPoints(waypoints,data?.viaje?.idproximopuestocontrol), [waypoints, data?.viaje?.idproximopuestocontrol]);

    React.useEffect(() => {
      let temp = dataTripPlan?.planviajePorViaje?.data?.length
        ? dataTripPlan?.planviajePorViaje?.data[0]?.planviajedetalles
        : undefined
      if (temp?.map)
        setWayPoints(
          temp?.map(
            (item: objectKV): markerInterface => ({
              lat: item?.geolocalizacion?.latitud,
              lng: item?.geolocalizacion?.longitud,
              tooltip: item?.puestocontro,
              icon: iconCheckPoint
            })
          )
        )
      temp = data?.viaje
      if (data?.viaje) {
        setOtherMarkers((o: Array<markerInterface>) => {
          if (!temp?.geolocalizacion) return []
          let _temp: markerInterface = {
            lat: temp.geolocalizacion.latitud + 0.0003,
            lng: temp.geolocalizacion.longitud,
            icon: iconMarkerVehicle,
            tooltip: 'Ultima posicion del vehiculo'
          }
          return [_temp]
        })
        // console.log(temp.origen);
        // setDestination(temp.destino? {position:temp.destino}: undefined);
        // setOrigin(temp.origen? {position:temp.origen}: undefined);
        // setcurrentLocation(temp.geolocalizacion? {position:{lat:temp.geolocalizacion.latitud, lng: temp.geolocalizacion.longitud}}: undefined);
      }
    }, [
      JSON.stringify(data?.viaje ?? {}),
      JSON.stringify(dataTripPlan?.planviajePorViaje?.data)
    ])

    React.useEffect(() => {
      if (data?.viaje?.idsysestado && status?.sysEstados?.data?.length) {
        let sys = status?.sysEstados?.data.find(
          (item: any) => item.id === data?.viaje?.idsysestado
        )
        if (sys?.descripcion === 'TERMINADO') {
          // setcurrentLocation(data?.viaje?.destino? {position:data?.viaje?.destino}: undefined);
          // setDestination(undefined);
        }
      }
    }, [status, data])

    return ![4, 7, undefined, null].includes(data?.viaje?.idsysestado) ? (
      <div className='map-container' style={props.style}>
        <MapGranada
          id='map-travel'
          routes={[{ waypoints: waypoints ?? [] }]}
          markers={otherMarkers}
          style={{ height: '100%', width: '100%' }}
        />

        {/* <MapTravel 
            currentLocation={currentLocation}
            waypointsCD={waypointsCD }
            waypointsOC={waypointsOC }
            waypoints={waypoints}
            origin={origin}
            destination={destination}
            style={{height:250,width:"100%"}}
            /> */}
      </div>
    ) : (
      <div />
    )
  }
)

/**
 * @description odtiene la informacion del GPS del store y la muestra en pantalla
 * @param props
 */
export const InfoGPS = React.memo(
  (props: { idVehicle?: string | number; style?: any }) => {
    /**
     * NOTA MUY IMPORTANTE: este query se hace y se refresca luego de que el query de vehiculos responda, el de vehiculos guarada en cache,
     * este saca la info de cache, revisar.
     */
    const [loadData, { called, loading, data }] = useLazyQueryToSever({
      query: GET_VEHICLE_INFO(),
      fetchPolicy: 'cache-only'
    })

    const [_loading, setLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
      if (props.idVehicle && !called)
        loadData({ variables: { id: props.idVehicle } })
    }, [props.idVehicle])

    React.useEffect(() => {
      if (loading) setLoading(true)
    }, [loading])

    React.useEffect(() => {
      if (data?.vehiculo) {
        setLoading(false)
      }
    }, [data])

    return (
      <div className='gps-container over-flow-hidden' style={props.style}>
        {!called || _loading ? (
          <LoadContainer />
        ) : (
          <React.Fragment>
            <div className='title over-flow-hidden'>GPS</div>
            <div className='user over-flow-hidden'>
              <TextView
                label='Usuario'
                text={data?.vehiculo?.gpsusuario ?? ''}
              />
            </div>
            <div className='password over-flow-hidden'>
              <TextView
                label='Contraseña'
                text={data?.vehiculo?.gpscontrasena ?? ''}
              />
            </div>
            <div className='url over-flow-hidden'>
              <TextView label='Url'>
                <a href={data?.vehiculo?.gpsurl ?? ''} target='_blank'>
                  {data?.vehiculo?.gpsurl ?? ''}
                </a>
              </TextView>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
)

/**
 * @description Odtine la informacion del conductor y la muestra en panatalla
 * @param props
 */
export const InfoDriver = React.memo(
  (props: {
    id?: string | number
    style?: any
    onClick?: (v?: any) => any
    emitData?: (v?: any) => any
  }) => {
    const [loadData, { called, loading, data }] = useLazyQueryToSever({
      query: GET_DRIVER_INFO
    })

    React.useEffect(() => {
      if (props.id && !called) loadData({ variables: { id: props.id } })
    }, [props.id])

    React.useEffect(() => {
      if (props.emitData) props.emitData(data?.conductorInfo)
    }, [data?.conductorInfo])

    const _onClick = () => {
      if (props.onClick) props.onClick()
    }

    return (
      <div className='driver-container' onClick={_onClick} style={props.style}>
        {/* con estos dos valores le induco sei ya fue llamdo y no esta cragando muestra valores de otro modo 
            solo muestra la imagen de carga  */}
        {!called || loading ? (
          <LoadContainer />
        ) : (
          <React.Fragment>
            <div className='name-driver'>
              <TextView
                label='Conductor'
                text={data?.conductorInfo?.nombre ?? 'NA'}
              />
            </div>
            <div className='doc-driver'>
              <TextView
                width={100}
                label='Documento'
                text={data?.conductorInfo?.numerodocumento ?? 'NA'}
              />
            </div>
            <div className='phone-driver'>
              <TextView width={100} label='Telefono'>
                <label>{data?.conductorInfo?.telefono ?? 'NA'}</label>
              </TextView>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
)

export const Vehicle = React.memo(
  (props: { id?: string | number; style?: any }) => {
    const [visible, setVisible] = React.useState<boolean>(false)

    const close = () => {
      setVisible(false)
    }

    const open = () => {
      setVisible(true)
    }

    const _onClick = () => {
      open()
    }

    const _clickOutside = () => {
      close()
    }

    return (
      <React.Fragment>
        <ModalVehicle
          id={props?.id}
          visible={visible}
          clickOutside={_clickOutside}
        />
        <InfoVehicle id={props?.id} onClick={_onClick} style={props.style} />
      </React.Fragment>
    )
  }
)

export const Driver = React.memo(
  (props: { id?: string | number; style?: any }) => {
    const [visible, setVisible] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any>()

    const close = () => {
      setVisible(false)
    }

    const open = () => {
      setVisible(true)
    }

    const _onClick = () => {
      open()
    }

    const _clickOutside = () => {
      close()
    }

    const _emitData = (v?: any) => {
      /**
         * Esta es la estructra actual del valor de v si existe
            correo: string | null
            foto: string | null
            fotominiatura: string | null
            id: number | null
            idempres: number | null
            contactos:null | string
            idsysperfil: number | null
            idsystipodocumento: number | null
            idusuario: number | null
            indicadoractivo: boolean
            nombre: string | null
            numerodocumento: string | null
            telefono: string | null
         */
      // console.log(v)
      // Por el momento se pasara tal y como se recibe ya que no se requiere mas nada en un futuro esto puede o debe cambiar
      if (v) setData(v)
    }

    return (
      <React.Fragment>
        <ModalDriver
          visible={visible}
          data={data}
          clickOutside={_clickOutside}
        />
        <InfoDriver
          id={props?.id}
          onClick={_onClick}
          emitData={_emitData}
          style={props.style}
        />
      </React.Fragment>
    )
  }
)

/**
 * @description este compoenente llama los dettalles que se encargan de mostar la Trazabilidad, el plan de viajes y las remesas
 * @param props
 */
export const InfoTable = React.memo(
  (props: {
    id?: string | number
    idTripPlan?: string | number
    opened?: any
  }) => {
    return props.id ? <Details {...props} /> : <LoadContainer />
  }
)

/**
 * @description esta es el componenteprincipal el cual se encarga de mostrar la vista
 */
export const Travel = React.memo(() => {
  const { idviaje, idReport, subscribe, unsubscribe } = useSocket()

  /**
   * esta bandera estara true si el viaje no se encuantra para que no se renderice componentes
   */
  const [travelNotFound, setTravelNotFound] = React.useState<boolean>(false)
  const [calledTravel, setCalledTravel] = React.useState<boolean>(false)
  const [openedGeneralInfo, setOpenedGeneralInfo] = React.useState<any>()
  const [_data, setData] = React.useState<objectKV>()

  /**
   * en esta variable se almacenara el id del viaje
   */
  const params: any = useParams()
  const { id } = params
  const { addNotification } = useNotification()

  // const { data,variables }  = useQueryToSever({query: GET_TRAVEL_REQUEST,variables:{id:+id},fetchPolicy:"cache-only"});

  const [
    loadData,
    { called, error, loading, data: dataRequest, fetchMore: refetchTravel }
  ] = useLazyQueryToSever({
    query: GET_TRAVEL_REQUEST,
    fetchPolicy: 'cache-first'
  })
  const [
    loadDataTripPlan,
    {
      called: calledTripPlan,
      loading: loadingTripPlan,
      data: dataTripPlan,
      refetch: refetchTripPlan
    }
  ] = useLazyQueryToSever({ query: queryTablesPlan })
  const [
    loadDataTrazability,
    {
      called: calledTrazability,
      loading: loadingTrazability,
      data: dataTrazability,
      refetch: refetchTrazability
    }
  ] = useLazyQueryToSever({ query: queryTablesTrazability })
  const [
    loadDataRemitances,
    {
      called: calledRemitances,
      loading: loadingRemitances,
      data: dataRemitances,
      refetch: refetchRemitances
    }
  ] = useLazyQueryToSever({ query: queryTablesRemitances })

  const { readFragment, writeQuery } = useClientApolloGranada()

  // const _readQuery = () => readQuery({ query: queryTablesTrazability, variables: { id: +id } })?.trazabilidadInfo?.data;
  // const _writeQuery = (data:any) => writeQuery({query:queryTablesTrazability,data,variables: {id:+id}});

  let getnewData = React.useCallback(
    (data: any) => {
      let temp
      let idsysevento = data?.data ?? []
      idsysevento = idsysevento[0]
      idsysevento = idsysevento?.idsysevento ?? 0
      if (refetchTravel && data.idviaje === +id)
        refetchTravel({ variables: { id: +id } })
      if (refetchTrazability && data.idviaje === +id) refetchTrazability()
      if (data.idviaje === +id && [3, 5, 7, 8].includes(idsysevento))
        refetchRemitances()
      // try {
      // let localData = _readQuery();
      // let serverData = data?.data;
      // if (!serverData?.length) throw new Error();
      // let points = serverData.filter((item: any) => item.idviaje === +id && (!localData?.length || !localData.find((item_2: any) => item_2?.id === item?.id)));
      // if (!points?.length) return;
      // let newFields = points.map((item: any) => {
      //     let { id,
      //         idviaje,
      //         lugar,
      //         sysevento,
      //         evento,
      //         idsysevento,
      //         fechahora,
      //         usuario,
      //         novedad,
      //         puestocontrol,
      //         retraso,
      //         uploadLinks,
      //         geolocalizacion
      //     } = item ?? {};
      //     let documentosreporte = uploadLinks?.length ? uploadLinks.map((item_2: any) => ({
      //         archivo: item_2.key,
      //         miniatura: null,
      //         __typename: "DocumentoReporteV2"
      //     })):[];
      //     return Object.assign({}, {
      //         id,
      //         idviaje,
      //         lugar,
      //         sysevento,
      //         evento,
      //         idsysevento,
      //         fechahora,
      //         usuario,
      //         novedad,
      //         puestocontrol,
      //         retraso,
      //         geolocalizacion: null,
      //         documentosreporte,
      //         __typename: "Trazabilidad"
      //     });
      // })
      //     let tempData = {
      //     id:+id,
      //     trazabilidadInfo: {
      //         data: [...(newFields ?? []), ...(localData ?? [])]
      //     }
      // };

      // _writeQuery(tempData)
      // } catch (error) {
      //     console.log(error);
      // }
    },
    [id, refetchTravel, refetchTrazability, refetchRemitances]
  )

  React.useEffect(() => {
    let _idtravel = +id
    if (!idviaje || !_idtravel) return
    if (idviaje === _idtravel) {
      if (addNotification) {
        addNotification({
          element: <NotificationReport id={idviaje} />
        })
      }
    }
  }, [idReport, idviaje])

  React.useEffect(() => {
    let _id = subscribe(getnewData, 'travel_home')
    return () => {
      unsubscribe(_id)
    }
  }, [getnewData])

  /**
   */
  React.useEffect(() => {
    let res: any
    try {
      res = readFragment({ fragment: GET_TRAVELS_LOCAL, id: 'Viajes:' + id })
      if (res !== null)
        writeQuery({
          query: GET_TRAVEL_REQUEST,
          variables: { id: +id },
          data: { viaje: res }
        })
      // setDataTravel(res);
    } catch (error) {}
    loadData({ variables: { id: +id } })
  }, [id])

  React.useEffect(() => {
    let _dataRequest = dataRequest
    if (_dataRequest?.viaje && !error) {
      setData(_dataRequest.viaje)
      if (!calledTripPlan && +(_dataRequest?.viaje?.id ?? 0))
        loadDataTripPlan({
          variables: { idviaje: +(_dataRequest.viaje.id ?? 0) }
        })
      if (!calledTrazability && +(_dataRequest?.viaje?.id ?? 0))
        loadDataTrazability({
          variables: { id: +(_dataRequest.viaje.id ?? 0) }
        })
      if (!calledRemitances && +(_dataRequest?.viaje?.id ?? 0))
        loadDataRemitances({ variables: { id: +(_dataRequest.viaje.id ?? 0) } })
      setTravelNotFound(false)
    } else if (called) setTravelNotFound(true)
  }, [dataRequest, error])

  const _onChangesCtnResize = React.useCallback((evt: any) => {
    setOpenedGeneralInfo(evt)
  }, [])

  if (travelNotFound) return <Redirect push to='/Home/network-error' />

  return (
    <div className='travel'>
      <div className='travel__title'>
        <OptionsButton />
      </div>
      {/* Con esta validacion se le muestra al ususario que el viaje que busca no se encuentra, 
        ya sea por permisos o simplemente no existe  */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'fit-content(316px) auto 10px',
          height: '95%',
          overflow: 'hidden'
        }}
      >
        {/* className="travel__container-first"
        className="travel__container-last"
        className="info-map"
        className="info-table" */}
        <div className='left-ctn-travel'>
          <CtnResize vertical>
            <div style={{ width: 300 }}>
              <Vehicle
                id={_data?.idvehiculo}
                style={{ height: 190, marginRigth: 5, marginBottom: 5 }}
              />
              <InfoMaps
                id={_data?.id}
                idTripPlan={_data?.idplanviaje}
                style={{ height: 250 }}
              />
              <Driver id={_data?.idconductor} style={{ height: 90 }} />
              <InfoGPS idVehicle={_data?.idvehiculo} style={{ height: 120 }} />
            </div>
          </CtnResize>
        </div>
        <div className='right-ctn-travel'>
          <CtnResize onChanges={_onChangesCtnResize}>
            <InfoGeneral id={_data?.id} />
          </CtnResize>
          <InfoReports id={_data?.id} />
          <div style={{ height: '100%' }}>
            <InfoTable
              opened={openedGeneralInfo}
              id={calledTrazability ? _data?.id : undefined}
              idTripPlan={_data?.idplanviaje}
            />
          </div>
        </div>
      </div>
    </div>
  )
})
