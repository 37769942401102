import React from 'react';
import { action } from '../../../utils/interface';
import "./Tabs.scss";

interface propsTabs {
    dispatch:(v:action) => any;
    tabs:Array<{value:string,description:string}>;
    value?:string;
}

interface propsTab {
    dispatch:(v:action) => any; 
    currentValue?:string; 
    value:string; 
    description:string;
}

const Tab = React.memo((props:propsTab) => {

    const { dispatch,currentValue,value,description } = props;

    const _onClick = () => {
        dispatch({type:"SET_TAB",value});
    };

    return <div onClick={_onClick} className={`tab-item ${value === currentValue?"tab-item__selected":""}`} >{description}</div>
});

export const Tabs = React.memo((props:propsTabs) => {
    
    const { dispatch, tabs,value } = props;

    return (<div className="tabs-component-container">
        <div className="tabs-container">
            {tabs.map((item:{value:string,description:string},index:number) => <Tab dispatch={dispatch} key={index} currentValue={value} value={item.value} description={item.description}/>)}
        </div>
        <div className="tabs-fill"/>
    </div>);
});
