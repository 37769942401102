import { fields } from "../../Componets/Helpers/Form/Form-context";
import { confTable } from "../../utils/interface";

export const dataInfoClient = `
id
nombrecliente
numerodocumentocliente
codigo
idusuario
frecuenciacorreo
correoreporte
tiempoenviocorreo
eventos
`;

export const queryClients = `
query getClients ( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
    clientesempresastransportadoras (
        filters: $filters,
        orders: $orders,
        pagination: { fields:$fields, page: $page}
      ) {
        data{
            ${dataInfoClient}
        }
        totalreg
      }
  }
`;

export const resetPassword = `
mutation resetPassword($id:Int!,$idapp: Int){
  resetPassword(id:$id,idapp:$idapp){
    id
    succesful
  }
}
`;

export const createsyseventosclienteocultos = `
mutation createsyseventosclienteocultos($idcliente:Int,$syseventos: [syseventosclienteocultosInput]){
  createsyseventosclienteocultos(idcliente:$idcliente,syseventos:$syseventos){
    id
  }
}
`;

export const syseventosclienteocultos = `
query syseventosclienteocultos($idcliente:Int){
  syseventosclienteocultos(idcliente:$idcliente){
    idsysevento
    sysevento
  }
}
`;


export const querySysEventos = `
query sysevents{
  sysEventos @client{
    data {
      id
      iconname
      descripcion
    }
  }
}
`;

export const fragmentClient = `
fragment ClientesEmpresasTransportadorasL on ClientesEmpresasTransportadoras {
    ${dataInfoClient}
}`;

export const fieldsTable : Array <confTable>  = [
    // {
    //     field:"id",
    //     label:"id",
    //     filterable:true,
    //     orderable:true,
    //     type:"TEXT",
    //     path:"id"
    // },
    {
        field:"nombrecliente",
        label:"Nombre",
        filterable:true,
        orderable:false,
        type:"TEXT",
        path:"nombrecliente"
    },
    {
        field:"numerodocumentocliente",
        label:"Documento",
        textAling:"CENTER",
        filterable:true,
        orderable:false,
        type:"TEXT",
        path:"numerodocumentocliente"
    },
    {
        field:"codigo",
        label:"Codigo",
        textAling:"CENTER",
        filterable:true,
        orderable:true,
        type:"TEXT",
        path:"codigo"
    }
];
