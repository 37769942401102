import { fields } from "../../Componets/Helpers/Form/Form-context";
import { confTable } from "../../utils/interface";

export const advancedSearchPoplation = `
query getsyspoblacion($filters:[FilterInput],$fields:Int!, $page:Int!,$orders: [OrderInput]){
sysPoblaciones (filters : $filters,pagination: { fields:$fields, page: $page},orders: $orders){
        data {
            id
            descripcion
            nombrenacional
        }
        totalreg
    }
}
`;

export const sysPoblationFragmentId = `
fragment mypuestoscontrolInfo on sysPobalcionesInfo { 
  id
  descripcion
  nombrenacional
}
`;

export const dataRoutes = `
id
origen
idorigen
iddestino
destino
tiempoestimado
indicadoraltoriesgo
via
descripcion
distancia
`;

export const queryRoutes = `
query routes( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
  rutasInfo (
      filters: $filters,
      orders: $orders,
      pagination: { fields:$fields, page: $page}
    ) {
      data{
          ${dataRoutes}
      }
      totalreg
    }
  }
`;

export const queryRoute = `
query route($id:Int!){
  rutaInfo(id:$id){
    ${dataRoutes}
  }
}
`;

export const mutationCreateRoute = `
mutation createRuta($ruta: RutaInput) {
  createRuta(ruta: $ruta){
    ${dataRoutes}
  }
}
`;

export const mutationDeleteRoute = `
mutation deleteRoute($ids: [Int]) {
    deleteRuta(ids: $ids) {
    ${dataRoutes}
  }
}
`;

export const mutationUpdateRoute = `
mutation updateRuta($id: Int, $ruta: RutaInput) {
  updateRuta(id: $id, ruta: $ruta) {
    ${dataRoutes}
  }
}
`;

export const fieldsTable : Array <confTable>  = [
    {
        field:"descripcion",
        orderable:true,
        filterable:true,
        label:"Descripción",
        type:"TEXT",
        path:"descripcion"
    },
    {
        field:"via",
        orderable:true,
        filterable:true,
        label:"Via",
        type:"TEXT",
        path:"via"
    },    
    {
      label:"Origen",
      field:"origen",
      orderable:true,
      filterable:true,
      width:80,
      useTooltip:true,
      path:"origen",
      type:"TEXT"
    },
    {
      label:"Destino",
      field:"destino",
      orderable:true,
      width:80,
      useTooltip:true,
      filterable:true,
      path:"destino",
      type:"TEXT"
    },
    {
        field:"distancia",
        orderable:true,
        filterable:true,
        label:"Distancia (KM)",
        type:"TEXT",
        path:"distancia",
        textAling:"RIGHT"
    },
    {
        field:"tiempoestimado",
        orderable:true,
        filterable:false,
        label:"Tiempo estimado",
        textAling:"RIGHT"
    },
    {
        field:"indicadoraltoriesgo",
        orderable:true,
        filterable:true,
        label:"Alto riesgo",
        type:"BOOLEAN",
        width:75,
        textAling:"CENTER",
        path:"indicadoraltoriesgo"
    }
];

export const filedsForm:Array<fields> = [
  {
    name:"indicadoraltoriesgo",
    path:"indicadoraltoriesgo",
  },
  {
    name:"idorigen",
    path:"idorigen",
    validator:[true]
  },
  {
    name:"iddestino",
    path:"iddestino",
    validator:[true]
  },
  {
    name:"via",
    path:"via",
    validator:[true,{max:100}]
  },
  {
    name:"descripcion",
    path:"descripcion",
    validator:[true,{max:200}]
  },  
  {
    name:"distancia",
    validator:[true],
    path:"distancia"

  },
  {
    name:"hours",
    validator:[true]
  },   
  {
    name:"minutes",
    validator:[true]
  }
];