import React from 'react'
import './Forms-report.scss'
import {
  GeneralInfo,
  Observations,
  ControlButtons,
  UploadFile,
  GET_INFO,
  LocationForm,
  GET_TIMES,
  REPORT_MUTATION,
  GET_UTILS_REPORT
} from './Form.general-info'
import { useMutationServer } from '../../../Componets/hook/mutation-hook'
import { objectKV, action, error } from '../../../utils/interface'
import { useLazyQueryToSever } from '../../../Componets/hook/query-hook'
import { useUser } from '../../../Componets/hook/user-hook'
import TextView from '../../../Componets/Basic/Text/TextView'
import {
  formatterTimeZoneDate,
  getDateServer,
  reportFormatter
} from '../../../utils/utils'
import { uploadImage } from '../../../Componets/General/UploadImage/UploadImage'
import { queryTablesRemitances } from '../Travel/Travel.config'

const idsysevento = 11

export const FormGps = (props: { id?: number | string; close: () => any }) => {
  const [sendData, { data }] = useMutationServer({ mutation: REPORT_MUTATION })
  const [loadData, { data: dataFill }] = useLazyQueryToSever({
    query: GET_INFO,
    fetchPolicy: 'cache-first'
  })
  const [
    loadDataRemittances,
    {
      data: dataRemittances,
      loading: loadinRemittances,
      error: errorRemittances
    }
  ] = useLazyQueryToSever({
    query: queryTablesRemitances,
    fetchPolicy: 'cache-first'
  })

  const {
    user: { id }
  } = useUser()

  const init = {
    fecha: '',
    hora: '',
    idevento: null,
    idremesas: [],
    idsysevento,
    idusuario: null,
    idviaje: null,
    imagenes: [],
    lugar: '',
    novedad: '',
    retraso: null
  }

  const reducer = (state: objectKV, action: action) => {
    switch (action.type) {
      case 'SET_DATA':
        return Object.assign({}, state, action.value)
      default:
        return state
    }
  }

  const [errorLocation, setErrorLocation] = React.useState<error>()
  const [errorNovedad, setErrorNovedad] = React.useState<error>()
  const [date, setDate] = React.useState<number>()
  const [uploading, setUploading] = React.useState<boolean>()

  const [state, dispatch] = React.useReducer(reducer, init)

  const [loadTravel, { data: dataTravel }] = useLazyQueryToSever({
    query: GET_UTILS_REPORT,
    fetchPolicy: 'cache-only'
  })

  React.useEffect(() => {
    if (props.id)
      loadTravel({
        variables: { idviaje: props.id, idsysevento }
      })
  }, [props.id])

  const _onClickSend = () => {
    if (!validator(state)) return
    // let date = new Date();
    // let _date = { fecha: date.toLocaleDateString(),hora: date.toLocaleTimeString().split(":").slice(0,2).join(":")}
    setUploading(true)
    sendReport(Object.assign({}, state)).finally(() => {
      setUploading(false)
    })
  }

  const sendReport = async (v: any) => {
    let dateRes = await getDateServer()
    if (!dateRes || typeof dateRes !== 'string') return
    let tempDate = formatterTimeZoneDate(dateRes).timeCurrentZone
    let date = new Date(tempDate ?? 0)
    let _date = Object.assign(
      {},
      {
        fecha: date
          .toLocaleDateString('en-GB')
          .split('/')
          .reverse()
          .join('/'),
        hora: date
          .toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          })
          .split(':')
          .slice(0, 2)
          .join(':')
      }
    )
    let { images, report } = await reportFormatter(Object.assign({}, v, _date))
    let resReport = await sendData({ variables: { report: [report] } }).catch(
      (err: any) => ({ error: true })
    )
    if (
      resReport?.data?.createReporteV2?.length &&
      resReport?.data?.createReporteV2[0]?.uploadLinks?.length
    ) {
      await Promise.all(
        resReport.data.createReporteV2[0].uploadLinks.map(
          async (item: objectKV) => {
            let file = images.find((f: File) => f.name === item.key)
            return item.url && file
              ? await uploadImage(item.url, file, file.type)
              : null
          }
        )
      )
    }
  }

  const _onClickCancel = () => {
    props.close()
  }

  const validator = (v: objectKV) => {
    setErrorLocation({ error: !v.lugar, message: 'requerido' })
    setErrorNovedad({ error: !v.lugar, message: 'requerido' })
    if (!v.novedad || !v.lugar) return false
    return true
  }

  React.useEffect(() => {
    setDate(new Date().getTime())
  }, [])

  React.useEffect(() => {
    if (props.id) {
    //   loadData({ variables: { id: props.id } })
      loadDataRemittances({ variables: { id: props.id } })
    }
  }, [props.id])

  React.useEffect(() => {
    let value = dataRemittances?.remesasInfo?.data
    if (value?.length) {
      dispatch({
        type: 'SET_DATA',
        value: {
          idviaje: props.id,
          idremesas:
            value?.map((item: { id: number | string }) => item.id) ?? []
        }
      })
    }
  }, [dataRemittances])

  React.useEffect(() => {
    dispatch({ type: 'SET_DATA', value: { idusuario: id } })
  }, [id])

  React.useEffect(() => {
    if (data?.createReporteV2?.length && !uploading) {
      props.close()
    }
  }, [data, uploading])

  return (
    <div className='form-gps general-form'>
      <div className='form-gps__general-info'>
        <GeneralInfo
          {...props}
          formato={2}
          title='Reporte de gps'
          idsysevento={idsysevento}
        />
      </div>
      <div className='form-gps__location'>
        <LocationForm
          style={{ width: 350 }}
          onChange={dispatch}
          error={errorLocation}
          label='Lugar*'
        />
      </div>
      <div className='form-gps__upload-file'>
        <UploadFile onChange={dispatch} />
      </div>
      <div className='form-gps__observation'>
        <Observations
          error={errorNovedad}
          onChange={dispatch}
          value='Sin Novedad'
        />
      </div>
      <div className='form-gps__last'>
        <TextView
          height={50}
          numLines={2}
          label='Ultimo GPS'
          text={dataTravel?.utilsReport?.ultimoreporte ?? ''}
        />
      </div>
      <div className='form-gps__buttons'>
        <ControlButtons
          buttonCancel={{ onClick: _onClickCancel, disabled: uploading }}
          buttonSend={{
            onClick: _onClickSend,
            loading: uploading,
            disabled: uploading
          }}
        />
      </div>
    </div>
  )
}
