export enum store {
    /**
     * esta es la llave con la que se guaradar la session en local stores
     */
    KEY_SESSION="SESSION_CTRL_TRAFICO",
    //esta es la key que guarda querys en la cache 
    KEY_UTILS="SESSION_CTRL_TRAFICO_UTILS",

    KEY_CONFIG_TABLE="SESSION_CTRL_TRAFICO_CONF_TABLE",
    /**
     * este es el codigo que indica que no hay nada en local store
     */
    STORE_IS_NULL=11

}

export enum colorAlerts {
    /**
     * es el color que se usa para indicar que el reporte esta bn 
     */
    REPORT_FINE = "#3597ec",

    /**
     * Es el color que se usa para indicar que el reporte esta atrasado 
     */
    REPORT_WRONG = "#d60038",
}
