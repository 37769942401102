import React from "react";
import { useUser } from "../../Componets/hook/user-hook";
import { Modal } from "../../Componets/Basic/Modal/Modal";
import { ModalAdapter, ModalConfirmationRemove } from "../../Componets/General/FormsUtils/FormModal";
import {
    fieldsTableDetalles,
    queryDetails as GET_CTNS 
 } from "./Ctns.conf";
import { ButtonRemove } from "../../Componets/General/FormsUtils/Buttons.form";
import { useNotification } from "../../Componets/hook/notification-hook";
import { useMutationServer } from "../../Componets/hook/mutation-hook";
import { NotificationSucces, NotificationError } from "../../Componets/General/FormsUtils/Notification.fom";
import { objectKV } from "../../utils/interface";
import { Form, FormInputSelect, FormInputText, FormSwitch } from "../../Componets/Basic/Form/Form";
import { BooleanView } from "../../Componets/Basic/Text/BooleanView";
import "./Ctns.scss";
import TextView from "../../Componets/Basic/Text/TextView";
import { useLazyQueryToSever,  } from "../../Componets/hook/query-hook";
import { fields } from "../../Componets/Helpers/Form/Form-context";
import { destructor } from "../../utils/utils";
import { dataInfoClient } from "../Clients/Client.conf";
import { Table } from "../../Componets/Basic/Table/Table";
import { factoryDetails } from "./Ctns.factory";


export const ModalOptionsCtns  = (props:{id?:string | number, visible:boolean, close:(v?:any) => any,refresh?:(v?:any) => any, onClickSave?:(v?:any) => any}) => {

    const { user } = useUser();
    
    const [isEditableOnly, setEditableOnly] = React.useState<boolean>(false);
    const [validate, setValidate] = React.useState<boolean>(false);
    const [isValid, setIsvalid] = React.useState<boolean>(false);
    const [dataForm, setDataForm] = React.useState<objectKV>();

    const {addNotification} = useNotification();

    const onCompleted = () => {
        if(props.refresh) props.refresh();
    };

    const [loadData, {data,loading:loadingEvent}] = useLazyQueryToSever({query:GET_CTNS,fetchPolicy:"cache-first"});

    React.useEffect(() => {
        console.log(data)
    }, [data]);

    const _data = React.useMemo(() => {
        if(data?.contenedores?.data?.length) return data?.contenedores?.data[0];
        return null;
    },[data]);

    React.useEffect(() => {
        let _id = +(props.id ?? 0);
        if(_id && user.idcompany && props.visible) {
            loadData({variables:{fields:1, page:1,filters:[{field:"id",value:_id+""}]}});
            setEditableOnly(false);
        } else {
            setEditableOnly(true);
        }
    }, [props.id, user,props.visible]);

    const _clickOutside = () => {
        props.close();
    };

    const assingDataForm = (v?:objectKV) => {
        setDataForm(v);
    };

    const assingValid = (v:boolean) => {
        setIsvalid(v);
    };

    const _clickSave = () => {

    };

    return (
        <Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
            <ModalAdapter title={`Contenedor N° ${_data?.numerocontenedor??""}`} noEdit  close={props.close} clickSave={_clickSave} loading={false}  >
                <ContentModal key="VIEW-EVENT" data={_data} loading={loadingEvent} />
                <div></div>
            </ModalAdapter>
        </Modal>
    );
};

export const ContentModal = React.memo((props:{
    isEditable?:boolean;
    data?:objectKV;
    validate?:boolean;
    getIsvalid?:(v:boolean) => any; 
    getValue?:(v?:objectKV) => any;
    loading?:boolean;
}) => {
    let { data } = props;
    return (
        <div className="ctn-ctn">
            <TextView label="N° Comodato" text={data?.comodato}/>
            <TextView label="BL" text={data?.bl}/>
            <TextView label="Docto. cte." text={data?.documentocliente}/>
            <TextView label="Comercial" text={data?.nombrecomercialembarcador}/>
            <TextView label="Estado" text={data?._sysestado?.descripcion}/>
            <TextView label="Naviera" text={data?.naviera}/>
            <div style={{gridColumn:"2/4"}}>
                <TextView label="Lugar devolucion" text={data?.lugardevolucion}/>
            </div>
            <TextView label="Días mora" text={data?.diasmora}/>
            <TextView label="Cliente" text={data?.nombrecliente}/>
            <div className="ctn-ctn__table">
                <Table
                factory={factoryDetails}
                fields={fieldsTableDetalles}
                data={data?.detalles??{}}
                />
            </div>
        </div>
    );
});

