import React from "react";
import { useUser } from "../../Componets/hook/user-hook";
import { Modal } from "../../Componets/Basic/Modal/Modal";
import { ModalAdapter, ModalConfirmationRemove } from "../../Componets/General/FormsUtils/FormModal";
import { 
     mutationCreateRoute as SET_ROUTE,
     mutationUpdateRoute as UPDATE_ROUTE,
     queryRoute as GET_ROUTE,
     mutationDeleteRoute as DEL_ROUTE, filedsForm, advancedSearchPoplation, sysPoblationFragmentId
 } from "./Routes.conf";
import "./Routes.scss";
import { objectKV } from "../../utils/interface";
import { useMutationServer } from "../../Componets/hook/mutation-hook";
import { useNotification } from "../../Componets/hook/notification-hook";
import { NotificationSucces, NotificationError } from "../../Componets/General/FormsUtils/Notification.fom";
import { ButtonRemove } from "../../Componets/General/FormsUtils/Buttons.form";
import { Form, FormInputAdvanced, FormInputText, FormSwitch } from "../../Componets/Basic/Form/Form";
import { BooleanView } from "../../Componets/Basic/Text/BooleanView";
import TextView from "../../Componets/Basic/Text/TextView";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { destructor } from "../../utils/utils";
import { fields } from "../../Componets/Helpers/Form/Form-context";
import { usePrevious } from "../../Componets/hook/prevValue-hook";
import { useClientApolloGranada } from "../../Componets/hook/client-apollo-hook";



export const ModalOptionsRoute  = React.memo((props:{refresh:() => void,id?:string | number, visible:boolean, close:(v?:any) => any, onClickSave?:(v?:any) => any}) => {

    const { user } = useUser();

    const [isEditableOnly, setEditableOnly] = React.useState<boolean>(false);
    const [validate, setValidate] = React.useState<boolean>(false);
    const [isValid, setIsvalid] = React.useState<boolean>(false);
    const [dataForm, setDataForm] = React.useState<objectKV>();

    const {addNotification} = useNotification();

    const [loadData, {data:routeData,loading:loadingRoute}] = useLazyQueryToSever({query:GET_ROUTE,fetchPolicy:"cache-first"});

    const onCompleted = () => {
        if(props.refresh) props.refresh();
    };

    const [createRoute, {loading:loadingCrate, data:dataCreate, error:errorCreate}] = useMutationServer({mutation:SET_ROUTE,onCompleted});

    const [updateRoute, {loading:loadingUpdate, data:dataUpdate, error:errorUpdate}] = useMutationServer({mutation:UPDATE_ROUTE});

    React.useEffect(() => {
    }, []);

    React.useEffect(() => {
        let _id = +(props.id ?? 0);
        setValidate(false);

        if(_id && user.idcompany && props.visible) {
            loadData({variables:{id:_id}});
            setEditableOnly(false);
        } else {
            setEditableOnly(true);
        }
    }, [props.id,props.visible, user]);

    React.useEffect(() => {
        if(dataUpdate || dataCreate) {
            if(!loadingCrate || !loadingUpdate) {
                props.close();
                addNotification({element:<NotificationSucces message="procesos exitoso" />,delay:3000});
            }
        }
    }, [dataCreate, dataUpdate, loadingUpdate,loadingCrate]);

    React.useEffect(() => {
        if(errorCreate || errorUpdate){
            addNotification({element:<NotificationError message="Ocurrio un error, revisar si la ruta ya existe." />,delay:3000});
        }
    }, [errorCreate,errorUpdate]);

    const _clickOutside = () => {
        props.close();
    };

    const assingDataForm = (v?:objectKV) => {
        setDataForm(v);
    };

    const assingValid = (v:boolean) => {
        setIsvalid(v);
    };

    const _clickSave = () => {
        setValidate(true)
        if(!isValid || !dataForm){
            setValidate(true);
            return;
        }
        let ruta = {
            descripcion: dataForm.descripcion,
            distancia: +dataForm.distancia,
            iddestino: dataForm.iddestino,
            idorigen: dataForm.idorigen,
            indicadoraltoriesgo: !!dataForm.indicadoraltoriesgo,
            via: dataForm.via,
            tiempoestimado:(((+(dataForm.hours ??0))*60)+(+(dataForm.minutes ?? 0)))*60,
        };
        // console.log(dataForm,ruta);
        // return;
        if(props.id) {
            updateRoute({variables:{ruta,id:+props.id}});
        } else {
            createRoute({variables:{ruta}});
        }
    };

    return (
        <Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
            <ModalAdapter editableOnly={isEditableOnly} close={props.close} title="Rutas" clickSave={_clickSave} loading={loadingCrate || loadingUpdate} >
                <ContentModal key="View" data={routeData?.rutaInfo} loading={loadingRoute}/>
                <ContentModal key="EDIT" getValue={assingDataForm} getIsvalid={assingValid} data={isEditableOnly?null:routeData?.rutaInfo} validate={validate} isEditable/>
            </ModalAdapter>
        </Modal>
    );
});

export const ContentModal = React.memo((props:{loading?:boolean,isEditable?:boolean;data?:objectKV,validate?:boolean,getIsvalid?:(v:boolean) => any, getValue?:(v?:objectKV) => any}) => {
    
    const el  = React.useRef(null);

    const client = useClientApolloGranada();

    const { data,isEditable,validate, getIsvalid, getValue,loading } = props;

    const [valuesForm, setValuesForm] = React.useState<objectKV>();

    React.useEffect(() => {
        filedsForm.forEach((item:fields) => {
            let _el:any = el?.current;
            let _value:any = undefined;
            if("hours" === item.name) _value = getHours(data?.tiempoestimado);
            else if("minutes" === item.name) _value = getMinutes(data?.tiempoestimado);
            else if(_el?.assingValue && item.path) {
                _value = destructor(item.path,data ?? {});
            }
            _el.assingValue(_value,item.name);
            item.value = _value;
        });
    },[data]);

    const getMinutes = (time?:number) => {
        if(!time) return 0;
        return (time/60)%60;
    };

    const getHours = (time?:number) => {
        if(!time) return 0;
        return ((time/60) - ((time/60)%60))/60;
    };

    const _onFocusOnBlurDescription = React.useCallback(() => {
        let tempOri = "",temDes = "";
        let v = valuesForm;
        if(v?.descripcion) return;
        if(v?.iddestino ){
            const poblation = client.readFragment({fragment:sysPoblationFragmentId,id:`sysPobalcionesInfo:${v?.iddestino}`});
            temDes = poblation?.nombrenacional??temDes;
        }
        if(v?.idorigen ){
            const poblation = client.readFragment({fragment:sysPoblationFragmentId,id:`sysPobalcionesInfo:${v?.idorigen}`});
            tempOri = poblation?.nombrenacional??tempOri;
        }        
        let _el:any = el?.current;
        if(_el.assingValue)_el.assingValue(`${tempOri}${temDes&&tempOri?" - ":""}${temDes}${temDes&&v?.via?" ":""}${v?.via??""}`,"descripcion");
        // console.log(valuesForm,el.current);
    },[valuesForm,el]);

    const _getValue = (v?:objectKV) => {
        let tempV = v;
        if(getValue)getValue(tempV);
        setValuesForm(v);
    };

    // const _getIsvalid = React.useCallback((v:boolean) => {
    //     if(getIsvalid) getIsvalid(v);
    // },[getIsvalid]);

    return (<Form ref={el} fields={filedsForm} validate={validate} getIsValid={getIsvalid} getOnchangeValues={_getValue} >
        <div className={"modal-route"}>
            <div className="modal-route__risk">
                {isEditable?
                <FormSwitch name="indicadoraltoriesgo" label="Alto riesgo"/>:
                !loading && <BooleanView iconFalse="icon-alerta" iconTrue="icon-alerta" label="Alto riesgo" value={!data?.indicadoraltoriesgo}/>}
            </div>
            <div className="modal-route__origin">
                {isEditable?
                    <FormInputAdvanced 
                    valuesDescription={data?.origen} 
                    style={{width:400}} 
                    name={"idorigen"} 
                    label="Origen*" 
                    pathTotalReg="sysPoblaciones.totalreg" 
                    fieldFilter="descripcion" 
                    descripcion 
                    query={advancedSearchPoplation} 
                    config={{pathEmit:"id", pathOptions:"descripcion"}} 
                    pathData="sysPoblaciones.data" />
                :<TextView label="Origen" text={data?.origen}/>}
            </div>
            <div className="modal-route__destination">
            {isEditable?
                <FormInputAdvanced 
                valuesDescription={data?.destino} 
                style={{width:400}} 
                name={"iddestino"} 
                label="Destino*" 
                pathTotalReg="sysPoblaciones.totalreg" 
                fieldFilter="descripcion" 
                descripcion 
                query={advancedSearchPoplation} 
                config={{pathEmit:"id", pathOptions:"descripcion"}} 
                pathData="sysPoblaciones.data" />:
                <TextView label="Destino" text={data?.destino}/>}
            </div>
            <div className="modal-route__via">
                {isEditable?
                <FormInputText style={{width:400}} name="via" label="Vía"/>
                :<TextView label="Vía" text={data?.via}/>}
            </div>
            <div className="modal-route__description">
                {isEditable?
                <FormInputText onFocus={_onFocusOnBlurDescription} onBlur={_onFocusOnBlurDescription} style={{width:400}} name="descripcion" label="Descripción"/>
                :<TextView label="Descripción" text={data?.descripcion}/>}
            </div>
            <div className="modal-route__distance">
                {isEditable?
                <FormInputText style={{width:160}} type="number" name="distancia" label="Distancía"/>
                :<TextView label="Distancía" text={data?.distancia}/>}
            </div>
            <div className="modal-route__hours">
                {isEditable?
                <FormInputText style={{width:60}} type="number" name="hours" label="Horas"/>
                :<TextView label="Horas" text={getHours(data?.tiempoestimado)}/>}
            </div>
            <div className="modal-route__minutes">
                {isEditable?
                <FormInputText style={{width:60}} type="number" name="minutes" label="Minutos"/>
                :<TextView label="Minutos" text={getMinutes(data?.tiempoestimado)}/>}
            </div>
        </div>
    </Form>);
});

export const RemoveRoute = (props:{ids:Array<number | string>,refresh:() => void}) => {

    const [visible, setVisible ] = React.useState<boolean>(false);

    const onCompleted = () => {
        if(props.refresh) props.refresh();
    };

    const [removeData, {loading, data,error}] = useMutationServer({mutation:DEL_ROUTE,onCompleted});

    React.useEffect(() => {
        if(data) setVisible(false);
    }, [data]);

    const _onClick = () => {
        setVisible(true);
    };

    const _close = () => {
        setVisible(false);
    };

    const _accept = () => {
        let tempId = props.ids.map((item) => +item? +item:null).filter(Boolean);
        if(tempId.length) removeData({variables:{ids:tempId}});
    };

    return (
    <React.Fragment>
        <ModalConfirmationRemove loading={loading} visible={visible} close={_close} numberReg={props.ids?.length ?? 0} accept={_accept} />
        <ButtonRemove onClick={_onClick} />
    </React.Fragment>
    );
};
