import { store } from "./constants";
import { objectKV } from "./interface";
import { parseJson } from "./utils";

export const getStoreInfoAsync = async () => {
    return getStoreInfo();
};

interface ruturnGetstore {
    roll?:number; 
    token?:string; 
    name?:string; 
    id?:number; 
    idcompany?:number;
    logo?: string;
    minutes?: number;
}

export const getStoreInfo =  ():ruturnGetstore => {
    return getItem(store.KEY_SESSION);
};

export const getStoreInfoUtils =  () => {
    return getItem(store.KEY_UTILS);
};

/**
 * guarda en las cookies un valor
 * NOTA: falta arreglar.
 * @param v 
 */
export const setStoreInfo =  (v:{roll?:number; 
    token?:string; 
    name?:string; 
    id?:number; 
    idcompany?: number;
    minutes?: number;
    logo?:string;}) => {
    let current = getStoreInfo();
    saveItem(Object.assign({},{
        roll: v.roll ?? current.roll,
        token: v.token ?? current.token,
        name: v.name ?? current.name,
        id: v.id ?? current.id,
        idcompany: v.idcompany ?? current.idcompany,
        logo: v.logo ?? current.logo,
        minutes:v.minutes ?? current.minutes
    }),store.KEY_SESSION);
};

export const setStoreInfoUtils =  (v:objectKV) => {
    saveItem(v,store.KEY_UTILS);
};

export const setStoreConfTable =  (v:Array<objectKV>,key:string) => {
    let itemsTemp = getItem(store.KEY_CONFIG_TABLE);
    let values = Object.assign({},itemsTemp?itemsTemp:{},{[key]:v});
    saveItem(values,store.KEY_CONFIG_TABLE);
};

export const getStoreConfTable =  (key:string) => {
    let itemsTemp = getItem(store.KEY_CONFIG_TABLE);
    return itemsTemp?itemsTemp[key]:null;
};

/**
 * Elimina los valores de la cookie 
 */
export const delStoreInfo =  () => {
    delItem(store.KEY_SESSION);
};

export const delStoreInfoUtils =  () => {
    delItem(store.KEY_UTILS);
};

export const delCookies = () => {
    delStoreInfo();
    delStoreInfoUtils();
    delItem(store.KEY_CONFIG_TABLE);
};

export const getItem = (key: string) => {
    let name = key + "=";
    let _cookie = document.cookie.split(";");
    for(let i = 0; i < _cookie.length; i++) {
        let c = _cookie[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return parseJson( c.substring(name.length, c.length));
        }
      }
      return "";
};

export const delItem = (key:string) => {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};

export const saveItem = (v:objectKV,key:string) => {
    document.cookie = `${key}=${
        JSON.stringify(v)
    };expires=${new Date(new Date().getTime()+(24*60*60*1000*10))}; path=/`;
};