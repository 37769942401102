import React from "react";
import { action, error, objectKV } from "../../../utils/interface";
import { config } from "process";


export interface fieldInteface {
    bind:{
        value: string | number | boolean;
        onChange: (event:any) => any;
        [v:string]:any;
    }
    error?:error;
    valid?:boolean;
    validator?:Array<((value:string | number | boolean, name:string, values?:objectKV) => string | boolean) | boolean | {max?:number; min?:number}>
}

export interface fields {
    name:string;
    value?: string | number | boolean;
    //se usa para estraer un posible valor inicial.
    path?:string;
    validator?:Array<((value:string | number | boolean, name:string, values?:objectKV) => string | boolean) | boolean | {max?:number; min?:number}>
}

export interface formInterface {
    
}

const FormContext = React.createContext(undefined);

/**
 * NOTA:todo se debe migrara a el reducer, tambein el congf */
interface formState {
    isValid:boolean;
    validate:boolean;
    values:{[v:string]:string | number | boolean};
    fields:Array<fields>;
    config:{[v:string]:fieldInteface};
}

export const FormProvider = (props:any) => {

    const onChange = (event:any) => {
        let name = event?.target?.name;
        let value = event?.target?.value;
        if(name) {
            dispatch({value:{[name]:value},type:"SET_VALUE"});
        }
    };

    const getError = (v:Array<((value:string | number | boolean, name:string, values?:objectKV) => string | boolean) | boolean | {max?:number; min?:number}>,value:string | number | boolean, name:string ) => {
        if(!v.length) return [];
        return v.map((item:((value:string | number | boolean, name:string, values?:objectKV) => string | boolean) | boolean | {max?:number; min?:number}) => {
            if(typeof(value) === "boolean") return null;
            if(typeof(item) === "boolean") {
                if(typeof(value) === "number") return null;
                return !!value ?null:"Requerido";
            }
            let temp:any = item;
            if(temp?.max ) {
                if(typeof(value) === "number" ) return value <= +temp.max? null:`Debe ser mayor a ${+temp.max ?? 0}`;
                return value?.length <= +temp.max? null:`Debe tener max ${+temp.max ?? 0} crts.`;
            }
            if(temp?.min ) {
                if(typeof(value) === "number") return value >= +temp.min? null:`Debe ser mayor a ${+temp.min ?? 0}`;
                return value?.length >= +temp.min? null:`Debe tener min ${+temp.min ?? 0} crts.`;
            }
            if(typeof(item) === "function") {
                let valueTemp =  item(value, name);
                if(typeof(valueTemp) === "string") return valueTemp;
                else if (valueTemp) return "Error";
                else return null;
            }
            return null;
        }).filter(Boolean);
    };

    const _getConfigValidate = (_state:formState,validate:boolean) => {
        let temp = Object.assign({}, ...Object.keys(_state.config ?? {}).map((item:string) => {
            let field = _state.config[item];
            let value = _state?.values[item];
            if(field) {
                if(field.bind) field.bind.value = value;
                let valid = getError(field.validator ?? [],value,item);
                field.valid = !valid.length;
                if(validate) {
                    field.error = {error:!!valid.length, message:valid[0] ?? ""};
                }
            }
            return {[item]:field};
        }));
        return temp;
    };

    const reducer = (state:formState, action:action) => {
        switch(action.type) {
            case "SET_VALID_FORM":
                return Object.assign({},state, {isValid:action.value});
            case "SET_VALIDATE_FORM":
                let _temp = _getConfigValidate(state,action.value);
                return Object.assign({},state, {validate:action.value,config:_temp});
            case "SET_VALUES":
                let temp = _getConfigValidate({...state,...{values:action.value}},state.validate);
                return Object.assign({},state, {values:action.value,config:temp});
            case "SET_FIELDS":
                if(action?.value?.length){
                    let temp = state.config;
                        let _config = Object.assign({},...action?.value.map((item:fields) => {
                            let field = temp[item.name];
                            return {[item.name]:Object.assign({}, field, {
                                bind:{
                                ...field?.bind,
                                value: (field?.bind?.value ?? item.value) ?? "",
                                onChange:onChange
                            },
                            validator:item.validator ?? []
                        })};
                    }));
                    return Object.assign({},state, {fields:action.value,config:_config});
                }
                return Object.assign({},state, {fields:action.value});
            case "SET_CONFIG":
                return Object.assign({},state, {config:action.value});
            case "SET_VALUE":
                let tempConf = state.config;
                let key = Object.keys(action.value ?? {})[0];
                if(!key && !tempConf[key]?.bind) return state;
                let valid = getError(tempConf[key].validator ?? [],action?.value[key],key);
                tempConf[key].valid = !valid.length;
                if(state.validate) {
                    tempConf[key].error = {error:!!valid.length, message:valid[0] ?? ""};
                }
                tempConf[key].bind.value = action.value[key];   
                let _isValid = Object.values(tempConf).reduce((acc:any,cur:fieldInteface) => {
                    return acc && cur.valid;
                },true);    
                return Object.assign({},state, {isValid:_isValid,config:tempConf,values:{...state.values,...action.value}});
            default:
                throw new Error();
        }
    };

    const init:formState = {
        isValid:false,
        validate:false,
        values:{},
        config:{},
        fields:[]
    };

    const [state,dispatch] = React.useReducer(reducer,init);

    React.useEffect(() => {
        let _valid = Object.values(state.config ?? {}).reduce((acc:any,item:any) => {
            return acc && !!item.valid;
        },true);
        dispatch({type:"SET_VALID_FORM", value:_valid});
    }, [state.config]);

    const getConfig = (key:string) =>  {
        return state?.config[key]?.bind;
    };

    const assingValues = (v:{[k:string]:string | number | boolean}) => {
        dispatch({value:Object.assign({},v),type:"SET_VALUES"});
    };

    const assingFields = (v:Array<fields>) => {
        dispatch({value:Object.assign({},...v.map((item:fields) => ({[item.name]:item.value}))),type:"SET_VALUES"});
        dispatch({value:v,type:"SET_FIELDS"});
    };

    const getValues = () => {
        return state?.values;
    };

    const getField = (key:string) => {
        return state?.config[key];
    };

    const getValue = (key:string) => {
        let value = state?.values[key];
        return value;
    };
    
    let value = React.useMemo(()=>({isValid:state.isValid,setState:dispatch,getConfig,assingValues,getValues,getValue,fields:state.fields,config:state.config,assingFields,getField}), [dispatch,JSON.stringify(state),getField,assingValues,assingFields, getConfig,getValues,getValue]);
    return <FormContext.Provider value={value} {...props} />
};

interface returnUseForm {
    isValid:boolean;
    setState:(v:action) => any;
    getConfig: (key: string) => {
        [v: string]: any;
        value: string | number | boolean;
        onChange: (event: any) => any;
    };
    assingValues: (v: {
        [k: string]: string | number | boolean;
    }) => void;
    getValues: () => {
        [v: string]: string | number | boolean;
    };
    getField: (key: string) => fieldInteface;
    getValue: (key: string) => string | number | boolean;
    fields: fields[] | undefined;
    config: {
        [v: string]: fieldInteface;
    };
    assingFields: (v: fields[]) => any;
    setFields: React.Dispatch<React.SetStateAction<fields[] | undefined>>;
}

export const useForm = ():returnUseForm => {
    const context = React.useContext(FormContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};