import React from "react";
import { error } from "../../../utils/interface";
import "./Input.scss";

interface InputTextProps {
    /** indica si hay algun error */
    error?:error;
    /** esta funcion se dispara cada cambio, y recibe true el evento es focus o false si es blur */
    focused?:(v:boolean) => any;
    /**
     * es el label que se mostrara en el input
     */
    label?:string;
    /**
     * renderizara un text area y no un input basico
     */
    isTextArea?:boolean;
    /** */
    [v:string]:any;
}
const InputTextArea = (props: InputTextProps) => {

    const {label,error,focused, className,...bind} = props;

    const { value, onChange, ...allRest} = bind??{};

    const [_value, setValue ] = React.useState<string>();

    const [focus, setFocus] = React.useState<boolean>(false);

    const _onFocus = () => {
        if(focused) focused(true);
        setFocus(true);
    };

    const _onBlur = () => {
        if(focused) focused(false);
        setFocus(false);
    };

    const _onChange = React.useCallback((e:any) => {
        setValue(e?.target?.value);
        onChange({target:{value:e?.target?.value,name:e?.target?.name}});
    },[]);

    React.useEffect(() => {
        if(value !== _value) {
            if(!value) setValue("");
            else setValue(value);
        }
    },[value]);

    return (
        <div className="container">
            {label?<label className={`text-conf ${focus?"label-focus":""} ${error?.error?"error":""}`}>{label}</label>:null}
            <textarea 
            value={_value}
            onChangeCapture={_onChange}
            onFocusCapture={_onFocus}
            onBlurCapture={_onBlur}
            className={`input ${className ?? ""}`}
            style={{borderColor:error?.error?"red":""}}
            {...allRest}
            />
            {error?.error?<label className="text-conf error">{error.message}</label>:null}
        </div>
    );
};

export default InputTextArea;
