import React from "react";
import { paths } from "./SideBar.config";
import { pathConfig, objectKV } from "./../../../utils/interface";
import logo from "./../../../utils/img/icon/logo.svg";
import "./SideBar.scss";
import { useHistory } from "react-router-dom";
import { useSideBar } from "../../hook/side-bar-hook";
import imageLogoGranada from "./../../../utils/img/icon/icono-granada.svg";
import { useUser } from "../../hook/user-hook";
import Button from "../../Basic/Button/Button";


const ItemChild = (props: {name:string,path:string}) => {

    let history = useHistory();

    const [hover, setHover] = React.useState<boolean>(false);

    const _onClick = () => {
        if(props.path) history.push(props.path);
    };

    return (<div className="side-bar__item side-bar__item--child"
    onClick={_onClick}
    style={{background:hover? "var(--sidebar-item-hover-bg-color)":""}}
    onMouseLeave={()=>setHover(false)} onMouseEnter={()=>setHover(true)}>
        <div className="side-bar__item__name side-bar__item__name--child">{props.name}</div>
    </div>);
}

const ItemPath = (props: pathConfig) => {

    let history = useHistory();

    const [hover, setHover] = React.useState<boolean>(false);

    const _onMouseLeave = () => {
        setHover(false);
    };

    const _onMouseEnter = () => {
        setHover(true)
    };

    const _onClick = () => {
        if(props.path) history.push(props.path);
    };

    return (<React.Fragment>
    <div className="side-bar__item first-icon"
    onClick={_onClick}
    style={{background:hover? "var(--sidebar-item-hover-bg-color)":""}}
    onMouseLeave={_onMouseLeave} onMouseEnter={_onMouseEnter}>
        <div className="side-bar__item__icon" 
        style={{background:hover? "var(--gray-darker)":""}} 
        >
            <i className={props.icon} 
            style={{color:hover? "var(--sidebar-item-hover-bg-color)":""}}
            />
        </div>
        <div className="side-bar__item__name">{props.name}</div>
    </div>
    
    {props.children?<div 
    className="accordion" 
    onMouseLeave={_onMouseLeave} onMouseEnter={_onMouseEnter}
    //Nota se definio en el scss como tamaño de cada hijo 50px por eso la multiplicacion siguiente
    style={{height:hover?(props.children?.length ?? 0)*50:0}}>
        {props.children?.map((item:{name:string,path:string},index:number) => <ItemChild key={index} {...item}/>)}
    </div>:null}
    </React.Fragment>
    );
}

let SideBar = React.memo(() => {
    const el = React.useRef(null);
    const {state,setStateSideBar} = useSideBar();
    const {user} = useUser();
    const _onMouseEnter = () => setStateSideBar({isOpen:true});
    const _onMouseLeave = () => setStateSideBar({isOpen:false});

    const _onMouseMoveCapture = (e:objectKV) => {
        setStateSideBar({isOpen:true})
        const _el:any = el?.current;
        if(_el?.offsetHeight - e.clientY < 20){
            if(_el){
                _el.scrollTop = _el.scrollTop + 20;
            }
        }
    };

    const _onClickBackGround = () => {
        setStateSideBar({isOpen:false});
    };

    /**
     * Estos permisos estan mal creados, ya que se hace como un parche temporal
     */
    const _paths = React.useMemo(() => {
        let tempPaths = paths.filter((item:pathConfig) => {
            if(item.add?.length) return !!item.add?.find((item2:any) => item2.roll === user?.roll);
            if(item.drop?.length) return !item.drop?.find((item2:any) => item2.idcompany === user?.idcompany);
            return true;
        });
        tempPaths = tempPaths.map((item:pathConfig) => {
            if(item.children?.length){
                let _children = item.children.filter((item3:any) => {
                    if(item3.add?.length) return !!item3.add?.find((item2:any) => item2.roll === user?.roll);
                    if(item3.drop?.length) return !item3.drop?.find((item2:any) => item2.idcompany === user?.idcompany);
                    return true;
                })
                return {...item,children:_children}
            }
            return item;
        });
        return tempPaths;
    },[paths,user]);

    const clickGranadaLogo = React.useCallback(() => {
        window.open("http://granada.io/");
    },[]);

    return (<React.Fragment>
        <div ref={el} onMouseEnter={_onMouseEnter} onMouseLeave={_onMouseLeave} onMouseMoveCapture={_onMouseMoveCapture} className={`side-bar ${state.isOpen?"side-bar--show":""}`} style={{width:state?.isOpen ? 200: 50}}>
            <div className="side-bar__container">
                <img className="side-bar__container__logo" src={logo} alt="Control Tráfico"/>
            </div>
            <div className="container-options" style={{ height:  `calc(100% - ${state.isOpen?310:215}px)`}}>
            {_paths.map((item:pathConfig,index:number) => <ItemPath key={index} {...item}/>)}
            </div>
            <div className="container-contact">
                <Button className="button-logo-granada" message="Ir a granada.io" onClick={clickGranadaLogo}>
                    <img src={imageLogoGranada} className="logoGranada"/>
                </Button>
                {new Date().getFullYear()}
                &#169;

            </div>
        </div>

        {/* este compoenente depende mucho de su style y de la propiedad isOpen
        ya que solo sirve y se muestra en pantallas que tengan el tamaño de ancho menor o igual a 600px
        y se pero se renderiza siempre, este compoenente se us con el fin de cerrra la barra de navegacion */}
        {state.isOpen?<div className="side-bar--background" onClick={_onClickBackGround}/>:null}
        
        </React.Fragment>
    );
});

export default SideBar;