import React from "react";
import { InputSelect } from "../../Componets/Basic/Input/InputSelect";
import { confTable, factorFilter, objectKV } from "../../utils/interface";

const priority = [
    {value:"1",description:"BAJA"},
    {value:"2",description:"MEDIA"},
    {value:"3",description:"ALTA"} ];

export const Priority = (props:{field: confTable,data:objectKV}) => {
    return (<label>{priority[(props.data?.nivelprioridad ?? 0) - 1].description}</label>);
};

export const factory = (props:{field: confTable,data:objectKV}) => {
    if(props.field.field === "nivelprioridad") return <Priority {...props}/>;
    return null;
};

export const factoryFilters = (props:factorFilter) => {
    if(props.confField.field === "nivelprioridad")         
        return (<InputSelect style={{width:150}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}
            data={priority}
            confSelect={{pathEmit:"value",pathOption:"description",textAling:"CENTER"}}
        />);    
    return null;
};