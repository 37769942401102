import { fields } from "../../Componets/Helpers/Form/Form-context";
import { confTable } from "../../utils/interface";

let fieldsCtns = `
id
idempresa
idsysestado
idorigen
iddestino
idcomercial
idclienteempresatransportadora
numerocontenedor
comodato
bl
naviera
digitoverificacion
documentocliente
fechainiciomorasempresa
fechainiciomorascomodato
lugardevolucion
fechadevolucioncontenedor
origen
destino
nombrecliente
estado
diasmora
nombrecomercialembarcador
_idsysestado
_sysestado{
  id
  descripcion
}
`;


let fieldsDetails = `
id
idviaje
numeroremesa
destinatario
remitente
documentocliente
indicadoraltoriesgo
contenido
empaque
direcciondestinatario
direccionremitente
clienteempresatransportadora
origen
destino
estado
idsysestado
pesomercancia
tipoacompanamiento
numerounidades
idcomercial
comercial
fechahoracitacargue
tiempopactadocargue
tiempopactadodescargue
fechahoracitadescargue
fechahoracarguellegada
fechahoracarguesalida
fechahoradescarguellegada
fechahoradescarguesalida
lugarentregacontenedor
lugarrecogidacontenedor
fechasalidacontenedor
fechaentregacontenedor
fechalimiteentregacontenedor
idremitente
iddestinatario
observaciones
numerocontenedor
correoreporte
devolvercontenedor
numeromanifiesto
placa
numerodocumentoconductor
nombreconductor
estadoviaje
`


export const query =  `
query contenedores ( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
    contenedores (
        filters: $filters,
        orders: $orders,
        pagination: { fields:$fields, page: $page}
      ) {
        data{
            ${fieldsCtns}
        }
        totalreg
      }
  }
`;

export const queryDetails =  `
query contenedores ( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
    contenedores (
        filters: $filters,
        orders: $orders,
        pagination: { fields:$fields, page: $page}
      ) {
        data{
            ${fieldsCtns}
            detalles {
                ${fieldsDetails}
            }
        }
        totalreg
      }
  }
`;

export const fieldsTable : Array <confTable>  = [
    {
        field:"_idsysestado",
        label:"Estado",
        filterable:true,
        width:120,
        orderable:true,
        type:"TEXT",
        path:"_sysestado.descripcion"
    },
    {
        field:"diasmora",
        label:"Días mora",
        textAling:"RIGHT",
        width:100,
        filterable:false,
        orderable:true,
        type:"TEXT",
        path:"diasmora"
    },
    {
        field:"fechainiciomorasempresa",
        label:"Inicio moras",
        width:120,
        type:"DATE",
        path:"fechainiciomorasempresa",
        filterable:true,
        orderable:true,
    },
    {
        field:"comodato",
        label:"N° Comodato",
        type:"TEXT",
        width:150,
        path:"comodato",
        filterable:true,
        orderable:true,
    },
    {
        field:"numerocontenedor",
        label:"N° Contenedor",
        type:"TEXT",
        width:150,
        path:"numerocontenedor",
        filterable:true,
        orderable:true,
    },
    {
        field:"digitoverificacion",
        label:"D. verificación",
        type:"TEXT",
        width:150,
        path:"digitoverificacion",
        filterable:true,
        orderable:true,
    },
    {
        field:"documentocliente",
        label:"Docto. cte.",
        type:"TEXT",
        width:150,
        path:"documentocliente",
        filterable:true,
        orderable:true,
    },
    {
        field:"bl",
        label:"BL",
        width:150,
        type:"TEXT",
        path:"bl",
        filterable:true,
        orderable:true,
    },
    {
        field:"naviera",
        label:"Naviera",
        type:"TEXT",
        width:150,
        path:"naviera",
        filterable:true,
        orderable:true,
    },
    {
        field:"origen",
        label:"Origen",
        type:"TEXT",
        path:"origen",
        filterable:true,
        orderable:true,
    },
    {
        field:"destino",
        label:"Destino",
        type:"TEXT",
        path:"destino",
        filterable:true,
        orderable:true,
    },
    {
        field:"lugardevolucion",
        label:"Lugar devolucion",
        type:"TEXT",
        path:"lugardevolucion",
        filterable:true,
        orderable:true,
    },
    {
        field:"nombrecliente",
        label:"Cliente",
        type:"TEXT",
        path:"nombrecliente",
        filterable:true,
        orderable:true,
    },
    {
        field:"nombrecomercialembarcador",
        label:"Comercial",
        type:"TEXT",
        path:"nombrecomercialembarcador",
        filterable:true,
        orderable:true,
    },
];


export const fieldsTableDetalles : Array <confTable>  = [
    {
        field:"estadoviaje",
        label:"Estado",
        width:120,
        type:"TEXT",
        path:"estadoviaje"
    },
    {
        field:"dias",
        label:"Días",
        width:70,
        path:"dias"
    },
    {
        field:"fechasalidacontenedor",
        label:"Fecha salida",
        width:150,
        type:"DATE",
        path:"fechasalidacontenedor"
    },
    {
        field:"fechalimiteentregacontenedor",
        label:"Fecha lim. Entrega",
        width:150,
        type:"DATE",
        path:"fechalimiteentregacontenedor"
    },
    {
        field:"fechaentregacontenedor",
        label:"Fecha entrega",
        width:150,
        type:"DATE",
        path:"fechaentregacontenedor"
    },
    {
        field:"origen",
        label:"Origen",
        type:"TEXT",
        path:"origen"
    },
    {
        field:"destino",
        label:"Destino",
        type:"TEXT",
        path:"destino"
    },
    {
        field:"placa",
        label:"Placa",
        width:70,
        type:"TEXT",
        path:"placa"
    },
    {
        field:"lugarentregacontenedor",
        label:"Lugar descargue",
        type:"TEXT",
        path:"lugarentregacontenedor"
    },
    {
        field:"numeromanifiesto",
        label:"Manifiesto",
        type:"TEXT",
        path:"numeromanifiesto"
    },
    {
        field:"nombreconductor",
        label:"Nombre conductor",
        type:"TEXT",
        path:"nombreconductor"
    }
];
