
export const queryDocuments = `
query viajes($id:Int!){
    trazabilidadInfo(id:$id){
        data{
            id
            numberdocs
            fechahora
            puestocontrol
            sysevento
            evento
        }
    }
}
`;


export const queryUrlReport = `
query getSignedURLReport($idreporte:Int){
    getSignedURLReport(idreporte:$idreporte){
        id
        key
        url
    }
}
`;