import * as React from 'react';
import { error, objectKV } from '../../../utils/interface';
import "./Input.scss";
// import TimePicker from 'react-time-picker';
import Icon from '../Icon/Icon.tooltip';
interface interfaceProps {
    /** indica si hay algun error */
    error?:error;
    /** esta funcion se dispara cada cambio, y recibe true el evento es focus o false si es blur */
    focused?:(v:boolean) => any;
    /**
     * es el label que se mostrara en el input
     */
    label?:string;
    /** */
    [v:string]:any;
}

export const InputTime = React.memo((props:interfaceProps) => {
    
    const {label,error,focused,name, className,style,...bind} = props;

    const { value, onChange, ...allRest} = bind??{};

    const [_valueHours, setValueHours ] = React.useState<string>("");
    const [_valueMinutes, setValueMinutes ] = React.useState<string>("");

    const [focus, setFocus] = React.useState<boolean>(false);

    const refMinutes:any = React.useRef(null);
    const refHours:any = React.useRef(null);

    const validateNumber = (v:string,k:"m"|"h") => {
        if(!v) return false;
        let tempV = +v;
        if(!tempV && tempV !== 0) return false;
        if(k === "h") return tempV >= 0 && tempV < 24;
        if(k === "m") return tempV >= 0 && tempV < 59;
        return false;
    };

    const _onFocus = () => {
        if(focused) focused(true);
        setFocus(true);
    };

    const _onBlur = () => {
        if(focused) focused(false);
        setFocus(false);
    };

    /**
     * validamos que no se pueda ingresar el caracter de la comilla, para disminuir la posibilidad de ataque de inyeccion
     * @param e 
     */
    const _onKeyPress = (e:objectKV) => {
        if(!(e.key+"").match(/[0-9]/g)) {
            e.preventDefault();
        }
    };
    
    const _onChange = React.useCallback((e:any) => {
        if(onChange)onChange({target:{value:e,name}});
    },[]);

    React.useEffect(() => {
        if(`${_valueHours}`.length === 2 && `${_valueMinutes}`.length === 2) _onChange(`${_valueHours}:${_valueMinutes}`);
    },[_valueHours,_valueMinutes]);

    React.useEffect(() => {
        if(value !== `${_valueHours}:${_valueMinutes}`) {
            if(!value) {
                setValueHours("");
                setValueMinutes("");
            }
            else {
                let tempValue = `${value}`.split(":");
                if(!validateNumber(tempValue[0]??"","h") || !validateNumber(tempValue[1]??"","m")) return;
                if(tempValue[0] !== _valueHours) setValueHours(tempValue[0]);
                if(tempValue[1] !== _valueMinutes)setValueMinutes(tempValue[1]);
            };
        }
    },[value]);

    const _onChangeCaptureHours = React.useCallback((e:any) => {
        let length = `${e?.target?.value}`.length;
        let valueTemp = +(e?.target?.value??0);
        const focus = () => {
            setTimeout(() => {
                let temprefMinutes:any = refMinutes?.current;
                if(temprefMinutes?.focus) temprefMinutes.focus();
            },12);
        };
        if(valueTemp < 0) {
            setValueHours("00");
            return focus(); 
        } 
        if(valueTemp > 2 && length < 2) {
            setValueHours("0"+valueTemp);
            return focus();
        }
        if( valueTemp > 23) {
            setValueHours("23");
            return focus();
        }
        setValueHours(e.target.value);
        if(length === 2) focus();
    },[setValueHours]);

    const _onChangeCaptureMinutes = React.useCallback((e:any) => {
        let length = `${e?.target?.value}`.length;
        let valueTemp = +(e?.target?.value??0);
        const blur = ()  => {
            setTimeout(() => {            
                let temprefMinutes:any = refMinutes?.current;
                if(temprefMinutes?.blur) temprefMinutes.blur();
            },12);

        }
        if(valueTemp < 0) {
            setValueMinutes("00");
            return blur();
        }
        if(valueTemp > 5 && length < 2) {
            setValueMinutes("0"+valueTemp);
            return blur();
        } 
        setValueMinutes(e.target.value);
        if(length === 2) blur();
    },[setValueMinutes]);

    const onBlurHours = React.useCallback(() => {
        if(+(_valueHours??0)<10) setValueHours("0"+(+(_valueHours??0)));
        _onBlur();
    },[_valueHours,setValueHours]);
    const onBlurMinutes = React.useCallback(() => {
        if(+(_valueMinutes??0)<10) setValueMinutes("0"+(+(_valueMinutes??0)));
        _onBlur();
    },[_valueMinutes,setValueMinutes]);

    return (
        <div className="container">
            {label?<label className={`text-conf ${focus?"label-focus":""} ${error?.error?"error":""}`}>{label}</label>:null}
                <div className={`input-time ${focus?"input-time__focus":""}`}  style={{borderColor:error?.error?"red":""}} >
                    <input
                    ref={refHours}
                    onKeyPress={_onKeyPress}
                    type="text"
                    className="input-time__numeric input-time__numeric--hours"
                    max={23}
                    min={0}
                    onChangeCapture={_onChangeCaptureHours}
                    placeholder="HH"
                    value={_valueHours}
                    onFocus={_onFocus}
                    onBlur={onBlurHours}
                    />
                    :
                    <input
                    onKeyPress={_onKeyPress}
                    ref={refMinutes}
                    placeholder="MM"
                    type="text"
                    className="input-time__numeric input-time__numeric--minutes"
                    max={59}
                    min={0}
                    onFocus={_onFocus}
                    onBlur={onBlurMinutes}
                    onChangeCapture={_onChangeCaptureMinutes}
                    value={_valueMinutes}
                    />
                    <Icon className="icon-hours" message="Formato 24 horas"/>
                </div>
            {error?.error?<label className="text-conf error">{error.message}</label>:null}
        </div>
    );
});