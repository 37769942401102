import React from 'react';
import { Table, Row } from './Table';
import "./Table-details.scss";
import { objectKV, confTable, action } from '../../../utils/interface';
import Button from '../Button/Button';
import Icon from '../Icon/Icon.tooltip';
import { FormProvider, useForm, fields, fieldInteface } from '../../Helpers/Form/Form-context';
import { DetailsProvider, useDetails, detail, keyEdit, keySave } from './../../Helpers/Table-details/Table-details-context';


interface propsTableDetails {

    showArrows?:boolean;
    hiddenDelete?:boolean;

    getValue?:(v:Array<objectKV>) => any;

    assingValues?:(data:objectKV,fields:Array<fields>, key:number) => detail;

    /**
     * esta funcion se llama cuando alguna fila sufre un cambio
     */
    onChange?:(assignDetailforKey: (v: action) => any,values:{
        [k: string]: string | number | boolean;
    },fields?:Array<fields>,id?:string | number) => any;
    /**
     * esta funcion mustra los cambios en slos valores del formulario, se puede usar apara hacer cambios de valores calculados
     *NOTA: es un primer acercamiento falta mucho por arreglarle.
     */
    onChangeForm?:(assignDetail: (v: action) => any,details?: Array<detail>) => any;
    data: Array<objectKV>;

    /**
     * Configuracion de la tabla, define los headers de la tabla
     */
    fields: Array<confTable>;

    /** esta es una fabrica commun para la tabla  */
    factory?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null ) ;

    /**
     * esta es una fabrica que crea componentes para el formulario de cada row
     */
    factoryEditables?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null );

    /**
     * fields form
     */
    fieldsForm:Array<fields>;

}

const fieldEdit:confTable = {
    label:"   ",
    width:75,
    field:"editRow",
    stopClick:true, 
    isEditable:true
};

export const ButtonsEdit = React.memo((props:{ data:objectKV,saveForm?:() => any, showArrows?:boolean,hiddenDelete?:boolean }) => {

    const {setState} = useDetails();

    const _clickDown = () => {
        if(props?.data?.id){
            setState({type:"MOVE_DETAIL",value:{id:props?.data?.id,move:"DOWN"}});
        }
    };

    const _clickUp = () => {
        if(props?.data?.id){
            setState({type:"MOVE_DETAIL",value:{id:props?.data?.id,move:"UP"}});
        }
    };

    const _onClickEdit = () => {
        if(props.data?.id) setState({type:"EDITABLE_DETAIL", value:{id:props.data?.id, edit:true}});
    };
    const _onClickSave = () => {
        if(props.data?.id && props.saveForm) {
            props.saveForm();
            if(!`${props.data?.id}`.includes(keyEdit))setState({type:"EDITABLE_DETAIL", value:{id:props.data?.id, edit:false}});
        }
    };
    const _onClickCancel = () => {
        if(`${props.data?.id}`.includes(keyEdit)) return;

        if(props.data?.id) setState({type:"EDITABLE_DETAIL", value:{id:props.data?.id, edit:false}});
    };
    const _onClickDelete = () => {
        if(props.data?.id)  setState({type:"DELETE_DETAIL", value:{id:props.data?.id}});
    };

    return (
        <div className="continer-button-edit">
            {
                props.data.editable?
                    <>
                        <Button onClick={_onClickSave} className="buttons-edit-detail buttons-edit-detail--edit">
                            <Icon className="icon-check" message="Guardar"/>
                        </Button>
                        {!`${props.data?.id}`.includes(keyEdit)?
                        <Button onClick={_onClickCancel} className="buttons-edit-detail buttons-edit-detail--delete">
                            <Icon className="icon-close" message="Cancelar"/>
                        </Button>:
                        null}
                    </>
                :
                <>
                    <Button onClick={_onClickEdit} className="buttons-edit-detail buttons-edit-detail--edit">
                        <Icon className="icon-editar2" message="Editar"/>
                    </Button>
                    {props.hiddenDelete?null:<Button onClick={_onClickDelete} className="buttons-edit-detail buttons-edit-detail--delete">
                        <Icon className="icon-basura-negra" message="Eliminar"/>
                    </Button>}
                </>
            }
            {!`${props.data?.id}`.includes(keyEdit) && !props.data.editable && props.showArrows?<div className="arrow-buttons">
                <Button onClick={_clickDown} className="arrow-buttons__button">
                    <Icon message="Desplazara arriba" className="icon-expand_less"/>
                </Button>
                <Button onClick={_clickUp} className="arrow-buttons__button">
                    <Icon message="Desplazara abajo" className="icon-expand_more"/>
                </Button>
            </div>:null}
        </div>)
});

export const Factory = (data?:objectKV,saveForm?:() => any ,factory?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null ), showArrow?:boolean,hiddenDelete?:boolean ) => (props: {
    field: confTable;
    data: objectKV;
}) => {
    if(props.field.field === "editRow"){
         return <ButtonsEdit showArrows={showArrow} hiddenDelete={hiddenDelete} saveForm={saveForm} data={data ?? {}}/>;
    }
    if(factory) return factory({...props});
    return null;
};

interface propsRowDetails {
    showArrows?:boolean;
    hiddenDelete?:boolean;
    fieldsForm:Array<fields>;
    fields: Array<confTable>;
    factoryEditables?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null );
    factory?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null );
    data:objectKV; 
    index:number;
    onChange?:(assignDetailforKey: (v: action) => any,values:{
        [k: string]: string | number | boolean;
    },fields?:Array<fields>, id?:string | number) => any;
    formatteValues?:((data: objectKV, fields: fields[], key: number) => detail);
}

export const RowDetails = React.memo((props:propsRowDetails) => {
    const {fields, factory, data, index,factoryEditables, fieldsForm} = props;

    const [_data, setData ] = React.useState<objectKV>();

    const {getDetail,setState} = useDetails();

    const {assingFields:setFieldsForm,getValues,setState:setStateForm, isValid, config} = useForm();

    const [_fields, setFields] = React.useState<Array<confTable>>();

    React.useEffect(() => {
        if(props?.data?.id) {
            let _detail = getDetail(props?.data?.id);
            setData(_detail);
        }
    }, [props.data]);

    React.useEffect(() => {
        setFields([...(_data?.editable?fields.map((item:confTable) => {
            if(`${_data?.id}`.includes(keyEdit)) {
                const {type, ...all} = item;
                return all;
            }
            if(!item.isEditable) return item;
            const {type, ...all} = item;
            return all;
        }):fields)]);
    }, [_data?.editable,fields]);

    React.useEffect(() => {
        setFieldsForm([...fieldsForm.map((item:fields) => {
            item.value = _data?.data[item.name];
            return item;
        })]);
    }, [fieldsForm,_data?.data]);

    React.useEffect(() => {
        
    }, [config]);

    const saveForm = () => {
        // console.log(isValid)
        if(!isValid) {
            setStateForm({type:"SET_VALIDATE_FORM", value:true});
            return;
        }

        let _values = {...getValues()};
        if(!Object.values(_values).length) return;
        let temp:Array<fields> = Object.keys(config).map((item:string) => {
            let tempField = _data?.data[item];
            return {name:item, value:tempField};
        });
        let _id = `${_data?.id}`.includes(keyEdit)?`${new Date().getTime()}${keySave}`:_data?.id;
        if(props.onChange){
            let tempValues = {..._values,...(_values?.id?{id:_id}:{})};
            props.onChange(setState,tempValues, temp,_id);
        } else {
            let tempValues = {...Object.assign({},...temp.map((item:fields) => ({[item.name]:item.value}))),..._values,...(_values?.id?{id:_id}:{})};
            setState({type:"ASSING_DETAIL_FOR_KEY", value:{id:_id, value:tempValues}});
        }
        if(_id !== _data?.id) {
            let tempDetail  = props.formatteValues?props.formatteValues({id:_data?.id ?? "000"},props.fieldsForm,_data?.id ?? "000"):null;
            setState({type:"ASSING_DETAIL_FOR_KEY", value:{id:_data?.id ?? 0, value:tempDetail?.data ?? {}}});
        }
    };

    return _fields?<Row clickSelect={(v:any) => {}} factory={Factory(_data,saveForm,(!_data?.editable?factory:factoryEditables),props.showArrows,props.hiddenDelete)}  fields={_fields} data={_data?.data} index={index} onClick={() => {}}/>:null;
});

export const RowDetailAdapter = React.memo((props:propsRowDetails) => {
    return <FormProvider>
        <RowDetails {...props}/>
    </FormProvider>
});

interface propsList {
    showArrows?:boolean;
    hiddenDelete?:boolean;
    data:objectKV;
    fieldsForm:Array<fields>;
    fields: Array<confTable>;
    factoryEditables?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null );
    factory?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null );
    onChange?:(assignDetailforKey: (v: action) => any,values:{
        [k: string]: string | number | boolean;
    },
    fields?:Array<fields>,id?:string | number) => any;
    formatteValues?:((data: objectKV, fields: fields[], key: number) => detail);
}

const List = React.memo((props:propsList) => {
    const {factory, factoryEditables, data,fieldsForm,fields} = props;
    return data?.length? data?.map( (itemData:objectKV, indexData:number) => {
        return <RowDetailAdapter showArrows={props.showArrows} hiddenDelete={props.hiddenDelete} formatteValues={props.formatteValues} onChange={props.onChange} fieldsForm={fieldsForm} factoryEditables={factoryEditables} factory={factory}  fields={fields} data={itemData} key={itemData.id ?? indexData} index={indexData}/>
    }):null;
});

const TableDetailsNOtProvider = React.memo((props:propsTableDetails) => {

    const [fields, setFields] = React.useState<Array<confTable>>([]);
    const [fieldsEdit, setFieldsEdit] = React.useState<Array<confTable>>([]);
    const [data, setData] = React.useState<Array<{id:string | number}>>([]);

    const {details,getDetails, setState} = useDetails();

    React.useEffect(() => {
        setFields([fieldEdit,...props.fields]);
    }, [props.fields]);

    React.useEffect(() => {
        let _fields:Array<confTable> = [];
        if(fields.length) _fields = fields.map((item:confTable) => {
            const {type,path, ...all} = item;
            return all;
        });
        setFieldsEdit([..._fields]);
    },[fields]);

    React.useEffect(() => {
        if(props.fieldsForm){
            const _details:Array<detail> = [
                ...[...props.data, {id:`000${keyEdit}`}].map((item:objectKV, index:number) => {
                    if(props.assingValues) {
                        let temp = props.assingValues(item,props.fieldsForm,index);
                        if(`${item?.id ?? 0}`.includes(keyEdit)) return {...temp, editable:true}
                        return temp;
                    }
                    return {id:item?.id ?? index,data:item, editable:true};
                })
            ]

            setState({type:"ASSING_DETAIL", value:_details});
        }
    }, [props.data,props.fieldsForm]);

    React.useEffect(() => {
        let tempDetail = (details?.details ?? []).map((item:detail) => ({id:item.id,data:item.data}));
        if(JSON.stringify(tempDetail) !== JSON.stringify(data)) {
            setData([...tempDetail]);
            if(props.onChangeForm) props.onChangeForm(setState,details?.details?.filter((item:detail) => !item.id.toString().includes(keyEdit)));
        }
    },[JSON.stringify(details?.details)]);

    React.useEffect(() => {
        if(props.getValue) props.getValue(getDetails() ?? []);
    },[details]);

    return (fields?
        <Table fields={fields} data={[]}>
            <>
                <List data={data} showArrows={props.showArrows} hiddenDelete={props.hiddenDelete} formatteValues={props.assingValues} onChange={props.onChange} fieldsForm={props.fieldsForm} factoryEditables={props.factoryEditables} factory={props.factory}  fields={fields} />
                {/* <RowDetailAdapter onChange={props.onChange} fieldsForm={props.fieldsForm} fields={fieldsEdit} factoryEditables={props.factoryEditables} factory={props.factory} data={{}} key={"edit_0"} index={0}/> */}
            </>
        </Table>
        :null
    );
});

export const TableDetails = React.memo((props:propsTableDetails) => (
    <DetailsProvider>
        <TableDetailsNOtProvider {...props}/>
    </DetailsProvider>
));
