import React from 'react';
import { error, objectKV,action } from '../../../utils/interface';
import { destructor, removeAccent } from '../../../utils/utils';
import { useInput } from '../../hook/input-hook';
import { usePrevious } from '../../hook/prevValue-hook';
import { ClickAwayListener } from '../ClickAwayListener/Click.away-listener';
import TextView from '../Text/TextView';
import "./Input.scss";
import InputText from './InputText';


interface propsInputMultipleSelect {
  
  /** indica si hay algun error */
  error?:error;
  /**
   * es el label que se mostrara en el input
   */
  label?: string;

  /**
   * Indica que el componente no esta disponible
   */
  disabled?: boolean;

  /**
   * Representa la configuracion del componente,
   * pathEmit epresenta el valor que se va a retornar 
   * pathKey representa el valor que se mostrar en los items del select
   * formatter es una funcion que permite formatera los valores (IMPORTANTE si agrega este parametro los demas no son necesraios)
   * pathShowInfo es la info que se mostrara en la parte superior del select, haceindo referencia a los que estan selecionados
   */
  config: {
    pathEmit?: string | Array<string>;
    pathKey?: string | Array<string>;
    formatter?: (v: any) => ({key: any;decription: string;valueShow: string;});
    pathShowInfo?: string | Array<string>;
  }

  value: Array<string | number>;

  onFocus?: () => any;

  onBlur?: () => any;

  onChange: (v: { target: { name: string, value: Array<number | string> }}) => any;

  /**
   * representa los datos que se mostran en el select
   */
  data: Array<objectKV>;
  /** */
  [v: string]: any;
}

interface itemSelect {
  key: any;
  decription: string;
  valueShow: string;
  show: boolean;
  selected: boolean;
 };

interface stateSelect {
  values: { [k: string]: itemSelect };
}

/**
 * Representa cada uno de los items en el select 
 */
const Item = React.memo((props: { data: itemSelect, onClick: (v?: any) => any,style?:any }) => {
  let { data, onClick } = props;
  
  /**
   * Reaciona al click de cada 
   */
  const _onClick = React.useCallback(() => {
    if (!onClick || !data) return;
    onClick({type:"CLICK_ROW",value:data});
  },[onClick,JSON.stringify(data)]);

  return (<div className="item-multi-select" style={props.style} onClickCapture={ _onClick }>
    <div className="check-icon-container">
      <i className={ `check-icon-container__icon ${data.selected?"icon-check-negro check-icon-container__icon--blue":"icon-check-blanco check-icon-container__icon--gray"}`} />
    </div>
    <TextView height={14} styleText={{lineHeight:"14px"}} text={data.decription} />
  </div>);
});

export const ItemSelect2 = Item;

const ModalOptions = React.memo((props: { visible?: boolean; close: (v: any) => any;open:(v:any) => any,getParentBoundsInfomation:(v?:any) => any,onChange:(v?:any) => any,data:stateSelect,style?:objectKV}) => {

  const nameInput = "inputSelectmultiple";

  const { visible, open, close, getParentBoundsInfomation, onChange, data,style } = props;
  
  const {setValueKV,valueKV,bind:bindInput,reset} = useInput("",300,nameInput);

  React.useEffect(() => {
    let valueFiltered = valueKV && valueKV[nameInput];
    if (onChange) onChange({type:"ADD_FILTERS",value:valueFiltered});
  },[valueKV,onChange]);

  let bounds = React.useMemo(() => {
    if (visible) return getParentBoundsInfomation();
    return null;
  }, [visible]);
  
  const clickRestart = React.useCallback(() => {
    if (onChange) onChange({type:"SELECT_ALL"});
   },[onChange]);

  return (visible && bounds?
    <ClickAwayListener clickOutside={ close } >
      <div className={`modal-select-multi`} style={ style }>
        <InputText placeholder="Buscar..." name={nameInput} style={{ border: "none" }} {...bindInput} />
        <Item key={`index-l9`} data={{   key:"select",decription:"selecionar todo",valueShow:" ",show:true,selected:!Object.values(data?.values ?? {}).find((item: itemSelect) => !item.selected) }} onClick={ clickRestart } />
        {Object.values(data?.values ?? {}).sort((a:any, b:any) => {
          if (a?.valueShow > b?.valueShow) {
              return 1;
          }
          if (b?.valueShow > a?.valueShow) {
              return -1;
          }
          return 0;
    }).filter((item: itemSelect) => item.show).map((item: itemSelect, index: number) => <Item key={item.key ?? `index-${index}`} data={item} onClick={onChange} />)}
      </div>
    </ClickAwayListener>
    : null);
});

const sortArray = (data:Array<any>) => {
  console.log(data);
  return data.sort((a:any, b:any) => {
    if (a?.valueShow > b?.valueShow) {
        return -1;
    }
    if (b?.valueShow > a?.valueShow) {
        return 1;
    }
    return 0;
});
};

const reducer = (state: stateSelect, action: action) => {
  let tempValues = {}, tempValue: any = {};
  switch (action.type) {
    case "SET_VALUES":
      return Object.assign({}, state, { values: action.value ?? {} });
    case "SELECT_ALL":
      let select = !!Object.values(state.values ?? {}).find((item: itemSelect) => !item.selected);
      for (const item in (state.values ?? {})) {
        let tmpItem = state.values[item];
        if (!tmpItem.key) continue;
        tempValues = { ...tempValues, ...{ [tmpItem.key]: {...tmpItem,selected:select}}};
      }
      return Object.assign({}, state, {values:tempValues});
    case "SET_VALUE":
      if (!Array.isArray(action.value)) return state;
      for (const item in (state.values ?? {})) {
        let tmpItem = state.values[item];
        if (!tmpItem.key) continue;
        tempValues = { ...tempValues, ...{ [tmpItem.key]: {...tmpItem, selected:action.value.includes(tmpItem.key)}}};
      }
      return Object.assign({}, state, { values: tempValues });
    case "CLICK_ROW":
      tempValue = { ...(action?.value ?? {}), selected: !action?.value?.selected };
      if (!tempValue?.key) return state;
      tempValues = Object.assign({}, state.values, { [tempValue.key]: tempValue });
      return Object.assign({}, state, {values:tempValues});
    case "ADD_FILTERS":
      if (typeof (action.value) !== "string") return state;
      for (const item in (state.values ?? {})) {
        let tmpItem = state.values[item];
        if (!tmpItem.key) continue;
        tempValues = { ...tempValues, ...{ [tmpItem.key]: {...tmpItem, show:!action.value || removeAccent(`${tmpItem.decription}`).toLocaleLowerCase().includes(removeAccent(action.value).toLocaleLowerCase())}}};
      }
      return Object.assign({}, state, {values:tempValues});
    case "RESET":
      for (const item in (state.values ?? {})) {
        let tmpItem = state.values[item];
        if (!tmpItem.key) continue;
        tempValues = { ...tempValues, ...{ [tmpItem.key]: {...tmpItem,selected:false}}};
      }
      return Object.assign({}, state, {values:tempValues});
    default:
      throw new Error();
  }
}

const init:stateSelect = {
  values: {}
};

export const InputSelectMultiple = React.memo((props: propsInputMultipleSelect) => {
  const { label, error, name, className, disabled, config, style, ...bind } = props;

  const { value, onChange, data,onFocus,onBlur, ...allRest } = bind ?? {};

  const [_value, setValue] = React.useState<string>();

  const [focus, setFocus] = React.useState<boolean>(false);

  const [visible, setVisible] = React.useState<boolean>();
  
  const el = React.useRef<any>(null);

  const prevValue = usePrevious(value);

  const prevData = usePrevious(data);

  const [state, dispatch] = React.useReducer(reducer, init);

  const close = React.useCallback(() => {
    setVisible(false);
  }, []);
  
  const open = React.useCallback(() => {
    setVisible(true);
  }, []);

  React.useEffect(() => {
    if (visible === undefined) {
      onChange({ target: { name, value: value??[] } });
    }
    else if (visible) {
      if (onFocus) onFocus();
    } else {
      onChange({ target: { name, value: Object.values(state.values ?? {}).filter((item: any) => item.selected).map((item: any) => item.key) } });
      if (onBlur) onBlur();
    }
  }, [visible]);
  
  /**
   * @description Obtiene la informacion de la posicion del componente en el documento
   */
  const getParentBoundsInfomation = React.useCallback(() => {
    const tempEl: any = el?.current;
    if (tempEl?.getBoundingClientRect) {
      const allRest = JSON.stringify(tempEl?.getBoundingClientRect());
      return Object.assign({}, JSON.parse(allRest));
    }
    return null;
  }, [JSON.stringify(el?.current?.getBoundingClientRect())]);

  const values = React.useMemo(() => {
    return Object.values(state?.values ?? {}).filter((item: any) => item.selected).map((item: any) => ({key: item.key,decription: item.decription,valueShow: item.valueShow}));
  }, [JSON.stringify(state.values ?? {})]);

  React.useEffect(() => {
    if (!data.length) return dispatch({ type: "SET_VALUES", value: [] });
    if (JSON.stringify(prevData) === JSON.stringify(data)) return;
    let newData = {};
    data.forEach((item: objectKV) => {
      let temp: any;
      if (config.formatter) {
        temp = config.formatter(item);
        newData = { ...newData, ...{ [temp.key]: { show: true, selected: false, ...temp } } };
      } else {
        temp = { key:destructor(config.pathEmit??"",item),decription:destructor(config.pathKey??"",item),valueShow: destructor(config.pathShowInfo??"",item)};
        newData = { ...newData, ...{ [temp.key]: { show: true, selected: false, ...temp } } }
      }
    });
    dispatch({ type: "SET_VALUES", value: newData});
  },[JSON.stringify(data), config]);
  
  React.useEffect(() => {
    if (JSON.stringify(prevValue) === JSON.stringify(value)) return;
    dispatch({ type: "SET_VALUE", value});
  },[JSON.stringify(value)]);

    return (
      <div className="container" ref={ el }>
            {label?<label className={`text-conf ${error?.error?"error":""}`}>{label}</label>:null}
            <div
            onClickCapture={ open}
            className={`select_multiple__button ${disabled ? "select_multiple__button--disabled" : ""} `}
            style={ style }
            >
              <div className="select_multiple__button__text">
                {values.map((item:any) => item.valueShow).filter(Boolean).join(", ") }
              </div>
            </div>
        {error?.error ? <label className="text-conf error">{error.message}</label> : null}
        <ModalOptions data={state} style={style?.width ? {width:style?.width}:undefined} onChange={ dispatch } visible={visible} open={open} close={close} getParentBoundsInfomation={ getParentBoundsInfomation }/>
        </div>
    );
});
