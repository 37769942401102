import React from "react";
import SideBar from "./../Componets/General/SideBar/SideBar";
import { useLazyQueryToSever } from "../Componets/hook/query-hook";
import { getSysEvents, getSysState, queryUtils as GET_UTILS } from "./../utils/utils";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useRouteMatch,
  } from "react-router-dom";
import Home from "./Home/Home";
import Login from "./Login/Login";
// import AddController from "./../src-old/pages/AddController/AddController";
// import Pages from "./../src-old/pages/Export";
import { Travel } from "./Home/Travel/Travel";
import { PlanTrip } from "./PlanTrip/PlanTrip";
import { Users } from "./Users/Users";
import { Routes } from "./Routes/Routes";
import { Events } from "./Events/Events";
import { CheckPoint } from "./CheckPoint/CheckPoint";
// import { CheckPointFisico } from "./CheckPointFisico/CheckPoint";
import "./Navigation.scss";
import NotFound from "./../Componets/Basic/NotFound/NotFound";

//desabilitar para producion 
import PlayGround from "./PlayGround/PayGround";

import { UserProvider } from "./../Componets/Helpers/Session/user-context";
import { objectKV } from "../utils/interface";
import { getStoreInfo, getStoreInfoUtils, setStoreInfoUtils } from "../utils/store";
import { SocketProvider } from "../Componets/Helpers/Socket/socket-context";
import { NotificationContainer } from "./../Componets/General/Notification/Notification";
import { GalleryView } from "./Home/Gallery/Gallery";
import { ResetPass } from "./Login/ResetPass";
import Stadistics from "./Stadistics/Stadistics";
import { SideBarProvider } from "../Componets/Helpers/SideBar/side-bar-context";
import { HistoryView } from "./History/History";
import { useApolloClient } from "@apollo/client";
import { useClientApolloGranada } from "../Componets/hook/client-apollo-hook";
import { useNotification } from "../Componets/hook/notification-hook";
import { NotificationError } from "../Componets/General/FormsUtils/Notification.fom";
import { TravelDelete } from "./TravelDelete/TravelDelete";
import { Client } from "./Clients/Client";
import { useUser } from "../Componets/hook/user-hook";
import { Ctns } from "./Ctns/Ctns";
import { Downloadmanagment } from "./Downloadmanagment/Downloadmanagment";

const disabledPaths:any = {
    3:["TrvlDelete"],
    // 4:["TrvlDelete","statistics"]
};

const ViewsSideBar = () => {

    
    let { url } = useRouteMatch();
    
    let [loadData, { data }] = useLazyQueryToSever({query:GET_UTILS});
    
    const apolloClient = useClientApolloGranada();

    //este es un contador que se altera cada minuto sirve para los botones y todos los contadores internos
    // NOTA si lo va a alterar debe tener mucho cuidado, pues puede afectar el socket los bootones de reporttes, los contadores de retrazo
    React.useEffect(() => {
        let interval = setInterval(() => {
            apolloClient.writeQuery({query:`
            query counter{
                timeCounter    
            }`, data: { timeCounter: new Date().getTime() } });
        },60000);
        getUtils();
        return () => {
            clearInterval(interval);
        }
    }, []);

    React.useEffect(() => {
        const { sysEstados, sysEventos } = data ?? {};
        if(sysEstados || sysEventos){
            const tempData = {
                sysEstados:{
                    data:sysEstados
                },
                sysEventos:{
                    data:sysEventos
                }
            };
            setStoreInfoUtils(tempData);
        }
    }, [data]);

    const getUtils = () => {
        const { sysEstados, sysEventos } = getStoreInfoUtils() ?? {};
        if(!sysEstados?.data || !sysEventos?.data) loadData();
        else {
            apolloClient.writeQuery({query:getSysEvents,data:{sysEventos:sysEventos?.data}});
            apolloClient.writeQuery({query:getSysState,data:{sysEstados:sysEstados?.data}});
        }
    };

    return (
        <SideBarProvider>
        <SocketProvider>
            <div className="views-home">
                <div className="views-home__sidebar">
                    <SideBar/>
                </div>
                <div className="views-home__navigation">
                    <Switch>
                        <ProtectedRoute exact path={url}>
                            <Home />
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/Viaje/:id`}>
                            <Travel/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/history`}>
                            <HistoryView/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/downloadmanagment`}>
                            <Downloadmanagment/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/Gallery/:id`}>
                            <GalleryView/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/statistics`}>
                            <Stadistics/>
                        </ProtectedRoute>
                        {/* <ProtectedRoute path={`${url}/Controlador`}>
                            <AddController/>
                        </ProtectedRoute> */}
                        <ProtectedRoute path={`${url}/PuestoControl`}>
                            <CheckPoint/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/PlanViaje`}>
                            <PlanTrip/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/Rutas`}>
                            <Routes/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/Usuarios`}>
                            <Users/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/Eventos`}>
                            <Events/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/TrvlDelete`}>
                            <TravelDelete/>
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/ctns`}>
                            <Ctns />
                        </ProtectedRoute>
                        <ProtectedRoute path={`${url}/Client`}>
                            <Client />
                        </ProtectedRoute>
                        {/* <ProtectedRoute path={`${url}/puestosdecontrolfisicos`}>
                            {   getStoreInfo().idcompany === 4?
                                <CheckPointFisico/>:
                                <Redirect to={`${url}/network-error`} /> 
                            }
                        </ProtectedRoute> */}
                        <Route path={`${url}/network-error`} >
                            <NotFound />
                        </Route>
                        <Route  >
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
            </div>
        </SocketProvider>
    </SideBarProvider>
    );
}

const ProtectedRoute = (props:{children: JSX.Element } | objectKV) => {
    let {children, ...all} = props;
    const {user} = useUser();

    const _disabled = React.useMemo(() => {
        let _all:any = all;
        let path = `${_all.path}`.split("/").reverse()[0];
        return disabledPaths[`${user?.idcompany}`]?.includes(path);
    },[JSON.stringify(all),user]);
    return ( 
    // <Route {...all} render={() => children } />
    //NOTA: use esta funcio devido a que el hook es async, en este caso es mejor que sea sync 
    !_disabled?<Route {...all} render={() => getStoreInfo()?.token? children :<Redirect to="/Login" />} />:<Redirect to="/Home/network-error" />
    );
}
const Navigation = () => {

    const {addNotification} = useNotification();

    // React.useEffect(() => {
    //     let browsers = ["Chrome", "Firefox", "Opera"];
    //     // let browsers = ["Firefox", "Opera"];
    //     const getBrowser = () => {
    //         console.log(window.navigator.userAgent)
    //         return browsers.find((item:string) =>  window.navigator.userAgent.indexOf(item)!= -1);
    //     };
    //     let browserInUse = getBrowser();
    //     console.log(browserInUse);
    //     if(!browserInUse) {
    //         addNotification({element:<NotificationError message={`Se recomienda usar ${browsers.join(",")}`} />,delay:6000});
    //     }
    // },[]);

    return (
        <React.Fragment>
            <NotificationContainer />
            <Switch>
                <ProtectedRoute path="/Home"> 
                    <ViewsSideBar /> 
                </ProtectedRoute>
    
                <Route exact path="/">
                    <Redirect to="/Home" />
                </Route>
    
                <Route exact path="/Login" >
                    <Login />
                </Route>

                <ProtectedRoute exact path="/changePassword" >
                    <ResetPass disabledCancel/>
                </ProtectedRoute>
                
                <ProtectedRoute exact path="/resetPass" >
                    <ResetPass/>
                </ProtectedRoute>
                {/* desabilitar para producion  */}
                <Route path ="/playGround" >
                    <PlayGround />
                </Route>

                <Route  >
                    <NotFound />
                </Route>
            </Switch>
        </React.Fragment>
        );
}

const NavigationAdapter = () => {
    return (
        <Router>
            <UserProvider>
                <Navigation />
            </UserProvider>
        </Router>
    )
};

export default NavigationAdapter;
