import React from "react";
import { dataInfoClient } from "../../../Views/Clients/Client.conf";
import "./TextView.scss";

interface propsListViwer {
    /**
     * indica la etiqueta de 
     */
    label?: string;

    /**
     * es el ancho del campo en caso de no estar se ajustara al padre
     */
    width?:number;

    /**
     * es el largo del campo en caso de no estar se ajustara al padre
     */
    height?:number;

    /**
     * determina el tamaño de la lista antes de hacer scroll
     */
    heightList:number|string;

    /**
     * Es la informacion que se va a presentar
     */
    data:Array<string|number>;

    children?:JSX.Element[] | JSX.Element

}


/**
 * @description esta funcion se encarga de crear un componete de visualizacion para texto,
 * en caso de que el texto supere el tamaño del compoenente este lanzara un tooltip con el contenido del texto
 * @param props 
 */

const ListView = (props:propsListViwer) => {
    
    return (
        <div className="text-view" 
        style={{  width: props.width ?? "",height: (props.heightList && typeof(props.heightList) === "number"?props.heightList+15:props.heightList) ?? ""}} 
        >
            {props.label? <div
            className="text-view__label">{props.label}</div>:null}
            <div
            className="text-view__list"
            style={{height:props.heightList}} >
                <ul>
                    {props.data.map((item:string|number) => <li>{item}</li>)}
                </ul>
            </div>
        </div>
    );
}

export default ListView;