import React from 'react'
import './Forms-report.scss'
import {
  GeneralInfo,
  Observations,
  ControlButtons,
  UploadFile,
  DatePicker,
  GET_INFO,
  GET_TIMES,
  REPORT_MUTATION
} from './Form.general-info'
import { useMutationServer } from '../../../Componets/hook/mutation-hook'
import { objectKV, action, error } from '../../../utils/interface'
import { useLazyQueryToSever } from '../../../Componets/hook/query-hook'
import { useUser } from '../../../Componets/hook/user-hook'
import TextView from '../../../Componets/Basic/Text/TextView'
import {
  formatterTimeZoneDate,
  getDateServer,
  reportFormatter,
  validatorTimeDate
} from '../../../utils/utils'
import { uploadImage } from '../../../Componets/General/UploadImage/UploadImage'
import { queryTablesPlan, queryTablesRemitances } from '../Travel/Travel.config'

export const FormCheck = (props: {
  id?: number | string
  close: () => any
  sysEvent?: objectKV
}) => {
  const [sendData, { data, error }] = useMutationServer({
    mutation: REPORT_MUTATION
  })
  const [loadDataTimes, {}] = useLazyQueryToSever({
    query: GET_TIMES,
    fetchPolicy: 'cache-first'
  })
  const [loadData, { data: dataFill }] = useLazyQueryToSever({
    query: GET_INFO,
    fetchPolicy: 'cache-first'
  })
  const [loadDataTripPlan, { data: dataFillTripPlan }] = useLazyQueryToSever({
    query: queryTablesPlan,
    fetchPolicy: 'cache-first'
  })
  const [
    loadDataRemittances,
    { data: dataFillRemittances }
  ] = useLazyQueryToSever({
    query: queryTablesRemitances,
    fetchPolicy: 'cache-first'
  })
  const {
    user: { id }
  } = useUser()

  const init = {
    fecha: '',
    hora: '',
    idpuestocontrol: null,
    idevento: null,
    idremesas: [],
    idusuario: null,
    idviaje: null,
    imagenes: [],
    lugar: '',
    novedad: ''
  }

  // React.useEffect(() => {
  //     console.log("este es el error",error);
  // },[error]);
  React.useEffect(() => {
    let tempId = +dataFill?.viaje?.id
    let tempIdTrip = +dataFill?.viaje?.idplanviaje
    if (tempId) loadDataRemittances({ variables: { id: tempId } })
    if (tempIdTrip)
      loadDataTripPlan({ variables: { idviaje: dataFill?.viaje?.id } })
  }, [dataFill?.viaje])

  const reducer = (state: objectKV, action: action) => {
    switch (action.type) {
      case 'SET_DATA':
        return Object.assign({}, state, action.value)
      default:
        return state
    }
  }

  const [messageErrorDatePicker, setMessageErrorDatePicker] = React.useState<
    string
  >()
  const [dateServer, setDateServer] = React.useState<string>()
  const [keyLocalDate, setKeyLocalDate] = React.useState<string>()

  const [nextPoint, setNextPoint] = React.useState<string>()
  const [errorDate, setErrorDate] = React.useState<error>()
  const [errorTime, setErrorTime] = React.useState<error>()
  const [errorNovedad, setErrorNovedad] = React.useState<error>()
  const [date, setDate] = React.useState<number>()
  const [uploading, setUploading] = React.useState<boolean>()

  const [state, dispatch] = React.useReducer(reducer, init)

  const _onClickSend = async () => {
    if (!(await validator(state))) return
    setUploading(true)
    await sendReport(state).finally(() => {
      setUploading(false)
    })
  }

  const sendReport = async (v: any) => {
    let { images, report } = await reportFormatter(
      Object.assign({}, v, { novedad: `${v.novedad}${keyLocalDate ?? ''}` })
    )
    let resReport = await sendData({ variables: { report: [report] } }).catch(
      (err: any) => ({ error: true })
    )
    if (
      resReport?.data?.createReporteV2?.length &&
      resReport?.data?.createReporteV2[0]?.uploadLinks?.length
    ) {
      await Promise.all(
        resReport.data.createReporteV2[0].uploadLinks.map(
          async (item: objectKV) => {
            let file = images.find((f: File) => f.name === item.key)
            return item.url && file
              ? await uploadImage(item.url, file, file.type)
              : null
          }
        )
      )
    }
  }

  const _onClickCancel = () => {
    props.close()
  }

  const validator = async (v: objectKV) => {
    let currentDate = (await getDateServer(!!keyLocalDate)).split('##')[0]

    if (!currentDate || typeof currentDate !== 'string') {
      setMessageErrorDatePicker('No se pudo obtener la hora del servidor')
      return false
    }
    setErrorDate({ error: !v.fecha, message: 'Requerido' })
    setErrorTime({ error: !v.hora, message: 'Requerido' })
    setErrorNovedad({ error: !v.novedad, message: 'Requerido' })
    let isnotvalidDate = validatorTimeDate(
      v.hora ?? '',
      v.fecha ?? '',
      formatterTimeZoneDate(currentDate).timeCurrentZone,
      formatterTimeZoneDate(+(dataFill?.viaje?.fechahoraultimoevento ?? 0))
        .timeCurrentZone
    )
    setMessageErrorDatePicker(isnotvalidDate)
    if (!v.fecha || !v.hora || !v.novedad || isnotvalidDate) return false
    return true
  }

  React.useEffect(() => {
    getDateServer().then((res: string) => {
      let _resDate = res.split('##')
      setKeyLocalDate(_resDate[1])
      setDateServer(_resDate[0])
      setDate(formatterTimeZoneDate(_resDate[0]).timeCurrentZone ?? 0)
    })
  }, [])

  React.useEffect(() => {
    if (props.id) {
      loadData({ variables: { id: props.id } })
      loadDataTimes({ variables: { id: props.id } })
    }
  }, [props.id])

  React.useEffect(() => {
    let value = dataFill?.viaje
    let valueRemittances = dataFillRemittances?.remesasInfo?.data
    let valueTripPlan = dataFillTripPlan?.planviajePorViaje?.data?.length
      ? dataFillTripPlan?.planviajePorViaje?.data[0]?.planviajedetalles
      : undefined
    if (
      value &&
      props.sysEvent &&
      valueTripPlan?.length &&
      valueRemittances?.length
    ) {
      let cPoint = valueTripPlan.find(
        (item: objectKV) =>
          value.idproximopuestocontrol === item.idpuestocontrol
      )
      // console.log("VVVV",dataTravelPlan);
      setNextPoint(value.proximopuestocontrol)
      dispatch({
        type: 'SET_DATA',
        value: {
          lugar:
            props.sysEvent.id === 7
              ? 'sistema'
              : cPoint?.direccion ?? 'sistema',
          ...(cPoint?.geolocalizacion && props.sysEvent.id !== 7
            ? {
                geolocalizacion: {
                  latitud: cPoint?.geolocalizacion?.latitud,
                  longitud: cPoint?.geolocalizacion?.longitud
                }
              }
            : {}),
          idviaje: value.id,
          idsysevento: props.sysEvent.id,
          idpuestocontrol:
            props.sysEvent.id === 10 ? value.idproximopuestocontrol : null,
          idremesas:
            valueRemittances
              .filter((item: objectKV) => [13].includes(item?.idsysestado ?? 0))
              .map((item: { id: number | string }) => item.id) ?? []
        }
      })
    }
  }, [dataFill, props.sysEvent, dataFillRemittances, dataFillTripPlan])

  React.useEffect(() => {
    dispatch({ type: 'SET_DATA', value: { idusuario: id } })
  }, [id])

  React.useEffect(() => {
    if (data?.createReporteV2?.length && !uploading) {
      props.close()
    }
  }, [data, uploading])

  return (
    <div className='form-check general-form'>
      {/* <div className='form-check__name-report name-report'>
        {props.sysEvent?.descripcion ?? 'Reporte Puesto de control'}
      </div> */}
      <div className='form-check__general-info'>
        <GeneralInfo
          {...props}
          title={props.sysEvent?.descripcion ?? 'Reporte Puesto de control'}
          formato={2}
        />
      </div>
      <div className='form-check__check-point'>
        <TextView text={nextPoint ?? 'NA'} styleText={{ fontWeight: 900, fontSize: 16 }} label='Puesto de Control' />
      </div>

      <div className='form-check__date-picker'>
        <DatePicker
          dateString={dateServer}
          messageGeneralError={messageErrorDatePicker}
          onChange={dispatch}
          date={date}
          errorDate={errorDate}
          errorTime={errorTime}
        />
      </div>

      {/* <div className={"form-check__event"}>
                <TextView label={"Evento"} text="NA"/>
            </div> */}
      <div className='form-check__upload-file'>
        <UploadFile onChange={dispatch} />
      </div>

      <div className='form-check__observation'>
        <Observations
          error={errorNovedad}
          onChange={dispatch}
          value='Sin Novedad'
        />
      </div>
      <div className='form-check__buttons'>
        <ControlButtons
          buttonCancel={{ onClick: _onClickCancel, disabled: uploading }}
          buttonSend={{
            onClick: _onClickSend,
            loading: uploading,
            disabled: uploading
          }}
        />
      </div>
    </div>
  )
}
