import React from "react";
import { objectKV } from "../../../utils/interface";
import { destructor, formatterTimeZoneDate } from "../../../utils/utils";

interface propsDateViwer {
    path:string | Array<string>;
    data:objectKV;
    className?:string;
}

export const formatterDateTime = (date:any) => {
    let _date:any;
    let time = new Date(formatterTimeZoneDate(+date).dateString).toTimeString();
    time = time.split(':').slice(0,2).join(':');
    _date = new Date(formatterTimeZoneDate(+date).dateString);
    _date = _date.toLocaleDateString().split("/").join('/');
    _date = `${_date}-${time} `;
    return _date;
};

export const DateViwer = (props:propsDateViwer) => {
    let date:any;

    date = destructor(props.path,props.data);
    
    if(+date) {
       date = formatterDateTime(+date);
    }

    return (date? <label className={props.className}>{date}</label>:null);
};