import React from 'react';
import Button from '../../Componets/Basic/Button/Button';
import Icon from '../../Componets/Basic/Icon/Icon.tooltip';
import InputText from '../../Componets/Basic/Input/InputText';
import { Modal } from '../../Componets/Basic/Modal/Modal';
import TextView from '../../Componets/Basic/Text/TextView';
import { ButtonsFooterForm } from '../../Componets/General/FormsUtils/Buttons.form';
import { ModalAdapter } from '../../Componets/General/FormsUtils/FormModal';
import { NotificationError, NotificationSucces } from '../../Componets/General/FormsUtils/Notification.fom';
import { LayoutWithOutPage } from '../../Componets/General/Layout/LayoutWithOutPage';
import { useInput } from '../../Componets/hook/input-hook';
import { useMutationServer } from '../../Componets/hook/mutation-hook';
import { useNotification } from '../../Componets/hook/notification-hook';
import { usePrevious } from '../../Componets/hook/prevValue-hook';
import { useLazyQueryToSever } from '../../Componets/hook/query-hook';
import { objectKV } from '../../utils/interface';
import "./TravelDelete.scss";

const mutationDelete = `
mutation delTravel($id: Int,$numero: String){
	deleteTravel(id:$id,numero:$numero){
    id
  }
}`;

export const travelsQuery = `
query ViajesInfo ($id:Int!){
  viaje(id:$id) {
    id
    numero
    placa
    origen
    destino
    ruta
  }
}
`;

export const TravelDelete = React.memo(() => {

    let {value:id,bind:bindId} = useInput();
    let {value:numero,bind:bindNumero} = useInput();
    const {addNotification} = useNotification();

    const [modalVisible, setModalVisible] = React.useState<boolean>(false);

    const [travelDelete, {loading:loadingDelete, data:dataDelete, error:errorDelete}] = useMutationServer({mutation:mutationDelete});

    const deleteTravel = React.useCallback(() => {
        let _id = +(id??0);
        if(!_id||!numero||loadingDelete) return;
        travelDelete({variables:{id:_id,numero}});
        setModalVisible(false);
    },[id,numero]);

    const close = React.useCallback(() => {
        setModalVisible(false);
    },[]);

    const open = React.useCallback(() => {
        let _id = +(id??0);
        if(!_id||!numero) return;
        setModalVisible(true);
    },[numero,id]);

    React.useEffect(() => {
        if(errorDelete){
            addNotification({element:<NotificationError message="Ocurrio un error" />,delay:3000});
        }
    }, [errorDelete]);

    React.useEffect(() => {
        if(dataDelete?.deleteTravel?.id&&!errorDelete){
            addNotification({element:<NotificationSucces message="Se Elimino el viaje" />,delay:3000});
        }
    }, [dataDelete]);

    return <LayoutWithOutPage
    title="Borrar viaje"
    >
        <ModalConfirm 
        deleteTravel={deleteTravel} 
        visible={modalVisible} 
        close={close} 
        open={open} 
        id={id}
        number={numero}
        loading={loadingDelete}/>
        <div className="container-form-delete">
            <div className=" warning-container">
                <div className="warning-container__icon content-center">
                    <Icon className="icon-warning"/>
                </div>
                <h2 className="warning-container__title content-center">Atencion!</h2>
                <div className="warning-container__text content-center">
                    <ul>
                        <li>Esta opción está habilitada únicamente para el administrador, si usted no lo es, abstenerse de realizar cualquier acción.</li>
                        <li>Esta opción permite eliminar un viaje de la plataforma de CTRL TRÁFICO.</li>
                        <li>Después de que el viaje sea eliminado <b>toda la información (remesas y reportes) se eliminará definitivamente.</b> </li>
                        <li>Luego de que el viaje es eliminado CTRL TRÁFICO permitirá que se suba nuevamente este manifiesto desde SYSCAR, KRGO o la plataforma de su preferencia.</li>
                        <li>Realizar esta acción solo si esta seguro.</li>
                    </ul>
                </div>
            </div>
            <div className="content-center">
                <h4>Por favor ingrese los siguientes datos (Ambos).</h4>
            </div>
            <div className="content-center">
                <InputText style={{width:100}} label="N° Viaje*" {...bindId}/>
                <InputText style={{width:200}} label="N° Manifiesto*" {...bindNumero}/>
                <Button disabled={loadingDelete} label={loadingDelete?"...":"Borrar"} className="button-delete-travel" onClick={open}/>
            </div>
        </div>
    </LayoutWithOutPage>
});


interface modalConfirmProps {
    close:()=>any;
    open:()=>any;
    deleteTravel:()=>any;
    visible?:boolean;
    loading?:boolean;
    id?:string;
    number?:string;
}

const ModalConfirm = React.memo((props:modalConfirmProps) => {

    const [loadData,{data,loading,error,called}] = useLazyQueryToSever({query:travelsQuery,fetchPolicy:"cache-first"});

    const [travelsData,setTravelsData] = React.useState<objectKV>();

    const _onClickConfirm = React.useCallback(() => {
        if(loading || !called || !data?.viaje?.id || data?.viaje?.numero !== props.number ) return props.close();
        props.deleteTravel();
    },[loading,called,props.number,data]);

    const prevId = usePrevious(props.id);

    React.useEffect(() => {
        let _id = +(props.id??0);
        if(props.visible && _id) loadData({variables:{id:_id}});
        if(+(prevId??0) !== _id)setTravelsData(undefined);
    },[props.visible]);

    React.useEffect(() => {
        if(loading) return;
        if(!data?.viaje?.id || data?.viaje?.numero !== props.number) return setTravelsData(undefined);
        setTravelsData(data.viaje);
    },[data,error]);

    return (
        <Modal visible={!!props.visible} clickOutside={props.close} backGroundColor="#00000030">
            <div className="container-confirm-modal">
                <div className="container-confirm-modal__info">
                {!travelsData && !loading? <div className="container-confirm-modal__info__error"><h3>No se encontro el viaje</h3></div>:
                    <React.Fragment>
                        <div className="container-confirm-modal__info__icon"><Icon className="icon-warning"/></div>
                        <div className="container-confirm-modal__info__message"><h3>{`Esta seguro de eliminar el viaje ${travelsData?.id??""}`}</h3></div>
                        <div className="container-confirm-modal__info__plate"><TextView label="Placa" text={travelsData?.placa}/></div>
                        <div className="container-confirm-modal__info__origin"><TextView label="Origen" text={travelsData?.origen}/></div>
                        <div className="container-confirm-modal__info__destination"><TextView label="Destino" text={travelsData?.destino}/></div>
                        <div className="container-confirm-modal__info__route"><TextView label="Ruta" text={travelsData?.ruta}/></div>
                    </React.Fragment>
                }
                </div>
                <ButtonsFooterForm loading={props.loading} btnClose={{label:"Cancelar",onClick:props.close, style:{color:"#d10038", borderColor:"rgb(236, 233, 233)"}}} btnSave={{label:"Aceptar",onClick:_onClickConfirm, style:{backgroundColor:"#d10038"}}} />
            </div>
        </Modal>
    );
});