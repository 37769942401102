import { confTable } from "../../utils/interface";

export const queryStadistics = `
query getStadistics($field:String!,$filters: [FilterInput]){
    viajesStadistics(field:$field,filters:$filters){
      data{
        id
        value
        total
        numregs
      }
      totalreg
    }
  }
`;

export const dataSelect = [
  {value:"cliente",description:"Generador"},
  {value:"nombreconductor",description:"Conductor"},
  {value:"placa",description:"Placa"},
  {value:"comercial",description:"Comercial"},
  {value:"tipovehiculo",description:"Tipo vehiculo"},
  {value:"ruta",description:"Ruta"},
  {value:"origen",description:"Origen"},
  {value:"destino",description:"Destino"},
  {value:"modalidadnombre",description:"Modalidad"},
  {value:"nombrepropietariovehiculo",description:"Tenedor"},
  {value:"puestocontrol",description:"Puesto control"}
  // {value:"numero",description:"dd"},
];

export const fieldsStadistics : Array <confTable> = [
    {
      label:"#",
      field:"consecutivo",
      filterable:false,
      orderable:false,
      type:"TEXT",
      path:"consecutivo",
      // width:50,
      textAling:"LEFT"
    },
    {
        label:"Valor",
        field:"value",
        filterable:false,
        orderable:false,
        type:"TEXT",
        path:"value",
        width:400,
        textAling:"LEFT"
    },
    {
      label:"Cant.",
      field:"total",
      filterable:false,
      orderable:false,
      type:"TEXT",
      path:"total",
      width:50,
      textAling:"RIGHT"
    },
    {
        label:"% Part.",
        field:"percentage",
        filterable:false,
        orderable:false,
        type:"TEXT",
        path:"percentage",
        width:60,
        textAling:"RIGHT"
    },
    {
        label:"Acum.",
        field:"acumulado",
        filterable:false,
        orderable:false,
        width:100,
        type:"TEXT",
        path:"accumulated",
        textAling:"RIGHT"
    },
    {
      label:"",
      field:"acumulado2",
      filterable:false,
      orderable:false,
      width:10,
      voidValue:"",
      path:"1",
      textAling:"RIGHT"
  }
]