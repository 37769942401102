import React from 'react';
import { InputSelect } from '../../Componets/Basic/Input/InputSelect';
import { ClientFilter } from '../../Componets/General/UtilsView/Utils-view';
import { factorFilter } from "../../utils/interface";
import { Modality, SysStatusFilter } from '../Home/FactoryHome';


export const FactoryFilters = (props:factorFilter) => {

    if(props.confField.field === "idsysestado"){
        return (<SysStatusFilter idsRemoved={[0]} style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "modalidad"){
        return (<Modality style={{width:60}}  name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if (props.confField.field === "cliente") {
        return <ClientFilter idsysevents={[1,2,3,4,5,6,7,8,9,10,11,12,13]} style={{width:props.confField.width}}  name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>
     }

    //se usa indicador de alto riesgo para referenciar las mercancias ya que este es el campo que lo representa en GRAPHQL
    if(props.confField.field === "indicadoraltoriesgo"){
        return (<InputSelect style={{width:50}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}
            data={[{value:"false",className:"icon-icons8-box box-icon"},{value:"true",className:"icon-warning warning-icon"}]}
            confSelect={{pathEmit:"value",textAling:"CENTER"}}
        />);
    }
    return null;
};
