import { fields } from "../../Componets/Helpers/Form/Form-context";
import { confTable } from "../../utils/interface";

export const dataInfoEvent = `
id
descripcion
nivelprioridad
indicadorvisiblecliente
indicadorpuestocontrol
indicadorconductor
`;

export const queryEvent = `
query getEvent($id: Int!){
    eventoV2(id:$id){
        ${dataInfoEvent}
    }
}
`;
export const queryEvents = `
query getEvents ( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
    eventosV2 (
        filters: $filters,
        orders: $orders,
        pagination: { fields:$fields, page: $page}
      ) {
        data{
            ${dataInfoEvent}
        }
        totalreg
      }
  }
`;

export const mutationDelEvent = `
mutation daleteEvento($ids: [Int]) {
    daleteEvento(ids: $ids) {
       ${dataInfoEvent}
    }
}
`;

export const mutationCreateEvent = `
mutation createEvento($evento: EventoInput) {
    createEvento(evento: $evento) {
       ${dataInfoEvent}
    }
}
`;

export const mutationUpdateEvent = `
mutation updateEvento($evento: EventoInput, $id: Int) {
    updateEvento(evento: $evento, id: $id) {
       ${dataInfoEvent}
    }
}
`;

export const queryInfoEvents = `
{
    id
    descripcion
    nivelprioridad
    idempresatransportadora
    indicadorvisiblecliente
    idempresa
  }
`;

export const fieldsTable : Array <confTable>  = [
    {
        field:"Descripcion",
        label:"Descripción",
        filterable:true,
        orderable:true,
        type:"TEXT",
        path:"descripcion"
    },
    {
        field:"nivelprioridad",
        label:"Nivel prioridad",
        textAling:"CENTER",
        filterable:true,
        orderable:true,
    },
    {
        field:"indicadorvisiblecliente",
        label:"Mostrar Cliente",
        type:"BOOLEAN",
        textAling:"CENTER",
        path:"indicadorvisiblecliente",
        width:150,
        filterable:true,
        orderable:true,
    },
    {
        field:"indicadorconductor",
        label:"Indicador conductor",
        type:"BOOLEAN",
        width:150,
        textAling:"CENTER",
        path:"indicadorconductor",
        filterable:true,
        orderable:true,
    },
    {
        field:"indicadorpuestocontrol",
        label:"puesto control",
        type:"BOOLEAN",
        hidden:true,
        width:150,
        textAling:"CENTER",
        path:"indicadorpuestocontrol",
        filterable:true,
        orderable:true,
    }
];

export const filedsForm:Array<fields> = [
    {
        name:"descripcion",
        path:"descripcion",
        validator:[true]
    },
    {
        name:"nivelprioridad",
        path:"nivelprioridad",
        validator:[true]
    },
    {
        name:"indicadorvisiblecliente",
        path:"indicadorvisiblecliente",
    },
    // {
    //     name:"indicadorpuestocontrol",
    //     path:"indicadorpuestocontrol",
    // },
    {
        name:"indicadorconductor",
        path:"indicadorconductor",
    }
];