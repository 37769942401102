import React from "react";
import  { Table } from "./../../Componets/Basic/Table/Table"
import { queryViajes,fields as fieldsConfig } from "./Home.config";
import { FactoryFilters, Factory, NotificationReport, useSysState } from "./FactoryHome";
import { Layout } from "./../../Componets/General/Layout/Layout";
import { objectKV, action, confMap } from "./../../utils/interface";
import { useLazyQueryToSever } from "./../../Componets/hook/query-hook";
import { Redirect } from "react-router-dom";
import { useSocket } from "../../Componets/hook/socket-hook";
import { useNotification } from "../../Componets/hook/notification-hook";
import { ExportExcel, FiltersOptions, parceOrders, TotalRegs } from "../../Componets/General/UtilsView/Utils-view";
import { useConfTable } from "../../Componets/hook/conf-table-hook";
import "./Home.scss";
import { useUser } from "../../Componets/hook/user-hook";
import { usePrevious } from "../../Componets/hook/prevValue-hook";

const GET_TRAVELS = `${queryViajes}`;

interface HomeProps {}

const KEY = "homeT";

const Home = (props: HomeProps) => {

    const tableRef =  React.useRef(null);

    const { sys } = useSysState([4,7]);

    const { user } = useUser();

    //Uso el id 1000 para la tabla de viajes 
    const {
        table,
        setConfig
    } = useConfTable(fieldsConfig,{id:1000,key:KEY});

    let [id, setId] = React.useState<number>();

    const initial = {
        /**
         * estos valores tambien estan en el state pero no tiene un valor inicial
         */
        //data: es la info que se odtine al dar click en una columna de la tabla ,
    };

    const reducer = (state:objectKV, action:action) => {
        switch(action.type) {
            case "CLICK_ROW":
                if(action.value?.id) setId(action.value?.id);
                return Object.assign({},state,{data:action.value});
                case "ADD_CONFIG_TABLE":
                    return Object.assign({}, state);
            default: 
            return state;
        }
    };
    
    const [ state, dispatch ] = React.useReducer(reducer,initial);
    
    const  [loadData, { called, loading, data, refetch,fetchMore }] = useLazyQueryToSever({query:GET_TRAVELS});
    
    const _utils = {refetch:() => refetch()};
    const {addNotification} = useNotification();

    const { idReport, idviaje,data:datasocket } = useSocket();

    React.useEffect(() => {
        const isValid = () => {
            if (!idviaje) return false;
            if (!data?.viajes?.data?.length) return true;
            let _travel = data?.viajes?.data?.find((item: any) => item.id === idviaje);
            if (_travel) return true;
            if ([18, 13, 14, 20, 19, 21, 28, 29, 30, 31].includes(datasocket.length?datasocket[0]?.idsysevento ?? 0:0)) return true;
            return false;
        };
        if(idviaje) {
            if(addNotification) {
                addNotification({
                    element:<NotificationReport id={idviaje}/>
                });
            }
        };
        if (isValid() && refetch) refetch();
    }, [idReport]);

    let [hiddenFilters, sethiddenFilters] = React.useState<boolean>();


    const mapNewFilters = (filters:any,_sys:Array<confMap>) => {
        let _filters, tempValue: any = filters.find((item: false | { field: string; value: any; }) => item && item.field === "idsysestado");
            if(!tempValue?.value && sys?.length) _filters=[...filters,...sys.map((item:objectKV) => ({field:"idsysestado",value:item.id+""}))];
            else _filters = filters;
            _filters = _filters.map((item:false | { field: string; value: any; }) => {
                if (item && item?.field === "cliente") {
                    let _item = JSON.parse(item.value ?? "{\"values\":[]}")?.values;
                    _item = _item.length ? { field: "idclientes", value: `[${_item.join(",")}]` } : null;
                    return _item;
                }
                return item;
            })
        return _filters;
    };
    
    const filtersExcel = React.useMemo(() => {
        let { filters } = table ?? {};
        if(!sys) return [];
        if(filters) return mapNewFilters(filters,sys);
        return [];
     },[JSON.stringify(table),sys]);

    React.useEffect(() => {
        let { filters, numReg, page, orders } = table??{};
        //Se parcean los campos de orden que traen una estructura diferente de el state
        let tempOrders:any = orders?parceOrders(orders):null; 
        if(!sys) return;
        if(filters){
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            let _filters = mapNewFilters(filters,sys);
            if(_filters?.find((item:any) => item.field === "idclientes")?.value){
                let idcompany = user.idcompany;
                let orderClient = [{type: "ASC", field: "idclientes"}];
                if(![3,8].includes(idcompany??0)) orderClient.push({type:"ASC",field:"fechahoraproximallamada"});
                else orderClient.push({type:"ASC",field:"id"});
                tempOrders = tempOrders?[tempOrders,{type: "ASC", field: "idclientes"}]:orderClient;
            } else if(!tempOrders) tempOrders = [{type:"ASC",field:"fechahoraproximallamada"}];
            else {}
            if(tempOrders&& tempOrders.field && ['idorigen','iddestino'].includes(tempOrders.field)) tempOrders.field = `${tempOrders.field}`.replace('id','');
            loadData({variables:{filters:_filters, page:page??1, fields: numReg??20, orders:tempOrders }})
        }
        // if(!isInView) setIsInView(true);
    }, [JSON.stringify(table),JSON.stringify(sys),JSON.stringify(user)]);

    const resetFilters = ()=>{
        let el:any = tableRef?.current;
        if(el?.resetFilters){
            // dispatch({type:"ADD_FILTERS",value:{}})
            el?.resetFilters();
        }
    };

    //     React.useEffect(() => {
    //     console.log("tableee",table);
    // },[table]);


    if( id ) return <Redirect push to={`/Home/Viaje/${id}`}/>;

    return (
            <Layout
            title="Viajes"
            pageCurrent={table?.page}
            right={<FiltersOptions
            filters={table?.filters}
            reset={resetFilters} 
            hiddenFilters={sethiddenFilters}
            lastButtom={<ExportExcel filters={ filtersExcel } order={table?.orders} pagination={{ fields: table?.numReg, page: table?.page }} queryId={2} name="Viajes_" fields={[
                "id"
                ,"numero"
                ,"fecha"
                ,"placa"
                ,"lugar"
                ,"fechahoraultimoevento"
                ,"ultimoevento"
                ,"cliente"
                ,"estado"
                ,"indicadoraltoriesgo"
                ,"destino"
                ,"origen"
                ,"modalidad"
                ,"nombreconductor"
                ,"telefonoconductor"
                ,"trailerplaca"
                ,"ruta"
                ,"nombreconductor"
                , "numerodocumentoconductor"
            ] }/>}
            hidden={!!hiddenFilters} />}
            goToPage={setConfig}
            total={data?.viajes?.totalreg}
            leftBottom={<TotalRegs text={`Total viajes: ${data?.viajes?.totalreg??0}`}/>}
            numReg={table?.numReg}>
                <Table
                ref={tableRef}
                utils={_utils}
                factoryFilters={FactoryFilters}
                factory={Factory}
                getFilters={setConfig} 
                hiddenFilters={hiddenFilters} 
                data={data?.viajes?.data} 
                clickRow={dispatch}
                loading={(loading || !called)}
                orderClick={setConfig} 
                orderableField={table?.orders} 
                fields={table?.fields??[]}/>
            </Layout>
    );   
};

export default Home;