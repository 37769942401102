import React from "react";
import InputText from "../../Componets/Basic/Input/InputText";
import TextView from "../../Componets/Basic/Text/TextView";
import { confTable, factorFilter, objectKV } from "../../utils/interface";
import { destructor } from "../../utils/utils";
import "./Routes.scss";

export const Time = (props:{field: confTable,data:objectKV}) => {
    let days:string = "", hours:number | string = "", minutes:number | string ="";
    if(+props.data?.tiempoestimado) {
        let _time = (+props.data?.tiempoestimado)/(60*60);
        if(_time > 24){
            let temp = (_time/24).toString().split(".");
            days = temp[0];
            hours = +days?(_time - (+days*24)).toString().split(".")[0]:"00";
            minutes = temp[1]?Math.round((+`0.${temp[1]}`)*60):"00";
        } else {
            let temp = _time.toString().split(".");
            hours = _time?temp[0]:"00";
            minutes = temp[1]?Math.round((+`0.${temp[1]}`)*60):"00";
        }
    }
    return (<label>{`${days?`${days} días `:""} ${hours}:${minutes}`}</label>);
};

export const Place = (props:{field: confTable,data:objectKV}) => {
    
    // let population = ""; 
    
    let value:string | undefined = undefined;
    
    // if(props.field?.multiPath) value = props.field?.multiPath.map((item: string | Array <string>) => destructor(item,props.data) );
    
    if(props.field?.path) value = destructor(props.field.path,props.data);
    // population = `${value[0]} (${value[1]})`;
    
    return (value !== undefined ?<div className="text-place"><TextView text={value} /></div>  : null);
}

export const factory = (props:{field: confTable,data:objectKV}) => {
    if(props.field.field === "tiempoestimado") return <Time {...props}/>;
    return null;
};

export const factoryFilters = (props:factorFilter) => {
    if(props.confField.field === "tiempoestimado") return <InputText style={{width:120}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>;
    
    if(props.confField.field === "origen" || props.confField.field === "destino" || props.confField.field === "distancia")  return (<InputText style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    return null;
};