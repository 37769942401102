import React from "react";
import logo from "./../../utils/img/icon/chpwd.svg";
import "./Login.scss";
import InputFloat from "../../Componets/Basic/Input/InputFloat";
import Button from "../../Componets/Basic/Button/Button";
import { useInput } from "../../Componets/hook/input-hook";
import { error } from "../../utils/interface";
import { useHistory } from "react-router-dom";
import { useUser } from "../../Componets/hook/user-hook";
import LoadCircular from "../../Componets/Basic/LoadIndicator/LoadCircular";

interface changePassWordError {
    visible?:boolean;
    message?:string;
    error?:boolean;
}

/**
 * @description esta funcion hace el login y lo guarda en una cookie 
 * @param user 
 * @param password 
 */
const _reset = (oldPassword:string, newPassword:string ,  confPassword:string,token?:string, companyId?:string | number) => new Promise((resolve:any,reject:any) => {
    if(!companyId || !token) reject();
    fetch(process?.env?.REACT_APP_URL+"/api/login?idapp="+process.env?.REACT_APP_ID+"&idempresa="+(companyId ?? "").toString(), {
        method:"put", 
        headers: {
            Authorization:token ?? "",
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body:JSON.stringify({
            "claveactual": oldPassword,
            "claveconfirmacion": newPassword,
            "clavenueva": confPassword
        })
    })
    .then((res:any) => {
        if(res.ok) return res.json();
        reject();
    })
    .then((res:any) => {
        resolve();

    }).catch((err:any) => {
        reject();
    });
});

const ErrorView = (props:changePassWordError) => {
    return (props.visible?<div className="erro-view-container-login" style={{background:props.error?"":"#82ae38"}}>
        {props?.message ?? "Error"}
    </div>:null);
}

export const ResetPass = (props:{disabledCancel?:boolean}) => {

    let history = useHistory()

    let {logout, user} = useUser();

    let {value:passwordOld, bind:bindPassOld} = useInput();
    let {value:passwordNew, bind:bindPassNew} = useInput();
    let {value:passwordConf,  bind:bindPassConf} = useInput();
    let [errorPassOld, setErrorPassOld] = React.useState<error>();
    let [errorPassNew, setErrorPassNew] = React.useState<error>();
    let [errorPassConf, setErrorPassConf] = React.useState<error>();
    let [loading, setLoading] = React.useState<boolean>(false);
    let [error, setError] = React.useState<changePassWordError>();


    React.useEffect(() => {
        if(passwordNew && passwordConf){
            if(passwordConf === passwordNew) setErrorPassConf({error:false, message:""});
            else setErrorPassConf({error:true, message:"La contraseña es diferente"});
        }
    }, [passwordNew, passwordConf] );

    const _onClickCancel = () => {
        history.goBack()
    };

    const _onClickSave = () => {
        setErrorPassConf({error:!passwordConf, message:"Requerido"});
        setErrorPassNew({error:!passwordNew, message:"Requerido"});
        setErrorPassOld({error:!passwordOld, message:"Requerido"});
        if(!passwordOld || !passwordNew || !passwordConf) return;
        if(passwordConf !== passwordNew) return;
        setLoading(true);
        _reset(passwordOld, passwordNew, passwordConf, user.token, user.idcompany)
        .then(() => {
            setError({visible:true, message:"Se cambio la contraseña", error:false});
            setTimeout(() =>  {
                logout();
                setError({visible:false, message:"Se cambio la contraseña", error:false})
            },1000 );
        })
        .catch(() => {
            setError({visible:true, message:"Ocurrio un error", error:true});
            setTimeout(() =>  setError({visible:false, message:"Se cambi la contraseña", error:false}),1500 );
        })
        .finally(()=> setLoading(false));
    };

    return (
        <div className="view-reset-pass">
            <div className="view-reset-pass__container">
            <div className="image">
                <img src={logo} alt="" className="icon"/>
            </div>
            <div className="title">
                <label>Cambio de contraseña</label>
            </div>
            <div className="inputs_password">
                <InputFloat { ...bindPassOld} error={errorPassOld} width={250} label="Contraseña actual" type="password" />
                <InputFloat {...bindPassNew} error={errorPassNew} width={250} label="Nueva contraseña" type="password" />
                <InputFloat {...bindPassConf} error={errorPassConf} width={250} label="Confirmacion Contraseña" type="password"/>
            </div>
            <div className="buttons">
                <Button onClick={_onClickCancel} disabled={props.disabledCancel} className="buttons__cancel" label="Cancelar"/>
                <Button onClick={_onClickSave} className="buttons__send">
                {!loading? <label>Cambiar</label>:<LoadCircular />}
                </Button>
            </div>
            </div>
            <ErrorView {...error} />
        </div>
    );
};