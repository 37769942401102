import React from "react";
import ToolTip from "../ToolTip/ToolTip";
import "./Button.scss";

interface propsButton {
    /**
     * este campo representa el texto del boton
     */
    label?: string;
    /**
     * Es el posible hijo del botón
     */
    children?: JSX.Element[] | JSX.Element;
    /**
     * Son los nuevos estylos del button 
     */
    className?: string;

    /**Es un mensaje que se muestra sobre el boton cuando se coloca el mouse sobr este */
    message?: string;
    /**
     * Son todos los parametros que puede recibir un button 
     */
    [v: string]: any;
}

/**
 * @description esta funcion crea el componente button
 * @version 1.0
 */
const Button = React.memo(React.forwardRef((props: propsButton, ref: any) => {

    /** */
    let { className, message, onMouseMoveCapture, onMouseLeave, onClick, ...bind } = props;

    /**Posicion del tooltip verticalmente */
    const [top, setTop] = React.useState<number>(0);

    /**Posicion del tooltip horizontalmente */
    const [left, setLeft] = React.useState<number>(0);

    /**Este valor es true cuando el tooltip es visible*/
    const [visible, setVisible] = React.useState<boolean>(false);

    /**
     * Indica si se ha daddo click, previene el doble click en un corto lapso de tiempo
     */
    const disabledButton: any = React.useRef(null);

    /**Estate para refernciar el button */
    const el: any = React.useRef(null);


    /**Creamos los valores que tendra, referenciar este componente */
    React.useImperativeHandle(ref, () => ({
        /**Esta funcion permite que el padre tenga los valores de los limites de este componente */
        getBoundingClientRect: () => {
            if (el?.current?.getBoundingClientRect) return el?.current?.getBoundingClientRect();
            return null;
        }
    }), [el?.current]);

    /**  */
    const _onMouseMoveCapture = (e: any) => {
        if (onMouseMoveCapture) onMouseMoveCapture(e);
        if (e && message) {
            let _top = 0, _left = 0;
            if (+e.clientY) {
                _top = e.clientY;
                // este elemento cambia cuando se esta cerca de la posicion final de la pagina
                // para permitir renderizar el tool tip sobre el mouse 
                if ((window.innerHeight - e.clientY) < 50) _top = e.clientY - 50;
            }
            if (+e.clientX) {
                _left = +e.clientX;
            }
            setTop(_top);
            setLeft(_left);
        }
        if (!visible) setVisible(true);
    };

    const _onMouseLeave = () => {
        if (onMouseLeave) onMouseLeave();
        if (!message) return;
        setVisible(false);
        setTop(0);
        setLeft(0);
    };

    const _onClick = (evt: any) => {
        if (typeof onClick == "function" && !disabledButton.current) onClick(evt);
        disabledButton.current = true;
        setTimeout(() => {
            disabledButton.current = false;
        }, 500);
    };

    return (

        <button ref={el}
            className={`content-center button ${className ?? ""}`}
            {...bind}
            onMouseMoveCapture={_onMouseMoveCapture}
            onMouseLeave={_onMouseLeave}
            onClick={_onClick}
        >
            {message ? <ToolTip visible={visible} top={top} left={left} >
                <label className="text-tooltip">{message.split("\n").map((item: string) => <React.Fragment>{item}<br /></React.Fragment>)} </label>
            </ToolTip> : null}
            {props.children ?? <label>{props.label || "Clik"}</label>}
        </button>
    );
}));

export default Button;