import React from "react";
import  { Table } from "../../Componets/Basic/Table/Table";
import { Layout } from "../../Componets/General/Layout/Layout";
import { useUser } from "../../Componets/hook/user-hook";
import { objectKV,action } from "../../utils/interface";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { 
    getCheckPoint as GET_CHECK_POINT,
    queryGetTrafic as GET_TRAFIC,
    getDrivers as GET_DRIVERS, 
    fieldsTableTrafic,
    fieldsTableCheck,
    fieldsTableDriver } from "./Users.conf";
import { ModalOptionsUser, RemoveUser } from "./User.options";
import { ButtonAdd } from "../../Componets/General/FormsUtils/Buttons.form";
import { Tabs } from "../../Componets/Basic/Tabs/Tabs";
import { createFilters, FiltersOptions, parceOrders } from "../../Componets/General/UtilsView/Utils-view";


const tabs = [
    {value:"TRAFIC",description:"Controladores de trafico"},
    {value:"CONTROL_POINT",description:"Puestos de control"},
    {value:"DRIVER",description:"Conductores"}
    ];

/**
 * pagination-fix-1 corresponde a machetazo para la paginacion
 */ 
export const Users = () => {

    const tableRef =  React.useRef(null);

    let { logout } = useUser();

    
    const  [loadDataCheckPoint, { called:calledCheck, loading:loadingCheck, data:dataRequestCheck }] = useLazyQueryToSever({query:GET_CHECK_POINT});
    const  [loadDataDriver, { called:calledDrivers, loading:loadingDriver, data:dataRequestDriver}] = useLazyQueryToSever({query:GET_DRIVERS});
    const  [loadDataTrafic, { called:calledTrafic, loading:loadingTrafic, data:dataRequestTrafic, refetch:refetchTrafic }] = useLazyQueryToSever({query:GET_TRAFIC});

    let [idSelected, setIdSelected] = React.useState<Array<number | string>>([]);
    let [idSelectedUser, setIdSelectedUser] = React.useState<Array<number | string>>([]);

    const resetFilters = ()=>{
        let el:any = tableRef?.current;
        if(el?.resetFilters){
            el?.resetFilters()
        }
    };

    let [id, setId] = React.useState<number>();

    const [data, setData] = React.useState<Array<any>>();
    const [visible, setVisible] = React.useState<boolean>(false);
    let [hiddenFilters, sethiddenFilters] = React.useState<boolean>();

    const initial = {
        /**
         * estos valores tambien estan en el state pero no tiene un valor inicial
         */
        //data: es la info que se odtine al dar click en una columna de la tabla ,
        //filters: aqui se guarda los filtros,
        //orders: se guardan los valores del orden

        currentPage:1,
        numReg:30,
        tab:"TRAFIC"
    };

    const reducer = (state:objectKV, action:action) => {
        switch(action.type) {
            case "CLICK_ROW":
                if(action.value?.id && (state.tab === "TRAFIC" || state.tab === "DRIVER")) {
                    setId(action.value?.id);
                    setVisible(true);
                }
                return Object.assign({},state,{data:action.value});
                case "ADD_FILTERS":

                    let filters = createFilters(action.value);
                    if(JSON.stringify(filters) === JSON.stringify(state.filters)) return state;
                    return Object.assign({}, state, {filters, currentPage:1});
            case "ADD_CURRENT_PAGE":
                return Object.assign({}, state, {currentPage:action.value});
            case "SET_ORDER":
                return Object.assign({}, state, {orders:action.value});
                case "SET_NUM_REG":
                    return Object.assign({}, state, {numReg:+action.value ?+action.value:10, currentPage:1});
            case "SET_TAB":
                resetFilters();
                setIdSelected([]);
                const { orders, ...all } = state;
                return Object.assign({}, all, {tab:action.value,filters:[]});
            default: 
                return state;
        }
    };

    const [ state, dispatch ] = React.useReducer(reducer,initial);

    React.useEffect(() => {
        //esto es algo asi como un machetazo
        setIdSelected([]);
        let { filters, numReg, currentPage, orders,tab } = state;
        //Se parcean los campos de orden que traen una estructura diferente de el state
        orders = parceOrders(orders); 

        if(filters && numReg && currentPage){
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            if(tab === "TRAFIC") loadDataTrafic({variables:{filters, page: currentPage, fields: numReg, orders }});
            else if(tab === "CONTROL_POINT") loadDataCheckPoint({variables:{filters, page: currentPage, fields: numReg, orders }});
            else if(tab === "DRIVER") loadDataDriver({variables:{filters, page: currentPage, fields: numReg, orders }});
        }
    }, [state.tab,state.filters, state.numReg, state.currentPage, JSON.stringify(state.orders)]);


    const _valuesSelected = (v:Array<string | number>) => {
        if(state.tab === "TRAFIC") {
            if(v[0] === Infinity && dataRequestTrafic?.controladoresTraficoInfo?.data?.length) {
                let ids = dataRequestTrafic.controladoresTraficoInfo.data.map((item:objectKV) => item && item.id).filter(Boolean);
                let idsUser = dataRequestTrafic.controladoresTraficoInfo.data.map((item:objectKV) => item && item.idusuario).filter(Boolean);
                setIdSelected([...ids]);
                setIdSelectedUser([...idsUser]);
            }
            else {
                let ids = dataRequestTrafic?.controladoresTraficoInfo?.data?.length?dataRequestTrafic.controladoresTraficoInfo.data.filter((item:objectKV) => item && v.includes(item.id)).map((item:objectKV) => item && item.idusuario).filter(Boolean):[];
                setIdSelected([...v]);
                setIdSelectedUser([...ids]);
            }
            
        }
    };

    const _onClickAdd = () => {
        setVisible(visible => {
            setId(undefined);
            return !visible;
        });
    };

    const refresh = () => {
        if(refetchTrafic)refetchTrafic();
        setIdSelected([]);
    };

    const _close = () => {
        setVisible(false);
    };

    return (
        <React.Fragment>
            <ModalOptionsUser refresh={refresh} tab={state.tab} visible={visible} id={id} close={_close} />
            <Layout
            title="Usuarios"
            goToPage={dispatch}
            right={<FiltersOptions
                filters={state.filters}
                reset={resetFilters} 
                hiddenFilters={sethiddenFilters}
                hidden={!!hiddenFilters} />}
            pageCurrent={state.currentPage}
            optionMore={ state.tab !== "TRAFIC"?undefined:!idSelected?.length? <ButtonAdd onClick={_onClickAdd} />:<RemoveUser ids={idSelectedUser} refresh={refresh} />}
            total={state.tab === "TRAFIC"?dataRequestTrafic?.controladoresTraficoInfo?.totalreg:
            state.tab === "CONTROL_POINT"?dataRequestCheck?.puestosControlUsuariosInfo?.totalreg:
            dataRequestDriver?.usuariosConductoresInfo?.totalreg}
            numReg={state.numReg}>
            <Tabs value={state.tab} tabs={tabs} dispatch={dispatch} />
                <Table
                key={state.tab}
                ref={tableRef}
                getFilters={dispatch} 
                isSelectable={state.tab === "TRAFIC"}
                hiddenFilters={hiddenFilters}
                idSelected={idSelected}
                valuesSelected={_valuesSelected}
                data={ 
                state.tab === "TRAFIC"?dataRequestTrafic?.controladoresTraficoInfo?.data ?? []:
                state.tab === "CONTROL_POINT"?dataRequestCheck?.puestosControlUsuariosInfo?.data ?? []:
                state.tab === "DRIVER"?dataRequestDriver?.usuariosConductoresInfo?.data ?? []:[]} 
                clickRow={dispatch}
                // en este caso solo se usa calledTrafic poruqe es el valor por defecto
                loading={(loadingCheck || loadingDriver || loadingTrafic || !calledTrafic )}
                orderClick={dispatch} 
                orderableField={state.orders} 
                fields={
                    state.tab === "TRAFIC"?fieldsTableTrafic:
                    state.tab === "CONTROL_POINT"?fieldsTableCheck:
                    state.tab === "DRIVER"?fieldsTableDriver:[]
                }/>
            </Layout>
        </React.Fragment>
    );   
};

