import React from "react";
import { action, confHeaderTable, confTable, orderableField } from "../../../utils/interface";
import { createFilters } from "../../General/UtilsView/Utils-view";

const ConfTableContext = React.createContext(undefined);

interface tableInterface {
    fields:Array <confTable>;
    orders:Array<orderableField>|null;
    page:number;
    tempCurrentPage:number|null;
    numReg:number;
    filters:any
}
interface tablesInterface {
    [v:string]:tableInterface
}

interface reducerInterface {
    tables:tablesInterface;
    currentKey:string | null;
}

const newTable:tableInterface = {
    fields:[],
    orders:null,
    page:1,
    numReg:20,
    tempCurrentPage:null,
    filters:[]
};

export const ConfTableProvider = (props:any) => {

    const init:reducerInterface = {
        tables:{},
        currentKey:null
    };

    const formatterFilters = (fields:Array <confTable>,filters:any) => {
        let _filters = Object.assign({});
        if(filters?.length) filters.forEach((item:any) => {
            if(!item?.field) return;
            _filters = Object.assign({},_filters,{[item.field]:`${item.value}`});
        });
        let tempFields = fields.map((item:confTable) => {
            let valueFiltered = _filters[item.field];
            let temp = Object.assign({},item);
            temp.valueFiltered = valueFiltered;
            return temp;
        });
        return tempFields;
    };

    const reducer = (state:reducerInterface,action:action) => {
        let table:tableInterface;
        let tables: tablesInterface;
        let temp: any;
        switch(action.type){
            case "SET_KEY_TABLE":
                return Object.assign({},state,{currentKey:action.value??null});
            case "RESET_TEMP_CURRENT":
                if(!state?.currentKey) return state;
                tables = state.tables;
                table = tables[state.currentKey]??newTable;
                table = Object.assign({},table,{tempCurrentPage:null});
                tables = Object.assign({},tables,{[state.currentKey]:table});
                return Object.assign({},state,{tables});
            case "SET_FIELDS":
                // if(!state?.currentKey) return state;
                // tables = state.tables;
                // table = tables[state.currentKey] ?? newTable;
                // if (table?.fields?.length) table = Object.assign({},table,{fields:table?.fields??[]});
                // else table = Object.assign({},table,{fields:action.value.fields??[]});
                // tables = Object.assign({}, tables, { [state.currentKey]: table });
                // return Object.assign({}, state, { tables });
                if(!state?.currentKey) return state;
                tables = state.tables;
                table = tables[state.currentKey]??newTable;
                table = Object.assign({},table,{fields:action.value.fields??[]});
                tables = Object.assign({},tables,{[state.currentKey]:table});
                return Object.assign({},state,{tables});
            case "SET_ORDER":
                if(!state?.currentKey) return state;
                tables = state.tables;
                table = tables[state.currentKey]??newTable;
                table = Object.assign({},table,{orders:action.value??[]});
                tables = Object.assign({},tables,{[state.currentKey]:table});
                return Object.assign({},state,{tables});
            case "SET_NUM_REG":
                if(!state?.currentKey) return state;
                tables = state.tables;
                table = tables[state.currentKey]??newTable;
                table = Object.assign({},table,{numReg:+(action.value??20),page:1});
                tables = Object.assign({},tables,{[state.currentKey]:table});
                return Object.assign({},state,{tables});
            case "ADD_CURRENT_PAGE":
                if(!state?.currentKey) return state;
                tables = state.tables;
                table = tables[state.currentKey]??newTable;
                table = Object.assign({},table,{page:action.value??1});
                tables = Object.assign({},tables,{[state.currentKey]:table});
                return Object.assign({},state,{tables});
            case "ADD_FILTERS":
                if(!state?.currentKey) return state;
                tables = state.tables;
                table = tables[state.currentKey]??newTable;
                let filters = createFilters(action.value);
                if (JSON.stringify(filters) === JSON.stringify(table?.filters)) return state;
                table = Object.assign({},table,{page:table.tempCurrentPage??1,filters});
                tables = Object.assign({},tables,{[state.currentKey]:table});
                return Object.assign({}, state, { tables });
            case "ADD_FILTERS_AND_FORMATTER":
                if (!state?.currentKey) return state;
                tables = state.tables;
                table = tables[state.currentKey] ?? newTable;
                temp = {};
                if (Array.isArray(table?.filters)) table?.filters.forEach((item: any) => temp = Object.assign({}, temp, { [item.field ? item.field : "value_temp_void"]: item.value }));
                let { value_temp_void, ...allRest } = temp;
                if(action.value === "RESET_ALL") temp = Object.assign({});
                else temp = Object.assign({}, allRest, action.value);
                let filters2 = createFilters(temp);
                if (JSON.stringify(filters2) === JSON.stringify(table?.filters)) return state;
                temp = table.fields;
                temp = formatterFilters(temp, filters2 ?? []);
                table = Object.assign({}, table, { page: table.tempCurrentPage ?? 1, filters: filters2, fields: temp });
                tables = Object.assign({}, tables, { [state.currentKey]: table });
                return Object.assign({},state,{tables});
            case "FORMATTER":
                if(!state?.currentKey && typeof(action?.value?.key) !== "string") return state;
                tables = state.tables;
                table = tables[action?.value?.key??state.currentKey]??newTable;
                let fields = table.fields;
                fields = formatterFilters(fields,table.filters);
                table = Object.assign({},table,{fields,tempCurrentPage:table.page});
                tables = Object.assign({},tables,{[action?.value?.key??state.currentKey]:table});
                return Object.assign({}, state, { tables });
            default:
                throw new Error('Unexpected action');
        }
    };

    const [state, dispatch] = React.useReducer(reducer,init);

    // const getTable = React.useCallback((k?: string) => {
    //     if (!k) return newTable;
    //     let temp:any = state.tables;
    //     temp = temp[k] ?? newTable;
    //  },[JSON.stringify(state)]);

    let value = React.useMemo(()=>({
        tables:state.tables,
        currentKey:state.currentKey,
        setConfTable:dispatch
    }), [JSON.stringify(state),dispatch]);
    return <ConfTableContext.Provider value={value} {...props} />
};

interface returnHookConfTable {
    tables: tablesInterface;
    currentKey: any;
    setConfTable: React.Dispatch<action>;
}
export const useConfTableContext = ():returnHookConfTable => {
    const context = React.useContext(ConfTableContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};