import React from "react";
import socket from 'socket.io-client';
import { useUser } from "../../hook/user-hook";
import { action } from "../../../utils/interface";


/**
 * este es el retorno actual del socket un array con 
 * ________________________________________________________
aplicacion: null
created_at: "2020-06-04T00:09:19.000Z"
criticidad: 0
fechahora: "2020-06-03T19:09:00.000Z"
geolocalizacion: null
id: 799
idestadoreporte: 1
idevento: null
idpuestocontrol: null
idremesas: null
idsysevento: 12
idusuario: 32
idviaje: 85
lugar: "granada"
novedad: "Reporte de Llamada"
observaciones: ""
proximahora: null
retraso: 7
updated_at: "2020-06-04T00:09:19.0
 */

interface stateSocket {
    callBacks: { [v: string]: (v: any) => any };
    data: socketData;
    callStack: { [v: string]: (v: any) => any };
}

interface socketDataRest {
    idReport?:number;
    idviaje?: number;
    data?: any;
    client?:SocketIOClient.Socket;
    subscribe:(v: (v: any) => any,k?:string) => string,
    unsubscribe: (v: string) => void;
    assigFunction: (v: (v: any) => any, k?: string) => void;
    removeFunction:(v: string) => void
}
interface socketData {
    idReport?:number;
    idviaje?: number;
    [v: string]: any;
}
// message
const SocketContext = React.createContext(undefined);

export const SocketProvider = (props:any) => {
    const [client, setClient] = React.useState<SocketIOClient.Socket>();
    const [dataSocket, setData] = React.useState<socketData>();
    const [socketConect, set] = React.useState<socketData>();
    // const [callBack, setCallBack] = React.useState<(v:any) => any>();
    
    const sendMessage = (data: any, _state: stateSocket) => {
        Object.values(_state.callBacks).forEach((item: (v: any) => any) => {
            item(data);
        });
    };

    const reducer = (state:stateSocket, action:action) => {
        let newCallBacks;
        switch(action.type) {
            case "SET_CALL_BACK":
                let {fnc,id} = action.value??{};
                if(typeof(fnc) !== "function" || typeof(id) !== "string") return state;
                newCallBacks = Object.assign({},state.callBacks,{[id]:fnc}) 
                return Object.assign({},state,{callBacks:newCallBacks});
            case "REMOVE_CALL_BACK":
                let id_2 = action.value;
                if(typeof(id_2) !== "string") return state;
                let {[id_2]:itemDelete,...allrest} = state.callBacks??{};
                newCallBacks = Object.assign({},allrest); 
                return Object.assign({}, state, { callBacks: newCallBacks });
            case "SET_DATA_SOCKET":
                if (!action.value) return state;
                if (JSON.stringify(state.data) === JSON.stringify(action.value)) return state;
                sendMessage(action.value,state);
                return Object.assign({}, state, { data: action.value });
            case "SET_DATA":
                if (typeof (action.value.key) !== "string" || typeof (action.value.fun) !== "function") return state;
                let tempCallStack = Object.assign({}, state.callStack, {[action.value.key]:action.value.fun});
                return Object.assign({}, state, { callStack: { ...tempCallStack } });
            case "REMOVE_DATA":
                if (typeof (action.value) !== "string") return state;
                let {[action.value]:del,...all} = state.callStack??{};
                return Object.assign({}, state, { callStack: {...all} });
            case "SET_VOID":
                return Object.assign({}, state, { data: {} });
            default: 
                throw new Error();
        }
    };

    let init:stateSocket = {
        callBacks: {},
        data: {},
        callStack: {}
    }
    const [state , dispatch] = React.useReducer(reducer, init);
    const { user } = useUser();

    React.useEffect(() => {
        if(user?.idcompany && user?.token && process.env.REACT_APP_URL && client === undefined) {
            const _client = socket(process.env.REACT_APP_URL??"", {
                reconnectionDelayMax: 10000,
                query: { 
                token: user?.token,
                idapp: process.env.REACT_APP_ID,
                idempresa: user?.idcompany
              }} );
// 
              _client.emit('joinEmpresa',  user?.idcompany);

              _client.on('joinEmpresa', (data:any) => {
                 return data;
                });

            _client.on('NewReport', (data: any) => {
                  if (data?.message[0]) setData({  idReport: data?.message[0].id, idviaje: data?.message[0].idviaje, data:data.message  });
              });

            _client.on('disconnect', (reason:any) => {
                console.log("**************************************************************\n",reason,"**************************************************************\n");
                _client.connect();
            });

              setClient(_client);
        }
 
    }, [user]);

    // React.useEffect(() => {
    //     console.log("state",state)
    // },[state]);

    React.useEffect(() => {
        let id:any;
        if (dataSocket?.idReport) {
            dispatch({type:"SET_DATA_SOCKET",value:dataSocket})
            id = setTimeout(() => {
                dispatch({type:"SET_VOID"})
            },1000);
        }
        return () => {
            if (id) clearTimeout(id);
         };
    }, [dataSocket?.idReport]);

    const subscribe = React.useCallback((v:(v:any)=>any,k?:string) => {
        let id = k??`${new Date().getTime()}_func`;
        dispatch({type:"SET_CALL_BACK",value:{id,fnc:v}});
        return id;
    },[dispatch]);

    const unsubscribe = React.useCallback((v:string) => {
        dispatch({type:"REMOVE_CALL_BACK",value:v});
    }, [dispatch]);
    
    const assigFunction = React.useCallback((v:(v:any)=>any,k:string) => {
        dispatch({type:"SET_DATA",value:{key:k,fnc:v}});
    },[dispatch]);

    const removeFunction = React.useCallback((v:(v:any)=>any,k:string) => {
        dispatch({type:"REMOVE_DATA",value:k});
    },[dispatch]);

    let value = React.useMemo(()=>({client, ...state.data,subscribe,unsubscribe,assigFunction,removeFunction}), [client, state.data,subscribe,unsubscribe]);
    return <SocketContext.Provider value={value} {...props} />
};

export const useSocketContext = ():socketDataRest => {
    const context = React.useContext(SocketContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};