import React from "react";
import "./Details.scss";
import  { Table } from "../../../../Componets/Basic/Table/Table";
import { 
    fieldsTrazability,
    fieldsPlan,
    fieldsRemitances
} from "./Details.conf";
import { useLazyQueryToSever } from "./../../../../Componets/hook/query-hook";
import { objectKV, confTable } from "./../../../../utils/interface";
import { Factory } from "./Details.factory";
import { destructor } from "./../../../../utils/utils";
import { useSocket } from "../../../../Componets/hook/socket-hook";
import {
    queryTablesTrazability as GET_TRAZA,
    queryTablesRemitances as GET_REMITTANCES, // esto esta mal escrito pero luego se cambia
    queryTablesPlan as GET_PLAN,
    fieldsTrazabilityQuery
 } from "../Travel.config";
import { useClientApolloGranada } from "../../../../Componets/hook/client-apollo-hook";
import { useUser } from "../../../../Componets/hook/user-hook";


interface propsDetails {
    id?: string | number;
    idTripPlan?: string | number;
    opened?:any;
}

const _queryTraza = `query getTraza($id:Int!){
    trazabilidadInfo(id:$id) @client{
        ${fieldsTrazabilityQuery}
    }
}`

const TableInfo = (props: {fields:confTable[],variables:any,fetchPolicy?:"cache-and-network" | "network-only" | "cache-first" | "cache-only", name?:"TR" | "PV" | "RM",query:string,path:string,id?:string | number}) => {
    const [_data , setData] = React.useState<Array<objectKV>>([]);
    const [_loading , setLoading] = React.useState<boolean>(false);
    const [loadData, { called, loading, data, refetch:refetchTableDetails }] = useLazyQueryToSever({ query: props.query, fetchPolicy: props.fetchPolicy ?? "cache-first" });

    React.useEffect(() => {
        if(props.id && !called) loadData({variables:{...props.variables}});
    }, [props.id]);

    React.useEffect(() => {
        if(!loading){
            let _data = destructor(props.path,data);
            if(Array.isArray(_data)) setData(_data);
            setLoading(false);
        } else setLoading(true);
    }, [data, loading]);

    //NOTA: seria mejor pasarla la Factory como un props para este componente, pero ya que solo lo uso un vez no importa, 
    // si se lo concidera mejor la pasa por props.
    return (<Table factory={Factory} loading={!called || _loading} fields={props.fields} data={_data}/>);
};

const Details = (props:propsDetails) => {

    const { user } = useUser();

    //la llaves son TR para trazabilidad, PV para pla de viajes, RM para remesas
    const [keyTable, setKeyTable] = React.useState<"TR" | "PV" | "RM">("TR");

    const _handleClick = (v:"TR" | "PV" | "RM") => {
        setKeyTable(v);
    };

    const _fieldsTrazability = React.useMemo(() => {
        if(user.roll === 3) return fieldsTrazability;
        return fieldsTrazability.filter((item:confTable) => item.field !== "fechareporte");
    },[fieldsTrazability,user]);

    return (
        <div className="details-container">
            <div className="header-details">
                <div onClick={() => _handleClick("TR")} className={`header-details__buttons content-center ${keyTable === "TR"? "header-details__active": ""}`}>
                    Trazabilidad
                </div>
                <div onClick={() => _handleClick("PV")}  className={`header-details__buttons content-center ${keyTable === "PV"? "header-details__active": ""}`}>
                    Plan de viajes
                </div>
                <div onClick={() => _handleClick("RM")}  className={`header-details__buttons content-center ${keyTable === "RM"? "header-details__active": ""}`}>
                    Remesas
                </div>
            </div>
            <div className="details-container__table" style={{height:` calc(72% + ${props.opened?"1px":"144px"})`}}>
                {
                    // se asigna un key para obligar a React a renderizar toda la tablas ya que si se deja si llave solo reacionara a los nuevos valores 
                    // pero durante un istante de tiempo se vera una tabla con NA, si considera no es un problema se le quita el key
                    // ya que para este caso sin la key es mas eficiente, pero se ve un poquito feo.
                    keyTable === "PV"?
                    <TableInfo name={keyTable} fetchPolicy="cache-first" key="Plan" query={GET_PLAN} fields={fieldsPlan} path="planviajePorViaje.data.0.planviajedetalles" id={props.id} variables={{idviaje:props.id}} />:
                    keyTable === "RM"?
                    <TableInfo key="rem" fetchPolicy="cache-first" name={keyTable} query={GET_REMITTANCES} fields={fieldsRemitances} path="remesasInfo.data" id={props.id} variables={{id:props.id}} />:
                    <TableInfo name={keyTable} key="traza" fetchPolicy="cache-first" query={GET_TRAZA} fields={_fieldsTrazability} path="trazabilidadInfo.data" id={props.id} variables={{id:props.id}} />
                }
            </div>
        </div>
    );
}

export default Details;