import { fields } from "../../Componets/Helpers/Form/Form-context";
import { confTable } from "../../utils/interface";


const viajeMin = `    
id
idempresa
idempresatransportadora
idagencia
numero
fechahoraproximogps
fechahoraultimallamada
fechahoraproximopuestocontrol
fecha
retenerviaje
idproximosysevento
idsysestado
idsysmodalidad
idplanviaje
idvehiculo
idconductor
idcargainternacional
idagencia
idembarcador
idruta
destino
origen
cliente
placa
lugar
numerocontenedor
documentocliente
`;

export const queryViajes = `
query viajes( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
  viajes(
    filters: $filters,
    orders: $orders,
    pagination: { fields:$fields, page: $page}
    ) {
      data {
        ${viajeMin}
      }
      totalreg
    }
  }`;

  export const queryViajesids = `
query viajes{
  viajes {
      data {
        id
      }
      totalreg
    }
  }`;
  
  export const fieldsTable: Array<confTable> = [
  {
    label:"No. Viaje",
    field: "id",
    path:"id",
    type: "TEXT",
    width:100,
    orderable:true,
    filterable:true
  },
  {
    label:"No. manifiesto",
    field: "numero",
    path:"numero",
    type: "TEXT",
    width:100,
    orderable:true,
    filterable:true
  },
  {
    label:"Placa",
    field:"placa",
    width:70,
    orderable:true,
    filterable:true
  },
  {
    label:"Generador de carga",
    field:"cliente",
    path:"cliente",
    orderable:true,
    filterable:true,
    type:"TEXT",
    // valueFiltered:"241"
  },
  {
    label:"Merc.",
    field:"indicadoraltoriesgo",
    orderable:true,
    filterable:true,
    textAling:"CENTER"
  },
  {
    label:"Origen",
    field:"origen",
    orderable:true,
    filterable:true,
    useTooltip:true,
    path:"origen",
    type:"TEXT"
  },
  {
    label:"Destino",
    field:"destino",
    orderable:true,
    useTooltip:true,
    filterable:true,
    path:"destino",
    type:"TEXT"
  },
  {
    label:"No. cont.",
    field: "numerocontenedor",
    path:"numerocontenedor",
    type: "TEXT",
    width:100,
    orderable:true,
    voidValue:" ",
    filterable:true
  },
  {
    label:"Doc. cliente",
    field: "documentocliente",
    path:"documentocliente",
    type: "TEXT",
    width:100,
    useTooltip:true,
    orderable:true,
    voidValue:" ",
    filterable:true
  }

];

  
export const queryClientesViajes = `
query clienteViajesInfo{
  clienteViajesInfo(
    orders:[
    {field:"nombre",type:ASC}
    ],
    filters:[
    {field:"idsysestado",value:"7"},  	
    {field:"idsysestado",value:"4"}
    ]
  ){
    data{
      id
      idempresa
      nombre
    }
  }
}`;

  export const filedsFormFilters:Array<fields> = [
    {
        name:"days",
        path:"days",
        validator: [{min:1}]
    },
    {
        name:"from",
        path:"from"
    },
    {
        name:"to",
        path:"to",
    },
    {
        name:"origen",
        path:"origen",
    },
    {
      name:"destino",
      path:"destino",
    },
    {
      name:"placa",
      path:"placa",
    },
    {
      name:"nombreconductor",
      path:"nombreconductor",
    },
    {
      name:"cliente",
      path:"cliente",
    }
];

  ///que pasooo