import React from "react";
import "./Gallery.scss";
import { ImageViwer, ModalViewImage } from "../Image/ImageViwer";
import { objectKV } from "../../../utils/interface";
import { GalleryProvider, documentInterface, useGallery } from "../../Helpers/Gallery/gallery-context";
import LoadCircular from "../LoadIndicator/LoadCircular";
import { useGeneralStore } from "../../hook/general-store-hook";


interface propsGallery {
    /**Indica si la galeria esta o no visible */
    visible?:boolean;
    /**Este es una array con la imagenes */
    images?:null | Array<{
        miniatura?:string;
        archivo?:string;
        key?:string;
    }>;
    loading?:boolean;
    name?:string;
}

interface images {
    image:string;
}

/**
 * @description es el componente que renderiza una imagen en toda la pantalla
 * @version 1.0
 * 
 */
export const GalleryModal = React.memo(() => {

    /**Estado que maneja las imagenes ACTUAL */
    const [image, _setImage] = React.useState<documentInterface>();

    /**Estado que representa SIGUIENTE imagen */
    const [imageNext, _setImageNext] = React.useState<documentInterface>();

    /**Estado que representa la ANTERIOR imagen */
    const [imagePrev, _setImagePrev] = React.useState<documentInterface>();

    const {state, setImage} = useGallery();

    React.useEffect(() => {
        let _image, _next, _prev;
        if(state?.images?.length){
            let flag = false;
            state?.images?.some((item:documentInterface) => {
                if(flag) {
                    _next = item;
                    return true;
                }
                if(item.visible) {
                    _image = item;
                    flag = true;
                } else {
                    _prev = item;
                }
            });
            if(state?.images?.length > 1){
                if(!_prev) _prev = [...(state?.images ?? [])].reverse()[0];
                if(!_next) _next = [...(state?.images ?? [])][0];
            }
        }
        if(_next) _setImageNext(_next);
        if(_prev) _setImagePrev(_prev);
        if(_image) {
            _setImage(_image);
        } else {
            _setImage(undefined);
        }
    }, [state]);

    /**Pemite cerrar una imagen */
    const _close = () => {
        if(image) {
            let _image = Object.assign({}, image);
            _image.visible = false;
            setImage({type:"SET_IMAGE", value:_image});
        }
    };

    /**Permite avanzar la anterior imagen */
    const _clickLeft = () => {
        if(state?.images?.length < 2) return;
        _close();  
        if(imagePrev) {
            let _image = Object.assign({}, imagePrev);
            _image.visible = true;
            setImage({type:"SET_IMAGE", value:_image});
        }
    };

    /**Permite avanzar a la siguiente imagen */
    const _clickRight = () => {
        if(state?.images?.length < 2) return;
        _close();  
        if(imageNext) {
            let _image = Object.assign({}, imageNext);
            _image.visible = true;
            setImage({type:"SET_IMAGE", value:_image});
        }
    };
    return <ModalViewImage name={image?.name} visible={!!image?.visible} showControl clickLeft={_clickLeft} clickRight={_clickRight} localUri={image?.document} close={_close} />;
});

export const getImage = async (uri:string) => {
    return await fetch(uri).then((res:any) => {
        if(!res.ok) throw new Error();
        return res.blob();
    }).then((res:any) => {
        let urlCreator = window.URL || window.webkitURL;
        let newUri = urlCreator.createObjectURL(res);
        return newUri;
    }).catch((e:any) => {
        return null;
    });
}

/**
 * @description Este componente representa toda la galeria
 * @version 1.0
 */
export const GalleryAdapter = React.memo((props:propsGallery) => {

    const {state,setImage} = useGallery();
    const {state:storeImages,setData} = useGeneralStore();

    React.useEffect(() => {
        if(props.images?.length) {
            props.images.forEach((item:objectKV) => {
                if(item?.archivo){
                    setImage({type:"SET_IMAGE", value:{
                        document:item?.archivo,
                        visible:false,
                        name:item.key
                    }});
                }
            });
            if(props.name)setData({type:"SET_DATA",value:{key:"images",value:{...(storeImages?.images??{}),[props.name]:props.images}}});
        } else setImage({type:"SET_VOID"});
    }, [props.images]);

    /**Hace visible una imagen */
    const _onClick = (v:documentInterface) => {
        let image = v;
        image.visible = true;
        setImage({type:"SET_IMAGE",value:image});
    }

     return (           
        <React.Fragment>
            <GalleryModal /> 
            {state?.images?.length ? state.images.map((item:documentInterface, index:number) => {
                return <ImageViwer  key={index} name={item.name} disabledView onClick={() => _onClick(item) } uri={item.document} className={"gallery-container__image"} />
            }):null}
            {props.loading?<LoadCircular />:null}
        </React.Fragment>
     );
});

/**
 * @description esta funcion es un parche momentaneo para presentar la informacion en pantalla 
 */
export const GalleryAbsolute = React.memo((props:any) => {
    return <div className="gallery-container">
        <Gallery {...props}/>
    </div>
});


/**
 * @description esta funcion es un parche momentaneo para presentar la informacion en pantalla 
 */
 export const GalleryCard = React.memo((props:any) => {
    return <div className="gallery-container-card">
        <Gallery {...props}/>
    </div>
});

/**
 * @description esta funcion crea el componente galeria, es mas un HOC, para agregar el provider de galeria.
 * @version 1.0
 */
export const Gallery = React.memo((props:propsGallery) => {
    return <GalleryProvider>
        <GalleryAdapter {...props} />
    </GalleryProvider>
})