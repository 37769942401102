import React from 'react';
import { action, objectKV } from "../../../utils/interface";

interface detailsState {
    details:Array<detail>;
    detailsDelete:Array<detail>;
}

interface returnHook {
    getDetails: () => objectKV[] | undefined;
    details?:detailsState;
    getValues: () => Array<objectKV> | null;
    getDetail: (id: string | number) => detail | undefined;
    getValuesDelete: () => objectKV[] | null;
    setState:(v:action) => any;
}

export interface detail {
    data:objectKV;
    editable?:boolean;
    id:string | number;
}

export const keyEdit = "-Edit";
export const keySave = "-Save";

const DetailsContext = React.createContext(undefined);

export const DetailsProvider = (props:any) => {

    const reducer = (state:detailsState, action:action) => {
        switch(action.type) {
            case "MOVE_DETAIL":
                //se necesita un id, y un move:UP o DOWN
                let tempDetails = [...state.details];
                let _newDetails:Array<detail> = [...tempDetails];
                if(action?.value?.move === "UP"){
                    let index = tempDetails.findIndex((item:detail) => item.id === action?.value?.id);
                    if(index !== -1 &&  index < tempDetails.length-2){
                        _newDetails = [...tempDetails.slice(0,index),tempDetails[index+1],tempDetails[index],...tempDetails.slice(index+2)];
                    } 
                } else if(action?.value?.move === "DOWN") {
                    let index = tempDetails.findIndex((item:detail) => item.id === action?.value?.id);
                    if(index !== -1 &&  index > 0){
                        _newDetails = [...tempDetails.slice(0,index-1),tempDetails[index],tempDetails[index-1],...tempDetails.slice(index+1)];
                    } 
                }
                // tempDetails.forEach((item:detail, index:number, _tempDetails:Array<detail> ) => {
                //     if(action?.value?.move === "UP"){
                //         if(action?.value?.id === item.id &&  index < _tempDetails.length-2 ){
                //             let tempData = {...item.data};
                //             //este es un machetazo
                //             if(tempData?.orden) tempData.orden = index + 2;

                //             item.data = {..._tempDetails[index+1].data};
                //             //este es un machetazo
                //             if(item?.data?.orden) item.data.orden = index + 1;
                //             _tempDetails[index+1].data = tempData;
                //             console.log("up",_tempDetails[index+1],item);
                //         }

                //     } else if(action?.value?.move === "DOWN") {
                //         if(action?.value?.id === item.id &&  index > 0 ){
                //             let tempData = {...item.data};
                //             //este es un machetazo
                //             if(tempData?.orden) tempData.orden = index;
                //             item.data = {..._tempDetails[index-1].data};
                //             //este es un machetazo
                //             if(item?.data?.orden) item.data.orden = index + 1;
                //             _tempDetails[index-1].data = tempData;
                //             console.log("donw",_tempDetails[index-1],item);
                //         }
                //     }
                // });
                return Object.assign({},state,{details:[...(_newDetails ?? [])]})
            case "ASSING_DETAIL":
                let _state = Object.assign({},state,{details:[...(action.value ?? [])]});
                return _state;
            case "ASSING_DETAIL_FOR_KEY":
                if(!action.value?.id) return state;
                if(!state.details?.find((item:detail) => item.id === action.value?.id)){
                        let _detailsEdit = state.details?.length ? state.details[state.details.length - 1]:null;
                        let _details = _detailsEdit?state.details?.slice(0,state.details?.length - 1):null;
                        let dataTemp = {...action.value?.value};
                        dataTemp.id = action.value?.id;
                        let tempNew = [...(_details ?? []),{id:action.value?.id, data:dataTemp},_detailsEdit ?? {id:0, data:{}}];
                        return Object.assign({},state, {details:tempNew});
                }
                else {
                        let _details = state.details?.length ?state.details?.map((item:detail) => {
                            if(action.value?.id !== item.id) return item;
                            item.data = action.value?.value;
                            return item;
                        }):null;
                        return Object.assign({},state, {details:[...(_details ?? [])]});
                }
            case "EDITABLE_DETAIL":
                const _details:Array<detail> = state.details;
                if(_details?.length){
                    return Object.assign({},state, {details:[..._details.map((item:detail) => {
                        if(item.id !== action.value?.id) return item;
                        item.editable = !!action.value?.edit;
                        return item;
                    })]});
                }
                else return state;

            case "DELETE_DETAIL":
                let del:any , newDetails = state.details?.filter((item:detail) => {
                    if(item.id === action.value?.id) {
                        del = item;
                        return false;
                    }
                    return true;
                });
                let tempNewDetails = [...(newDetails ?? [])];
                if(del && !`${del?.id ?? 0}`.includes(keySave)) return Object.assign({}, {detailsDelete:[...( state.detailsDelete ?? []),del],details:tempNewDetails});
                return Object.assign({}, state, {details:tempNewDetails});

            default:
                throw new Error();
        }
    };
    
    const init:detailsState = {
        details:[],
        detailsDelete:[],
    };

    const [details,dispatch] = React.useReducer(reducer,init);

    const getValues = () => {
        return details.details?.length? details.details.map((item:detail) => item.data):null;
    };
    const getValuesDelete = () => {
        return details.detailsDelete?.length? details.detailsDelete.map((item:detail) => item.data):null;
    };

    const getDetail = (id:string | number) => {
        if(details.details?.length) return details.details?.find((item:detail) => item.id === id);
    };
    const getDetails = () => {
        if(details.details?.length) return details.details?.filter((item:detail) => !item.id.toString().includes(keyEdit)).map((item:detail) => item.data);
    };

    let value = React.useMemo(()=>({getValuesDelete,getDetails,details,getValues,setState:dispatch,getDetail}), [getValuesDelete,getDetails,dispatch,details,getDetail,getValues]);
    return <DetailsContext.Provider value={value} {...props} />
};

export const useDetails = ():returnHook => {
    const context = React.useContext(DetailsContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};
