import React from "react";
import "./Forms-report.scss";
import { GeneralInfo, LocationForm, Observations, ControlButtons, UploadFile, DatePicker, GET_INFO, GET_TIMES, REPORT_MUTATION, GET_UTILS_REPORT, NotMatch } from "./Form.general-info";
import { useMutationServer } from "../../../Componets/hook/mutation-hook";
import { objectKV, action, error } from "../../../utils/interface";
import { useLazyQueryToSever } from "../../../Componets/hook/query-hook";
import { useUser } from "../../../Componets/hook/user-hook";
import TextView from "../../../Componets/Basic/Text/TextView";
import { formatterTimeZoneDate, getDateServer, reportFormatter, validatorTimeDate } from "../../../utils/utils";
import { uploadImage } from "../../../Componets/General/UploadImage/UploadImage";
import { queryTablesRemitances } from "../Travel/Travel.config";


const idsysevento = 12;

export const FormCall = (props: { id?: number | string, close: () => any }) => {

    const [sendData, { data }] = useMutationServer({ mutation: REPORT_MUTATION });
    const [loadData, { data: dataFill }] = useLazyQueryToSever({ query: GET_INFO, fetchPolicy: "cache-first" });
    const [loadDataRemittances, { data: dataRemittances, loading: loadinRemittances, error: errorRemittances }] = useLazyQueryToSever({ query: queryTablesRemitances, fetchPolicy: "cache-first" });


    const { user: { id } } = useUser();


    const init = {
        fecha: "",
        hora: "",
        idevento: null,
        idremesas: [],
        idsysevento,
        idusuario: null,
        idviaje: null,
        imagenes: [],
        lugar: "",
        novedad: "",
        indicadornocoincide:null
    };

    const reducer = (state: objectKV, action: action) => {
        switch (action.type) {
            case "SET_DATA":
                return Object.assign({}, state, action.value);
            default:
                return state;
        }
    };

    const [messageErrorDatePicker, setMessageErrorDatePicker] = React.useState<string>();

    const [dateServer, setDateServer] = React.useState<string>();
    const [keyLocalDate, setKeyLocalDate] = React.useState<string>();

    const [errorDate, setErrorDate] = React.useState<error>();
    const [errorLocation, setErrorLocation] = React.useState<error>();
    const [errorTime, setErrorTime] = React.useState<error>();
    const [errorNovedad, setErrorNovedad] = React.useState<error>();
    const [date, setDate] = React.useState<number>();
    const [uploading, setUploading] = React.useState<boolean>();

    const [state, dispatch] = React.useReducer(reducer, init);

    const [loadTravel, { data: dataTravel }] = useLazyQueryToSever({
        query: GET_UTILS_REPORT,
        fetchPolicy: 'cache-only'
      });
    
      React.useEffect(() => {
        if (props.id)
          loadTravel({
            variables: { idviaje: props.id, idsysevento }
          })
      }, [props.id])

    const _onClickSend = async () => {
        if (!(await validator(state))) return;
        setUploading(true);
        await sendReport(state).finally(() => {
            setUploading(false);
        });

    };

    const sendReport = async (v: any) => {
        let { images, report } = await reportFormatter(Object.assign({}, v, { novedad: `${v.novedad}${keyLocalDate ?? ""}` }));
        let resReport = await sendData({ variables: { report: [report] } }).catch((err: any) => ({ error: true }));
        if (resReport?.data?.createReporteV2?.length && resReport?.data?.createReporteV2[0]?.uploadLinks?.length) {
            await Promise.all(resReport.data.createReporteV2[0].uploadLinks.map(async (item: objectKV) => {
                let file = images.find((f: File) => f.name === item.key);
                return (item.url && file ? await uploadImage(item.url, file, file.type) : null);
            }));
        }
    };

    const _onClickCancel = () => {
        props.close();
    };

    const validator = async (v: objectKV) => {
        let currentDate = (await getDateServer(!!keyLocalDate)).split("##")[0];
        if (!currentDate || typeof (currentDate) !== "string") {
            setMessageErrorDatePicker("No se pudo obtener la hora del servidor");
            return false;
        }
        setErrorLocation({ error: !v.lugar, message: "Requerido" });
        setErrorNovedad({ error: !v.novedad, message: "Requerido" });
        setErrorDate({ error: !v.fecha, message: "Requerido" });
        setErrorTime({ error: !v.hora, message: "Requerido" });
        let isnotvalidDate = validatorTimeDate(v.hora ?? "", v.fecha ?? "", formatterTimeZoneDate(currentDate).timeCurrentZone, formatterTimeZoneDate(+(dataFill?.viaje?.fechahoraultimoevento ?? 0)).timeCurrentZone);
        setMessageErrorDatePicker(isnotvalidDate);
        if (!v.lugar || !v.fecha || !v.hora || !v.novedad || isnotvalidDate) return false;
        return true;
    }

    React.useEffect(() => {
        getDateServer().then((res: string) => {
            let _resDate = res.split("##");
            setKeyLocalDate(_resDate[1])
            setDateServer(_resDate[0]);
            setDate(formatterTimeZoneDate(_resDate[0]).timeCurrentZone ?? 0);
        });
    }, []);

    React.useEffect(() => {
        if (props.id) {
            loadData({ variables: { id: props.id } });
            loadDataRemittances({ variables: { id: props.id } });
        }
    }, [props.id]);

    React.useEffect(() => {
        let value = dataRemittances?.remesasInfo?.data;
        if (value) {
            dispatch({
                type: "SET_DATA", value: {
                    idviaje: props.id,
                    idremesas: (value?.length && value.map((item: { id: number | string }) => item.id)) ?? []
                }
            });
        }
    }, [dataRemittances]);

    React.useEffect(() => {
        dispatch({ type: "SET_DATA", value: { idusuario: id } });
    }, [id]);

    React.useEffect(() => {
        if (data?.createReporteV2?.length && !uploading) {
            props.close();
        }
    }, [data, uploading]);

    return (
        <div className="form-call general-form">
            <div className="form-call__general-info">
                <GeneralInfo {...props} formato={2} title="Reporte de llamada" idsysevento={idsysevento} />
            </div>
            <div className="form-call__location">
                <LocationForm style={{width:400}} onChange={dispatch} error={errorLocation} label="Lugar reporte*" />
            </div>

            <div className="form-call__date-picker">
                <DatePicker dateString={dateServer} messageGeneralError={messageErrorDatePicker} onChange={dispatch} date={date} errorDate={errorDate} errorTime={errorTime} />
            </div>
            <div className="form-call__match">
                <NotMatch onChange={dispatch} />
            </div>
            <div className="form-call__upload-file">
                <UploadFile onChange={dispatch} />
            </div>

            <div className="form-call__observation">
                <Observations error={errorNovedad} onChange={dispatch} value="Sin Novedad" />
            </div>
            <div className="form-call__last">
            <TextView height={50} numLines={2} label="Ultima llamada" text={dataTravel?.utilsReport?.ultimoreporte??""} />
            </div>
            <div className="form-call__buttons">
                <ControlButtons
                    buttonCancel={{ onClick: _onClickCancel, disabled: uploading }}
                    buttonSend={{
                        onClick: _onClickSend,
                        loading: uploading,
                        disabled: uploading
                    }} />
            </div>
        </div>
    );
};

