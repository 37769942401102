import React from "react";
import { Table } from "./../../Componets/Basic/Table/Table"
import { Layout } from "./../../Componets/General/Layout/Layout";
import { objectKV, action, confMap, confTable } from "./../../utils/interface";
import { useLazyQueryToSever } from "./../../Componets/hook/query-hook";
import { Redirect } from "react-router-dom";
import { ExportExcel, FiltersOptions, parceOrders, TotalRegs } from "../../Componets/General/UtilsView/Utils-view";
import { Factory, useSysState } from "../Home/FactoryHome";
import { useConfTable } from "../../Componets/hook/conf-table-hook";
import { createZip, dateToString, downloadBlob, getRangeDays, getRangeMonths, parseJson, stringToDate } from "../../utils/utils";
import "./Downloadmanagment.scss";
import { getItemSelectedRange } from "../../Componets/Basic/Input/DateRange";
import { FactoryFilters } from "./Downloadmanagment.factory";
import { fieldsTable, queryViajes, queryViajesids } from "./Downloadmanagment.config";
import { ButtonDownLoad } from "../../Componets/General/FormsUtils/Buttons.form";
import { useNotification } from "../../Componets/hook/notification-hook";
import { NotificationError, NotificationSucces } from "../../Componets/General/FormsUtils/Notification.fom";
import { useUser } from "../../Componets/hook/user-hook";
import { Modal } from "../../Componets/Basic/Modal/Modal";
import Button from "../../Componets/Basic/Button/Button";

const KEY = "DownloadManagment";


const __fields = [
    "id"
    , "numero"
    , "fecha"
    , "placa"
    , "lugar"
    , "fechahoraultimoevento"
    , "ultimoevento"
    , "cliente"
    , "estado"
    , "indicadoraltoriesgo"
    , "destino"
    , "origen"
    , "modalidad"
    , "nombreconductor"
    , "telefonoconductor"
    , "trailerplaca"
    , "ruta"
    , "nombreconductor"
    , "numerodocumentoconductor"
];

const ModalConfirmation = React.memo((props:any) => {

    let {idSelected,screenids} = props;
    let [visible, setVisible] = React.useState<boolean>(false);
    let [downloading, setdownloading] = React.useState<boolean>();
    let [count, setCount] = React.useState<number>(0);

    const { user } = useUser();

    const { addNotification } = useNotification();
    
    const [loadData, { called, loading, data, refetch, fetchMore }] = useLazyQueryToSever({ query: queryViajesids });


    const close = () => {
        setVisible(false);
    };

    const open = () => {
        setVisible(true);
    };

    const getImages = async (id: any) => {
        let headers = new Headers();
        headers.append("Authorization", user?.token ?? "");
        headers.append("idapp", "12");
        headers.append("idempresa", "" + (user.idcompany ?? ""));
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        let body = JSON.stringify({"operationName":null,"variables":{},"query":`
            query viajes{
                trazabilidadInfo(id:${id}){
                    data{
                        id
                        numberdocs
                        fechahora
                        puestocontrol
                        sysevento
                        evento
                        orden
                        idviaje
                    }
                }
            }
        `});
        let uri = `${process.env.REACT_APP_URL}/graphql`;
        let traza = await fetch(uri, { method: "POST", headers, body }).then((res: any) => res.json());
        traza = traza?.data?.trazabilidadInfo?.data?.filter((i:any) => i.numberdocs);
        let _trazalinks:Array<any> = [];

        const getBody = (idrep:any) => JSON.stringify({"operationName":null,"variables":{},"query":`
        query getSignedURLReport{
            getSignedURLReport(idreporte:${idrep}){
                id
                key
                url
            }
        }
    `});
        for(const trz of traza){
            const _body = getBody(trz.id);
            let temp = await fetch(uri, { method: "POST", headers, body:_body }).then((res: any) => res.json());
            if(temp?.data?.getSignedURLReport.length) {
                await Promise.all(temp.data.getSignedURLReport.map(async (item:any) => {
                   let tempImg = await fetch(item.url).then((res:any) => res.blob());
                    _trazalinks.push({
                        data:tempImg,
                        name:`${item.key}`.split("/").reverse()[0],
                        path:`${trz.idviaje}/${trz.orden}`
                    });
                }));
            }
        }
        return _trazalinks;
    };


    const downLoadTraza = async (id: any) => {
        let headers = new Headers();
        headers.append("Authorization", user?.token ?? "");
        headers.append("idapp", "12");
        headers.append("idempresa", "" + (user.idcompany ?? ""));
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        let body = JSON.stringify({ "filters": [{ "field": "idviaje", "value": id }], fields: ["lugar", "fecha", "remesas", "usuario", "evento", "orden", "sysevento", "puestocontrol", "novedad"], "idquery": 1 });
        let uri = `${process.env.REACT_APP_URL}/api/v1/filesDoc`;
        return await fetch(uri, { method: "POST", headers, body }).then((res: any) => res.blob());
    };

    const downloadExcel = async (filters: Array<{ field: string, value: string }>) => {
        if (!user.idcompany) return;
        let headers = new Headers();
        headers.append("Authorization", user?.token ?? "");
        headers.append("idapp", "12");
        headers.append("idempresa", "" + (user.idcompany ?? ""));
        headers.append("Content-Type", "application/json");
        let body = JSON.stringify({ filters, fields: __fields, idquery: 2 });
        let uri = `${process.env.REACT_APP_URL}/api/v1/filesDoc`;
        return await fetch(uri, { method: "POST", headers, body }).then((res: any) => res.blob());
        // .then((res) => res)
        // .then((blob) => URL.createObjectURL(blob));
    };

    const _onClickDown = async (_idSelected:any,all?:any) => {
        setdownloading(true);
        close();
        addNotification({ element: <NotificationSucces message="Se inicio la descarga, este proceso puede tomar algo de tiempo" />, delay: 3000 });
        try {
            
            if(!_idSelected&&all) {
                _idSelected = await loadData().then((res:any) => {
                    if(!res?.data?.viajes?.data.length) return [];
                    return res?.data?.viajes?.data?.map((item: objectKV) => item && item.id).filter(Boolean);
                });
            } else if(!_idSelected) {
                setdownloading(false);
                setCount(0);
                throw new Error("");
            }
            let filters = _idSelected.filter(Boolean).map((i: any) => ({ field: "id", value: i }));
            if(!_idSelected.length) throw new Error("");
            let excel = await downloadExcel(filters);
            let traza:Array<any> = [];
            for (const filter of filters) {
                let temp = await downLoadTraza(+filter.value);
                let imgs = await getImages(+filter.value);
                traza.push({
                    data: temp,
                    name: "TRAZABILIDAD.xlsx",
                    path: filter.value
                },...imgs);
                setCount((c:any) => c+1);
            }
            let values = [
                {
                    data: excel,
                    name: "VIAJES.xlsx",
                    path: ""
                },
                ...traza
            ];
            await createZip(values).then((res: any) => downloadBlob(res, `${new Date().getTime()}.zip`));
        } catch (error) {
            addNotification({ element: <NotificationError message="Ocurrio un error" />, delay: 3000 });
        }
        setdownloading(false);
        setCount(0);
    };

    return (
        <>
        <ButtonDownLoad disabled={downloading} message={downloading?`${count}`:undefined} onClick={open} />
        <Modal visible={visible} clickOutside={close} backGroundColor="#1b1b1b30">
            <div className="modal-confirmation-download-managment">
                <h3 style={{textAlign:"center"}}>
                    Esta descarga toma algo de tiempo,
                    recomendamos que se haga por paginas,
                    tiempo estimado 60 viajes en 10 minutos
                </h3>
                <Button className="modal-confirmation-download-managment__btn-accept" onClick={()=>_onClickDown(idSelected)}><label>Descargar selecionados</label></Button>
                <Button className="modal-confirmation-download-managment__btn-accept" onClick={()=>_onClickDown(screenids)}><label>Descargar en pantalla</label></Button>
                <Button className="modal-confirmation-download-managment__btn-accept" onClick={()=>_onClickDown(null,true)}><label>Descargar todo</label></Button>
                <Button className="modal-confirmation-download-managment__btn-cancel" onClick={close}><label>Cancelar</label></Button>
            </div>
        </Modal>
        </>
    );
});

export const Downloadmanagment = React.memo(() => {

    const tableRef = React.useRef(null);

    const { sys } = useSysState([0]);

    let [id, setId] = React.useState<number>();
    const [calledFiltres, setCalled] = React.useState<boolean>(false);

    //Uso el id 1000 para la tabla de viajes 
    const {
        table,
        setConfig
    } = useConfTable(fieldsTable, { id: 1001, key: KEY });

    const initial = {
        /**
         * estos valores tambien estan en el state pero no tiene un valor inicial
         */
        //data: es la info que se odtine al dar click en una columna de la tabla ,
        //filters: aqui se guarda los filtros,
        //orders: se guardan los valores del orden

    };

    // React.useEffect(() => {
    //     console.log("tablesssss",table);
    // },[table]);

    const reducer = (state: objectKV, action: action) => {
        switch (action.type) {
            case "CLICK_ROW":
                if (action.value?.id) setId(action.value?.id);
                return Object.assign({}, state, { data: action.value });
            case "ADD_CONFIG_TABLE":
                return Object.assign({}, state);
            default:
                return state;
        }
    };

    const [state, dispatch] = React.useReducer(reducer, initial);

    const [loadData, { called, loading, data, refetch, fetchMore }] = useLazyQueryToSever({ query: queryViajes });

    let [hiddenFilters, sethiddenFilters] = React.useState<boolean>();
    let [downloading, setdownloading] = React.useState<boolean>();

    const mapNewFilters = (filters: any, _sys: Array<confMap>) => {
        let tempDateArray: Array<any> = [];
        let _filters, tempValue: any = filters.find((item: false | { field: string; value: any; }) => item && item.field === "idsysestado");
        if (!tempValue?.value && _sys?.length) _filters = [...filters, ..._sys.map((item: objectKV) => ({ field: "idsysestado", value: item.id + "" }))];
        else _filters = filters;
        _filters = _filters.map((item: false | { field: string; value: any; }) => {
            if (item && item?.field === "cliente") {
                let _item = JSON.parse(item.value ?? "{\"values\":[]}")?.values;
                _item = _item.length ? { field: "idclientes", value: `[${_item.join(",")}]` } : null;
                return _item;
            } else if (item && item?.field === "fecha") {
                let dateTemp, dateTempObj: any = item.value?.split("-");
                dateTemp = stringToDate(dateTempObj[0]?.split("/")?.join("-"));
                if (dateTemp?.getTime) {
                    tempDateArray.push({ field: "fecha", value: `${dateTemp.getFullYear()}-${dateTemp.getMonth() + 1}-${dateTemp.getDate()}` });
                }
                dateTemp = stringToDate(dateTempObj[1]?.split("/")?.join("-"));
                if (dateTemp?.getTime) {
                    tempDateArray.push({ field: "fecha", value: `${dateTemp.getFullYear()}-${dateTemp.getMonth() + 1}-${dateTemp.getDate()}` });
                }
                return null;
            }
            else { }
            return item;
        }).filter(Boolean);
        _filters = [..._filters, ...tempDateArray];
        return _filters;
    };

    const filtersExcel = React.useMemo(() => {
        let { filters } = table ?? {};
        if (!sys) return [];
        if (filters) return mapNewFilters(filters, sys);
        return [];
    }, [JSON.stringify(table), sys]);

    let [idSelected, setIdSelected] = React.useState<Array<number | string>>([]);


    React.useEffect(() => {
        setIdSelected([]);
        // if (!calledFiltres) return;
        let { filters, numReg, page, orders } = table ?? {};
        //Se parcean los campos de orden que traen una estructura diferente de el state
        let tempOrders = orders ? parceOrders(orders) : {field: "id", type: "DESC"};
        if (!sys) return;
        if (filters) {
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            let _filters = mapNewFilters(filters, sys);
            loadData({ variables: { filters: _filters, page: page ?? 1, fields: numReg ?? 20, orders: tempOrders } })
        }

    }, [JSON.stringify(table), sys]);

    const resetFilters = () => {
        let el: any = tableRef?.current;
        if (el?.resetFilters) {
            el?.resetFilters()
        }
    };

    const title = React.useMemo(() => {
        const general = "Gestor de descarga";
        let _date = table?.filters?.find((item: any) => item.field === "fecha")?.value?.split("-") ?? [];
        let itemSelected = getItemSelectedRange(_date);
        switch (itemSelected) {
            case "TODAY": return `${general} hoy`;
            case "WEEK": return `${general} ultima semana`;
            case "MONTH": return `${general} ultima mes`;
            case "3-MONTHS": return `${general} ultimo trimestre`;
            case "6-MOUTHS": return `${general} ultimo semestre`;
            case "YEAR": return `${general} ultimo año`;
            default: return `${general} ${_date?.join("-") ?? ""}`
        }
    }, [table?.filters]);

    // React.useEffect(() => {
    //     console.log(table);
    // },[table]);

    const _valuesSelected = (v: Array<string | number>) => {
        if (v[0] === Infinity && data?.viajes?.data?.length) {
            let ids = data.viajes.data.map((item: objectKV) => item && item.id).filter(Boolean);
            setIdSelected([...ids]);
        }
        else {
            setIdSelected([...v]);
        }
    };

    const screenids = React.useMemo(() => {
        if(!data?.viajes?.data.length) return [];
        return data?.viajes?.data?.map((item: objectKV) => item && item.id).filter(Boolean);
    },[JSON.stringify(data?.viajes?.data)]);

    return (
        <Layout
            title={title}
            use500
            pageCurrent={table?.page}
            right={<FiltersOptions
                filters={table?.filters}
                reset={resetFilters}
                hiddenFilters={sethiddenFilters}
                hidden={!!hiddenFilters} />}
            goToPage={setConfig}
            total={data?.viajes?.totalreg}
            optionMore={<ModalConfirmation idSelected={idSelected} screenids={screenids}/>}
            leftBottom={<TotalRegs text={`Total viajes: ${data?.viajes?.totalreg ?? 0}`} />}
            numReg={table?.numReg}>
            <Table
                ref={tableRef}
                factoryFilters={FactoryFilters}
                factory={Factory}
                isSelectable
                idSelected={idSelected}
                valuesSelected={_valuesSelected}
                getFilters={setConfig}
                hiddenFilters={hiddenFilters}
                data={data?.viajes?.data}
                clickRow={dispatch}
                loading={(loading || !called)}
                orderClick={setConfig}
                orderableField={table?.orders}
                fields={table?.fields ?? []} />
        </Layout>
    );
});


