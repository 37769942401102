import { fields } from "../../Componets/Helpers/Form/Form-context";
import { confTable } from "../../utils/interface";
import { getLastMonthRange } from "../../utils/utils";


const viajeMin = `    
id
idempresa
idempresatransportadora
idagencia
numero
fechahoraproximogps
fechahoraultimallamada
fechahoraproximopuestocontrol
fecha
placa
retenerviaje
idproximosysevento
idsysestado
idsysmodalidad
idplanviaje
idvehiculo
idconductor
idcargainternacional
idagencia
idembarcador
idruta
lugar
fechahoraultimoevento
fechahoraproximallamada
ultimoevento
controlador
cliente
estado
mercancias
modalidadnombre
indicadoraltoriesgo
destino
origen
modalidad
proximopuestocontrol
nombreconductor
telefonoconductor
idclientes
indicadorvialibre
idproximopuestocontrol
trailerplaca
sincronizado
ruta
novedad
sysevento
evento
comercial
nombreconductor
numerocontenedor
documentocliente
numerodocumentoconductor
empresatransportadora
tipovehiculo
geolocalizacion {
  latitud
  longitud
}
`;

export const queryViajes = `
query viajes( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
  viajes(
    filters: $filters,
    orders: $orders,
    pagination: { fields:$fields, page: $page}
  ) {
      data {
        ${viajeMin}
      }
      totalreg
    }
}`;

export const fieldsTable: Array<confTable> = [
  {
    label:"Fecha",
    field: "fecha",
    path:"fecha",
    type: "DATE",
    width:100,
    orderable:true,
    filterable: true,
    valueFiltered:getLastMonthRange(new Date())
    
  },
  {
    label:"Placa",
    field:"placa",
    width:70,
    orderable:true,
    filterable:true
  },
  {
    label:"Tipo",
    field:"tipovehiculo",
    path:"tipovehiculo",
    type:"TEXT",
    width:80,
    useTooltip:true,
    orderable:true,
    filterable:true
  },
  {
    label:"No. manifiesto",
    field: "numero",
    path:"numero",
    type: "TEXT",
    width:100,
    orderable:true,
    filterable:true
  },
  {
    label:"No. cont.",
    field: "numerocontenedor",
    path:"numerocontenedor",
    type: "TEXT",
    width:100,
    orderable:true,
    voidValue:" ",
    filterable:true
  },
  {
    label:"Doc. cliente",
    field: "documentocliente",
    path:"documentocliente",
    type: "TEXT",
    width:100,
    useTooltip:true,
    orderable:true,
    voidValue:" ",
    filterable:true
  },
  {
    label:"Estado",
    field:"idsysestado",
    orderable:true,
    filterable:true,
    textAling:"CENTER",
    // valueFiltered:"PENDIENTE"
  },
  {
    label:"Generador de carga",
    field:"cliente",
    path:"cliente",
    orderable:true,
    width:200,
    filterable:true,
    type:"TEXT",
    // valueFiltered:"241"
  },
  {
    label:"Tel. Cond",
    field:"telefonoconductor",
    width:100,
    orderable:true,
    filterable:true,
    stopClick:true,
    textAling:"CENTER"
  },
  {
    label:"Merc.",
    field:"indicadoraltoriesgo",
    orderable:true,
    filterable:true,
    textAling:"CENTER"
  },
  {
    label:"Origen",
    field:"origen",
    orderable:true,
    filterable:true,
    width:80,
    useTooltip:true,
    path:"origen",
    type:"TEXT"
  },
  {
    label:"Destino",
    field:"destino",
    orderable:true,
    width:80,
    useTooltip:true,
    filterable:true,
    path:"destino",
    type:"TEXT"
  },
  {
    label:"Ultimo reporte",
    field:"novedad",
    orderable:true,
    filterable:true,
    width:310
  },
  {
    label:"Fecha-hora",
    field:"fechahoraultimoevento",
    path:"fechahoraultimoevento",
    orderable:true,
    width:100,
    filterable:false,
    type:"DATE-TIME",
    textAling:"RIGHT"
  },
  {
    label:"Modal",
    field:"modalidad",
    orderable:true,
    filterable:true,
    width:60,
    multiPath:["modalidad","modalidadnombre"],
    concatToken:" - ",
    useTooltip:true,
    type:"TEXT",
    textAling:"CENTER"
  },
  {
    label:"Lugar",
    field:"lugar",
    path:"lugar",
    orderable:true,
    filterable:true,
    width:200,
    type:"TEXT",
  },
  {
    label:"Conductor",
    field: "nombreconductor",
    path:"nombreconductor",
    type: "TEXT",
    orderable:true,
    filterable:true
  },
  {
    label:"Cédula",
    field: "numerodocumentoconductor",
    path:"numerodocumentoconductor",
    type: "TEXT",
    orderable:true,
    filterable:true
  },
  {
    label:"Comercial",
    field: "comercial",
    path:"comercial",
    type: "TEXT",
    orderable:true,
    filterable:true
  }
];

  
export const queryClientesViajes = `
query clienteViajesInfo{
  clienteViajesInfo(
    orders:[
    {field:"nombre",type:ASC}
    ],
    filters:[
    {field:"idsysestado",value:"7"},  	
    {field:"idsysestado",value:"4"}
    ]
  ){
    data{
      id
      idempresa
      nombre
    }
  }
}`;

  export const filedsFormFilters:Array<fields> = [
    {
        name:"days",
        path:"days",
        validator: [{min:1}]
    },
    {
        name:"from",
        path:"from"
    },
    {
        name:"to",
        path:"to",
    },
    {
        name:"origen",
        path:"origen",
    },
    {
      name:"destino",
      path:"destino",
    },
    {
      name:"placa",
      path:"placa",
    },
    {
      name:"nombreconductor",
      path:"nombreconductor",
    },
    {
      name:"cliente",
      path:"cliente",
    }
];

  ///que pasooo