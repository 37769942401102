import React from "react";
import { useUser } from "../../Componets/hook/user-hook";
import { Modal } from "../../Componets/Basic/Modal/Modal";
import { objectKV } from "../../utils/interface";
import "./Client.scss";
import { useNotification } from "../../Componets/hook/notification-hook";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { useMutationServer } from "../../Componets/hook/mutation-hook";
import { NotificationError, NotificationSucces } from "../../Componets/General/FormsUtils/Notification.fom";
import Button from "../../Componets/Basic/Button/Button";
import TextView from "../../Componets/Basic/Text/TextView";
import { useClientApolloGranada } from "../../Componets/hook/client-apollo-hook";
import { createsyseventosclienteocultos, fragmentClient, querySysEventos, resetPassword, syseventosclienteocultos } from "./Client.conf";
import { Close } from "../../Componets/General/FormsUtils/Buttons.form";
import ListView from "../../Componets/Basic/Text/ListViwer";
import { ControlButtons } from "../Home/Forms/Form.general-info";
import { Option2 } from "../../Componets/Basic/Input/InputSelect";
import { ItemSelect2 } from "../../Componets/Basic/Input/InputSelectMultiple";

export const ModalOptionsClient = (props: { id?: string | number, visible: boolean, close: (v?: any) => any, refresh?: (v?: any) => any, onClickSave?: (v?: any) => any }) => {

    const { user } = useUser();

    const [data, setData] = React.useState<objectKV>();

    const { readFragment } = useClientApolloGranada();

    const { addNotification } = useNotification();

    const [resetPass, { loading: loadingReset, data: dataReset, error }] = useMutationServer({ mutation: resetPassword });

    React.useEffect(() => {
        if (error) {
            addNotification({ element: <NotificationError message="Ocurrio un error" />, delay: 3000 });
        }
    }, [error]);

    React.useEffect(() => {
        if (dataReset?.resetPassword?.succesful && !error) {
            addNotification({ element: <NotificationSucces message="Se restablecio la contraseña" />, delay: 3000 });
        }
    }, [dataReset]);

    React.useEffect(() => {
        if (props.visible) {
            try {
                let value = readFragment({
                    fragment: fragmentClient,
                    id: `ClientesEmpresasTransportadoras:${props.id}`
                });
                setData(value);
            } catch (error) {
            }
        }
    }, [props.visible]);

    const _clickOutside = () => {
        props.close();
    };

    const _clickReset = () => {
        if (loadingReset) return;
        let id = +(data?.idusuario ?? 0)
        if (id) resetPass({ variables: { id, idapp: 15 } });
    };

    return (
        <Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
            <div className="client-modal">
                <div className="client-modal__container">
                    <div className="name">{data?.nombrecliente ?? ""}</div>
                    <div className="close"><Close onClick={_clickOutside} /></div>
                    <div className="number">
                        <TextView label="Número documento" text={data?.numerodocumentocliente ?? ""} />
                    </div>
                    <div className="reset">
                        <ModalOptionsClientSysEvents id={data?.id} />
                        <Button
                            disabled={loadingReset}
                            onClick={_clickReset}
                            className="button-reset"
                            label="Reset Pass"
                            message={`ATENCION:\nEste proceso restablecera la contraseña del cliente\nNo se puede revertir\nSí esta seguro continue.`}
                        />
                    </div>
                    <div className="code">
                        <TextView label="Codigo" text={data?.codigo ?? ""} />
                    </div>
                    <div className="frequency">
                        <TextView label="Frecuencía mail(mnts)" text={data?.frecuenciacorreo ?? ""} />
                    </div>
                    <div className="hours">
                        <ListView label="Horas especificas" heightList={80} data={(data?.tiempoenviocorreo ?? [])?.filter(Boolean)} />
                    </div>
                    <div className="events">
                        <ListView label="Eventos" heightList={80} data={(data?.eventos ?? [])?.filter(Boolean)} />
                    </div>
                    <div className="mail">
                        <ListView label="Correos" heightList={80} data={(data?.correoreporte ?? "").split(",")?.filter(Boolean)} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const ItemSys = (props: any) => {
    const { item, _assingValue, ids } = props;
    // console.log(888,ids)
    const data = React.useMemo(() => {
        // console.log(999,ids)
        return { key: item.id, show: true, valueShow: item.descripcion, decription: item.descripcion, selected: ids.includes(item.id) };
    }, [JSON.stringify(item), JSON.stringify(ids)]);
    return <div key={item.id} style={{ display: "flex", gap: 10 }}><ItemSelect2 style={{ display: "flex", width: 300, fontSize: 15 }} key={item.id} onClick={_assingValue} data={data} /></div>

}

export const ModalOptionsClientSysEvents = (props: { id?: any }) => {

    const [visible, setVisible] = React.useState<boolean>(false);
    const [ids, setIds] = React.useState<Array<number>>([]);

    const { addNotification } = useNotification();
    const [loadDataSys, { data: datasys }] = useLazyQueryToSever({ query: querySysEventos, fetchPolicy: "cache-first" });
    const [loadsyseventosclienteocultos, { data: syseventosclienteocultosdata, loading: syseventosclienteocultosloading }] = useLazyQueryToSever({ query: syseventosclienteocultos, fetchPolicy: "network-only" });

    const onCompleted = (res: any) => {
        if (res?.createsyseventosclienteocultos?.id) {
            addNotification({ element: <NotificationSucces message={`Proceso exitoso`} />, delay: 2000 });
        } else {
            addNotification({ element: <NotificationError message={`Ocurrio un error`} />, delay: 2000 });
        }
        _onClickCancel();
    };
    const [create, { loading: loading, data: data, error: error }] = useMutationServer({ mutation: createsyseventosclienteocultos, onCompleted });

    React.useEffect(() => {
        loadDataSys();
    }, []);

    React.useEffect(() => {
        if (!syseventosclienteocultosloading && syseventosclienteocultosdata?.syseventosclienteocultos?.length) {
            setIds((_ids: Array<number>) => {
                _ids = [...syseventosclienteocultosdata.syseventosclienteocultos.map((item: any) => item.idsysevento)];
                return _ids;
            });
        }
    }, [JSON.stringify(syseventosclienteocultosdata), syseventosclienteocultosloading]);

    React.useEffect(() => {
        console.log(ids);
    }, [ids]);

    const syseventos = React.useMemo(() => JSON.parse(JSON.stringify(datasys?.sysEventos?.data ?? [])).filter((item: any) => ![1, 22, 23, 24, 25, 30, 31, 32].includes(item.id)).sort((a: any, b: any) => a.id - b.id), [datasys?.sysEventos?.data]);

    const close = () => setVisible(false);
    const open = () => setVisible(true);
    const _clickOutside = () => {
        close();
    };

    const _click = () => {
        loadsyseventosclienteocultos({ variables: { idcliente: props.id ?? 0 } });
        open();
    }

    const _onClickSend = async () => {
        create({ variables: { idcliente: props.id, syseventos: ids.map((id: any) => ({ id })) } });
    };

    const _onClickCancel = () => {
        close();
    };

    const _assingValue = (v: any) => {
        setIds((_ids: Array<number>) => {
            _ids = [..._ids];
            let _key = v?.value?.key;
            if (_ids.includes(_key)) _ids = _ids.filter((item) => item != _key);
            else _ids.push(_key);
            return _ids;
        });
    }

    return (
        <>
            <Modal visible={visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
                <div className="client-events-modal">
                    <h2 style={{margin:0}}>
                        Eventos no mostrar cliente
                    </h2>
                    <h4 style={{margin:0}}>
                        Los reportes se le mostraran al cliente dependiendo de la configuracion que hay aqui en el momento que se crea este.
                    </h4>
                    <div className="client-events-modal__form">
                        {syseventos?.map((item: objectKV) => <ItemSys key={item.id} item={item} _assingValue={_assingValue} ids={ids} />)}
                    </div>
                    <div>
                        <ControlButtons
                            labelCancel="Cerrar"
                            labelSend="Enviar"
                            buttonCancel={{ onClick: _onClickCancel, disabled: loading || syseventosclienteocultosloading }}
                            buttonSend={{
                                onClick: _onClickSend,
                                loading: loading || syseventosclienteocultosloading,
                                disabled: loading || syseventosclienteocultosloading
                            }} />
                    </div>

                </div>
            </Modal>
            {/* disabled={true} */}
            <Button
                onClick={_click}
                className="button-reset"
                label="Ocultar eventos"
                message={`Con esta opción se puede configurar los eventos del sistema que no se deben mostrar al cliente.`}
            />
        </>
    );
};