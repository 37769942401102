import React, { forwardRef } from 'react';
import { FormProvider, useForm, fields } from './../../Helpers/Form/Form-context';
import { error, action, objectKV} from "./../../../utils/interface";
import InputText from './../Input/InputText';
import { InputSelect } from './../Input/InputSelect';
import { InputDate } from './../Input/InputDate';
import { AdvancedSearch } from '../AdvancedSearch/AdvancedSearch';
import { Switch } from '../Switch/Switch';
import InputTextArea from '../Input/InputTextArea';
import { InputSelectMultiple } from '../Input/InputSelectMultiple';
import { useInputMultiSelect } from '../../hook/input-hook';

export interface propsForm {
    /**Esta funcion odtendra los valores  */
    getValues?:(v:objectKV) => any;
    /**
     * @description Esta funcion odtendra los valores cuando ocurra un cambio
     * @deprecated
     *  */
    getOnchangeValues?:(v:objectKV) => any;
    /**Esta funcion odtiene un booleno que indica si el formulario es valido */
    getIsValid?:(v:boolean) => any;
    /**Indica si el foemulario debe empezar a mostar los errores, SIEMPRE ESTA VALIDANDO */
    validate?:boolean;
    /**Son los componentes hijos del formulario */
    children?: JSX.Element[] | JSX.Element;
    /**Es un array con la configuracion del campo  */
    fields:Array<fields>;
}

/**
 * @description este funcion permite decorar (HOC) un componente
 * @version 1.0
 * @param props 
 */
 export const WithForm = (props:any) => {

    /**Destructuring de las props */
    const { assingOtherProps, ...allRest} = props; 

    /**Se obtiene el form por un hook */
    const { getConfig, getField, config, getValues } = useForm();

    /**Estado que administra la configuracion del campo */
    const [bind, setBind] = React.useState<any>();

    /**Estado que administra los errores del campo */
    const [error, setError] = React.useState<error>();
    /**Estado que almacena posibles props que dependan del formulario, como un valor por defecto */
    const [otherProps, setOtherProps] = React.useState<objectKV | null>();

    React.useEffect(() => {
        /**Se valida si es que existe una funcion para generar nuevas props, entonces se llama y alamcenan los valores que este retorne */
        if(assingOtherProps) setOtherProps(assingOtherProps(getValues()));
        /**Se verifica la existencia de un nombre y una configuracion */
        if(props.name && Object.keys(config).length){
            /**Se obtienen los valores de la configuracion para cierto campo */
            let temp = getConfig(props.name);
            /**Se asigna la configuracion que se obtuvo en un state */
            if(temp) setBind(temp);
            else console.warn("WARNING: no se encuentra una configuracion");
            /**Se valida la existencia del valor getField el cual eprmite obtener los errores en elcampo si es que existen */
            if(getField){
                let _error = getField(props.name)?.error;
                if(_error) setError(_error);
            }
        }
    },[props.name,JSON.stringify(config)]);

    /**
     * Se retorna una funcion(HOC)
     * https://reactjs.org/docs/higher-order-components.html
     */
    return (ComponentForm:any,type?:string) => {
        return bind?<ComponentForm {...bind} {...otherProps} error={error}  {...allRest}/>:null;
    }
};

/**
 * @description este funcion permite decorar (HOC) un componente
 * @version 1.0
 * @param props 
 */
export const WithFormSelectMultiple = (props:any) => {

    /**Destructuring de las props */
    const { assingOtherProps, ...allRest} = props; 

    /**Se obtiene el form por un hook */
    const { getConfig, getField, config, getValues } = useForm();

    /**Estado que administra la configuracion del campo */
    const [cofigInput, setCofigInput] = React.useState<any>();

    /**Estado que administra los errores del campo */
    const [error, setError] = React.useState<error>();
    /**Estado que almacena posibles props que dependan del formulario, como un valor por defecto */
    const [otherProps, setOtherProps] = React.useState<objectKV | null>();

    React.useEffect(() => {
        /**Se valida si es que existe una funcion para generar nuevas props, entonces se llama y alamcenan los valores que este retorne */
        if(assingOtherProps) setOtherProps(assingOtherProps(getValues()));
        /**Se verifica la existencia de un nombre y una configuracion */
        if(props.name && Object.keys(config).length){
            /**Se obtienen los valores de la configuracion para cierto campo */
            let temp = getConfig(props.name);
            /**Se asigna la configuracion que se obtuvo en un state */
            if(temp) setCofigInput(temp);
            else console.warn("WARNING: no se encuentra una configuracion");
            /**Se valida la existencia del valor getField el cual eprmite obtener los errores en elcampo si es que existen */
            if(getField){
                let _error = getField(props.name)?.error;
                if(_error) setError(_error);
            }
        }
    }, [props.name, JSON.stringify(config)]);
    
    // React.useEffect(() => {
        // if (temp === cofigInput?.value) return;
        // console.log("heres",cofigInput?.value,temp)
        // if (cofigInput?.onChange) cofigInput?.onChange({ target: {name:props.name,value:temp}});
    // }, [value]);
    
    // React.useEffect(() => {

    //     setValue(temp);
    // }, [cofigInput?.value]);
    
    const value = React.useMemo(() => {
        return JSON.parse(cofigInput?.value?cofigInput?.value:"{\"values\":[]}").values;
    }, [cofigInput?.value]);
    
    const _onChange = React.useCallback((e: any) => {
        let temp = e?.target?.value?.length?JSON.stringify({ values: e?.target?.value??[] }):undefined;
        // let temp = JSON.stringify({ values: e?.target?.value??[] });
        if (cofigInput?.onChange) cofigInput?.onChange({ target: {name:props.name,value:temp}});
    }, [cofigInput?.onChange]);


    /**
     * Se retorna una funcion(HOC)
     * https://reactjs.org/docs/higher-order-components.html
     */
    return (ComponentForm:any,type?:string) => {
        return cofigInput ? <ComponentForm value={ value } onChange={_onChange } {...otherProps} error={error}  {...allRest}/>:null;
    }
};

/**
 * @description este funcion permite decorar (HOC) un componente de fecha
 * @version 1.0
 * @param props 
 */
export const WithFormDate = (props:any) => {
    /**Se obtiene el form por un hook */
    const {getConfig,getField, config} = useForm();

    /**Estado que administra la configuracion del campo */
    const [bind, setBind] = React.useState<any>();

    /**Estado que administra los errores del campo */
    const [error, setError] = React.useState<error>();

    React.useEffect(() => {
        if(props.name && Object.keys(config).length){
            let temp = getConfig(props.name);
            if(temp) setBind(temp);
            else console.warn("WARNING: no se encuentra una configuracion");
            if(getField){
                let _error = getField(props.name)?.error;
                if(_error) setError(_error);
            }
        }
    },[props.name,config]);

    /**
     * Nota esto es un agrgado para que los formularios funciones con este componente 
     * @param v 
     */
    const _onDayChange = (v: action) => {
        if(bind?.onChange && props.name) bind.onChange({target:{name:props.name,value:v?.value?.value}})
    };

    return (ComponentForm:any) => {
        return bind?<ComponentForm {...props} date={bind.value} error={error}  onChange={_onDayChange}/>:null;
    }
};

/**
 * @description este es el formulario basico
 * @version 1.0
 */
const FormBasic = React.memo(forwardRef((props:propsForm, ref:any) => {

    const {assingFields,setState,isValid, config} = useForm();

    React.useEffect(() => {
       if(assingFields ){
            assingFields([...props.fields]);
       } 
    },[JSON.stringify(props.fields)]);

    React.useEffect(() => {
        if(props.getIsValid) props.getIsValid(isValid);
    },[isValid]);

    React.useEffect(() => {
        if(props.validate !== undefined) setState({type:"SET_VALIDATE_FORM", value:props.validate});
    },[props.validate]);

    React.useEffect(() => {
        if(props.getOnchangeValues) props.getOnchangeValues(Object.assign({},...Object.keys(config ?? {}).map((item:string) => ({[item]:config[item]?.bind?.value}))));
    }, [JSON.stringify(config)]);

    /**Contral el evnto submit */
    const _onSubmit = () => {
        if(props.getValues) props.getValues(Object.keys(config ?? {}).map((item:string) => ({[item]:config[item].bind.value})));
    };

    /**Assinga los valores al form */
    const assingValue = (v:any, key:string) => {
        setState({value:{[key]:v},type:"SET_VALUE"});
    } 

    React.useImperativeHandle(ref, ()=>({
        assingValue
    }));

    return (
        <form onSubmit={_onSubmit}>
            {props.children}
        </form>
    );
}));

/**
 * @description esta funcion reperesenta el componente formulario, funciona como un decorador para el provider
 * @version 1.0
 */
export const Form = React.memo(forwardRef((props:propsForm, ref:any) => {
    return (
        <FormProvider>
            <FormBasic {...props} ref={ref} />
        </FormProvider>
    );
}));

export const FormInputText = (props:any) =>  WithForm(props)(InputText);
export const FormInputTextArea = (props:any) =>  WithForm(props)(InputTextArea);
export const FormSwitch = (props:any) =>  WithForm(props)(Switch);
export const FormInputSelect = (props:any) =>  WithForm(props)(InputSelect);
export const FormInputAdvanced = (props:any) =>  WithForm(props)(AdvancedSearch);
export const FormInputDate = (props:any) =>  WithFormDate(props)(InputDate);
export const FormSelectMultiple = (props:any) =>  WithFormSelectMultiple(props)(InputSelectMultiple);
// export const FormInputText = (props:any) =>  WithForm(props)(InputText);

