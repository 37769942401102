import React from "react";
import Icon from "./../../../../Componets/Basic/Icon/Icon.tooltip";
import { confTable, objectKV } from "./../../../../utils/interface";
import { querySysEventos as GET_SYSEVENTOS, queryTablesRemitances } from "./../Travel.config";
import { useLazyQueryToSever } from "./../../../../Componets/hook/query-hook";
import "./Details.scss";
import { colorAlerts } from "../../../../utils/conf";
import { Modal } from "./../../../../Componets/Basic/Modal/Modal";
import { Gallery, GalleryAbsolute, getImage } from "../../../../Componets/Basic/Gallery/Gallery";
import { getSignedImage as GET_SIGNED_IMAGE } from "./Details.conf";
import { useClientApolloGranada } from "../../../../Componets/hook/client-apollo-hook";
import TextView from "../../../../Componets/Basic/Text/TextView";
import { useGeneralStore } from "../../../../Componets/hook/general-store-hook";
import { NotMatch } from "../../FactoryHome";



export const InfoIcon = React.memo((props:{ field: confTable, data:objectKV }) => {

    const [loadSysEvt, { data }] = useLazyQueryToSever({query:GET_SYSEVENTOS,fetchPolicy:"cache-only"});

    let [ className, setClassName ] = React.useState<string>("");
    let [ info, setInfo ] = React.useState<string>("");
    let [ color, setColor ] = React.useState<string>("gray");


    React.useEffect(() => {
        loadSysEvt();
    }, []);

    React.useEffect(() => {
        if( data?.sysEventos?.data?.find ) {
            // retraso
            let _color = colorAlerts.sort((a:objectKV, b:objectKV) => a.time - b.time ).find((item:objectKV) => item.time >= (props?.data?.retraso ?? 0));
            setColor(_color?.color ?? "gray");
            let temp = data?.sysEventos?.data?.find((item:objectKV) => (props?.data?.idsysevento ?? 17) === item.id);
            if(temp){
                setClassName(temp.iconname);
                setInfo(temp.descripcion);
            }
        }
    }, [JSON.stringify(data)]);

    return className?<div><i className="icon-report" style={{background:color}}>
        <Icon className={className} message={info} stabilizer={30}/>
    </i></div>:null;
});


export const Picture = (props:{ field: confTable, data:objectKV }) => {
    let [ numPicture, setNumPicture ] = React.useState<string>();
    let [ visible, setVisible ] = React.useState<boolean>(false);
    let [ images, setImages ] = React.useState<Array<{archivo:string}>>();
    const {state:storeImages,setData,getData} = useGeneralStore();

    const [loadData, { data, called,loading }] = useLazyQueryToSever({query:GET_SIGNED_IMAGE,fetchPolicy:"cache-first"});
    
    /**
     * esto es para pruebas visuales 
     * ____________________________________________________
     */
    // let [ numPictureT, setNumPictureT ] = React.useState<number>(0);
    // React.useEffect(() => {
    //     if(numPictureT) {
    //         let _numPicture= numPictureT;
    //         setNumPicture(_numPicture > 9?"9+":_numPicture+"");
    //     }
    //     else setNumPicture("");
    // }, [numPictureT]);


    // React.useEffect(() => {
    //     let interval = setInterval(() => {
    //         setNumPictureT(numPictureT => numPictureT + 1);
    //     },1000);
    //     return () => {
    //         clearInterval(interval);
    //     }
    // },[]);

    /**
     * _____________________________________________
     */

    React.useEffect(() => {
        if(props?.data?.numberdocs) {
            let _numPicture= props?.data?.numberdocs;
            setNumPicture(_numPicture > 9?"9+":_numPicture+"");
        }
        else setNumPicture("");
    }, [props?.data?.numberdocs]);    
    
    React.useEffect(() => {
        if(!data?.getSignedURLReport?.length) return setImages( []);
        Promise.all(data?.getSignedURLReport?.map(async (item:any) => {
            let uri = await getImage(item?.url);
            if(uri)setData({type:"SET_DATA",value:{key:item.key,value:uri}});
            else uri = getData(item.key);
            return {miniatura:uri,key:item.key,archivo:uri};
        }))
        .then((res:any)=> {
            setImages([...res]);
        })
    }, [data]);

    const open = () => {
        let _id = +(props?.data?.id ?? 0)
        if(!called && _id)loadData({variables:{id:_id}});
        setVisible(true);
    };

    const close = () => {
        setVisible(false);
    };
    
return numPicture? <div>
    <Modal visible={visible} clickOutside={close} noCenter backGroundColor="#00000061">
        <GalleryAbsolute images={images} loading={loading}/>
    </Modal>
    <i className="icon-fotos-cargadas icon-fotos-cargadas-cl" onClick={open} />
    </div>: null;
};

const Remittances = React.memo((props: { field: confTable, data: objectKV }) => {
    const { data,field } = props;

    const { readQuery } = useClientApolloGranada();
    
    const text = React.useMemo(() => {
        try {
            let res = readQuery({ query: queryTablesRemitances, variables: { id: +(data.idviaje ?? 0) } });
            let rem = res.remesasInfo.data.filter((item: any) => (data.idsremesas ?? []).includes(item.id));
            return rem.map((item: any) => `${item.numeroremesa} ${item.clienteempresatransportadora}`).join(",");
        } catch (error) {  
        }
        return "";
    }, [data]);
    
    if (![3, 5, 7, 8].includes(data?.idsysevento ?? 0)) return <label />;

    return <TextView width={ field.width } text={ text }/>;
});

const Place = React.memo((props: { field: confTable, data: objectKV }) => {
    /**
     * id de ctrlconductor y de puestos de control
     */
    if ([11, 13].includes(+(props.data?.aplicacion ?? 0))) {
        let { latitud,longitud } = props.data?.geolocalizacion ?? {};
        return <a href={`https://www.google.com.co/maps?q=${latitud},${longitud}`} target="_blank">{ props.data?.lugar??"NA"}</a>
    }
    return <label>{ props.data?.lugar??"NA"}</label>;
});

// https://www.google.com.co/maps/q=6.339465,-75.5645007
export const Factory = (props:{ field: confTable, data:objectKV }) => {
    if(props.field.field === "cump") return <InfoIcon {...props} />;
    if (props.field.field === "fotos") return <Picture {...props} />;
    if (props.field.field === "remittances") return <Remittances {...props}/>;
    if (props.field.field === "lugar") return <Place {...props}/>;
    if (props.field.field === "indicadornocoincide") return <NotMatch indicadornocoincide={props.data.indicadornocoincide} />;
    
    return <div></div>
}