import React from 'react'
import './Forms-report.scss'
import { useLazyQueryToSever } from './../../../Componets/hook/query-hook'
import TextView from './../../../Componets/Basic/Text/TextView'
import InputText from '../../../Componets/Basic/Input/InputText'
import Button from './../../../Componets/Basic/Button/Button'
import InputTextArea from './../../../Componets/Basic/Input/InputTextArea'
import { InputFile } from './../../../Componets/Basic/Input/InputFile'
import { InputDate } from './../../../Componets/Basic/Input/InputDate'
import { objectKV, action, error } from '../../../utils/interface'
import { useInput, useInputBoolean } from '../../../Componets/hook/input-hook'
import LoadCircular from '../../../Componets/Basic/LoadIndicator/LoadCircular'
import Icon from '../../../Componets/Basic/Icon/Icon.tooltip'
import { InputTime } from '../../../Componets/Basic/Input/InputTime'
import { formatterTimeZoneDate } from '../../../utils/utils'
import { Switch } from '../../../Componets/Basic/Switch/Switch'

export const GET_TRAVEL_REQUEST = `
query viajes ($id:Int!){
  viaje(id: $id) {
    id
    idvehiculo
    idconductor
    numero
    ruta

  }
}
`
export const GET_ALL_INFO = `
query allData($driverId:Int!, $vehicleId:Int!){
    conductorInfo(id: $driverId) {
      id
      nombre
      telefono
    }
    vehiculo(id:$vehicleId) {
      id
      placa
    }
}
`

export const GET_UTILS_REPORT = `
query utilsReport($idviaje:Int, $idsysevento:Int){
    utilsReport(idviaje: $idviaje,idsysevento: $idsysevento) {
        id
        gpsurl
        gpscontrasena
        gpsusuario
        ruta
        telefono
        idvehiculo
        idruta
        idultimoreporte
        idconductor
        ultimoreporte
        numero
        placa
        nombreconductor
    }
}
`

export const REPORT_CALL = `
mutation sendReport ($reportes:[ReporteInput]) {
    createReporte(reportes:$reportes){
        id
    }
}
`
export const REPORT_MUTATION = `
mutation createReport( $report:[reporteInputV2]) {
    createReporteV2(reportes: $report){
      id
      idsysevento
      idsremesas
      idevento
      evento
      fechahora
      puestocontrol
      novedad
      documentosreporte {
        id
        archivo
      }
      uploadLinks{
        key
        url
      }
    }
  }
`

export const GET_TIMES = `
query data($id:Int!){
    viaje(id: $id)  {
        id
        fechahoraproximogps
        fechahoraproximopuestocontrol
        fechahoraproximallamada
    }
}        
`

export const GET_INFO = `
query data($id:Int!){
    viaje(id: $id)  {
        id
        idproximopuestocontrol
        proximopuestocontrol
        fechahoraultimoevento
        idplanviaje
    }
}
`

export const GET_EVENTS = `
query getEvents{
    eventosV2{
        data{
          id
          descripcion
        }  
      }
}`

export const PhoneCall = React.memo(
  (props: { phone?: string | null; icon?: any; useLink?: any }) => {
    const el = React.useRef(null)

    const _onClick = () => {
      if (!props.phone && !props.useLink) return
      let _el: any = el?.current
      if (_el.click) _el.click()
    }

    return (
      <Button className='button-phone' onClick={_onClick}>
        {props.useLink ? (
          <a href={props.useLink} ref={el} target='_blank' />
        ) : (
          <a href={`tel:${props.phone}`} ref={el} />
        )}
        <Icon
          className={props.icon ?? 'icon-telefono'}
          message={`${props.useLink || (props.phone ?? '...')}`}
        />
      </Button>
    )
  }
)

export const GeneralInfo = (props: {
  id?: number | string
  idsysevento?: any
  title?: any
  formato?: any
}) => {
    // const [loadTravel, { data:dataTravel }] = useLazyQueryToSever({query:GET_TRAVEL_REQUEST, fetchPolicy:"cache-first"});
  
  //   const [loadAllData, { data: dataAll }] = useLazyQueryToSever({
  //     query: GET_ALL_INFO,
  //     fetchPolicy: 'cache-first'
  //   })
  const [loadTravel, { data: dataTravel }] = useLazyQueryToSever({
    query: GET_UTILS_REPORT,
    fetchPolicy: 'cache-and-network'
  });

  React.useEffect(() => {
    if (props.id)
      loadTravel({
        variables: { idviaje: props.id, idsysevento: props.idsysevento ?? 0 }
      })
  }, [props.id])

  return props.formato == 2 ? (
    <div className='general-info-2'>
      <div className='general-info-2__title name-report'>{props.title}</div>
      <div className='general-info-2__travel'>
        <TextView label='Nº Viaje' text={dataTravel?.utilsReport?.numero} />
      </div>
      <div className='general-info-2__vehicle'>
        <TextView label='Placa' text={dataTravel?.utilsReport?.placa} />
      </div>
      <div className='general-info-2__route'>
        <TextView label='Ruta' text={dataTravel?.utilsReport?.ruta} />
      </div>
      <div className='general-info-2__driver'>
        <TextView
          label='Conductor'
          styleText={{ fontWeight: 900, fontSize: 18 }}
          text={dataTravel?.utilsReport?.nombreconductor}
        />
        <PhoneCall
          phone={`${(dataTravel?.utilsReport?.telefono ?? '').split(',')[0]}`}
        />
      </div>
      <div className='general-info-2__gps'>
        <TextView
          label='Usuario'
          showCopy
          text={dataTravel?.utilsReport?.gpsusuario}
        />
        <div></div>
        <TextView
          showCopy
          label='Contraseña'
          text={dataTravel?.utilsReport?.gpscontrasena}
        />
        <PhoneCall
          icon={'icon-gps'}
          useLink={dataTravel?.utilsReport?.gpsurl}
        />
      </div>
    </div>
  ) : (
    <div className='general-info'>
      <div className='general-info__number'>
        <TextView label='Nº Viaje' text={dataTravel?.utilsReport?.numero} />
      </div>
      <div className='general-info__plate'>
        <TextView label='Placa' text={dataTravel?.utilsReport?.placa} />
      </div>
      <div className='general-info__call-button'>
        <PhoneCall
          phone={`${(dataTravel?.utilsReport?.telefono ?? '').split(',')[0]}`}
        />
      </div>
      <div className='general-info__name'>
        <TextView
          label='Conductor'
          text={dataTravel?.utilsReport?.nombreconductor}
        />
      </div>
      <div className='general-info__route'>
        <TextView label='Ruta' text={dataTravel?.utilsReport?.ruta} />
      </div>
    </div>
  )
}

export const GeneralInfoCall = (props: { id?: number | string }) => {
  const [loadTravel, { data: dataTravel }] = useLazyQueryToSever({
    query: GET_TRAVEL_REQUEST,
    fetchPolicy: 'cache-first'
  })

  const [loadAllData, { data: dataAll }] = useLazyQueryToSever({
    query: GET_ALL_INFO,
    fetchPolicy: 'cache-first'
  })

  React.useEffect(() => {
    if (props.id) loadTravel({ variables: { id: props.id } })
  }, [props.id])

  React.useEffect(() => {
    let { idvehiculo, idconductor } = dataTravel?.viaje ?? {}

    if (idvehiculo && idconductor)
      loadAllData({
        variables: { vehicleId: idvehiculo, driverId: idconductor }
      })
  }, [dataTravel])

  return (
    <div className='general-info'>
      <div className='general-info__number'>
        <TextView label='Nº Viaje' text={dataTravel?.viaje?.numero} />
      </div>
      <div className='general-info__plate'>
        <TextView label='Placa' text={dataAll?.vehiculo?.placa} />
      </div>
      <div className='general-info__call-button'>
        <PhoneCall
          phone={`${(dataAll?.conductorInfo?.telefono ?? '').split(',')[0]}`}
        />
      </div>
      <div className='general-info__name'>
        <TextView label='Conductor' text={dataAll?.conductorInfo?.nombre} />
      </div>
      <div className='general-info__route'>
        <TextView label='Ruta' text={dataTravel?.viaje?.ruta} />
      </div>
    </div>
  )
}

export const DatePicker = (props: {
  dateString?: string
  onChange: (v: action) => any
  messageGeneralError?: string
  date?: number
  errorDate?: error
  errorTime?: error
}) => {
  const { value, bind, setValue } = useInput()

  // const generalError = React.useMemo(() => {
  //     return props.getGeneralError?props.getGeneralError({time:value,date:props.date}):"";
  // },[props.date,value]);

  const _onDayChange = (v: any) => {
    props.onChange({
      type: 'SET_DATA',
      value: {
        fecha:
          v.value?.year && v.value?.month && v.value?.day
            ? `${v.value?.year}/${v.value?.month}/${v.value?.day}`
            : ''
      }
    })
  }

  React.useEffect(() => {
    if (!props.dateString) return
    let _date = new Date(formatterTimeZoneDate(props.dateString).dateString)
    let tempDate: any = _date
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
      .split(':')
      .slice(0, 2)
    tempDate = `${
      tempDate[0]?.split && tempDate[0]?.split('').length < 2 ? '0' : ''
    }${tempDate[0] === '24' ? '00' : tempDate[0]}:${
      tempDate[1]?.split && tempDate[1]?.split('').length < 2 ? '0' : ''
    }${tempDate[1]}`
    // console.log("here",tempDate)
    setValue(tempDate)
  }, [props.dateString])

  React.useEffect(() => {
    props.onChange({ type: 'SET_DATA', value: { hora: value } })
  }, [value])

  return (
    <React.Fragment>
      {props.messageGeneralError ? (
        <label
          style={{
            color: 'red',
            fontSize: 12,
            position: 'absolute',
            background: 'white',
            width: '89%'
          }}
        >
          {props.messageGeneralError}
        </label>
      ) : null}
      <div className='date-picker-container'>
        <div className='date-picker-container__date'>
          <InputDate
            date={props.date}
            label='Fecha*'
            error={props.errorDate}
            onChange={_onDayChange}
          />
        </div>
        <div className='date-picker-container__time'>
          <InputTime {...bind} label='Hora*' error={props.errorTime} />
        </div>
      </div>
    </React.Fragment>
  )
}

export const Event = () => {
  return <div></div>
}

export const UploadFile = (props: {
  onChange: (v: action) => any
  error?: error
}) => {
  const _onChange = (v: any) => {
    props.onChange({ type: 'SET_DATA', value: { imagenes: v } })
  }
  return (
    <div>
      <InputFile error={props.error} onChange={_onChange} />
    </div>
  )
}

export const Observations = (props: {
  onChange: (v: action) => any
  error?: error
  value?: string
}) => {
  const { value, bind } = useInput(props.value)

  React.useEffect(() => {
    props.onChange({ type: 'SET_DATA', value: { novedad: value } })
  }, [value])

  return (
    <InputTextArea
      error={props.error}
      {...bind}
      name={'novedad'}
      label='Reporte'
      className='obsertvation-input'
    />
  )
}

export const ControlButtons = (props: {
  buttonCancel: objectKV
  buttonSend: objectKV
  labelCancel?: string
  labelSend?: string
}) => {
  const { loading, ...all } = props.buttonSend

  return (
    <div className='buttons-container'>
      <div>
        <Button
          {...props.buttonCancel}
          label={props.labelCancel ?? 'Cancelar'}
          className='buttons-container__cancel'
        />
      </div>
      <div>
        <Button {...all} className='buttons-container__send'>
          {!loading ? (
            <label>{props.labelSend ?? 'Reportar'}</label>
          ) : (
            <LoadCircular />
          )}
        </Button>
      </div>
    </div>
  )
}

export const NotMatch = (props: {
  onChange: (v: action) => any
  error?: error
  style?: objectKV
}) => {
  const { valueKV, bind } = useInputBoolean()

  React.useEffect(() => {
    props.onChange({ type: 'SET_DATA', value: valueKV })
  }, [valueKV])

  return (
    <Switch label="No coincide" name="indicadornocoincide" {...bind} ></Switch>
  )
}

export const LocationForm = (props: {
  onChange: (v: action) => any
  error?: error
  label: string
  style?: objectKV
}) => {
  const { valueKV, bind } = useInput()

  React.useEffect(() => {
    props.onChange({ type: 'SET_DATA', value: valueKV })
  }, [valueKV])

  return (
    <div className='location-container'>
      <div className='location-container__input'>
        <InputText
          {...bind}
          name='lugar'
          error={props.error}
          label={props.label}
          className='location-input'
          style={props.style}
        />
      </div>
      <div className='location-container__button'>
        {/* <Button className="button-location"><i className="icon-informacion"/></Button> */}
      </div>
    </div>
  )
}

export const getMinutes = (
  date_1?: string | null | number,
  date_2?: string | null | number
) => {
  if (!date_1 && date_1 !== 0) return null
  if (!date_2 && date_2 !== 0) return null
  if (!+date_1 && +date_1 !== 0) return null
  if (!+date_2 && +date_2 !== 0) return null

  let _date_1 = new Date(+date_1)
  let _date_2 = new Date(+date_2)
  let millisecons = _date_1.getTime() - _date_2.getTime()
  return millisecons / (1000 * 60)
}
