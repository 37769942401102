import React from "react";
import { action, confHeaderTable, confTable, objectKV, orderableField } from "../../utils/interface";
import { getStoreConfTable, setStoreConfTable } from "../../utils/store";
import { useConfTableContext } from "../Helpers/Conf-table/Conf-table-context";
import { useClientApolloGranada } from "./client-apollo-hook";

/** 
 * @param options  key nombre de la tabla(Requerido), id no usar!
 */
export const useConfTable = (vParam:Array <confTable>,options?:{key?:string,id?:number}) => {
    
    const { id, key } = options??{};
    const {currentKey,setConfTable,tables} = useConfTableContext();

    const table = React.useMemo(() => {
        return tables[key ?? "temp"] ?? null;
    },[JSON.stringify(tables)]);

    const v = React.useMemo(() => {
        return vParam.map((item:confTable) => {return Object.assign({},item);})
    },[JSON.stringify(vParam)]);
    
    React.useEffect(() => {
        setConfTable({type:"SET_KEY_TABLE",value:key && typeof(key) === "string"?key:null});
        let idtimeOut = setTimeout(() => {
            setConfTable({type:"RESET_TEMP_CURRENT"});
        },500);
        return () => {
            clearTimeout(idtimeOut);
            setConfTable({ type: "FORMATTER", value: {key}});
            setConfTable({type:"SET_KEY_TABLE",value:null});
        };
    },[]);    

    React.useEffect(() => {
        if(!currentKey) return;
        if (JSON.stringify(table?.fields??null) !== JSON.stringify(v) && !table?.fields?.length) setConfTable({type:"SET_FIELDS",value:{fields:v,key:currentKey }});
    },[currentKey,v]);

    /**
     * @deprecated
     */
    interface reducerInterface {
        configHeadersTable?:Array<confHeaderTable>;
        confTable?:Array <confTable>;
        allConfTable?:Array <confTable>;
        orders?:Array<orderableField>,
        page?:number;
        isInView?:boolean;
        numberfields?:number;
    }


    /**
     * @deprecated
     */
    const queryConfig = `query config{
        configTable(id:${id}){
            page
            numberfields
            isInView
            orders {
                id
                field
                order
            }
            filters {
                field
                value
            }
        }
    }`

    /**
     * @deprecated
     */
    const client = useClientApolloGranada();

    /**
     * @deprecated
     */
    const getfieldsPerInit = (fields:reducerInterface) => {
        let allFields = fields.confTable?.map((item:confTable) => {
            let tempHeader = item.id !== undefined?fields.configHeadersTable?.find((item_2:confHeaderTable) => item_2.id === item.id):null;
            let tempItem = Object.assign({},item);
            tempItem.hidden = tempHeader?.hidden ?? tempItem.hidden;
            tempItem.width = tempHeader?.width ?? tempItem.width;
            tempItem.order = tempHeader?.order ?? tempItem.order ?? 0;
            return tempItem;
        })
        .sort((a:confTable,b:confTable) => ((a.order??0) - (b.order??0))) ?? [];
        let newFields = allFields.filter((item:confTable) => !item.hidden);
        return {newFields,allFields};
    };

    /**
     * @deprecated
     */
    const getfieldsForSave = (fields:Array <confTable>) => {
        let allFields = fields?.map((item:confTable) => {
            let tempItem = {
                id:item.id,
                hidden:item.hidden,
                width:item.width,
                order:item.order ?? 0
            }
            return tempItem;
        });
        return allFields;
    };

    /**
     * @deprecated
     */
    const assingOrder = (v:Array <confTable>) => {
        let tempArray:Array<Array <confTable>> = [];
        v.forEach((item:confTable) => tempArray[+(item.order??0)].push(item));
    };

    /**
     * @deprecated
     */
    const init:reducerInterface = {
        configHeadersTable:[],
        confTable:[],
        allConfTable:[...v],
        orders:[],
        page:1
    };

    /**
     * @deprecated
     */
    const reducer = (state:reducerInterface,action:action) => {
        let temp:any;
        switch(action.type){
            case "SET_CONF_HEADER_TABLE":
                if(!Array.isArray(action.value) && !action.value?.confTable) return state;
                temp = getfieldsPerInit({confTable:state.confTable?.length?state.confTable:action.value?.confTable,configHeadersTable:state.confTable?.length?action.value:{}});
                return Object.assign({},state,{configHeadersTable:action.value,confTable:temp.newFields,allConfTable:temp.allFields});
            case "SET_HEADER_TABLE":
                temp = getfieldsPerInit({confTable:action.value});
                return Object.assign({},state,{confTable:temp.newFields,allConfTable:temp.allFields});
            case "RESET_FILTER_IN_HOOK":
                temp = getfieldsPerInit({confTable:state.allConfTable?.map((item:confTable) => {
                    let { valueFiltered, ...newItem} = item;
                    return newItem;
                })});
                return Object.assign({},state,{confTable:temp.newFields,allConfTable:temp.allFields});
            case "SET_ORDER":
                return Object.assign({},state,{orders:action.value});
            case "ADD_CURRENT_PAGE":
                return Object.assign({},state,{page:action.value});
            case "ADD_CURRENT_NUMBER_FIELDS":
                return Object.assign({},state,{numberfields:action.value});
            case "SET_IS_IN_VIEW":
                return Object.assign({},state,{isInView:action.value});
            default:
                throw new Error('Unexpected action');
        }
    };

    /**
     * @deprecated
     */
    const [state, dispatch] = React.useReducer(reducer,init);

    /**
     * @deprecated
     */
    const readQuery = () => {
        try {
            return client.readQuery({query:queryConfig});
        } catch (error) {
            return null;
        }
    };

    /**
     * @deprecated
     */
    React.useEffect(() => {
        let fields = key?getStoreConfTable(key):{confTable:[...v]};
        try {
            let _config = readQuery();
            dispatch({type:"SET_ORDER",value:_config?.configTable?.orders??[]});
            dispatch({type:"SET_IS_IN_VIEW",value:_config?.configTable?.isInView??false});
            dispatch({type:"ADD_CURRENT_PAGE",value:_config?.configTable?.page??1});
            dispatch({type:"ADD_CURRENT_NUMBER_FIELDS",value:_config?.configTable?.numberfields??20});
            // console.log("query",_config)
            if(_config?.configTable?.filters && Array.isArray(_config?.configTable?.filters)){
                let _confTable = fields.confTable;
                _config?.configTable?.filters.forEach((item:any) => {
                    _confTable = _confTable.map((itemH:confTable) => {
                        if(itemH.field === item.field) itemH.valueFiltered = `${item.value}`;
                        return itemH;
                    });
                })
                fields = Object.assign({},fields,{confTable:_confTable});
            }
        } catch (error) {
            console.warn(error)
        }
        dispatch({type:"SET_CONF_HEADER_TABLE",value:fields});
    },[]);

    /**
     * @deprecated
     */
    React.useEffect(() => {
        if(key)setStoreConfTable(getfieldsForSave(state.allConfTable),key);
    },[JSON.stringify(state.allConfTable)]);

    /**
     * @deprecated
     */
    const setFilters = React.useCallback((filter:Array<any>) => {
        if(!filter?.length) dispatch({type:"RESET_FILTER_IN_HOOK"});
        let _config = readQuery();
        client.writeQuery({query:queryConfig,data:{configTable:{filters:filter, page:_config?.configTable?.page??null,orders:_config?.configTable?.orders??null,numberfields:_config?.configTable?.numberfields??null,isInView:_config?.configTable?.isInView??null}}});
    },[]);    

    /**
     * @deprecated
     */
    const setOrders = React.useCallback((orders:Array<any>) => {
        // console.log("or",orders);
        let _config = readQuery();
        client.writeQuery({query:queryConfig,data:{configTable:{filters:_config?.configTable?.filters??null, page:_config?.configTable?.page??null,orders,numberfields:_config?.configTable?.numberfields??null,isInView:_config?.configTable?.isInView??null}}});
    },[]);

    /**
     * @deprecated
     */
    const setPage = React.useCallback((page:number) => {
        let _config = readQuery();
        client.writeQuery({query:queryConfig,data:{configTable:{filters:_config?.configTable?.filters??null, page,orders:_config?.configTable?.orders??null,numberfields:_config?.configTable?.numberfields??null,isInView:_config?.configTable?.isInView??null}}});
    },[]);

    /**
     * @deprecated
     */
    const setNumberFields = React.useCallback((numberfields:number) => {
        let _config = readQuery();
        client.writeQuery({query:queryConfig,data:{configTable:{filters:_config?.configTable?.filters??null, page:_config?.configTable?.page??null,orders:_config?.configTable?.orders??null,numberfields,isInView:_config?.configTable?.isInView??null}}});
    },[]);

    /**
     * @deprecated
     */
    const setIsInView = React.useCallback((isInView:boolean) => {
        let _config = readQuery();
        client.writeQuery({query:queryConfig,data:{configTable:{filters:_config?.configTable?.filters??null, page:_config?.configTable?.page??null,orders:_config?.configTable?.orders??null,numberfields:_config?.configTable?.numberfields??null,isInView}}});
    },[]);

    const value = React.useMemo(() => ({
        fields:{newFields: state.confTable,allFields:state.allConfTable},
        setConfTable:dispatch,
        setFilters,
        setOrders,
        setPage,
        setIsInView,
        setNumberFields,
        orders:state.orders,
        page:state.page,
        isInView:state.isInView,
        numberfields: state.numberfields,
        table,
        setConfig:setConfTable
    }),[state,dispatch,JSON.stringify(table)]);
    return value;
};