import React from "react";
import  { Table } from "./../../Componets/Basic/Table/Table";
import "./Stadistics.scss";
import { LayoutWithOutPage } from "./../../Componets/General/Layout/LayoutWithOutPage";
import { dataSelect, fieldsStadistics, queryStadistics } from "./Stadistics.conf";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { InputSelect } from "../../Componets/Basic/Input/InputSelect";
import { useInput } from "../../Componets/hook/input-hook";
import { BasicChart } from "../../Componets/Basic/Chart/BasicChart";
import { confTable, objectKV, action } from "../../utils/interface";
import { DateRange } from "../../Componets/Basic/Input/DateRange";
import { createFilters, parceOrders } from "../../Componets/General/UtilsView/Utils-view";
import { stringToDate } from "../../utils/utils";
import { Switch } from "../../Componets/Basic/Switch/Switch";

interface StadisticsProps {}
interface TableStadisticsProps {
    setValue:(v:any) => any;
}
const numregParetos = 10;
const paretos = (data:Array<any>,n:number,usePercentage?:boolean) => {
    let temp = [...data].sort((a:any,b:any) => ((+(b.total??0)) - (+(a.total??0))));
    let accumulated = 0;
    let value = {
        id:-1,
        value:"Otros",
        total:0,
        numregs:0,
        percentage:0,
        accumulated:0
    };
    if(usePercentage && temp.length > numregParetos){
        let tempArray:Array<objectKV> = [];
        temp.forEach((item:any) => {
            let percentage = getPercentage(item); 
            if(accumulated > 80){
                value.id = item.id;
                value.numregs = item.numregs;
                value.total = value.total + item.total;
                value.value = "Otros N-regs:" + value.total;
                accumulated = accumulated+(+percentage);
                accumulated = accumulated > 100?100:accumulated;
                value.accumulated = accumulated;
                value.percentage = value.percentage+(+percentage);
            } else {
                accumulated = accumulated+(+percentage);
                accumulated = accumulated > 100?100:accumulated;
                tempArray.push({...item,percentage,accumulated:accumulated.toFixed(2)});
            }
        });
        tempArray.push(value);
        return tempArray;
    }
    let _n = temp.slice(0,n);
    accumulated = 0;
    temp.splice(n).forEach((item:any) => {
        value.id = item.id;
        value.numregs = item.numregs;
        value.total = value.total + item.total;
        value.value = "Otros N-regs:" + value.total;
    });

    accumulated = 0;
    return [..._n,value.id === -1?null:value]
    .filter(Boolean)
    .map((item:any) => {
        let percentage = getPercentage(item); 
        accumulated = accumulated+(+percentage);
        accumulated = accumulated > 100?100:accumulated;
        return {...item,percentage,accumulated:accumulated.toFixed(2)};
    });
};

const rowStyleMaker = (data:any,index:any) => {
    let {accumulated} = data;
    accumulated = +accumulated;
    // console.log(index)
    if(accumulated > 80 && numregParetos < index)return {background:"#5cff0029"};
    return;
};

const getRangeMonth = () => {
    let date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
    let year = date.getFullYear();

    return `1/${month}/${year}-${day}/${month}/${year}`;
};

const getPercentage = (data:any) => {
    const {numregs,total} = data??{};
    if(numregs === 0) return "0.00";
    if(!numregs) return "0.00";
    if(!total) return "0.00";
    return ((total/numregs)*100).toFixed(2);
};

const Percentage = (props:{data:any}) => {
    const percentage = React.useMemo(() => {
        return getPercentage(props.data);
    },[props.data]);
    return <div><b>{percentage}</b></div>;
};

export const Factory = (props:{field: confTable,data:objectKV}) => {
    
    if(props.field.field === "total") return <Percentage {...props}/>;

    return null;
};


const mapNewFilters = (date: any) => {
    let tempDateArray = []
    let dateTemp;
    let dateTempObj: any = date?.split("-");
    if(!Array.isArray(dateTempObj)) return [];
    dateTemp = stringToDate(dateTempObj[0]?.split("/")?.join("-"));
    if (dateTemp?.getTime) {
        tempDateArray.push({ field: "fecha", value:`${dateTemp.getFullYear()}-${dateTemp.getMonth()+1}-${dateTemp.getDate()}`});
    }
    dateTemp = stringToDate(dateTempObj[1]?.split("/")?.join("-"));
    if (dateTemp?.getTime) {
        tempDateArray.push({ field: "fecha", value:`${dateTemp.getFullYear()}-${dateTemp.getMonth()+1}-${dateTemp.getDate()}`});
    }
    return tempDateArray;
};

const TableStadistics = React.memo((props:TableStadisticsProps) => {
    
    const  [loadData, { called, loading, data, refetch,fetchMore }] = useLazyQueryToSever({query:queryStadistics});
    const {value,bind} = useInput("cliente");
    // const {value:range,bind:bindRange} = useInput();
    const {value:range,bind:bindRange} = useInput(getRangeMonth());

    React.useEffect(() => {
        let _filters = mapNewFilters(range);
        loadData({variables:{field:value,filters:_filters }});
        props.setValue({value,filters:_filters});
    },[value,range]);

    const _fieldsTable = React.useMemo(() => {
        if(!value) return fieldsStadistics;
        return fieldsStadistics.map((item:any) => {
            if(item.field === "value") item.label = value.toUpperCase();
            return item;
        });
    },[fieldsStadistics,value]);
    // .sort((a:any,b:any) => ((+(a.total??0)) - (+(b.total??0))))
    const _data = React.useMemo(() => {
        if(!Array.isArray(data?.viajesStadistics?.data)) return [];
        let temp = [...data?.viajesStadistics?.data]
        let _data = paretos(temp,temp.length+1)
        _data = _data.map((item:any,index:number) => ({...item,consecutivo:index+1}));
        return _data;
    },[data?.viajesStadistics?.data])

    return (<React.Fragment>
        <div className="container-inputs-stadistics">
            <div>
                <DateRange label="Rango" name="range" {...bindRange}/>
            </div>
            <div>
                <InputSelect style={{width:170}} name={"consolidado"} 
                    data={dataSelect}
                    confSelect={{pathEmit:"value",pathOption:"description"}}
                    {...bind}
                    label="Consolidar por"
                />
            </div>
        </div>
        <div className="container-table-stadistics">
            <Table 
            loading={(loading || !called)}
            fields = {_fieldsTable}
            data={_data}
            rowStyleMaker={rowStyleMaker}
            hiddenFilters
            />
        </div>
    </React.Fragment>);
});

interface ChartStadisticsProps {
    value?:objectKV;
}

const ChartStadistics = React.memo((props:ChartStadisticsProps) => {

    const  [loadData, { called, loading, data, refetch,fetchMore }] = useLazyQueryToSever({query:queryStadistics,fetchPolicy:"cache-only"});

    const {value,bind} = useInput("bar-y");
    const {value:valueParetos,bind:paretosBind} = useInput("true");
    const {value:valueNRegs,bind:nRegsBind} = useInput("10");

    React.useEffect(() => {
        const {value:valuesConsolidate,filters} = props.value??{};
        loadData({variables:{field:valuesConsolidate,filters }});
    },[JSON.stringify(props.value)]);

    const dataChart = React.useMemo(() => {
        const {value:valuesConsolidate} = props.value??{};
        return paretos((data?.viajesStadistics?.data??[]),+(valueNRegs??0),["true",true].includes(valueParetos??"")).map((item:any) => {
            return {label:item.value,
            data:+getPercentage(item),labelDataSet:props.value?.value,id:item.id};
        });
    },[JSON.stringify(data),valueParetos,valueNRegs]);

    const confChart = React.useMemo<{ width?:string|number;indexAxis?:"x"|"y";type:"bar"|"pie";}>(() => {
        if(value === "pie") return {type:"pie",width:"57%"};
        else if(value === "bar-y") return {type:"bar",width:"80%",indexAxis:"y"};
        else if(value === "bar-x") return {type:"bar",width:"80%",indexAxis:"x"};
        else return {type:"bar",width:"80%"};
    },[value]);

    // React.useEffect(() => {
    //     console.log({valueParetos});
    // },[valueParetos]);

    return (<div className="container-stadistics__chart">
            <div className="select-type-chart">
                <InputSelect style={{width:200}} name={"consolidado"} 
                data={[
                    {description:"Barras horizontoles",value:"bar-y"},
                    {description:"Barras verticales",value:"bar-x"},
                    {description:"Pie",value:"pie"}
                ]}
                confSelect={{pathEmit:"value",pathOption:"description"}}
                {...bind}
                label="Tipo de grafico"
                />
                <div className="inputs-paretos">
                    <Switch label="Pareto" {...paretosBind}/>
                    <InputSelect label="N regs"
                    disabled={["true",true].includes(valueParetos??"")}
                     data={[
                        {value:5},
                        {value:10},
                        {value:20},
                        {value:30},
                        {value:50}
                    ]}
                    confSelect={{pathEmit:"value",pathOption:"value"}}
                    {...nRegsBind}
                    />
                </div>
            </div>
            <div className="chart-render-container">
                <BasicChart 
                type={confChart.type} 
                indexAxis={confChart.indexAxis} 
                data={dataChart} 
                title="ESTADISTICAS DE VIAJE"
                width={confChart.width}/>
            </div>
        </div>);
});

const Stadistics = (props: StadisticsProps) => {

    const [valuesFilters,setValuesFilters] = React.useState<objectKV>();

    return (<LayoutWithOutPage
        title={`Estadisticas de viajes ${valuesFilters?.value??""}`}
    >
        <div className="container-stadistics">
            <div className="container-stadistics__table">
                <TableStadistics setValue={setValuesFilters}/>
            </div>
            <ChartStadistics value={valuesFilters}/>
        </div>
    </LayoutWithOutPage>);   
};

export default Stadistics;