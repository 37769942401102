import { confTable } from "../../utils/interface";
import { fields } from "../../Componets/Helpers/Form/Form-context";

export const infoCheckPoint = `
        id   
        descripcion    
        contacto    
        nombrepropietario
        disponibilidad
        direccion
        indicadorfisico
        indicadorpernoctar
        indicadoraltoriesgo
        correocontacto
        telefono
        poblacion
        idsyspoblacion
        geolocalizacion{
            latitud
            longitud
        }
`;

export const getCheckPoint = `
query getcontrolPoint($id:Int!){
	puestocontrolInfo (id:$id) {
        ${infoCheckPoint}
  }
}
`;

export const queryCheckPointLocal = `
query checkPoint {
    puestoscontrolInfo {
        data {
            ${infoCheckPoint}
        }
    }
}
`;

export const queryCheckPoint = `
query checkPoint ( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
    puestoscontrolInfo (
        filters: $filters,
        orders: $orders,
        pagination: { fields:$fields, page: $page}
      ) {
        data{
            ${infoCheckPoint}
        }
        totalreg
      }
  }
`;

export const queryUpdateCheckPoint = `
mutation updatePuestoControl($id: Int, $puestoControlVirtual: PuestoControlVirtualInput) {  
    updatePuestoControlVirtual(
        id: $id
        puestoControlVirtual: $puestoControlVirtual
        ){
        ${infoCheckPoint}
}}`;

export const queryRemoveCheckPoint = `
mutation deletePuestoControl($ids: [Int]) {
    deletePuestoControlVirtual(ids: $ids){
        id
}}`;

export const queryCreateCheckPoint = `
mutation createPuestoControl($puestoControlVirtual: PuestoControlVirtualInput) {
    createPuestoControlVirtual(puestoControlVirtual: $puestoControlVirtual) {
        ${infoCheckPoint}
}
}`;

export const queryInfoCheckPoint = `
{
    id
    descripcion
    contacto
    nombrepropietario
    disponibilidad
    direccion
    indicadorfisico
    indicadorpernoctar
    indicadoraltoriesgo
    correocontacto
    codigounico
    geolocalizacion{
      latitud,
      longitud
    }
    telefono
    idsyspoblacion
    syspoblacion{
      id
      descripcion
      codigonacional
      nombrenacional
    }
  }
`;

export const fieldsTable : Array <confTable>  = [
    {
        field:"descripcion",
        orderable:true,
        filterable:true,
        label:"Descripción",
        type:"TEXT",
        path:"descripcion"
    },
    {
        field:"contacto",
        orderable:true,
        filterable:true,
        label:"Contacto",
        type:"TEXT",
        path:"contacto"
    },
    {
        field:"nombrepropietario",
        orderable:true,
        filterable:true,
        label:"Propietario",
        type:"TEXT",
        path:"nombrepropietario"
    },
    {
        field:"indicadoraltoriesgo",
        orderable:true,
        filterable:true,
        label:"Alto riesgo",
        type:"BOOLEAN",
        width:75,
        textAling:"CENTER",
        path:"indicadoraltoriesgo"
    },
    {
        field:"indicadorpernoctar",
        orderable:true,
        filterable:true,
        label:"Pernotar",
        type:"BOOLEAN",
        width:75,
        textAling:"CENTER",
        path:"indicadorpernoctar"
    }, 
    {
        field:"disponibilidad",
        orderable:true,
        filterable:true,
        label:"Disponibilidad",
        type:"TEXT",
        path:"disponibilidad"
    }, 
    {
        field:"indicadorfisico",
        orderable:true,
        filterable:true,
        label:"Tipo",
        type:"BOOLEAN",
        width:75,
        textAling:"CENTER",
        booleanValues:{isFalse:"Virtual", isTrue:"Físico"},
        path:"indicadorfisico"
    }

];

export const advancedSearchPoplation = `
query getsyspoblacion($filters:[FilterInput],$fields:Int!, $page:Int!){
sysPoblaciones (filters : $filters,pagination: { fields:$fields, page: $page}){
        data {
            id
            descripcion
            nombrenacional
        }
        totalreg
    }
}
`;
export const filedsForm:Array<fields> = [
    {
        name:"adress",
        path:"direccion",
        validator:[true]
    },
    {
        name:"description",
        path:"descripcion",
        validator:[true]
    },
    {
        name:"poblation",
        path:"idsyspoblacion",
        validator:[true]
    },
    {
        name:"indicadoraltoriesgo",
        path:"indicadoraltoriesgo",
    },
    {
        name:"indicadorpernoctar",
        path:"indicadorpernoctar",
    },
    
    
];