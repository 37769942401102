import React from "react";
import { NotificationError } from "../../../Componets/General/FormsUtils/Notification.fom";
import { uploadImage } from "../../../Componets/General/UploadImage/UploadImage";
import { fields } from "../../../Componets/Helpers/Form/Form-context";
import { useMutationServer } from "../../../Componets/hook/mutation-hook";
import { useNotification } from "../../../Componets/hook/notification-hook";
import { useLazyQueryToSever } from "../../../Componets/hook/query-hook";
import { useUser } from "../../../Componets/hook/user-hook";
import { error, objectKV, action } from "../../../utils/interface";
import { formatterTimeZoneDate, getDateServer, reportFormatter } from "../../../utils/utils";
import { queryTablesRemitances } from "../Travel/Travel.config";
import { ControlButtons, DatePicker, GeneralInfo, GET_INFO, LocationForm, Observations, REPORT_MUTATION, UploadFile } from "./Form.general-info";
import "./Forms-report.scss";


export const filedsForm:Array<fields> = [
    {
        name:"place",
        validator:[true]
    },
    {
        name:"news"
    }
];

export const FormBasicProcces = React.memo((props:{id?:number | string; close:() => any;sysEvent:number;title:string;}) => {

    const init = {
        fecha: "",
        hora: "",
        idevento: null,
        idremesas: [],
        idsysevento: props.sysEvent,
        idusuario: null,
        idviaje: null,
        imagenes: [],
        lugar: "",
        novedad: ""
    };

    const reducer = (state:objectKV, action:action) => {
        switch(action.type){
            case "SET_DATA":
                return Object.assign({}, state, action.value);
            default:
                return state;
        }
    };


    const [loadData, {data:dataFill, loading:loadinFill, error:errorFill}] = useLazyQueryToSever({query:queryTablesRemitances,fetchPolicy:"cache-first"});
    const [uploading, setUploading] = React.useState<boolean>();
    const [dateServer, setDateServer] = React.useState<string>();
    const {addNotification} = useNotification();


    const [sendData, {data, loading, error}] = useMutationServer({mutation:REPORT_MUTATION});
    const [errorDate, setErrorDate] = React.useState<error>();
    const [messageErrorDatePicker, setMessageErrorDatePicker] = React.useState<string>();
    const [errorTime, setErrorTime] = React.useState<error>();
    const [errorNovedad, setErrorNovedad] = React.useState<error>();
    const [errorLocation, setErrorLocation] = React.useState<error>();
    const [date, setDate] = React.useState<number>();
    const { user:{id} } = useUser();

    const [state, dispatch] = React.useReducer(reducer, init);

    const validator = (v:objectKV) => {
        setErrorDate({error:!v.fecha, message:"Requerido"});
        setErrorTime({error:!v.hora, message:"Requerido"});
        setErrorNovedad({error:!v.novedad, message:"Requerido"});
        setErrorLocation({error:!v.lugar, message:"Requerido"});
        if( !v.lugar || !v.novedad || !v.fecha || !v.hora) return false;
        return true;
    }

    const _onClickSend = () => {
        if(!validator(state)) return;
        setUploading(true);
        sendReport(state).finally(() => {
            setUploading(false);
        });
    };

    const sendReport = async (v:any) => {
        let {images,report} = await reportFormatter(v);
        let resReport = await sendData({variables:{report:[report]}}).catch((err:any) => ({error:true}));
        if(resReport?.data?.createReporteV2?.length && resReport?.data?.createReporteV2[0]?.uploadLinks?.length){
            await Promise.all(resReport.data.createReporteV2[0].uploadLinks.map(async (item:objectKV) => {
                let file = images.find((f:File) => f.name === item.key);
                return (item.url && file? await uploadImage(item.url,file,file.type):null);
            }));
        }
    };

    React.useEffect(() => {
        let value = dataFill?.remesasInfo?.data;
        if(value){
            dispatch({type:"SET_DATA", value:{
                idviaje:props.id,
                idremesas:(value?.length &&(props.sysEvent === 37?value.filter((item:any) => item.idsysestado === 8):value).map((item:{id:number | string}) => item.id)) ?? []}});
        }
    }, [dataFill]);

    React.useEffect(() => {
        dispatch({type:"SET_DATA", value:{idusuario:id}});
    }, [id]);

    React.useEffect(() => {
        if(props.id) {
            loadData({variables:{id:props.id}});
        }
    }, [props.id]);

    React.useEffect(() => {
        if(error?.message) {
            if(addNotification) {
                addNotification({element:<NotificationError message={error.message} />,delay:3000});
            }
            props.close();
        }
    }, [error]);

    React.useEffect(() => {
        if(data?.createReporteV2?.length && !uploading){
            props.close();
        }
    }, [data,uploading]);
    
    React.useEffect(() => {
        getDateServer().then((res:string) => {
            let _resDate = res.split("##")[0];
            setDateServer(_resDate);
            setDate(formatterTimeZoneDate(_resDate).timeCurrentZone??0);
        });
    }, []);


    return(<div className="general-form form-process">
            <div className="form-process__header">
                <div className="name-report">{props.title ?? ""}</div>
            </div>
            <div className="form-process__general-info">
                <GeneralInfo {...props}/>
            </div>
            <div className="form-process__date">
                <DatePicker dateString={dateServer} messageGeneralError={messageErrorDatePicker} onChange={dispatch} date={date} errorDate={errorDate} errorTime={errorTime} />
            </div>
            <div className="form-process__place">
                <LocationForm onChange={dispatch} error={errorLocation} label="Lugar reporte*" style={{width:240}} />
            </div>
            <div className="form-process__files">
                <UploadFile  onChange={dispatch}/>
            </div>
            <div className="form-process__news">
                <Observations error={errorNovedad} onChange={dispatch} value="Sin Novedad" />
            </div>
            <div className="form-process__buttons">
            <ControlButtons 
                buttonCancel={{onClick:props.close,disabled:uploading}} 
                buttonSend={{
                    onClick:_onClickSend,
                    loading:uploading,
                    disabled:uploading
                    }} />
            </div>
        </div>);
});