import React from "react";
import Button from "../../Componets/Basic/Button/Button";
import { confTable, factorFilter, objectKV } from "../../utils/interface";

export const factory = (props:{field: confTable,data:objectKV}) => {
    return null;
    if(props.field.field === "reset")         
    return (<Button label="reset pass"/>);    
};

export const factoryFilters = (props:factorFilter) => {
    return null;
};