import React from "react";
import { ClientFilter } from "../../Componets/General/UtilsView/Utils-view";
import { confTable, factorFilter, objectKV } from "../../utils/interface";
import { SysStatusFilter } from "../Home/FactoryHome";

export const factory = (props:{field: confTable,data:objectKV}) => {
    return null;
};

export const factoryDetails = (props:{field: confTable,data:objectKV}) => {

    if(props.field.field === "dias"){
        let temp:any = new Date(+props.data?.fechalimiteentregacontenedor);
        if(!temp.getTime()) return <>{0}</>;
        let real:any = new Date(+props.data?.fechaentregacontenedor);
        real = real.getTime()?real:new Date();
        temp = Math.floor((real.getTime()-temp.getTime())/(3600000*24));
        return <>{temp}</>;
    }
    return null;
};

export const factoryFilters = (props:factorFilter) => { 
    if(props.confField.field === "_idsysestado"){
        return (<SysStatusFilter idsRemoved={[2,3,4,5,7,8,9,10,11,12,13,14,15]} style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }
    return null;
};

