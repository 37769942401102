import { confTable, objectKV } from "../../utils/interface";
import { fields } from "../../Componets/Helpers/Form/Form-context";

export const dataInfoTripPlan = `
  id
  codigo
  descripcion
  idruta
  ruta
  indicadordeshabilitado
` 

const infoCheckPoint = `
id
descripcion
indicadorfisico
indicadorpernoctar
indicadoraltoriesgo
`;
export const getInfoControlPoint = `
query checkPoint($filters:[FilterInput],$fields:Int!, $page:Int!,$orders: [OrderInput]) {
  puestoscontrolInfo(filters : $filters,pagination: { fields:$fields, page: $page},orders: $orders){
      data{
       ${infoCheckPoint}
    }
    totalreg
  }
}
`;
// export const getCheckPointById = `
// query checkPoint($id:Int!) {
//   puestocontrolInfo(id:$id){
//     ${infoCheckPoint}
//   }
// }
// `;
export const getCheckPointById = `
fragment mypuestoscontrolInfo on PuestoControlInfo {
  ${infoCheckPoint}
} 
`;
export const getRoutes = `
query getControlPoints($filters:[FilterInput],$fields:Int!, $page:Int!,$orders: [OrderInput]) {
  rutasInfo(filters : $filters,pagination: { fields:$fields, page: $page},orders: $orders){
    data {
      id
      descripcion
    }
    totalreg
  }
}
`;

export const queryGetTripPlanDetail = `
query getPlanViaje (
  $filters: [FilterInput],
  $orders: [OrderInput],
  $pagination: PaginationInput,
  $idplanviaje: Int){
  detallesplanviaje(  
    filters: $filters,
    orders: $orders,
    pagination: $pagination,
    idplanviaje: $idplanviaje) {
    data {
      id
      idplanviaje
      idpuestocontrol
      puestocontrol
      orden
      duracion
     	distancia
      indicadorfisico
      indicadorpernoctar
      indicadoraltoriesgo
    }
    totalreg
  }
}
`;

export const queryTripPlan = `
query tripPlan  ( $fields:Int!, $page:Int!, $filters: [FilterInput], $orders: [OrderInput]){
  planesviaje (
    filters: $filters,
    orders: $orders,
    pagination: { fields:$fields, page: $page}
  ) {
      data {
        ${dataInfoTripPlan}
      }
      totalreg
    }
  }
`;

export const queryaTripPlan = `
query getTripPlan ($id:Int!) {
  planesviaje(id:$id) {
    ${dataInfoTripPlan}
  }
}
`;

export const mutationUpdateTripPlan = `
mutation updatePlanviaje($id: Int, $planviaje: PlanViajeInputV2,$detalles: [planViajeDetallesInputV2]) {
  updatePlanviajeV2(id: $id, planviaje:$planviaje,detalles:$detalles) {
    codigo
  }
}
`;

export const mutationCreateTripPlan = `
mutation createPlanViaje( $planviaje: PlanViajeInputV2,$detalles: [planViajeDetallesInputV2]) {
  createPlanviajeV2(planviaje:$planviaje,detalles:$detalles){
    codigo
  }
}
`;

export const habilitarPlanviajeV2 = `
mutation habilitarPlanviajeV2($id: Int) {
  habilitarPlanviajeV2(id: $id) {
    codigo
  }
}
`;


export const mutationDeleteTriPlan = `
mutation deletePlanesviajes($ids: [Int]) {
  deletePlanviajeV2(ids: $ids) {
    codigo
  }
}
`;

// export const queryTripPlanLocal = `
// query tripPlan($id:Int!){
//     planviaje(id: $id) ${infoTtipPlanQuery}
//   }
// `;

export const fieldsTable : Array <confTable>  = [
  {
      field:"codigo",
      orderable:true,
      filterable:true,
      label:"Código",
      type:"TEXT",
      path:"codigo",
      width:100
  },
    {
        field:"descripcion",
        orderable:true,
        filterable:true,
        label:"Descripción",
        type:"TEXT",
        path:"descripcion"
    },
    {
      field:"indicadordeshabilitado",
      orderable:true,
      filterable:true,
      label:"Deshabilitado",
      type:"BOOLEAN",
      width:75,
      textAling:"CENTER",
      path:"indicadordeshabilitado"
  }
    
];

export const fieldsDetailTripPlan:Array<confTable> = [
  {
    label:"Orden",
    field:"orden",
    type:"TEXT",
    path:"orden",
    textAling:"RIGHT"
  },
  {
    label:"Puesto de Control",
    field:"control_point",
    type:"TEXT",
    path:"puestocontrol",
    isEditable:true
  },
    {
      label:"Duración",
      field:"duration",
      textAling:"RIGHT",
      isEditable:true
    },
  {
    label:"Distancia (Km)",
    field:"distance",
    type:"TEXT",
    path:"distancia",
    textAling:"RIGHT",
    isEditable:true

  },
  {
    label:"Tipo",
    field:"type",
    type:"BOOLEAN",
    path:"indicadorfisico",
    booleanValues:{isFalse:"Virtual", isTrue:"Físico"}
  },
  {
    label:"Riesgo",
    field:"risk",
    type:"BOOLEAN",
    path:"indicadoraltoriesgo"
  },
  {
    label:"Pernoctar",
    field:"Pernoctar",
    type:"BOOLEAN",
    path:"indicadorpernoctar"
  }
];

export const fieldsDetailTripPlanDetails:Array<confTable> = [
  {
    label:"Orden",
    field:"orden",
    type:"TEXT",
    path:"orden",
    textAling:"RIGHT"
  },
  {
    label:"Puesto de Control",
    field:"control_point",
    type:"TEXT",
    path:"descripcion",
    isEditable:true,
    width:172
  },
  {
    label:"Duración",
    field:"duration",
    word:true,
    isEditable:true
  },
  {
    label:"Distancia (Km)",
    field:"distance",
    type:"TEXT",
    path:"distancia",
    textAling:"RIGHT",
    isEditable:true

  },
  {
    label:"Tipo",
    field:"type",
    type:"BOOLEAN",
    path:"indicadorfisico",
    booleanValues:{isFalse:"Virtual", isTrue:"Físico"}
  },
  {
    label:"Riesgo",
    field:"risk",
    type:"BOOLEAN",
    path:"indicadoraltoriesgo"
  },
  {
    label:"Pernoctar",
    field:"Pernoctar",
    type:"BOOLEAN",
    path:"indicadorpernoctar"
  }
];

const validatorIsIntiger = (value:string | number | boolean, name:string, values?:objectKV) => {
  if(Number.isInteger(+value)) return false;
  else return "Entero";
};

export const fieldsDeatils:Array<fields> = [
  {name:"orden"},
  {name:"id"},
  {name:"idpuestocontrol",validator:[true]},
  {name:"descripcion"},
  {name:"distancia",validator:[{min:0}]},
  {name:"horas",validator:[validatorIsIntiger]},
  {name:"minutos",validator:[validatorIsIntiger]},
  {name:"indicadorpernoctar"},
  {name:"indicadorfisico"},
  {name:"indicadoraltoriesgo"},
];

export const fieldsForm:Array<fields> = [
  {name:"descripcion",validator:[true],path:"descripcion"},
  {name:"ruta",validator:[true],path:"idruta"},
  {name:"idtemplate",path:"idtemplate"},
  {name:"code",path:"codigo",validator:[true, {max:3}]}
];