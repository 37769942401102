import React from "react";
import  { Table } from "../../Componets/Basic/Table/Table";
import { Layout } from "../../Componets/General/Layout/Layout";
import { objectKV,action, confMap } from "../../utils/interface";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { query as GET_CTNS , fieldsTable } from "./Ctns.conf";
import { factory, factoryFilters } from "./Ctns.factory"
import { ModalOptionsCtns } from "./Ctns.options";
import { ButtonAdd } from "../../Componets/General/FormsUtils/Buttons.form";
import { createFilters, ExportExcel, FiltersOptions, parceOrders } from "../../Componets/General/UtilsView/Utils-view";
import { stringToDate } from "../../utils/utils";


/**
 * pagination-fix-1 corresponde a machetazo para la paginacion
 */ 
export const Ctns = () => {

    const tableRef =  React.useRef(null);

    let [id, setId] = React.useState<number>();
    const [visible, setVisible] = React.useState<boolean>(false);

    // const [data, setData] = React.useState<Array<any>>();

    const  [loadData, { called, loading, data:dataRequest, refetch,fetchMore }] = useLazyQueryToSever({query:GET_CTNS});

    let [hiddenFilters, sethiddenFilters] = React.useState<boolean>();
    let [idSelected, setIdSelected] = React.useState<Array<number | string>>([]);


    const mapNewFilters = (filters: any) => {
        let tempDateArray:Array<any> = [];
            filters = filters.map((item: false | { field: string; value: any; }) => {
                if (item && item?.field === "fechainiciomorasempresa") {
                    let dateTemp, dateTempObj: any = item.value?.split("-");
                    dateTemp = stringToDate(dateTempObj[0]?.split("/")?.join("-"));
                    if (dateTemp?.getTime) {
                        tempDateArray.push({ field: item?.field, value:`${dateTemp.getFullYear()}-${dateTemp.getMonth()+1}-${dateTemp.getDate()}`});
                    }
                    dateTemp = stringToDate(dateTempObj[1]?.split("/")?.join("-"));
                    if (dateTemp?.getTime) {
                        tempDateArray.push({ field: item?.field, value:`${dateTemp.getFullYear()}-${dateTemp.getMonth()+1}-${dateTemp.getDate()}`});
                    }
                    return null;
                }
                else { }
                return item;
            }).filter(Boolean);
        filters = [...filters,...tempDateArray];
        return filters;
    };


    const initial = {
        /**
         * estos valores tambien estan en el state pero no tiene un valor inicial
         */
        //data: es la info que se odtine al dar click en una columna de la tabla ,
        //filters: aqui se guarda los filtros,
        //orders: se guardan los valores del orden

        currentPage:1,
        numReg:20,
    };

    const reducer = (state:objectKV, action:action) => {

        switch(action.type) {
            case "CLICK_ROW":
                if(action.value?.id) {
                    setId(action.value?.id);
                    setVisible(true);
                }
                return Object.assign({},state,{data:action.value});
            case "ADD_FILTERS":
                let filters = createFilters(action.value);
                if(JSON.stringify(filters) === JSON.stringify(state.filters)) return state;
                return Object.assign({}, state, {filters, currentPage:1});
            case "ADD_CURRENT_PAGE":
                return Object.assign({}, state, {currentPage:action.value});
            case "SET_ORDER":
                return Object.assign({}, state, {orders:action.value});
            case "SET_NUM_REG":
                return Object.assign({}, state, {numReg:+action.value ?+action.value:10, currentPage:1});
            default: 
                return state;
        }
    };

    const [ state, dispatch ] = React.useReducer(reducer,initial);

    React.useEffect(() => {
        //esto es algo asi como un machetazo
        setIdSelected([]);
        let { filters, numReg, currentPage, orders } = state;
        //Se parcean los campos de orden que traen una estructura diferente de el state
        orders = parceOrders(orders); 

        if(filters && numReg && currentPage){
            filters = mapNewFilters(filters);
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            loadData({variables:{filters, page: currentPage, fields: numReg, orders }})
        }
    }, [state.filters, state.numReg, state.currentPage, JSON.stringify(state.orders), fieldsTable]);


    const _valuesSelected = (v:Array<string | number>) => {
        if(v[0] === Infinity && dataRequest?.contenedores.data?.length) {
            let ids = dataRequest.contenedores.data.map((item:objectKV) => item && !item.indicadorfisico?item.id:null).filter(Boolean);
            setIdSelected([...ids]);
        }
        else {
            if(dataRequest?.contenedores.data?.length){
                let _data = dataRequest?.contenedores.data?.filter((_item:objectKV) => !_item?.indicadorfisico);
                let value = v.filter((item:string | number) => _data.find((_item:objectKV) => +_item.id === +item));
                setIdSelected([...value]);
            }
        }
    };

    const resetFilters = ()=>{
        let el:any = tableRef?.current;
        if(el?.resetFilters){
            el?.resetFilters()
        }
    };

    const _close = () => {
        setVisible(false);
    };

    const _onClickAdd = () => {
        setVisible(visible => {
            setId(undefined);
            return !visible;
        });
    };

    const refresh = () => {
        if(refetch)refetch();
        setIdSelected([]);
    };

    const _getIsSelectable = (data:objectKV) => {
        return data?.indicadorfisico;
    };

    const filtersExcel = React.useMemo(() => {
        let { filters } = state ?? {};
        if(filters) return mapNewFilters(filters);
        return [];
     },[JSON.stringify(state)]);

    return (
        <React.Fragment>
             <ModalOptionsCtns visible={visible} id={id} close={_close} refresh={refresh}/>
            <Layout
            title="Contenedores"
            goToPage={dispatch}
            right={<FiltersOptions
                filters={state.filters}
                reset={resetFilters} 
                hiddenFilters={sethiddenFilters}
                lastButtom={<ExportExcel filters={ filtersExcel } pagination={{ fields: state?.numReg, page: state?.page }} queryId={3} name="Contenedores_" fields={[
                    "sysestado",
                    "diasmora",
                    "fechainiciomorasempresa",
                    "comodato",
                    "numerocontenedor",
                    "documentocliente",
                    "bl",
                    "naviera",
                    "origen",
                    "destino",
                    "lugardevolucion",
                    "nombrecliente",
                    "nombrecomercialembarcador"
                ] }/>}
                hidden={!!hiddenFilters} />}
            pageCurrent={state.currentPage}
            //pagination-fix-1
            total={dataRequest?.contenedores?.totalreg}
            numReg={state.numReg}>
                <Table
                ref={tableRef}
                factoryFilters={factoryFilters}
                getIsSelectable={_getIsSelectable}
                factory={factory}
                getFilters={dispatch} 
                hiddenFilters={hiddenFilters}
                isSelectable
                idSelected={idSelected}
                valuesSelected={_valuesSelected}
                data={dataRequest?.contenedores?.data} 
                clickRow={dispatch}
                loading={(loading || !called )}
                orderClick={dispatch} 
                orderableField={state.orders} 
                fields={fieldsTable}/>
            </Layout>
        </React.Fragment>
    );   
};
