import React from 'react';
import { error } from '../../../utils/interface';
import "./Switch.scss";

interface propsSwitch {
    /** indica si hay algun error */
    error?:error;
    /**
     * es el label que se mostrara en el input
     */
    label?:string;
    disabled?:boolean;
    /** */
    [v:string]:any;
}

export const Switch = React.memo((props:propsSwitch) => {

    const { label, error,value,onChange,name,disabled } = props;

    const _onChangeCapture = (e:any) => {
        if(onChange) onChange({target:{name ,value:e?.target?.checked}});
    }

    return (
        <div className="container">
            {label?<label className={`text-conf ${error?.error?"error":""}`}>{label}</label>:null}
            <label className="switch" >
                <input disabled={disabled} type="checkbox" onChangeCapture={_onChangeCapture} defaultChecked={!!value} className="switch__input" />
                <div className={`slider__base ${!!value?"slider__base--checked":""}`} />
                <div className={`slider__round ${!!value?"slider__round--checked":""}`} />
            </label>
            {error?.error?<label className="text-conf error">{error.message}</label>:null}
        </div>
    )
});
