
const infoQueryTravel = `
id
idvehiculo
idconductor
idsysestado
origen
destino
alerta
idproximopuestocontrol
fechahoraproximogps
fechahoraproximopuestocontrol
fechahoraproximallamada
proximopuestocontrol
numero
fecha
trailerplaca
sincronizado
ruta
cliente
indicadortransitosolar
idproximosysevento
idempresatransportadora
empresatransportadora
indicadoraltoriesgo
mercancias
modalidadnombre
estado
indicadorvialibre
idplanviaje
retenerviaje
placa
devolviendocontenedor
numerocontenedor
fechahoraultimoevento
nombreconductor
numerodocumentoconductor
geolocalizacion {
  latitud
  longitud
}
`;

/**
 * @description retorna un DocumentNode query para traer la informacion del vehiculo, 
 * @param isLocal si es true significa que la solicitu es local a la cache
 */
export const queryVehicle = (isLocal:boolean = false) => `query vehicles($id:Int!){
    vehiculo(id: $id) ${isLocal? "@client" : ""} {
      id
      color
      marca
      placa
      tipo
      carroceria
      gpsurl
      gpsusuario
      gpscontrasena
      nombrepropietario
      telefonopropietario
      foto
      fotominiatura
      modelo
      idempresagps
    }
  }`;

  export const queryRoute = `
  query viajes($id:Int!){
    viaje(id: $id) {
      id
      idsysestado
      geolocalizacion {
        latitud
        longitud
      }
			origen
      destino
      idproximopuestocontrol
    }
  }
`;

export const queryTravelFragment = `
  fragment viajes on Viajes {
      ${infoQueryTravel}
    }
`;

export const queryTravelRequest = `
query viajes ($id:Int!){
  viaje(id: $id) {
    ${infoQueryTravel}
  }
}
`;


  export const queryGenralInfo = `
  query viajes($id:Int!){
    viaje(id: $id) {
      id
      numero
      fecha
      trailerplaca
      indicadortransitosolar
      sincronizado
      geolocalizacion {
        latitud
        longitud
      }
      ruta
      cliente
      idplanviaje
      idproximosysevento
      idempresatransportadora
      empresatransportadora
      indicadoraltoriesgo
      mercancias
      modalidadnombre
      indicadorvialibre
      idsysestado
      numerocontenedor
      devolviendocontenedor
    }
  }
  `;

  export const queryIdtravelLocal = `
  query value{
    numberSix @client
  }
  `;

  export const queryDriver = `
  query driver($id:Int!){
    conductorInfo(id: $id){
      id
      idsystipodocumento
      numerodocumento
      foto
      fotominiatura
      idusuario
      idsysperfil
      nombre
      correo
      telefono
      indicadoractivo
      idempres
      contactos
    }
  }
  `;

  export const queryLocationsLocal = `
  query ViajesInfo ($id:Int!){
    viaje(id:$id) {
      id
      idsysestado
      alerta
      retenerviaje
      indicadortransitosolar
      idproximosysevento
      devolviendocontenedor
      indicadoraltoriesgo
      geolocalizacion {
        latitud
        longitud
      }
      origen
      destino
      indicadorvialibre
    }
  }
  `;
  export const queryStatus = `
  query status{
    sysEstados{
      data{
        id
        iconname
        descripcion
      }
    }
  }
  `;

  export const queryLocalTrazability = `
  query ViajesInfo ($id:Int!){
    viaje(id:$id)  {
      id
      idsysestado
      trazabilidad {
        id
        fechahora
        idsysevento
      }
    }
  }
  `;

export const querySysEventos = `
query sysevents{
  sysEventos{
    data {
      id
      iconname
      descripcion
    }
  }
}
`;

export const GET_TIME_REPORTS = `
query ViajesInfo ($id:Int!){
  viaje(id:$id) {
    id
    fechahoraproximogps
    fechahoraproximopuestocontrol
    fechahoraproximallamada
    idproximosysevento
    idproximopuestocontrol
    proximopuestocontrol
    idsysestado
    alerta
  }
}
`;


// aplicacion: "12"
// criticidad: 0
// estadoreporte: "EN TRÁNSITO"
// evento: null
// fechahora: "2021-02-28T20:24:00.000Z"
// geolocalizacion: null
// id: 253076
// idempresa: 4
// idevento: null
// idsremesas: [1139]
// idsysevento: 12
// idviaje: 1199
// indicadorvisiblecliente: null
// lugar: "mede"
// novedad: "Sin Novedad"
// observaciones: ""
// perfil: "Administrador"
// puestocontrol: null
// retenerviaje: false
// retraso: 1544
// sysevento: "Reporte de llamada"
// uploadLinks: []
// usuario: "adminGranada"

/**
 * RECUERDE revisar si el nuevo campo que decea agregar en el query de traza no se encuentra entre los anteriores podria tener problemas.
 */
export const fieldsTrazabilityQuery = `
id
data{
  id
  idviaje
  indicadornocoincide
  lugar
  sysevento
  idevento
  evento
  idsysevento
  fechahora
  usuario
  novedad
  puestocontrol
  retraso
  idsremesas
  aplicacion
  orden
  numberdocs
  fechareporte
  geolocalizacion {
    latitud
    longitud
  }
}
`;
export const queryTablesTrazability = `
query viajesTraza($id:Int!){
    trazabilidadInfo(id:$id){
        ${fieldsTrazabilityQuery}
    }
}
`;

export const queryTablesPlan = `
query viajesPlan($idviaje:Int){
  planviajePorViaje(idviaje:$idviaje) {
    data {
      id
      planviajedetalles {
        id
        idplanviaje
        idpuestocontrol
        distancia
        orden
        duracion
        direccion
        indicadoraltoriesgo
        indicadorpernoctar
        indicadorfisico
        puestocontrol
        codigounico
        geolocalizacion{
            latitud
            longitud
        }
      }
    }
  }
}
`;

const remittances = `
remesasInfo(idviaje:$id){
  data{
      id
      idviaje
      numeroremesa
      destinatario
      remitente
      documentocliente
      indicadoraltoriesgo
      numerounidades
      observaciones
      estado
      direcciondestinatario
      contenido
      origen
      destino
      empaque
      devolvercontenedor
      numerocontenedor
      direccionremitente
      idsysestado
      clienteempresatransportadora
      fechahoracitacargue
      fechahoracitadescargue
      tiempopactadocargue
      tiempopactadodescargue
      pesomercancia,
      syseventosmail,
      eventosmail
      correoreporte
    }
}
`;

export const queryTablesRemitances = `
query viajesRem($id:Int!){
    ${remittances}
}
`;