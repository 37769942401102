import React from 'react';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import "./Map.scss";
import iconMarker from "./../../../utils/css/icons/common/pin_blue.png";
import { usePrevious } from '../../hook/prevValue-hook';
// import { markerEventHandler } from 'google-maps-react';

export interface markerInterface {
  lat:number;
  lng:number;
  icon?:any;
  tooltip?:string;
}

export interface routesInterface {
  waypoints:Array<markerInterface>;
}

export interface mapsInterface {
    onClick?:(v:any) => any;
    id:string;
    style:{
      height:number|string;
      width:number|string;
      [k:string]:number|string;
    };
    markers?:Array<markerInterface>;
    routes?:Array<routesInterface>;
}
export const MapGranada = React.memo((props:mapsInterface) => {

    const [ map, setMap ] = React.useState<any>();

    const [ markersRender, setMarkers ] = React.useState<Array<any>>([]);
    const [ routesRender, setRoutes ] = React.useState<Array<any>>([]);
    const [ latLng, setLatLng ] = React.useState<Array<any>>([]);

    const { onClick,style:tempStyle,markers,routes,id } = props;

    const prepRoutes = usePrevious(routes);
    const prepMarkers = usePrevious(markers);
    const prevlatLng = React.useRef({});

    React.useEffect(() => {
        let _tempMap:any;
        if(!map && id){
            const _map = L.map(id);
            _map.setView([3.713739, -73.091899], 6);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            // maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(_map);
            _tempMap = _map;
            setMap(_map);
        } else _tempMap = map;
      },[id]);

      const _onClick = React.useCallback((v:any) => {
        let temp:any = prevlatLng.current;
        if(temp[0] !== v?.latlng?.lat||temp[1] !== v?.latlng?.lng){
          prevlatLng.current = [v?.latlng?.lat,v?.latlng?.lng];
          return onClick&&onClick(v);
        }
      },[onClick,prevlatLng]);

      React.useEffect(() => {
        if(!map || !onClick) return;
        map.on('click', _onClick);
      },[_onClick,map]);

      React.useEffect(() => {
        if(!map) return;
        markersRender.forEach((i:any) => {
          if(map?.removeLayer)map.removeLayer(i);
        });
        setMarkers([]);
        if(!markers?.length) return;
        markers.forEach((m:markerInterface) => {
          if((!m.lat && m.lat !== 0) || (!m.lng && m.lng !== 0)) return;
          let icon = L.icon({
            iconUrl: m.icon??iconMarker,
            iconSize:     [25, 30],
            shadowSize:   [50, 64],
            iconAnchor:   [15,20],
            shadowAnchor: [4, 62],
            popupAnchor:  [-3, -76]
          });
          // console.log("here***************************",m)
          let newMarker = L.marker(L.latLng(m.lat,m.lng),{icon}).addTo(map);
          if(m.tooltip) newMarker.bindTooltip(m.tooltip);
          setMarkers((_m:Array<any>) => [..._m,newMarker]);
        });
      },[JSON.stringify(markers)]);      
      
      React.useEffect(() => {
        // console.log(JSON.stringify(routes) , JSON.stringify(prepRoutes))
        if(!map || JSON.stringify(routes) === JSON.stringify(prepRoutes)) return;
        if(routesRender.length){
          routesRender.forEach((r:any) => {if(map.removeControl)map.removeControl(r);});
          setRoutes([]);
        }
        if(!routes?.length) return;
        routes.forEach((i:routesInterface) => {
          let  waypoints = [...i.waypoints.map((m:markerInterface) => L.latLng(m.lat,m.lng))]
          if(!waypoints.length) return;
          let route:any =  L.Routing.control({
          waypoints,
          showAlternatives:false,
          addWaypoints: true,
          routeWhileDragging: true,
          show: true,
          plan: L.Routing.plan(waypoints, {
            createMarker: (index, wp) => {
              let icon = L.icon({
                iconUrl: (i.waypoints[index]&&i.waypoints[index].icon)??iconMarker,
                iconSize:     [25, 30],
                shadowSize:   [50, 64],
                iconAnchor:   [15,20],
                shadowAnchor: [4, 62],
                popupAnchor:  [-3, -76]
              });
              let marker = L.marker(L.latLng(wp.latLng?.lat,wp.latLng?.lng), {icon});
              if(i.waypoints[index]&&i.waypoints[index].tooltip) marker.bindTooltip(i.waypoints[index].tooltip??"");
              return marker;
            }
          })
          }).addTo(map);
          route.hide();
          setRoutes((r:Array<any>) => [...r,route])
        });
      },[routes]);

      React.useEffect(() => {
        return () => {
          if(!map || !map.removeLayer || !map._layers) return;
          map.removeLayer(map._layers);
        };
      },[]);

        return (<div id={id} style={{...tempStyle,overflow:"hidden"}}/>);
});