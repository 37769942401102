import React from "react";
import  { Table } from "./../../Componets/Basic/Table/Table"
import { Layout } from "./../../Componets/General/Layout/Layout";
import { objectKV, action, confMap } from "./../../utils/interface";
import { useLazyQueryToSever } from "./../../Componets/hook/query-hook";
import { Redirect } from "react-router-dom";
import {  ExportExcel, FiltersOptions, parceOrders, TotalRegs } from "../../Componets/General/UtilsView/Utils-view";
import { Factory, useSysState } from "../Home/FactoryHome";
import { fieldsTable,queryViajes as GET_TRAVELS } from "./History.config";
import { FactoryFilters } from "./History.factory";
import { useConfTable } from "../../Componets/hook/conf-table-hook";
import { dateToString, getRangeDays, getRangeMonths, parseJson, stringToDate } from "../../utils/utils";
import "./History.scss";
import { getItemSelectedRange } from "../../Componets/Basic/Input/DateRange";

const KEY = "historyT";

export const HistoryView = React.memo(() => {

    const tableRef =  React.useRef(null);

    const { sys } = useSysState([1,2,3,5,6,8,9,10,11,12,13]);

    let [id, setId] = React.useState<number>();
    const [calledFiltres, setCalled] = React.useState<boolean>(false);

    //Uso el id 1000 para la tabla de viajes 
    const {
        table,
        setConfig
    } = useConfTable(fieldsTable,{id:1001,key:KEY});

    const initial = {
        /**
         * estos valores tambien estan en el state pero no tiene un valor inicial
         */
        //data: es la info que se odtine al dar click en una columna de la tabla ,
        //filters: aqui se guarda los filtros,
        //orders: se guardan los valores del orden

    };

    // React.useEffect(() => {
    //     console.log("tablesssss",table);
    // },[table]);

    const reducer = (state:objectKV, action:action) => {
        switch(action.type) {
            case "CLICK_ROW":
                if(action.value?.id) setId(action.value?.id);
                return Object.assign({},state,{data:action.value});
            case "ADD_CONFIG_TABLE":
                return Object.assign({}, state);
            default: 
                return state;
        }
    };

    const [ state, dispatch ] = React.useReducer(reducer,initial);

    const  [loadData, { called, loading, data, refetch,fetchMore }] = useLazyQueryToSever({query:GET_TRAVELS});

    let [hiddenFilters, sethiddenFilters] = React.useState<boolean>();

    const mapNewFilters = (filters: any, _sys: Array<confMap>) => {
        let tempDateArray:Array<any> = [];
        let _filters, tempValue: any = filters.find((item: false | { field: string; value: any; }) => item && item.field === "idsysestado");
            if(!tempValue?.value && _sys?.length) _filters=[...filters,..._sys.map((item:objectKV) => ({field:"idsysestado",value:item.id+""}))];
            else _filters = filters;
            _filters = _filters.map((item: false | { field: string; value: any; }) => {
                if (item && item?.field === "cliente") {
                    let _item = JSON.parse(item.value ?? "{\"values\":[]}")?.values;
                    _item = _item.length ? { field: "idclientes", value: `[${_item.join(",")}]` } : null;
                    return _item;
                } else if (item && item?.field === "fecha") {
                    let dateTemp, dateTempObj: any = item.value?.split("-");
                    dateTemp = stringToDate(dateTempObj[0]?.split("/")?.join("-"));
                    console.log(dateTemp,dateTempObj);
                    if (dateTemp?.getTime) {
                        tempDateArray.push({ field: "fecha", value:`${dateTemp.getFullYear()}-${dateTemp.getMonth()+1}-${dateTemp.getDate()}`});
                    }
                    dateTemp = stringToDate(dateTempObj[1]?.split("/")?.join("-"));
                    if (dateTemp?.getTime) {
                        tempDateArray.push({ field: "fecha", value:`${dateTemp.getFullYear()}-${dateTemp.getMonth()+1}-${dateTemp.getDate()}`});
                    }
                    return null;
                }
                else { }
                return item;
            }).filter(Boolean);
        _filters = [..._filters,...tempDateArray];
        return _filters;
    };
    
    const filtersExcel = React.useMemo(() => {
        let { filters } = table ?? {};
        if(!sys) return [];
        if(filters) return mapNewFilters(filters,sys);
        return [];
     },[JSON.stringify(table),sys]);

    React.useEffect(() => {
        // if (!calledFiltres) return;
        let { filters, numReg, page, orders } = table ?? {};
        //Se parcean los campos de orden que traen una estructura diferente de el state
        let tempOrders = orders?parceOrders(orders):null; 
        if(!sys) return;
        if(filters){     
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            let _filters = mapNewFilters(filters,sys);
            loadData({variables:{filters:_filters, page:page??1, fields: numReg??20, orders:tempOrders }})
        }

    }, [JSON.stringify(table),sys]);

    const resetFilters = ()=>{
        let el:any = tableRef?.current;
        if(el?.resetFilters){
            el?.resetFilters()
        }
    };

    const title = React.useMemo(() => {
        const general = "Historial de viajes";
        let _date = table?.filters?.find((item: any) => item.field === "fecha")?.value?.split("-") ?? [];
        let itemSelected = getItemSelectedRange(_date);
        switch(itemSelected){
            case "TODAY": return `${general} hoy`;
            case "WEEK": return `${general} ultima semana`;
            case "MONTH": return `${general} ultima mes`;
            case "3-MONTHS": return `${general} ultimo trimestre`;
            case "6-MOUTHS": return `${general} ultimo semestre`;
            case "YEAR": return `${general} ultimo año`;
            default: return `${general} ${_date?.join("-")??""}`
        }
    },[table?.filters]);

    // React.useEffect(() => {
    //     console.log(table);
    // },[table]);

    if( id ) return <Redirect push to={`/Home/Viaje/${id}`} />;

    return (
        <Layout
            title={title}
            pageCurrent={table?.page}
            right={<FiltersOptions
                filters={table?.filters}
                reset={resetFilters} 
                hiddenFilters={sethiddenFilters}
                lastButtom={<ExportExcel filters={ filtersExcel } pagination={{ fields: table?.numReg, page: table?.page }} queryId={2} name="Viajes_" fields={[
                    "id"
                    ,"numero"
                    ,"fecha"
                    ,"placa"
                    ,"lugar"
                    ,"fechahoraultimoevento"
                    ,"ultimoevento"
                    ,"cliente"
                    ,"estado"
                    ,"indicadoraltoriesgo"
                    ,"destino"
                    ,"origen"
                    ,"modalidad"
                    ,"nombreconductor"
                    ,"telefonoconductor"
                    ,"trailerplaca"
                    ,"ruta"
                    ,"nombreconductor"
                    , "numerodocumentoconductor"
                ] }/>}
                hidden={!!hiddenFilters} />}
            goToPage={setConfig}
            total={data?.viajes?.totalreg}
            leftBottom={<TotalRegs text={`Total viajes: ${data?.viajes?.totalreg??0}`}/>}
            numReg={table?.numReg}>
                <Table
                ref={tableRef}
                factoryFilters={FactoryFilters}
                factory={Factory}
                getFilters={setConfig}
                hiddenFilters={hiddenFilters}
                data={data?.viajes?.data}
                clickRow={dispatch}
                loading={(loading || !called)}
                orderClick={setConfig}
                orderableField={table?.orders}
                fields={table?.fields??[]} />
            </Layout>
    ); 
});


