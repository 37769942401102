import React from "react";
import "./Forms-report.scss";
import { GeneralInfo, Observations, ControlButtons, UploadFile, DatePicker, GET_INFO, REPORT_MUTATION } from "./Form.general-info";
import { useMutationServer } from "../../../Componets/hook/mutation-hook";
import { objectKV, action,error } from "../../../utils/interface";
import { useLazyQueryToSever } from "../../../Componets/hook/query-hook";
import { useUser } from "../../../Componets/hook/user-hook";
import TextView from "../../../Componets/Basic/Text/TextView";
import { formatterTimeZoneDate, getDateServer, reportFormatter, validatorTimeDate } from "../../../utils/utils";
import { uploadImage } from "../../../Componets/General/UploadImage/UploadImage";
import { queryTablesRemitances } from "../Travel/Travel.config";



export const FormDocuments = (props:{id?:number | string, close:() => any}) => {
    
    const [sendData, {data}] = useMutationServer({mutation:REPORT_MUTATION});
    const [loadData, { data: dataFill }] = useLazyQueryToSever({ query: GET_INFO, fetchPolicy: "cache-first" });
    const [loadDataRemittances, {data:dataRemittances, loading:loadinRemittances, error:errorRemittances}] = useLazyQueryToSever({query:queryTablesRemitances,fetchPolicy:"cache-first"});
    
    const { user:{id} } = useUser();

    const init = {
        fecha: "",
        hora: "",
        idevento: null,
        idremesas: [],
        idsysevento: 16,
        idusuario: null,
        idviaje: null,
        imagenes: [],
        lugar: "",
        novedad: ""
    };

    const reducer = (state:objectKV, action:action) => {
        switch(action.type){
            case "SET_DATA":
                return Object.assign({}, state, action.value);
            default:
                return state;
        }
    };

    const [messageErrorDatePicker, setMessageErrorDatePicker] = React.useState<string>();
    const [dateServer, setDateServer] = React.useState<string>();
    const [keyLocalDate, setKeyLocalDate] = React.useState<string>();

    const [errorDocument, setErrorDocument] = React.useState<error>();
    const [errorDate, setErrorDate] = React.useState<error>();
    const [errorTime, setErrorTime] = React.useState<error>();
    const [errorNovedad, setErrorNovedad] = React.useState<error>();
    const [date, setDate] = React.useState<number>();
    const [uploading, setUploading] = React.useState<boolean>();

    const [state, dispatch] = React.useReducer(reducer, init);

    const _onClickSend = async () => {
        if(!(await validator(state))) return;
        setUploading(true);
        await sendReport(state).finally(() => {
            setUploading(false);
        });
    };

    const sendReport = async (v:any) => {
        let {images,report} = await reportFormatter(Object.assign({},v,{novedad:`${v.novedad}${keyLocalDate??""}`}));
        let resReport = await sendData({variables:{report:[report]}}).catch((err:any) => ({error:true}));
        if(resReport?.data?.createReporteV2?.length && resReport?.data?.createReporteV2[0]?.uploadLinks?.length){
            await Promise.all(resReport.data.createReporteV2[0].uploadLinks.map(async (item:objectKV) => {
                let file = images.find((f:File) => f.name === item.key);
                return (item.url && file? await uploadImage(item.url,file,file.type):null);
            }));
        }
    };

    const _onClickCancel = () => {
        props.close();
    };

    const validator = async (v:objectKV) => {
        let currentDate = (await getDateServer(!!keyLocalDate)).split("##")[0];

        if(!currentDate || typeof(currentDate) !== "string") {
            setMessageErrorDatePicker("No se pudo obtener la hora del servidor");
            return false;
        }
        setErrorDate({error:!v.fecha, message:"Requerido"});
        setErrorNovedad({error:!v.novedad, message:"Requerido"});
        setErrorTime({error:!v.hora, message:"Requerido"});
        setErrorDocument({error:!v.imagenes?.length, message:"Requerido"});
        let isnotvalidDate = validatorTimeDate(v.hora??"",v.fecha??"",formatterTimeZoneDate(currentDate).timeCurrentZone,formatterTimeZoneDate(+(dataFill?.viaje?.fechahoraultimoevento??0)).timeCurrentZone);
        setMessageErrorDatePicker(isnotvalidDate);
        if(!v.fecha || !v.hora || !v.novedad || !v.imagenes?.length || isnotvalidDate) return false;
        return true;
    }

    React.useEffect(() => {
        getDateServer().then((res:string) => {
            let _resDate = res.split("##");
            setKeyLocalDate(_resDate[1])
            setDateServer(_resDate[0]);
            setDate(formatterTimeZoneDate(_resDate[0]).timeCurrentZone??0);
        });
    }, []);


    React.useEffect(() => {
        if (props.id) {
            loadData({ variables: { id: props.id } });
            loadDataRemittances({ variables: { id: props.id } });
        }
    }, [props.id]);

    React.useEffect(() => {
        let value = dataRemittances?.remesasInfo?.data;
        if(value?.length){
            dispatch({type:"SET_DATA", value:{
                lugar:"Sistema",
                idviaje:props.id,
                idremesas:(value?.map((item:{id:number | string}) => item.id)) ?? []}});
        }
    }, [dataRemittances]);

    React.useEffect(() => {
        dispatch({type:"SET_DATA", value:{idusuario:id}});
    }, [id]);

    React.useEffect(() => {
        if(data?.createReporteV2?.length && !uploading){
            props.close();
        }
    }, [data,uploading]);

    return (
        <div className="form-document general-form">
            <div className="form-document__name-report name-report">
                Reporte Documentos
            </div>
            <div className="form-document__general-info">
                <GeneralInfo {...props}/>
            </div>
            <div className="form-document__date-picker">
                <DatePicker dateString={dateServer} messageGeneralError={messageErrorDatePicker} onChange={dispatch} date={date} errorDate={errorDate} errorTime={errorTime} />
            </div>

            <div className={"form-document__event"}>
                <TextView label={"Evento"} text="documentos"/>
            </div>
            <div className="form-document__upload-file">
                <UploadFile error={errorDocument}  onChange={dispatch}/>
            </div>

            <div className="form-document__observation">
                <Observations error={errorNovedad} onChange={dispatch} value="Sin Novedad"/>
            </div>
            <div className="form-document__buttons">
                <ControlButtons 
                buttonCancel={{onClick:_onClickCancel,disabled:uploading}} 
                buttonSend={{
                    onClick:_onClickSend,
                    loading:uploading,
                    disabled:uploading
                    }} />
            </div>
        </div>
    );
};