import React from "react";
import { useUser } from "../../Componets/hook/user-hook";
import { fields } from "./../../Componets/Helpers/Form/Form-context";
import {
    queryGetTripPlanDetail as GET_TRIP_PLAN_DETAIL,
    queryTripPlan as GET_TRIP_PLAN,
    queryTripPlan as GETA_TRIP_PLAN,
    mutationUpdateTripPlan as UPDATE_TRIP_PLAN,
    mutationCreateTripPlan as CREATE_TRIP_PLAN,
    mutationDeleteTriPlan as DEL_TRIP_PLAN,
    getInfoControlPoint as GET_CONTROL_POINTS,
    getRoutes as GET_ROUTES,
    fieldsDetailTripPlan,
    fieldsDeatils,
    fieldsDetailTripPlanDetails,
    fieldsForm,
    getCheckPointById,
    habilitarPlanviajeV2 as DISABLED_TRIP
} from "./PlanTrip.conf";
import { Modal } from "../../Componets/Basic/Modal/Modal";
import { ModalAdapter, ModalConfirmationRemove } from "../../Componets/General/FormsUtils/FormModal";
import "./PlanTrip.scss";

import { useNotification } from "../../Componets/hook/notification-hook";
import { useMutationServer } from "../../Componets/hook/mutation-hook";
import { NotificationSucces, NotificationError } from "../../Componets/General/FormsUtils/Notification.fom";
import { objectKV, confTable, action } from "../../utils/interface";
import { ButtonRemove } from "../../Componets/General/FormsUtils/Buttons.form";
import { Table } from "./../../Componets/Basic/Table/Table";
import { useLazyQueryToSever, useQueryToSever } from "../../Componets/hook/query-hook";
import TextView from "../../Componets/Basic/Text/TextView";
import { TableDetails } from "../../Componets/Basic/Table/Table-details";
import { FormInputText, FormInputSelect, Form, FormInputAdvanced } from "../../Componets/Basic/Form/Form";
import { detail } from "../../Componets/Helpers/Table-details/Table-details-context";
import { destructor } from "../../utils/utils";
import { getStoreInfo } from "../../utils/store";
import Button from "../../Componets/Basic/Button/Button";
import { useClientApolloGranada } from "../../Componets/hook/client-apollo-hook";

/**
 * @description esta funcion se encarga de renderizar la ventana modal para edicion, 
 * depende de las valores que s ele pasen para tomar la apariencia de edicion, vcreacion o visualizacion.
 */
export const ModalOptionsTrip = React.memo((props: { id?: string | number, refresh?: () => any, visible: boolean, close: (v?: any) => any, onClickSave?: (v?: any) => any }) => {

    const { user } = useUser();

    /**
     * esta bandera es util para determinar si la ventana sera unicamente de edicion
     */
    const [isEditableOnly, setEditableOnly] = React.useState<boolean>(false);

    /**
     * esta es la data inicial que se pasara a la visualizacion y edicion 
     */
    const [data, setData] = React.useState<Array<objectKV>>();

    /**
     * Esta es la data formateada o ya configuradad que se le pasarar al foemulario de creacion de retorno
     */
    const [dataBack, setDataBack] = React.useState<Array<objectKV>>();

    /**
     * Este valor guaradar esl estado del formulario (valido-invalido)
     */
    const [isValid, setIsvalid] = React.useState<boolean>(false);

    /**
     * Este valor deternima si el formulario debe mostar los errores en pantalla o aun no 
     */
    const [validate, setValidate] = React.useState<boolean>(false);

    /**
     * En etsa variable se guaradan los valores del formulario
     */
    const [dataForm, setDataForm] = React.useState<objectKV>();


    const { addNotification } = useNotification();

    /**
     * Refresca la pagina, se llama cuando la mutacion termina 
     */
    const onCompleted = () => {
        if (props.refresh) props.refresh();
    };

    const [createTripPlan, { loading: loadingCrate, data: dataCreate, error: errorCreate }] = useMutationServer({ mutation: CREATE_TRIP_PLAN, onCompleted });

    const [updateTriPlan, { loading: loadingUpdate, data: dataUpdate, error: errorUpdate }] = useMutationServer({ mutation: UPDATE_TRIP_PLAN, onCompleted });

    const [loadData, { data: dataTripPlanDetail, called: calledTripPlan, refetch: refetchTripPlan, loading: loadingtripPlanDetail }] = useLazyQueryToSever({ query: GET_TRIP_PLAN_DETAIL });

    React.useEffect(() => {
        //se valida si existe un id y una idcompany 
        //y si es visible la vetana, para determinar si es de edicion unicamente 
        //(Determina si solo se muetra el formulario de create )
        if (props.id && user.idcompany && props.visible) {
            setEditableOnly(false);
        } else {
            setEditableOnly(true);
        }
    }, [props.id, props.visible, user]);

    React.useEffect(() => {
        _assingValues(dataTripPlanDetail, props.visible, props.id, dataForm?.data?.idtemplate);
    }, [props.visible, JSON.stringify(dataTripPlanDetail), props.id]);

    React.useEffect(() => {
        let id = +(props.id ?? 0);
        /**Se determina si es necesario hacer la peticion de plan de viaje detalles 
         */
        if (id) loadData({ variables: { idplanviaje: id, orders: { field: "orden", type: "ASC" } } });
        if (!props.visible) setValidate(false);
    }, [props.id, props.visible]);

    React.useEffect(() => {
        /**Se muestar la notificacion de exito en caso de que sea necesrio */
        if (dataUpdate || dataCreate) {
            if (!loadingCrate && !loadingUpdate && !errorUpdate && !errorCreate) {
                props.close();
                addNotification({ element: <NotificationSucces message="procesos exitoso" />, delay: 3000 });
            }
        }
    }, [dataCreate, dataUpdate, loadingUpdate, loadingCrate]);

    /**
     * Se muestran los mensajes de error si es el casoo 
     * ___________________________________________
     */
    React.useEffect(() => {
        if (errorUpdate) {
            addNotification({ element: <NotificationError message={errorUpdate?.graphQLErrors[0]?.message ?? "Ocurrio un error"} />, delay: 3000 });
        }
    }, [errorUpdate]);
    React.useEffect(() => {
        if (errorCreate) {
            addNotification({ element: <NotificationError message={errorCreate?.graphQLErrors[0]?.message ?? "Ocurrio un error"} />, delay: 3000 });
        }
    }, [errorCreate]);
    /**______________________________________________ */

    /**Se formatea y crea la informacion del retorno  */
    React.useEffect(() => {
        setDataBack(parceDataTPB(data));
    }, [data]);

    /**
     * @description asigna la informacion del pan de viaje detalles 
     * @param _dataTripPlanDetail 
     * @param _visible 
     * @param id 
     */
    const _assingValues = (_dataTripPlanDetail: any, _visible: boolean, id?: string | number, idtemplate?: number) => {
        if (_visible && id) {
            setData(_dataTripPlanDetail?.detallesplanviaje?.data);
        } else if (idtemplate) {
            setData(_dataTripPlanDetail?.detallesplanviaje?.data ?? []);
        } else {
            setData([]);
        }
    };

    /**
     * @description Cierra la ventana modal, se crea para manejar el evento click outside 
     */
    const _clickOutside = () => {
        props.close();
    };

    /**
     * @description maneja el evento save, en esta se froamtean los valores para la mutacion 
     * ademas se define que mutacion se va a lanzar si create o update y se lanza la mutacion 
     */
    const _clickSave = () => {
        /**Se determina la validez del foemulario y se pide al formualrio que muetre los errores  */
        if (!isValid || !dataForm?.details?.length) {
            setValidate(true);
            return;
        }
        let detalles = dataForm?.details.map((item: objectKV) => {
            return {
                idplanviaje: props.id ?? null,
                idpuestocontrol: +item.idpuestocontrol,
                distancia: +item.distancia,
                orden: +item.orden,
                duracion: (+item.horas * 60) + (+item.minutos),
                indicadorpernoctar: !!item.indicadorpernoctar
            };
        });
        let planViajeInput = {
            codigo: dataForm?.data?.code,
            descripcion: dataForm?.data?.descripcion,
            idruta: +dataForm?.data?.ruta,
            idempresa: getStoreInfo().idcompany
        };
        if (props.id && !dataForm?.new) {
            updateTriPlan({ variables: { id: +props.id, planviaje: planViajeInput, detalles } });
        } else {
            createTripPlan({ variables: { planviaje: planViajeInput, detalles } });
        }
    };

    /**
     * @description agrega los valores dl form a una variable en este componente, manejael vento onChange  
     * @param v 
     */
    const assingDataForm = (v?: objectKV) => {
        setDataForm(v);
    };

    /**
     * @description agrega los valores dl form a una variable en este componente, manejael vento onChange 
     * este a su vez detemina que es uno nuevo, por lo que se salta la existencia del id y permite crear un nuevo plan de viaje
     * es util en la creacion de retorno 
     * @param v 
     */
    const assingDataFormCreateBack = (v?: objectKV) => {
        setDataForm({ ...v, new: true });
    };

    const assingValid = (v: boolean) => {
        setIsvalid(v);
    };

    React.useEffect(() => {
        if (dataForm?.data?.idtemplate) loadData({ variables: { idplanviaje: dataForm?.data?.idtemplate, orders: { field: "orden", type: "ASC" } } });
    }, [dataForm?.data?.idtemplate]);

    return (
        <Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
            <ModalAdapter
                showOptionsButton
                hiddenOptionsButtonInEdit
                className="container-modal-info"
                close={props.close} title="Plan de Viajes"
                style={{ width: 850 }}
                editableOnly={isEditableOnly}
                clickSave={_clickSave}
                option={(_props?: any) => {
                    return <ButtonsOptions {..._props} id={props.id} onCompleted={onCompleted}/>;
                }}
                loading={loadingCrate || loadingUpdate} >
                <Visualization loading={loadingtripPlanDetail} id={props.id} data={data} />
                <Edit validate={validate} getValue={assingDataForm} getIsvalid={assingValid} data={data} id={props.id} />
                <Edit token=" - regreso" validate={validate} getValue={assingDataFormCreateBack} getIsvalid={assingValid} data={dataBack} id={props.id} />
            </ModalAdapter>
        </Modal>
    );
});

/**
 * @description determina y altera la informacion para pasarsela al formulario de creacion de retorno
 * @param data 
 */
const parceDataTPB = (data?: Array<objectKV>) => {
    if (!data?.length) return data;
    let temp: Array<objectKV> = [];
    temp = [...data].map((item: any, index: number, _data: Array<objectKV>) => {
        const { distancia, duracion, idplanviaje, orden, ...all } = item;
        let _temp = {
            ...all,
            distancia: _data[index + 1]?.distancia ?? 0,
            duracion: _data[index + 1]?.duracion ?? 0,
            orden: _data.length - index
        };
        return _temp;
    }).reverse();
    return temp;
};

/**
 * @description Este componente muetra las opciones que tenddra el boton de opciones del formulario 
 */
const ButtonsOptions = React.memo((props?: any) => {

    /**Funciona con el evento onComplete de la mutacion */
    const onCompleted = () => {
        if (props.onCompleted) props.onCompleted();
        if (props.onClickClose) props.onClickClose();
    };

    const { addNotification } = useNotification();

    const [disable, { loading, data, error }] = useMutationServer({ mutation: DISABLED_TRIP, onCompleted });

    /**MUstra una notificacion de error si es el caso  */
    React.useEffect(() => {
        if (error) {
            addNotification({ element: <NotificationError message={error?.graphQLErrors[0]?.message ?? "Ocurrio un error"} />, delay: 3000 });
        }
    }, [error]);

    const _onClick = () => {
        if (props.changeState) props.changeState("CHANGE");
    };
    const _onClickHabilitar = () => {
        if(props?.id)disable({variables:{id:props.id}});
            // console.log(7777, props)
    };
    return (
        <>
            <Button onClick={_onClick} className="button-generate-back-trip-plan">
                <div className="button-generate-back-trip-plan__container">
                    <i className="icon-sync" />
                    <div>Crear plan de retorno</div>
                </div>
            </Button>
            <Button onClick={_onClickHabilitar} className="button-generate-back-trip-plan">
                <div className="button-generate-back-trip-plan__container">
                    <i className="icon-slash" />
                    <div>Habilitar/ Deshabilitar</div>
                </div>
            </Button>

        </>
    );
});


/**
 * @description esta funcion crea el componente que permite elminar plane de viaje 
 */
export const RemoveTripPlan = React.memo((props: { refresh?: () => any, ids: Array<number | string> }) => {

    /** Esta bandera indica si es visible */
    const [visible, setVisible] = React.useState<boolean>(false);

    /**Funciona con el evento onComplete de la mutacion */
    const onCompleted = () => {
        if (props.refresh) props.refresh();
    };

    const { addNotification } = useNotification();

    const [removeData, { loading, data, error }] = useMutationServer({ mutation: DEL_TRIP_PLAN, onCompleted });

    /**MUstra una notificacion de error si es el caso  */
    React.useEffect(() => {
        if (error) {
            addNotification({ element: <NotificationError message={error?.graphQLErrors[0]?.message ?? "Ocurrio un error"} />, delay: 3000 });
        }
    }, [error]);

    React.useEffect(() => {
        if (data) setVisible(false);
    }, [data]);

    const _onClick = () => {
        setVisible(true);
    };

    const _close = () => {
        setVisible(false);
    };

    /**
     * @description maneja el evento de acept en el componnete de eliminar, y lanza la mutacion 
     */
    const _accept = () => {
        let tempId = props.ids.map((item) => +item ? +item : null).filter(Boolean);
        if (tempId.length) removeData({ variables: { ids: tempId } });
    };

    return (
        <React.Fragment>
            <ModalConfirmationRemove loading={loading} visible={visible} close={_close} numberReg={props.ids?.length ?? 0} accept={_accept} />
            <ButtonRemove onClick={_onClick} />
        </React.Fragment>
    );
});

/**@description crea el componente de select de las rutas  */
export const RoutesInput = (props: any) => {

    return <FormInputAdvanced
        valuesDescription={props?.value}
        style={{ width: 500 }}
        name={"ruta"}
        label="Ruta*"
        pathTotalReg="rutasInfo.totalreg"
        fieldFilter="descripcion"
        descripcion
        orders={[{ type: "ASC", field: "descripcion" }]}
        query={GET_ROUTES}
        config={{ pathEmit: "id", pathOptions: "descripcion" }}
        pathData="rutasInfo.data" />

    // return <FormInputSelect
    // name="ruta"
    // label="Ruta*"
    // style={{width:500}}
    // data={data?.rutasInfo?.data}
    // confSelect={{pathEmit:"id", pathOption:"descripcion"}}
    // disabled={!data?.rutasInfo?.data?.length} />
};

/**
 * @description este funcion crea el componete de visualizacion 
 * @param props 
 */
export const Visualization = (props: { data?: any, loading?: boolean, id?: string | number }) => {
    return (
        <div className="container-trip-plan-modal">
            < FormInfoTripPlan data={props.data} id={props.id} />
            <div className="container-trip-plan-modal__table">
                <div className="container-trip-plan-modal__table__title">
                    Puestos de control
                </div>
                <div className="container-trip-plan-modal__table__container">
                    <Table factory={Factory} data={props.data ?? []} fields={fieldsDetailTripPlan} loading={props.loading} />
                </div>
                <div className="container-trip-plan-modal__table__totals">
                    <Total getValue={getValuesDetailsTripPlan} data={props.data} />
                </div>
            </div>
        </div>
    );
};

/**
 * @description crea el formulario de la informacion del plan de viajes, mustra la informacion si es el caso 
 */
export const FormInfoTripPlan = React.memo((props: { data?: objectKV, token?: string, validate?: boolean, id?: string | number, isForm?: boolean; getIsvalid?: (v: boolean) => any, getValue?: (v?: objectKV) => any }) => {

    const { isForm } = props;

    const [_fieldsForm, setFieldsForm] = React.useState<Array<fields>>();

    const [valuesForm, setValuesForm] = React.useState<objectKV>();

    const [loadData, { data }] = useLazyQueryToSever({ query: GETA_TRIP_PLAN, fetchPolicy: "cache-first" });

    React.useEffect(() => {
        let id = +(props.id ?? 0);
        if (id) loadData({ variables: { filters: [{ field: "id", value: "" + id }], fields: 1, page: 1 } });
    }, [props.id]);

    const _dataPlan = React.useMemo(() => {
        return data?.planesviaje?.data.length ? data?.planesviaje?.data[0] : null;
    }, [JSON.stringify(data)]);

    React.useEffect(() => {
        if (_dataPlan) {
            let temp = _dataPlan;
            let _fields = props.token ? fieldsForm : fieldsForm.map((item: fields) => {
                let tempItem = { ...item };
                if (tempItem.path) tempItem.value = destructor(tempItem.path, temp);
                if (tempItem.path === "idruta" && tempItem.value) tempItem.value = +tempItem.value;
                return tempItem;
            });
            setFieldsForm(_fields);
        } else if (!props.id) setFieldsForm(undefined);
    }, [_dataPlan]);

    const _getOnchangeValues = (v: objectKV) => {
        setValuesForm((_values: objectKV) => {
            const { geolocalizacion, ...all } = _values ?? {};
            return Object.assign({}, v, { geolocalizacion });
        });
    };

    const _getIsValid = (v: boolean) => {
        if (props.getIsvalid) props.getIsvalid(v);
    };

    React.useEffect(() => {
        if (props.getValue) props.getValue(valuesForm);
    }, [valuesForm]);

    return (
        <Form validate={props.validate} fields={_fieldsForm ?? fieldsForm} getIsValid={_getIsValid} getOnchangeValues={_getOnchangeValues}>
            <div className="container-trip-plan-modal__info">
                <div className="view-description">
                    {isForm ?
                        <FormInputText style={{ width: 490 }} label="Descripcion" name="descripcion" /> :
                        <TextView label="Descripción" text={_dataPlan?.descripcion} />
                    }
                </div>
                <div className="view-route">
                    {isForm ?
                        <RoutesInput value={_dataPlan?.ruta} /> :
                        <TextView label="Ruta" text={_dataPlan?.ruta} />}
                </div>
                <div className="view-code">
                    {isForm ?
                        <FormInputText upperCase label="Codigo" name="code" /> :
                        <TextView label="Código" text={_dataPlan?.codigo} />
                    }
                </div>
                {props.id ? null : <div className="view-template">
                    {isForm ?
                        <FormInputAdvanced
                            style={{ width: 170 }}
                            fetchPolicy={"cache-first"}
                            name={"idtemplate"}
                            label="Plantilla"
                            pathTotalReg="planesviaje.totalreg"
                            fieldFilter="descripcion"
                            descripcion
                            orders={[{ type: "ASC", field: "descripcion" }]}
                            query={GET_TRIP_PLAN}
                            config={{ pathEmit: "id", pathOptions: "descripcion" }}
                            pathData="planesviaje.data" />
                        : null
                    }
                </div>}
            </div>
        </Form>
    );
});

/**
 * crea el componente de edicion de la informacion
 * @param props 
 */
export const Edit = React.memo((props: { data?: any, token?: string, validate?: boolean, loading?: boolean, id?: string | number, getIsvalid?: (v: boolean) => any, getValue?: (v?: objectKV) => any }) => {

    const client = useClientApolloGranada();

    const [dataForm, setDataForm] = React.useState<{ data: objectKV, details: Array<objectKV> }>({ data: {}, details: [] });

    const _onChangeDeatilsRow = (assignDetailforKey: (v: action) => any
        , values: {
            [k: string]: string | number | boolean;
        }, _fields?: Array<fields>, id?: string | number) => {
        const point = client.readFragment({ fragment: getCheckPointById, id: `PuestoControlInfo:${values.idpuestocontrol}` });
        // if(points?.puestoscontrolInfo?.data?.length) point = points?.puestoscontrolInfo?.data?.find((item:objectKV) => item.id === values.idpuestocontrol);
        if (!point || !id) return assignDetailforKey({ type: "ASSING_DETAIL_FOR_KEY", value: { value: values, id } });
        let { indicadoraltoriesgo,
            indicadorfisico,
            indicadorpernoctar,
            descripcion } = point;
        values.indicadoraltoriesgo = indicadoraltoriesgo;
        values.indicadorfisico = indicadorfisico;
        values.indicadorpernoctar = indicadorpernoctar;
        values.descripcion = descripcion;
        assignDetailforKey({ type: "ASSING_DETAIL_FOR_KEY", value: { value: values, id } });
    };

    React.useEffect(() => {
        if (props.getValue) props.getValue(dataForm);
    }, [JSON.stringify(dataForm)]);

    const _onChangeForm = (assignDetail: (v: action) => any, details?: Array<detail>) => {
        const _details = details?.map((item: detail, index: number) => {
            item.data.orden = index + 1;
            return item;
        });
        if (JSON.stringify(_details) !== JSON.stringify(details)) assignDetail({ type: "ASSING_DETAIL", value: [...(_details ?? [])] });
    };

    const onChange = (v: any) => {
        if (Array.isArray(v)) setDataForm((data: any) => {
            data.details = v;
            return { ...data };
        });
        else setDataForm((data: any) => {
            data.data = v;
            return { ...data };
        });
    };

    return (
        <div className="container-trip-plan-modal">
            <FormInfoTripPlan token={props.token} validate={props.validate} data={props.data} getValue={onChange} getIsvalid={props.getIsvalid} id={props.id} isForm />
            {props.validate && !dataForm?.details?.length ? <div style={{ fontSize: 13, color: "red" }}>Debe tener minimo un puesto de control</div> : null}
            <div className="container-trip-plan-modal__table">
                <div className="container-trip-plan-modal__table__title">
                    Puestos de control
                </div>
                <div className="container-trip-plan-modal__table__container">
                    <TableDetails
                        showArrows
                        onChangeForm={_onChangeForm}
                        assingValues={assingValues}
                        onChange={_onChangeDeatilsRow}
                        getValue={onChange}
                        fieldsForm={fieldsDeatils}
                        factoryEditables={FactoryEditable}
                        factory={Factory}
                        data={props.data ?? []}
                        fields={fieldsDetailTripPlanDetails} />
                </div>
                <div className="container-trip-plan-modal__table__totals">
                    <Total getValue={getValuesDetailsTripPlan} data={dataForm?.details} />
                </div>
            </div>
        </div>
    );
});

export const getValuesDetailsTripPlan = (v: any) => {
    if (!v?.length) return {};
    let hrs: number = 0, min: number = 0, distance: number = 0;
    v.forEach((item: any) => {
        if (+item?.duracion || +item?.duration === 0) {
            let tempmin = 0;
            tempmin = +item?.duracion % 60;
            hrs += (+item?.duracion - tempmin) / 60;
            min += tempmin;
        }
        else {
            hrs += +(item?.horas ?? 0);
            min += +(item?.minutos ?? 0);
        }
        distance += +(item?.distancia ?? 0);
        if (min > 60) {
            hrs += 1;
            min = min - 60;
        }
    });
    return { min, hrs, distance };
};

export const Total = (props: { getValue: (data: any) => ({ hrs?: number; min?: number; distance?: number }), data: any }) => {

    const [hrs, setHrs] = React.useState<number>();
    const [min, setMin] = React.useState<number>();
    const [distance, setDistance] = React.useState<number>();

    React.useEffect(() => {

        let { hrs, min, distance } = props.getValue(props.data);
        setDistance(distance);
        setHrs(hrs);
        setMin(min);

    }, [JSON.stringify(props.data)]);

    return (
        <>
            <div className="container-trip-plan-modal__table__totals__distance">
                <TextView label="Duración" text={`${hrs ?? 0} hrs ${min ?? 0} min`} />
            </div>
            <div className="container-trip-plan-modal__table__totals__time">
                <TextView label="Distancia (Km)" text={distance ?? 0} />
            </div>
        </>
    );
};

const assingValues = (data: objectKV, fields: Array<fields>, key: number) => {
    let min, hrs, duration = data?.duracion;
    if (+duration || +duration === 0) {
        min = +duration % 60;
        hrs = (+duration - min) / 60;
    }

    let _detail: detail = {
        data: {
            id: data.id,
            orden: data.orden ?? 0,
            idpuestocontrol: data?.idpuestocontrol ?? "",
            descripcion: data?.puestocontrol ?? "",
            distancia: data?.distancia ?? 0,
            horas: hrs ?? 0,
            minutos: min ?? 0,
            indicadorpernoctar: data?.indicadorpernoctar ?? false,
            indicadorfisico: data?.indicadorfisico ?? false,
            indicadoraltoriesgo: data?.indicadoraltoriesgo ?? false
        }, id: data.id ?? key
    };
    return _detail;
};

export const ControlPoint = () => {

    // const {data} = useQueryToSever({query:GET_CONTROL_POINTS, fetchPolicy:"cache-and-network"});
    const formatterInfo = (v: objectKV) => {
        return v.descripcion ? `${v.descripcion ?? ""}(${v.indicadorfisico ? "Fisico" : "Virtual"})` : null;
    };

    const assingOtherProps = (v: any) => {
        return { valuesDescription: v.descripcion };
    }
    return <FormInputAdvanced
        style={{ width: 170 }}
        assingOtherProps={assingOtherProps}
        name="idpuestocontrol"
        pathTotalReg="puestoscontrolInfo.totalreg"
        fieldFilter="descripcion"
        query={GET_CONTROL_POINTS}
        formatterInfo={formatterInfo}
        orders={[{ type: "ASC", field: "descripcion" }]}
        config={{ pathEmit: "id", pathOptions: "descripcion" }}
        pathData="puestoscontrolInfo.data"
    />
    // return <FormInputSelect
    // name="idpuestocontrol"
    // style={{width:170}}
    // data={[{id:1},{id:2}]}
    // confSelect={{pathEmit:"id",pathOption:"id" }}
    //  />
};

export const Duration = (props: { field: confTable; data: objectKV; }) => {
    const [_label, setLabel] = React.useState<string>("");
    React.useEffect(() => {
        let min = props.data?.minutos, hrs = props.data?.horas, duration = props.data?.duracion;
        if (+duration || +duration === 0) {
            min = +duration % 60;
            hrs = (+duration - min) / 60;
        }
        setLabel(min || min === 0 || hrs || hrs === 0 ? `${hrs ? hrs : 0} hrs ${min ? min : 0} min` : `0 hrs 0 min`);
    }, [props.data]);
    return <label>{_label ? _label : "NA"}</label>
};

export const FactoryEditable = (props: { field: confTable; data: objectKV; }) => {
    if (props.field?.field === "control_point") return <ControlPoint />;
    if (props.field?.field === "duration") return <div style={{ whiteSpace: "nowrap", display: "flex" }}><FormInputText name="horas" type="number" style={{ width: 40 }} />hrs <FormInputText name="minutos" type="number" style={{ width: 40 }} />min</div>
    if (props.field?.field === "distance") return <FormInputText name="distancia" type="number" style={{ width: 60 }} />
    return null;
};

export const Factory = (props: { field: confTable; data: objectKV; }) => {
    if (props.field?.field === "duration") return <Duration {...props} />
    return null;
};

