import React from "react";
import { Table } from "../../Componets/Basic/Table/Table";
import { Layout } from "../../Componets/General/Layout/Layout";
import { objectKV, action } from "../../utils/interface";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { queryCheckPoint as GET_CHECK_POINTS, fieldsTable } from "./CheckPoint.conf";
import { ModalOptionsCheckPoint, RemoveCheckPoint } from "./CheckPoint.options";
import { ButtonAdd } from "../../Componets/General/FormsUtils/Buttons.form";
import { FiltersOptions, parceOrders, createFilters, ExportExcel } from "../../Componets/General/UtilsView/Utils-view";

/**
 * pagination-fix-1 corresponde a machetazo para la paginacion
 */

export const CheckPoint = () => {

    const tableRef = React.useRef(null);

    let [id, setId] = React.useState<number>();
    const [visible, setVisible] = React.useState<boolean>(false);

    // const [data, setData] = React.useState<Array<any>>();

    const [loadData, { called, loading, data: dataRequest, refetch, fetchMore }] = useLazyQueryToSever({ query: GET_CHECK_POINTS });

    let [hiddenFilters, sethiddenFilters] = React.useState<boolean>();
    let [idSelected, setIdSelected] = React.useState<Array<number | string>>([]);

    const initial = {
        /**
         * estos valores tambien estan en el state pero no tiene un valor inicial
         */
        //data: es la info que se odtine al dar click en una columna de la tabla ,
        //filters: aqui se guarda los filtros,
        //orders: se guardan los valores del orden

        currentPage: 1,
        numReg: 20,
    };

    const reducer = (state: objectKV, action: action) => {

        switch (action.type) {
            case "CLICK_ROW":
                if (action.value?.id) {
                    setId(action.value?.id);
                    setVisible(true);
                }
                return Object.assign({}, state, { data: action.value });
            case "ADD_FILTERS":
                let filters = createFilters(action.value);
                if (JSON.stringify(filters) === JSON.stringify(state.filters)) return state;
                return Object.assign({}, state, { filters, currentPage: 1 });
            case "ADD_CURRENT_PAGE":
                return Object.assign({}, state, { currentPage: action.value });
            case "SET_ORDER":
                return Object.assign({}, state, { orders: action.value });
            case "SET_NUM_REG":
                return Object.assign({}, state, { numReg: +action.value ? +action.value : 10, currentPage: 1 });
            default:
                return state;
        }
    };

    const [state, dispatch] = React.useReducer(reducer, initial);

    React.useEffect(() => {
        //esto es algo asi como un machetazo
        setIdSelected([]);
        let { filters, numReg, currentPage, orders } = state;
        //Se parcean los campos de orden que traen una estructura diferente de el state
        orders = parceOrders(orders);

        if (filters && numReg && currentPage) {
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            loadData({ variables: { filters, page: currentPage, fields: numReg, orders } })
        }
    }, [state.filters, state.numReg, state.currentPage, JSON.stringify(state.orders), fieldsTable]);

    const _valuesSelected = (v: Array<string | number>) => {
        if (v[0] === Infinity && dataRequest?.puestoscontrolInfo.data?.length) {
            let ids = dataRequest.puestoscontrolInfo.data.map((item: objectKV) => item && !item.indicadorfisico ? item.id : null).filter(Boolean);
            setIdSelected([...ids]);
        }
        else {
            if (dataRequest?.puestoscontrolInfo.data?.length) {
                let _data = dataRequest?.puestoscontrolInfo.data?.filter((_item: objectKV) => !_item?.indicadorfisico);
                let value = v.filter((item: string | number) => _data.find((_item: objectKV) => +_item.id === +item));
                setIdSelected([...value]);
            }
        }
    };

    const resetFilters = () => {
        let el: any = tableRef?.current;
        if (el?.resetFilters) {
            el?.resetFilters()
        }
    };

    const _close = () => {
        setVisible(false);
    };

    const _onClickAdd = () => {
        setVisible(visible => {
            setId(undefined);
            return !visible;
        });
    };

    const refresh = () => {
        if (refetch) refetch();
        setIdSelected([]);
    };

    const _getIsSelectable = (data: objectKV) => {
        return data?.indicadorfisico;
    };

    const filtersExcel = React.useMemo(() => {
        let { filters } = state ?? {};
        if (filters) return filters;
        return [];
    }, [JSON.stringify(state)]);

    return (
        <React.Fragment>
            <ModalOptionsCheckPoint refresh={refresh} id={id} visible={visible} close={_close} />
            <Layout
                title="Puestos de control"
                goToPage={dispatch}
                right={<FiltersOptions
                    filters={state.filters}
                    reset={resetFilters}
                    hiddenFilters={sethiddenFilters}
                    lastButtom={<ExportExcel filters={filtersExcel} order={state?.orders} pagination={{ fields: state?.numReg, page: state?.currentPage }} queryId={4} name="PUESTOS_DE_CONTROL_" fields={["descripcion", "contacto", "nombrepropietario", "indicadoraltoriesgo", "indicadorpernoctar", "disponibilidad", "indicadorfisico"]} />}
                    hidden={!!hiddenFilters} />}
                pageCurrent={state.currentPage}
                optionMore={!idSelected?.length ? <ButtonAdd onClick={_onClickAdd} /> : <RemoveCheckPoint refresh={refresh} ids={idSelected} />}
                //pagination-fix-1
                total={dataRequest?.puestoscontrolInfo?.totalreg}
                numReg={state.numReg}>
                <Table
                    ref={tableRef}
                    getIsSelectable={_getIsSelectable}
                    // factoryFilters={FactoryFilters}
                    getFilters={dispatch}
                    hiddenFilters={hiddenFilters}
                    isSelectable
                    idSelected={idSelected}
                    valuesSelected={_valuesSelected}
                    data={dataRequest?.puestoscontrolInfo?.data}
                    clickRow={dispatch}
                    loading={(loading || !called)}
                    orderClick={dispatch}
                    orderableField={state.orders}
                    fields={fieldsTable} />
            </Layout>
        </React.Fragment>
    );
};