import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { objectKV } from "../../utils/interface";

interface  propsMutation {
    mutation:string;
    [v:string]:any
}


/**
 * etse es un Hook que permite lanzar las mutaciones.
 * @param props e
 * @returns  data:any,loading:boolean,error:any,called
 */
export const useMutationServer = (props:propsMutation):[(v:any) => any, {data?:objectKV, loading:boolean, error:any, called:boolean}] => {
    const {mutation, ...all} = props;
    const [sendData, {data, loading, error, called}] = useMutation(gql`${mutation}`, {...all});
    return [sendData, {data, loading, error, called}];
};