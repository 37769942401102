import React from "react";
import { useUser } from "../../Componets/hook/user-hook";
import { Modal } from "../../Componets/Basic/Modal/Modal";
import { ModalAdapter, ModalConfirmationRemove } from "../../Componets/General/FormsUtils/FormModal";
import "./User.scss";
import { 
    mutationCreateUser as CREATE_USER,
    mutationUpdateUser as UPDATE_USER,
    mutationTraficControllerCreate as CREATE_TRAFIC_CONTROLLER,
    mutationRemoveUser as DEL_USER,
    filedsForm,
    querygetTraficOne,
    getDriversOne,
    resetPassword
} from "./Users.conf";
import { useNotification } from "../../Componets/hook/notification-hook";
import { useMutationServer } from "../../Componets/hook/mutation-hook";
import { NotificationSucces, NotificationError } from "../../Componets/General/FormsUtils/Notification.fom";
import { objectKV } from "../../utils/interface";
import { ButtonRemove } from "../../Componets/General/FormsUtils/Buttons.form";
import { Form, FormInputText, FormSwitch } from "../../Componets/Basic/Form/Form";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import TextView from "../../Componets/Basic/Text/TextView";
import { BooleanView } from "../../Componets/Basic/Text/BooleanView";
import { ImageViwer, ImageViwerByKeys } from "../../Componets/Basic/Image/ImageViwer";
import { fields } from "../../Componets/Helpers/Form/Form-context";
import { destructor } from "../../utils/utils";
import Button from "../../Componets/Basic/Button/Button";


export const ModalOptionsUser  = (props:{tab?:string;id?:string | number,refresh?:(v?:any) => any, visible:boolean, close:(v?:any) => any, onClickSave?:(v?:any) => any}) => {

    const { user } = useUser();

    const [editableOnly, setEditableOnly ] = React.useState<boolean>(false);

    const {addNotification} = useNotification();
    const [isValid, setIsvalid] = React.useState<boolean>(false);
    const [dataForm, setDataForm] = React.useState<objectKV>();
    const [validate, setValidate] = React.useState<boolean>(false);

    const onCompleted = () => {
        if(props.refresh) props.refresh();
    };

    const [loadDataTrafic, {data:traficData,loading:loadingTrafic}] = useLazyQueryToSever({query:querygetTraficOne,fetchPolicy:"cache-first"});
    const [loadDataDriver, {data:driverData,loading:loadingDriver}] = useLazyQueryToSever({query:getDriversOne,fetchPolicy:"cache-first"});

    const [createUser, {loading:loadingCrate, data:dataCreate, error:errorCreate}] = useMutationServer({mutation:CREATE_USER});
    const [createTrafic, {loading:loadingCrateTrafic, data:dataCreateTrafic, error:errorCreateTrafic}] = useMutationServer({mutation:CREATE_TRAFIC_CONTROLLER,onCompleted});

    const [updateUser, {loading:loadingUpdate, data:dataUpdate, error:errorUpdate}] = useMutationServer({mutation:UPDATE_USER,onCompleted});

    React.useEffect(() => {
        if(props.id && user?.idcompany && props.visible) {
            let _id = +(props.id ?? 0);
            if(props.tab === "TRAFIC" && _id)loadDataTrafic({variables:{id:_id}});
            if(props.tab === "DRIVER" && _id)loadDataDriver({variables:{id:_id}});
            setEditableOnly(false);
        } else {
            setEditableOnly(true);
        }   
    }, [props.id,props.visible, user]);

    React.useEffect(() => {
        if(dataUpdate || dataCreate || dataCreateTrafic) {
            if(!loadingCrate || !loadingUpdate || loadingCrateTrafic) {
                if(props.refresh) props.refresh();
                props.close();
                addNotification({element:<NotificationSucces message="procesos exitoso" />,delay:3000});
            }
        }
    }, [dataCreate, dataUpdate, loadingUpdate,loadingCrate, loadingCrateTrafic, dataCreateTrafic]);

    React.useEffect(() => {
        if(errorUpdate || errorCreate){
            addNotification({element:<NotificationError message={errorCreate?.message ?? "Ocurrio un error"} />,delay:3000});
        }
    }, [errorCreate,errorUpdate]);

    const _clickSave = () => {
        setValidate(true)
        // console.log(isValid,dataForm);
        if(!isValid || !dataForm){
            setValidate(true);
            return;
        }
        const { idusuario, ...tempData} = dataForm;
        let _id = +(idusuario ?? props.id ?? 0);
        if(_id) {
            updateUser({variables:{controladorTrafico:{...tempData,id:""+_id},idUser:_id}});
        } else {
            createTrafic({variables:{controladorTrafico:{...tempData}}});
        }
    };

    const _clickOutside = () => {
        props.close();
    };

    const assingDataForm = (v?:objectKV) => {
        setDataForm(v);
    };

    const assingValid = (v:boolean) => {
        setIsvalid(v);
    };

    return (
        <Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
            <ModalAdapter 
            showOptionsButton={props.tab === "TRAFIC" && user?.roll === 3}
            hiddenOptionsButtonInEdit
            noEdit={props.tab === "DRIVER"} 
            close={props.close} title={props.tab === "DRIVER"?"Conductor":props.tab === "TRAFIC"?"Controlador de trafico":"Usuarios"} 
            editableOnly={editableOnly} 
            clickSave={_clickSave} 
            loading={loadingCrate || loadingUpdate || loadingCrateTrafic}
            option={(_props?:any) => {
                return <ButtonsOptions {..._props} id={traficData?.controladorTraficoInfo?.idusuario}/>;
            }}
            >
                {props.tab === "DRIVER"?
                <ContentModalDriver key="View-driver" data={driverData?.usuarioConductoreInfo} loading={loadingDriver}/>:
                <ContentModal key="View" data={traficData?.controladorTraficoInfo} loading={loadingTrafic}/>}
                <ContentModal key="EDIT" getValue={assingDataForm} getIsvalid={assingValid} data={editableOnly?null:traficData?.controladorTraficoInfo} validate={validate} isEditable/>
            </ModalAdapter>
        </Modal>
    );
};

const ButtonsOptions = React.memo((props?:any) => {
    
    const [loadData, {data,loading}] = useMutationServer({mutation:resetPassword});

    const {addNotification} = useNotification();

    React.useEffect(() => {
        if(!loading && data?.resetPassword){
            addNotification({element:<NotificationSucces message="procesos exitoso" />,delay:3000});
        }
    },[data,loading]);

    const _onClick = React.useCallback(() => {
        loadData({variables:{id:props.id}});
    },[props.id]);

    return <Button label={`${loading?"Enviando ....":"Restablecer contraseña"}`} onClick={_onClick}/>
});

interface propsContentModal {
    loading?:boolean;
    isEditable?:boolean;
    data?:objectKV;
    validate?:boolean;
    getIsvalid?:(v:boolean) => any;
    getValue?:(v?:objectKV) => any;
}

export const ContentModal = React.memo((props:propsContentModal) => {

    const el  = React.useRef(null);

    const { loading, isEditable, data, validate, getIsvalid,getValue } = props;

    React.useEffect(() => {
        filedsForm.forEach((item:fields) => {
            let _el:any = el?.current;
            let _value:any = undefined;
            if(_el?.assingValue && item.path) {
                _value = destructor(item.path,data ?? {});
            }
            _el.assingValue(_value,item.name);
            item.value = _value;
        });
    },[data]);

    return (
        <Form fields={filedsForm} ref={el} validate={validate} getIsValid={getIsvalid} getOnchangeValues={getValue}>
            <div className="conatiner-user-trafic">
                <div className="conatiner-user-trafic__name">
                    {isEditable?
                    <FormInputText style={{width:400}} name="nombre" label="Nombre*"/>
                    :<TextView label="Nombre" text={data?.nombre}/>}
                </div>
                <div className="conatiner-user-trafic__mail">
                    {isEditable?
                    data?.correo?
                    <TextView label="Correo" text={data?.correo}/>:
                    <FormInputText style={{width:400}} name="correo" label="Correo*"/>
                    :<TextView label="Correo" text={data?.correo}/>}
                </div>
                <div className="conatiner-user-trafic__phone">
                    {isEditable?
                    <FormInputText  name="telefono" label="Teléfono*"/>
                    :<TextView label="Teléfono" text={data?.telefono}/>}
                </div>
                <div className="conatiner-user-trafic__active">
                    {isEditable?
                    <FormSwitch name="indicadoractivo" label="Activo"/>:
                    !loading && <BooleanView label="Activo" value={data?.indicadoractivo}/>}
                </div>
            </div>
        </Form>
    );
});

export const ContentModalDriver = React.memo((props:propsContentModal) => {

    const { data } = props;

    return (
        <div className="conatiner-user-driver">
            <div className="conatiner-user-driver__photo">
                <ImageViwerByKeys uri={data?.foto} className="image-driver" />
            </div>
            <div className="conatiner-user-driver__name">
                <TextView label="Nombre" text={data?.nombre}/>
            </div>
            <div className="conatiner-user-driver__doc">
                <TextView label="Documento" text={data?.numerodocumento}/>
            </div>
            <div className="conatiner-user-driver__mail">
                <TextView label="Correo" text={data?.correo ?? "NA"}/>
            </div>
            <div className="conatiner-user-driver__phones">
                <TextView label="Contactos" text={data?.telefono}/>
            </div>
        </div>
    );
});

export const RemoveUser = (props:{ids:Array<number | string>, refresh?:(v?:any) => any}) => {

    const [visible, setVisible ] = React.useState<boolean>(false);

    const [removeData, {loading, data,error}] = useMutationServer({mutation:DEL_USER});

    React.useEffect(() => {
        if(data) {
            setVisible(false);
            if(props.refresh) props.refresh();
        }
    }, [data]);

    const _onClick = () => {
        setVisible(true);
    };

    const _close = () => {
        setVisible(false);
    };

    const _accept = () => {
        let tempId = props.ids.map((item) => +item? +item:null).filter(Boolean);
        if(tempId.length) removeData({variables:{ids:tempId}});
    };

    return (
    <React.Fragment>
        <ModalConfirmationRemove loading={loading} visible={visible} close={_close} numberReg={props.ids?.length ?? 0} accept={_accept} />
        <ButtonRemove onClick={_onClick} />
    </React.Fragment>
    );
};

