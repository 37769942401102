import { getStoreInfo } from "../../../utils/store";

/** 
 * @description esta funcion recibe una key de un un archivo y retorna la url firmada del archivo
*/
export const getUriFile = async (key:string) => {
    let session = getStoreInfo();
    if(!session.token || !session.idcompany || !process.env.REACT_APP_ID) return new Error("No se pudo extraer la url");
    let headers = new Headers();

    headers.append("Authorization",session.token);
    headers.append("idapp",process.env.REACT_APP_ID);
    headers.append("idempresa",session.idcompany.toString());
    headers.append("Accept","application/json, text/plain, */*");
    let res:any = await fetch( `${process.env.REACT_APP_URL}/api/v1/fileUri?key=${key}`,{headers})
    .then((_res:any) => _res.json())
    .catch((err:any) => ({error:true}));
    if(res.error) return new Error("No se pudo extraer la url");
    return res?.data;
};

/** 
 * @description etsa funcion recibe uan uri a la cualse hara el PUT con el file
 * @version 1.0
*/
export const uploadImage = async (path:string, file:any,type?:string) => {
    let headers = new Headers();
    headers.append("Accept","application/json, text/plain, */*");
    if( type ) headers.append("Content-Type",type);
    let body = file;
    let res = await fetch(path,{
        method:"PUT",
        headers,
        body
    })
    .then((_res:any) => _res.json())
    .catch((err:any) => {
        return ({error:true});
    });
    return res;
}; 