import React from "react";
import "./Forms-report.scss";
import { objectKV, action, confTable } from "../../../utils/interface";

import { TableDetails } from "../../../Componets/Basic/Table/Table-details";
import { detail, keySave } from "../../../Componets/Helpers/Table-details/Table-details-context";
import { fields } from "../../../Componets/Helpers/Form/Form-context";
import { FormInputText } from "../../../Componets/Basic/Form/Form";
import { useMutationServer } from "../../../Componets/hook/mutation-hook";
import { useLazyQueryToSever } from "../../../Componets/hook/query-hook";
import { useNotification } from "../../../Componets/hook/notification-hook";
import { NotificationSucces } from "../../../Componets/General/FormsUtils/Notification.fom";

const mutationInstruction = `
mutation createObservacionesViaje($observacionesviaje:ObservacionesViajeInput){
    createObservacionesViaje(observacionesviaje:$observacionesviaje){
        id
    }
}
`;

const mutationInstructionUpdate = `
mutation updateObservacionesViaje($observacionesviaje:ObservacionesViajeInput,$id:Int){
    updateObservacionesViaje(observacionesviaje:$observacionesviaje,id:$id){
        id
    }
  }
`;


const queryInstructions = `
query obs($filters:[FilterInput]){
    observacionesviaje(filters:$filters,orders:[{field:"id",type:DESC}]){
      data{
        id
        idusuario
        idviaje
        usuarionombre
        ultimovisto
        observacion
        fecha
      }
    }
  }
`;

export const fieldsDetailTripPlanDetails:Array<confTable> = [
    {
      label:"Observaciones",
      field:"observacion",
      type:"TEXT",
      path:"observacion",
      isEditable:true
    },
    {
      label:"Fecha",
      field:"fecha",
      type:"DATE-TIME",
      path:"fecha"
    },
    {
        label:"Usuario",
        field:"usuarionombre",
        type:"TEXT",
        path:"usuarionombre"
    }
  ];

export const fieldsDeatils:Array<fields> = [
    {name:"observacion",validator:[true]},
    {name:"fecha"},
    {name:"usuarionombre"}
  ];

const assingValues = (data: objectKV, fields: Array<fields>, key: number) => {
    return {data,id:data.id ?? key};
};

export const InstructionsForm = (props:{id?:number | string, close:() => any,data:any}) => {
    
    const {addNotification} = useNotification();

    const [loadData,{data:instructionsRes, refetch,loading, called}] = useLazyQueryToSever({query:queryInstructions,fetchPolicy:"network-only"});

    const onCompleted = (res:any) => {
        console.log(444,res)
        if(props.id&&(res?.createObservacionesViaje?.id||res?.updateObservacionesViaje?.id)) loadData({variables:{filters:[{field:"idviaje",value:props.id+""}]}});
    };

    const [createInstruction, {loading:loadingCrate, data:dataCreate, error:errorCreate}] = useMutationServer({mutation:mutationInstruction,onCompleted});
    const [updateInstruction, {loading:loadingUpdate, data:dataUpdate, error:errorUpdate}] = useMutationServer({mutation:mutationInstructionUpdate,onCompleted});

    React.useEffect(() => {
        if(props.id) {
            loadData({variables:{filters:[{field:"idviaje",value:props.id+""}]}});
            loadingMessage();
        }
    },[props.id]);

    const _onChangeForm = (assignDetail: (v: action) => any,details?: Array<detail>) => {
        // const _details = details?.map((item:detail, index:number) => {
        //     item.data.orden = index + 1;
        //     return item;
        // });
        // if(JSON.stringify(_details) !== JSON.stringify(details)) assignDetail({type:"ASSING_DETAIL", value:[...(_details ?? [])]});
    };

    const loadingMessage = React.useCallback(() => {
        if(addNotification) {
            addNotification({element:<NotificationSucces message={`Cargando...`} />,delay:2000});
        }
    },[addNotification]);

    const _onChangeDeatilsRow = (assignDetailforKey: (v: action) => any
    , values: {
        [k: string]: string | number | boolean;
    },_fields?:Array<fields>,id?: string | number) => {
        if(`${id}`.includes(keySave)){
            if(props.id&&values.observacion) {
                createInstruction({variables:{observacionesviaje:{observacion:values.observacion,idviaje:props.id}}});
                loadingMessage();
            }
        } else {
            if(values.observacion) {
                updateInstruction({variables:{observacionesviaje:{observacion:values.observacion},id}});
                loadingMessage();
            }
        }
    };

    const onChange = (v:any) => {

    };

    return (
        <div className="form-instructions general-form">
            <div className="name-report form-instructions__title">
                Instruciones comerciales
            </div>
            <div className="form-instructions__table">
                <TableDetails 
                hiddenDelete
                onChangeForm={_onChangeForm}
                assingValues={assingValues} 
                onChange={_onChangeDeatilsRow}  
                getValue={onChange}
                fieldsForm={fieldsDeatils} 
                factoryEditables={FactoryEditable} 
                factory={Factory} 
                data={instructionsRes?.observacionesviaje?.data ?? []} 
                fields={fieldsDetailTripPlanDetails}  />
            </div>
        </div>
    );
};

export const FactoryEditable = (props: {field: confTable;data: objectKV;}) => {
    if(props.field?.field === "observacion") return <FormInputText name="observacion" style={{width:400}}/>
    return null;
};

export const Factory = (props: {field: confTable;data: objectKV;}) => {
    return null;
};
