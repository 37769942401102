import React from "react";
import "./AdvancedSearch.scss";
import { objectKV, error } from "../../../utils/interface";
import { destructor } from "../../../utils/utils";
import { useInput } from "../../hook/input-hook";
import { useLazyQueryToSever } from "../../hook/query-hook";
import { ClickAwayListener } from "../ClickAwayListener/Click.away-listener";
import ReactDOM from "react-dom";
import usePortal from "../../hook/portal-hook";

interface AdvancedSearchProps {
    /** indica si hay algun error */
    error?:error;
    /** esta funcion se dispara cada cambio, y recibe true el evento es focus o false si es blur */
    focused?:(v:boolean) => any;
    /**
     * es el label que se mostrara en el input
     */
    label?:string;

    /**
     * es una cadena que equivale al valor por defect que se pasa desde afuera, importante este valor no funciona si no se pasa como 
     * props un valor de fomulario ejemplo props:{valuesDescription:"ninja de konoha", value:77}, seria el id en eset caso
     */
    valuesDescription?:string;

    /**
     * Se usa para pasar valores por lo que se decea ordenar
     */
    orders?:Array<objectKV>;

    /**
     * es la data que se mostrara en el advanced
     */
    data?:Array<any>;

    //est el campo por el cual se va filtrar 
    fieldFilter:string;

    /** */
    searchFuncion?:(v:string, _fields?:number) => Promise<Array<any>>;
    /**
     * esta es la configuracion del advanced search
     */
    config:{
        pathEmit:string | Array<string>;
        pathOptions?: string | Array<string>;
        pathMultiple?:Array<string | Array<string>>;
        token?:string;
    };

    //representa el path de la data 
    pathData:string;

    //representa el path de la data 
    pathTotalReg:string;

    /**
     * Esta funcion permite formattear la informacion que se mostrara en el select 
     */
    formatterInfo?:(v:objectKV) => string ;

    query:string;

    //esla la cantidad actual de registros
    fields?:number;

    //Es la cantidad de registros que cumple con la condicion.
    totalRegs?:number;

    /**
     * indica si la data esta cargando
     */
    loading?:boolean;

    //Son filtros que se desea tenga el advanced search por defecto 
    filters?: Array<{ field: string, value: string | number }>;
    
    /**
     * determina las politcas de graphql 
     */
    fetchPolicy?:"cache-and-network" | "network-only" | "cache-first" | "cache-only";

    /**
     * Esta bandera se usa para indicar que las opciones deben aparecer sobre el componente y no debajo, sin importar posicion en pantalla 
     */
    showTop?:boolean;

    [v:string]:any;
}

interface option  {
    description:string;
    value:any
}

/**
 * @description este es el componente de advancedsearch
 * @version 1.0
 */
export const AdvancedSearchBasic = React.memo((props:AdvancedSearchProps) => {

    /** Variable para refernciar el input */
    const el = React.useRef(null);
    /**variable para refernciar la base del opriosn  */
    const elBase = React.useRef(null);

    let timeId;

    /** Estado para administar el focus del input*/
    const [focused, setFocused] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);

    /**Estado (hook) para gestionar el input */
    const {value:valueInput, setValue:setValueInput, valueKV, bind} = useInput("");

    /** Estado para gestionar las opciones */
    const [data, setData] = React.useState<Array<option>>([]);

    /**
     * @description maneja el evento de entradas, validamos que no se pueda ingresar el caracter de la comilla, para disminuir la posibilidad de ataque de inyeccion
     * @param e 
     */
    const _onKeyPress = (e:objectKV) => {
        //Evitamos la posible entrada de comilla con el fin de evitar el inyection sql
        if((e.key+"").match(/'/g)) {
            e.preventDefault();
        }
        //Capturamos el enter para llamar el refresh
        if (e.key === 'Enter') {
            refresh(valueInput ?? "");
        }
    };

    /**@description se usa para lanzar el lazy load */
    const lazyLoad = () => {
        //Agrega 20 nuevos valores
        let _fields = (props.fields  ?? 0) + 20;
        //SE valida si los registros si se debe aplicar un lazy o no 
        if(+(props.totalRegs ?? 0) > (props.fields  ?? 0))
        //Se piden mas registros
        if(props.searchFuncion) props.searchFuncion(valueInput ?? "",_fields);
    };

    /**@description pide los datos nuevamente */
    const refresh = (v:string) => {
        //Se llam el rest si no hay un valor en el fomulario
        if(!v && !props.value) {
            reset();
        }
        if(props.searchFuncion) props.searchFuncion(v);
    };

    /**@description crea un efecto focus */
    const _onClickForFocus = () => {
        const _el:any = el.current;
        //Se lanza el focus del componente input
        if(_el?.focus) _el.focus();
    };

    /**@description controla el evento focus */
    const _onFocus = () => {
        //Se cambia la bandera a focused a true 
        setFocused(true);
        setVisible(true);
        //Se llama la funcion focus
        if(props.onFocus) props.onFocus();
    };

    /**@description gestiona el evento blur del input  */
    const _onBlur = () => {
        //Se quita la bandera focused
        setFocused(false);
        /**
         * Si no existe un value en el input se llama el reset del campo en el foemulario
         * esto con el fin de que cuando el input este vacio tambien el valor en el formulario
         */
        if(!valueInput) {
            reset();
        }
        //Se llama el metodo blur
        if(props.onBlur) props.onBlur();
    };
    
    React.useEffect(() => {
        //Si el valor en el input cambia llammos en refresh
        refresh(Object.values(valueKV ?? "")[0]);
    }, [valueKV]);

    React.useEffect(() => {
        //Se asigna el valor a la descripcion si es que existe una y si es que el formulario tiene
        //un valor NOTA:uno no depende del otro por lo que es necesario ser cuidadoso o podria haber dos valores que no
        //coinciden uno en la descripcion y otro como valor del fromulario
        if(props.valuesDescription && props.value) setValueInput(props.valuesDescription);
    }, [props.valuesDescription,props.value]);

    React.useEffect(() => {
        /**
         * Asigna un value al input cuando en el foemulario hay un id o un value
         */
        changeValue(props.value, data);
    }, [props.value, data]);    
    
    React.useEffect(() => {
        //Elimina el valor en el input si que no existe un valor en el formulario
        if(!props.value) setValueInput("");
    }, [props.value]);

    React.useEffect(() => {
        /**Destructuring de la data */
        let {pathEmit,token,pathMultiple,pathOptions} = props.config;
        const { formatterInfo } = props;

        /**
         * Se formatea y asigna la data de manera que pueda ser mostrada en las opciones
         */
        let dataXtracted = xtractor({data:props.data ?? [],pathEmit,token,pathMultiple,pathOptions,formatterInfo});
        setData(dataXtracted);
    }, [props.data]);

    /**@description cambia un valor v, cuando se le da un click a una de las opciones */
    const _onClickOption = (v:string) => {
        changeValue(v, data);
        setVisible(false);
    }; 

    /**@description  busca el valor v en _data y la assigna*/
    const changeValue = (v:string, _data:Array<option>) => {
        //Se valida si v existe 
        if(!v) return;
        //Se busca el v en _data
        let temp = _data.find((item:option) => {
            return item.value === v || item.description === v;
        });
        //Se assigna el valor, si existe.
        if(temp?.description) {
            setValueInput(temp?.description);
        }
        //Se reportan los cambios si existen
        if(props.onChange && temp?.value) props.onChange({target:{value:v, name:props.name ?? "advanced"}});
    };

    /**
     * @description Obtiene la informacion de la posicion del componente en el documento
     */
    const getParentBoundsInfomation = () => {
        const tempEl:any = el?.current;
        if(tempEl?.getBoundingClientRect) {
            const allRest = JSON.stringify(tempEl?.getBoundingClientRect());
            return Object.assign({},JSON.parse(allRest));  
        } 
        return null;
    };

    /**@description reinicia los valores */
    const reset = () => {
        //Se vacea el input de texto
        setValueInput("");
        //Se envia valores vacios al fomulario
        if(props.onChange )props.onChange({target:{value:"", name:props.name ?? "advanced"}});
    };

    const clickOutside = () => {
        setVisible(false);
    }

    return (
            <div className="advanced-search-container" onClickCapture={_onFocus} >
            {props.label?<label style={{color:focused? "var(--input-border-focus)":""}} className={`${props.error?.error?"error":""}`}>{props.label}</label>:null}
                <div  ref={elBase} className="base-options"/>
            <div className="advanced-search-container__input" style={{...{borderColor:focused? "var(--input-border-focus)":props.error?.error?"red":""},...props.style}} onClick={_onClickForFocus}>
                <i className="icon-lupa"
                style={{color: focused? "var(--input-border-focus)":props.error?.error?"red":""}}
                />
                <input 
                ref={el}
                onBlurCapture={_onBlur}
                onKeyPress={_onKeyPress}
                className="input-advance-search"
                style={{borderColor: props.error?.error?"red":""}}
                {...bind}
                />
                 <i onClick={reset} className="icon-close"/>
            </div>
            <OptionsView clickOutside={clickOutside} visible={visible} showTop={props.showTop} onLazyLoaing={lazyLoad} getParentBoundsInfomation={getParentBoundsInfomation}>
                {
                    [...data.map((item:any, index:number) => <Option key={item.id ?? index } data={item} onClick={_onClickOption}/>)
                    ,<div style={{height:20}} key={"loading"}>{props.loading?"Cargando...":!data.length?"No hay datos":""}</div>]
                }
            </OptionsView>
            {props.error?.error?<label className="error">{props.error?.message ?? "Error"}</label>:null}
        </div>
    );
});


interface OptionsViewProps {
    children?: JSX.Element[] | JSX.Element;
    getParentBoundsInfomation?:()=> any;
    visible?:boolean;
    onLazyLoaing?:() => any; 
    clickOutside?:(v?:any) => any;
    showTop?:boolean;
};
/**
 * @description este es el componente que mostrara las opciones del advanced search 
 * @version 1.1 se agregaron los limites del padre
 * @todo Convertir el componente, para que identifique el click fuera de si mismo
 *   */
export const OptionsView = (props:OptionsViewProps) => {  
    
    const el = usePortal("select-root");    

    /**Estado que administra si el componente es visible */
    const [visible, setVisible] = React.useState<boolean>(false);
    /**Se almacena los valores en los que se deben posicionar el componente y posibles estilo que sean dependientes */
    const [bounds, setBounds ] = React.useState<{top?:number,bottom?:number,[k:string]:any}>();

    const calculatorPosition = (v:objectKV) => {
        //Este valor debe ser igual al padding dos veces aggregado en el scss
        let padding = 10;
        //Se analiza la posible posicion del componente, ya que si esta muy cerca al final del documento no debe poner las opciones 
        //debajo estas deben aparecer encima.
        if(props.showTop && v.top) return ({bottom:335-v.top,borderRadius:"5px 5px 0px 0px"});
        if((window.innerHeight - (v.bottom??0))<=150 ) return ({top:v.bottom-190,left:v.left-20,borderRadius:"5px 5px 0px 0px"});
        else return ({borderRadius:"0px 0px 5px 5px",top:v.bottom,left:v.left-20});
    };

    React.useEffect(() => {
        if(props.visible) {
            /**Se capturan los limites del padre */
            const _bounds = props.getParentBoundsInfomation? props.getParentBoundsInfomation():null;
            /**Se calcula la posicion que debe tomar las Options y se asignan  */
            let posicion = calculatorPosition(_bounds);
            setBounds(posicion);
            //Se cambia la bandera de visible a true
            setVisible(true);
        } else setVisible(false);

    }, [props.visible]);

    /**Controla el scroll */
    const _handleScroll = (e:any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && props.onLazyLoaing) {
            props.onLazyLoaing();
         }
      }
      return visible? ReactDOM.createPortal(
        <div>
          <div style={{...(bounds??{})}} className={`options-view `}>
            <ClickAwayListener clickOutside={props.clickOutside}>
                <div onScrollCapture={_handleScroll} className="options-view__scroll" >
                    {props.children}
                </div>
            </ClickAwayListener>
        </div>
        </div>,el):null;
};

/**
 * @description esta funcion crea el componente option que se renderiza en el advanced search 
 * @param props 
 * @version 1.0
 * @todo mejorar presentacion
 */
export const Option = (props:{data:option, onClick:(v:any) => any}) => {

    /**Controla el evento click sobre la opcion */
    const _onClick = () => {
        props.onClick(props.data.value ?? props.data.description);
    };

    return <div className={`option-container`} onClick={_onClick}>
        {/* <div className={`option-container__click`} /> */}
        <label >{props.data.description}</label>
    </div>
};

/**
 * @description Este es una represnetacion decorada del advanced search, para agregar el lazy
 * @version 1.0
 */
export const AdvancedSearch = React.memo((props:AdvancedSearchProps) => {

    /**Estado que controla el total de registros */
    const [totalReg, setTotalReg] = React.useState<number>(0);
    /**Estado que controla la cantidad de regitros por vez */ 
    const [fields, setFields] = React.useState<number>(0); 

    /**Controla el query que pedira la data al el api */
    const [loadData,{data, refetch,loading, called}] = useLazyQueryToSever({query:props.query,fetchPolicy:props.fetchPolicy});

    React.useEffect(() => {
        /**Se obtine el la cantidad de registros cada vez que cambia la data, esto suando el path totalreg que viene en la props */
        setTotalReg(destructor(props.pathTotalReg,data) ?? 0);
    },[data]);

    /**
     * @description Esta funcion se encarga de refrescar la data del advanced search 
     * @param v valor a filtar
     * @param _fields cantidad de registros
     */
    const refresh = async (v:string, _fields?:number ) => {
        try {
            let res:any;
            //Se obtinen la cantidad de de registros que se desean pedir, por defecto 20
            let numFields = _fields ?? 20;
            //Se Crean las variables con os filtros y la cantidad de registros 
            let variables = {filters:[{field:props.fieldFilter,value:v ?? "" }],fields:numFields,page:1};
            if(props.orders?.length) variables = Object.assign({},variables,{orders:[...props.orders]});
            //Se obtiene los filtros que se deben usar siempre estos vienen en las props, por defecto []
            let tempPropsFilters = props.filters??[];
            //Si es que existe algun filtro por defecto entonces se aggregan ala s variables
            if(tempPropsFilters?.length) variables = Object.assign({},variables,{filters:[...variables.filters,...tempPropsFilters]});
            //Si las variables estan nulas entonces se evita hacer la peticion ya que es necesrio que estan existan 
            if(!Object.keys(variables).length) return [];
            //Si ya el query fue llamado y existe la propiedad refresh entonces se feresca el query
            if(called && refetch) res = await refetch(variables);
            else loadData({variables});
            //Se assigna el numero de registros, esto puede ser un numero mayor a la realiadad, es util para control
            setFields(numFields);
            return [];
        } catch (error) {
            return [];
        }
    };
    return <AdvancedSearchBasic {...props} totalRegs={totalReg} fields={fields} data={destructor(props.pathData,data)} loading={loading} searchFuncion={refresh} />
});

/**
 * @description esta funcion estrae la data para renderizar, en una array de clave valor con descripcion y valor 
 * @param v 
 * @version 1.0
 */
export const xtractor = (v:{formatterInfo?:(v:objectKV) => string,data:Array<objectKV>, pathEmit:string | Array<string>, pathOptions?: string | Array<string>,pathMultiple?:Array<string | Array<string>>, token?:string}):Array<option> => {
    
    /** */
    let value:Array<{description:string, value:any}> = [];

    /**Valida y convierte el valor en string */
    const validateDestructor = (obj:any) => {
        if(typeof(obj) === "string" || typeof(obj) === "number" || typeof(obj) === "boolean") return obj.toString();
        return "";
    };

    /** Si existe data se extrae y crea el nuevo array */
    if(v.data.length){
        value = v.data.map((item:objectKV,index:number) => {
            let _description:Array<string> = [];
            let temp;
            if(typeof(v.formatterInfo) !== "function"){
                if(v.pathMultiple) _description = v.pathMultiple.map((itemPath: string | Array<string>) => validateDestructor(destructor(itemPath,item)));
                if(v.pathOptions) _description.push(validateDestructor(destructor(v.pathOptions, item)));
                temp = _description.filter(Boolean).join(v.token ?? " ");
            } else temp = v.formatterInfo(item);
            let _value = destructor(v.pathEmit, item) ?? temp;
            return {description:temp, value:_value};
        });
    }
    return value;
};