import * as React from 'react';
import Icon from '../Icon/Icon.tooltip';
import "./TextView.scss";

interface propsBooleanView {
    label?:string; 
    iconTrue?:string; 
    iconFalse?:string; 
    width?:number;
    height?:number;
    value?:boolean;
    messageTrue?:string;
    messageFalse?:string;
}

export const BooleanView = React.memo((props:propsBooleanView) => {

    const { label,width,value,iconTrue,iconFalse,messageFalse,messageTrue } = props;

    return (<div className="text-view justify-center" style={{  width ,height:label?40:20}}>
        {label && <label  className="text-view__label">{label}</label>}
        <div className="boolean-view">
            <Icon message={value?messageTrue:messageFalse} className={`${value?(iconTrue ?? "icon-check")+" icon-style-true":(iconFalse ?? "icon-close")+" icon-style-false"}`}/>
        </div>
    </div>);
});