import React, { forwardRef } from "react";
import { objectKV, action, error } from "./../../../utils/interface";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { useInput } from "../../hook/input-hook";
import "./Input.scss";


// interface itemProps {
//     value?:number;
//     valueChange: (v:action) => any
// }

/**
 * @description odtiene un valor de un objeto
 * @param key 
 * @version 1.0
 * @param value 
 */
const parceInfo = (key:"DAY" | "MONTH" | "YEAR",value?:{day:number,month:number, year:number} | number) => {
    switch(key){
        case "DAY":
            if(typeof(value) === "number") return new Date(value).getDate();
            return value?.day;
        case "MONTH":
            if(typeof(value) === "number") return new Date(value).getMonth() + 1;
            return value?.month;
        case "YEAR":
            if(typeof(value) === "number") return new Date(value).getFullYear();
            return value?.year;
    }
};

/**
 * @description renderiza un componente que recibe los dias - numeros de 1 a 31
 * @version 1.0
 */
export const Day = React.memo(forwardRef((props:objectKV, ref:any) => {
    
    const el = React.useRef(null);

    const { focusMonth , ...all} = props;

    const _onKeyPress = (e:objectKV) => {
        //validamos que el valor no se mayor a 3, no hay mas de 31 dias 
        if(+props.value > 3) e.preventDefault();
        //Se valida que no se ingresen valores negativos
        if(e.key === "-") return e.preventDefault();
        //Se valida que el tamaño no supere tres digitos
        if(props?.value?.length === 2) return e.preventDefault();
        //Se valida que no se agregue un numero superior a 31
        if(+e.key > 1 && +props.value === 3) return e.preventDefault();
        // uso esto para sacara de la pila la ejecucion de esta funcion es mas 
        if((props?.value?.length || e.key > 3) && props.focusMonth) setTimeout(() => props.focusMonth(), 10);
    }

    /**
     * @description esta funcion previene el evento paste 
     * @param e 
     */
    const _onPaste = (e:objectKV) => {
        e.preventDefault();
    }

    /**
     * @description esta funcion llama el metodo focus del input 
     */
    const setFocus = () => {
        let _el:any = el?.current;
        if(_el?.focus) _el.focus();      
    };

    /**
     * @description esta funcion llama el metodo blur del input 
     */
    const setBlur = () => {
        let _el:any = el?.current;
        if(_el?.blur) _el.blur();    
    };

    React.useImperativeHandle(ref,()=>({
        focus:setFocus,
        blur:setBlur,
    }));

    return (
        <input onPaste={_onPaste} ref={el} placeholder="DD" type="number" onKeyPress={_onKeyPress} max="31" min="1" className="input-date__text" {...all}/>
    );
}));

/**
 * @description  esta funcion es el componente que recibe el mes 
 * @version 1.0
 */
export const Month = React.memo(forwardRef((props:objectKV, ref:any) => {

    const el = React.useRef(null);

    const {focusYear, ...all} = props;

    const _onKeyPress = (e:objectKV) => {
        //Se valida que el primer digito no se mayor a 1 
        if(+props.value > 1 )  e.preventDefault();
        // Se valida que no se ingresen valores negativos 
        if(e.key === "-") return e.preventDefault();
        // Se validda que el valor no sea superior a dos digitos 
        if(props?.value?.length === 2) return e.preventDefault();
        //Se valida que los numeros no sea superiores a 12 
        if(+props.value === 1 && +e.key > 2) return e.preventDefault();
        //se hace un focus del campo year 
        if((props?.value?.length  || e.key > 1) && props.focusYear) setTimeout(() => props.focusYear(),10);

    }
    
    /**
     * @description etsa fumcion hace el focus de este componente 
     * @version 1,0 
     */
    const setFocus = () => {

        let _el:any = el?.current;
        
        if(_el?.focus) _el.focus();      
    };

    /**
     * @description esta funcion hace el blur de este componente 
     */
    const setBlur = () => {
      
        let _el:any = el?.current;
      
        if(_el?.blur) _el.blur();    
    };

    React.useImperativeHandle(ref,()=>({
      
        focus:setFocus,
      
        blur:setBlur,
    }));

    /**
     * @description esta funcion evita la opcion paste 
     * @param e 
     */
    const _onPaste = (e:objectKV) => {
        e.preventDefault();
    }

    return (
        <input {...all} ref={el} placeholder="MM" onPaste={_onPaste} onKeyPress={_onKeyPress} type="number" max="12" min="1" className="input-date__text" />
    );
}));

/**
 * @description este es el componente de año 
 * @version 1.0
 */
export const Year = React.memo(forwardRef((props:objectKV,ref:any) => {

    const el = React.useRef(null);

    const _onKeyPress = (e:objectKV) => {
        //se valida los numeros negativos 
        if(e.key === "-") return e.preventDefault();
        // se valida que no se ingresen numeros superiores a 9999
        if(props?.value?.length === 4) return e.preventDefault();
    }

    /**
     * @description esta funcion evita el evento paste 
     * @param e 
     */
    const _onPaste = (e:objectKV) => {
        e.preventDefault();
    }
    
    /**
     * @description esta funcion hace llama el metodo focus de este compoennete 
     */
    const setFocus = () => {
      
        let _el:any = el?.current;
      
        if(_el?.focus) _el.focus();      
    };

    /**
     * @description esta funcion llama el metodo blur de este componete 
     */
    const setBlur = () => {
      
        let _el:any = el?.current;
      
        if(_el?.blur) _el.blur();    
    };

    React.useImperativeHandle(ref,()=>({
      
        focus:setFocus,
      
        blur:setBlur,
    }));

    return (
        <input {...props} ref={el} placeholder="YYYY" onKeyPress={_onKeyPress} onPaste={_onPaste} type="number" className="input-date__text year"/>
    );
}));

/**@description nos presenta el componente de texto en el input date
 * @version 1.0
 */
export const InputDateText = React.memo((props:{
    /**Es el valor por defecto que tendra el componente  */
    value?:{day:number,month:number, year:number} | number, 
    /**
     * este valor devuelve los dias los meses y los años que se selecionan o escriben, tambien un valor int en milisegundo se recomineda trabajar con este valor, los valores pueden variar cuando se trabaja con milisegundos 
     */
    onChange?:(v:action) => any,
    /**Esta bandera indica si el calendario debe estar o no visible */
    calendaryVisible?:boolean;
    
    /**Es el titulo que tendra el componente  */
    label?:string;
    /**Indica la existencia de lagun error */
    error?:error;
    /**Se ejecuta cuando el calendario recibe un click */
    clickCalendary?:(v:action) => any
}) => {

    const {label,error} = props;
    /**Referencia el componente de día */
    let elDay = React.useRef(null);
    
    /**Referencia el componente de mes */
    let elMonth = React.useRef(null);
    
    /**Referencia el componente de año */
    let elYear = React.useRef(null);

    const {value:day,setValue:setValueDay, focused:fDay, bind:bindDay, reset:resetDay} = useInput( );
    
    const {value:month, setValue:setValueMonth, focused:fMonth, bind:bindMonth, reset:resetMont} = useInput();
    
    const {value:year, setValue:setValueYear, focused:fYear,bind:bindYear, reset:resetYaer} = useInput();

    const [focused, setFocused] = React.useState<boolean>(false);

    React.useEffect(() => {
         // cada vez que ocurra un cambio en los valoresse llama la funcion del padre que registra los cambios
        if(props.onChange) {
    
            let _date;
    
            if(day && month && year) _date = new Date(`${month}-${day}-${year}`).getTime();
            if (props.value === _date) return;
            props.onChange({type:"SET_DATE", value:{day,month,year, value:_date }})
        }
    }, [day,month, year]);

    React.useEffect(() => {
        // Si ocurre un cambio en le value se registra el cambio en el state 
        if(props?.value && typeof(props.value) === "object") console.warn("Warning: se recomienda usar el tiempo en milisegundo para evitar cualquier error ademas es mas facil de maneja\n ya que este componente no esta al 100% verificado");
    
        setValueDay(parceInfo("DAY",props?.value)?.toString() ?? "");
    
        setValueMonth(parceInfo("MONTH",props?.value)?.toString() ?? "");
    
        setValueYear(parceInfo("YEAR",props?.value)?.toString() ?? "");
    }, [props.value]);

    
    React.useEffect(() => {
        // En caso de que se de click al calendario 
        if(!!props.calendaryVisible && !focused) setFocused(true);

        else setFocused(false);

    }, [props.calendaryVisible]);
    
    
    React.useEffect(() => {
        //se llama assingan el estado focus, alguno de los input esta enfocado 
        if(fDay || fMonth || fYear){
    
            if(!focused) setFocused(true);
    
        } else if(focused) setFocused(false);
    }, [fDay,fYear,fMonth]);
    
    const _onClick = () => {
    
        if(props.clickCalendary) props.clickCalendary({type:"CLICK_CALENDARY", value:true});
    
    };
    const focusYear = () => {
    
        const el:any =  elYear.current;
    
        if(el?.focus ) el.focus();
    };

    const focusMonth = () => {
    
        const el:any =  elMonth.current;
    
        if(el?.focus) el.focus();
    };
    
    return (
        <div className="container">
            {label?<label className={`text-conf ${focused?"label-focus":""} ${error?.error?"error":""}`}>{label}</label>:null}
            <div className={`input-date ${focused? "input-date__focus": ""}`} style={{borderColor:error?.error?"red":""}}>
                <Day {...bindDay} ref={elDay} focusMonth={focusMonth} />
                /
                <Month {...bindMonth} ref={elMonth} focusYear={focusYear}/>
                /
                <Year {...bindYear} ref={elYear}/>
                <div onClick={_onClick} className="calendar-button">
                    <i className="icon-calendar" />
                </div>
            </div>
            {error?.error?<label className="text-conf error">{error.message}</label>:null}
        </div>
        
    )
});

/**
 * @description nos presenta el componente de Input date, en este componente de mezclan tanto el calendario como el input
 * @version 1.0
 * @param props
 */
export const InputDate = (props:{date?:number, label?:string, error?:error, onChange?:(v:action) => any}) => {

    /**Stado que indica si el calendario debe se rvisible */
    const [visible, setVisible ] = React.useState<boolean>(false);

    /**Estado que maneja el valor actual del calendario  */
    const [date, setDate] = React.useState<any>();

    // React.useEffect(() => {
    //     if(props.date) setDate(new Date(props.date));
    // }, []);

    React.useEffect(() => {
         setDate(props.date?new Date(props.date):undefined);
    }, [props.date]);

    /**Maneja el click en el calendario */
    const _onDayChange = React.useCallback((v:any) => {
        let _date = new Date(v);
        setDate(_date);
        if(props.onChange && _date.getTime() !== props.date) props.onChange({type:"SET_DATE", value:{value:_date.getTime()}});// este usa la accion SET_DATE
        setVisible(false);
    },[props.date,props.onChange]);
    

    const _clickCalendary = () => {
        setVisible(!visible);
    };

    const _onChange = (v: action) => {
        if(props.onChange) props.onChange(v);// este usa la accion SET_DATE
    };

    return (
        <div>
            <InputDateText onChange={_onChange} label={props.label} error={props.error} calendaryVisible={visible} value={date?.getTime()} clickCalendary={_clickCalendary}/>
            <div className={`calendary-view ${ !visible?"calendary-view__hidden":""}`}>
                <DayPicker onDayClick={_onDayChange}/>
            </div>
        </div>
    );
};
