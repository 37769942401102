import React from "react";
import "./FormModal.scss";
import { Close, ButtonsFooterForm } from "./Buttons.form";
import { Modal } from "../../Basic/Modal/Modal";
import { objectKV } from "../../../utils/interface";
import Button from "../../Basic/Button/Button";
import Icon from "../../Basic/Icon/Icon.tooltip";

export const ModalAdapter = (props:{
    noEdit?:boolean,
    showOptionsButton?:boolean,
    hiddenOptionsButtonInEdit?:boolean,
    loading?:boolean,
    close?:(v?:any) => any, 
    className?:string,
    clickSave?:(v?:any) => any,
    children?:JSX.Element[],
    option?:(p?:any) => JSX.Element | JSX.Element[] | null,
    title?:string,editableOnly?:boolean, style?:objectKV}) => {

    const [isEditable, setEditable ] = React.useState<"EDIT" | "SHOW">("SHOW");
    const [change, setChange ] = React.useState<boolean>(false);

    const _onClickClose = () => {
        if(props.close) props.close();
    }

    const changeState = (v:"EDIT" | "SHOW" | "CHANGE") => {
        if(v === "CHANGE") {
            setEditable("EDIT");
            setChange(true);
        } else {
            setEditable(v);
            setChange(false);
        }
    };

    const _onClickCancel = () => {
        setChange(false);
        if(isEditable !== "SHOW" && !props.editableOnly) return setEditable("SHOW");
        if(props.close) props.close();
    }

    const changeEditable = () => {
        if(!props.editableOnly && isEditable !== "EDIT") return setEditable("EDIT");
        if(props.clickSave) props.clickSave();
    };

    return (
        <div className="modal-container" style={props.style}>
            <div className="header">
            <div className="header__title">
                <label>{props.title ?? ""}</label>
            </div>
            <div className="header__close">
                {props.showOptionsButton && !props.editableOnly && !(props.hiddenOptionsButtonInEdit && isEditable === "EDIT") ?
                <OptionsModal >
                    {props.option?props.option({changeState,onClickClose:_onClickClose}):null}
                </OptionsModal>:null}
                <Close onClick={_onClickClose}/>
            </div>
            </div>
            <div className={`visual-info-container ${props.className}`}>
                {props.children?.length?
                isEditable === "EDIT" || props.editableOnly?
                change?props.children[2] ?? null: props.children[1] ?? null:
                props.children[0]: null}
            </div>
            <ButtonsFooterForm noEdit={props.noEdit} loading={props.loading} btnClose={{label:isEditable === "EDIT" || props.editableOnly?"Cancelar":"Cerrar", onClick:_onClickCancel}} btnSave={{label:isEditable === "EDIT" || props.editableOnly?"Guardar":"Editar",onClick:changeEditable}} />
        </div>
    );
};

export const ModalConfirmationRemove = (props:{loading:boolean,visible?:boolean, close:(v?:any) => any, numberReg:number, accept:(v?:any) => any}) => {

    const _clickOutside = () => {
        props.close();
    };
    const _onClickCancel = () => {
        props.close();
    };

    const _onClickAcept = () => {
        props.accept();
    };

    return (
        <Modal visible={!!props.visible} clickOutside={_clickOutside} backGroundColor="#00000030">
            <div className="modal-confirmation-remove">
            <div className="modal-confirmation-remove__icon">
                <i className="icon-alerta"/>
            </div>
            <div className="modal-confirmation-remove__message">
                Desea eliminar {props.numberReg} registros.
            </div>
            <div>
                <ButtonsFooterForm loading={props.loading} btnClose={{label:"Cancelar",onClick:_onClickCancel, style:{color:"#d10038", borderColor:"rgb(236, 233, 233)"}}} btnSave={{label:"Aceptar",onClick:_onClickAcept, style:{backgroundColor:"#d10038"}}} />
            </div>
            </div>
        </Modal>
    );
};

    
export const OptionsModal = (props:{children?: JSX.Element[] | JSX.Element | null }) => {

    const [visible, setVisible] = React.useState<boolean>(false);

    const _onMouseOutCapture = () => {
        setVisible(false); 
    };

    const _onMouseEnter = () => {
        setVisible(true);
    };

    return <div onMouseMoveCapture={_onMouseEnter} onMouseOutCapture={_onMouseOutCapture} className="container-options">
            <Button className="button-option"><Icon className="icon-menu"/></Button>
            <div 
            onMouseMoveCapture={_onMouseEnter} 
            onMouseOutCapture={_onMouseOutCapture} 
            className="modal-option"
            style={{...(!visible ?{height:0, padding:0,border:"none"}:{})}}
            >
                {props.children}
            </div>
        </div>

};