import React from "react";
import "./Image.scss";
import usePortal from "../../hook/portal-hook";
import ReactDOM from "react-dom";
import Button from "../Button/Button";
import Icon from "../Icon/Icon.tooltip";
import { objectKV } from "../../../utils/interface";
import { useLazyQueryGranada } from "../../hook/query-hook";
import { DownloadImage } from "./DownLoad.image";

interface  propsImage {
    /**Representa la uri de la imagen */
    uri?:string;
    /**Representa una uri de la imagen.min */
    minUri?:string;
    /**Evita que se abra la ventana modal con la imagen */
    disabledView?:boolean;
    /**Se dispara cuando ocurre el click sobre la imagen */
    onClick?:(v?:any) => any;
    /**Indica un error en la imagen */
    imageError?:any;
    [v:string]:any;
}

/**
 * @description es el componente que se resenta cuando la imagen no se pudo cargar correctamente 
 * @version 1.0
 */
export const ImageNotFound = (props:objectKV) => {
    return <div className="data-not-found-container" {...props}>
            <i className="icon-broken" />
    </div>
}

/**
 * @description esta funcion se encarga de hacer una petion AJAX por la imagen
 * @version 1.0
 */
export const getImage = (uri:string) => {
    return new Promise((resolve:any, reject:any) => {
        fetch(uri, {mode:"no-cors"})
        .then((res:any) => {
            if(res?.status === 200) return res.blob();
            reject("Error al cargar la image");
        })
        .then((res:any) => {
            let uri = URL.createObjectURL(res);
            resolve(uri);
        }).catch(reject);
    })
};


/**
* @description presenta el componente que muestra la imagen en la pantalla completa
* @version 1.0
 */
export const ModalViewImage = React.memo((props:{
    /**@description Representa la url de la image, si es local (INCOMPLETO)*/
     localUri?:string, 
     /** Representa la url de la imagen si no es local*/
     uri?:string,
    /** key del archivo*/
    name?:string,
     /**Es una bandera que indica si debe ser visible la ventana */
     visible?:boolean, 
     /**Se ejecutara cuando ocurra algun evento que solicite cerra la ventana */
     close?:(v?:any) => any, 
     /**Se ejecuta cuando se de ckick en la opcion de avanzar (a la izquierda)  */
     clickLeft?:(v?:any) => any, 
     /**Se ejecuta cuando se de ckick en la opcion de avanzar (a la derecha)  */
     clickRight?:(v?:any) => any, 
     /**es una bandera que muetsra los controles de avanzar  */
     showControl?:boolean}) => {
    
    const el = usePortal("modal-root");

    //Esta indicador se usa con retardo para permitir la animacion.
    //Pero a la pestaña de contenedor se le avisa en el momento para que ejecute la animacion 
    // actualmente solo funciona local uri.
    const [visible, setVisible] = React.useState<boolean>(false);
    const [loading, setLoading ] = React.useState<boolean>(false);
    const [uri, setUri] = React.useState<string>();

    React.useEffect(() => {
        if(props.localUri) setUri(props.localUri);
        // else if(props.uri){
        //     setLoading(true);
        //     getImage(props.uri).then((res:any) => {
        //         setUri(res);
        //     }).catch(console.warn)
        //     .finally(() => setLoading(false));
        // }
    }, [props.uri, props.localUri]);

    React.useEffect(() => {
        let idTime:any;
        if(props.visible) {
            setVisible(true);
        }
        else {
             idTime = setTimeout(() => setVisible(false), 150);
        }

        return () => {
            if(idTime) clearTimeout(idTime);
        };
    }, [props.visible]);

    /**Maneja el evento click en la X */
    const _onClick = () => {
        if(props.close) props.close();
    };

    /**Maneja el evento click en LEFT */
    const _clickLeft = () => {
        if(props.clickLeft) props.clickLeft();
    };

    /**Maneja el evento click en RIGHT */
    const _clickRight = () => {
        if(props.clickRight) props.clickRight();
    };

    return (visible? ReactDOM.createPortal(
                <div className="container-image-view" >
                    <div className="options">
                        {uri?<DownloadImage uri={uri} name={props.name}/>:null}
                        <Button onClick={_onClick} className="close-button"><Icon message="Cerrar" className="icon-close"/></Button>
                    </div>
                    {props.showControl?
                    <React.Fragment>
                        <Button onClick={_clickLeft} className="arrow-left"><Icon message="Anterior" className="icon-chevron_left"/></Button>
                        <Button onClick={_clickRight} className="arrow-right" ><Icon message="Siguiente" className="icon-chevron_right"/></Button>
                    </React.Fragment> :null}
                    {uri?<img src={uri} className="image-full"/>:<ImageNotFound style={{background:"#00000000"}} />} 
                </div>,el)
            :null
        );
});

/**@description Representa el componente de imagen, funciona igual que <img> de html, pero con cambios para adaptarse a l
 * a aplicion
 * @version 1.0
 */
export const ImageViwer = (props:propsImage) => {

    /**Estado para manejar la visibilidad de la ventana modal con la imagen  */
    const [visible, setVisible ] = React.useState<boolean>(false);

    /** */
    const [error, setError ] = React.useState<boolean>(false);

    const {uri, minUri,disabledView,onClick,imageError,name, ...allOther} = props;

    /**Abre le ventana modal con la imagen */
    const open = () => {
        setVisible(true);
    };

    /**Cierra la ventana modal con la imagen */
    const close = () => {
        setVisible(false);
    };

    const _onError = () => {
        setError(true);
    };

    /**Manej ale vento click sobre la imagen */
    const _onClick = () => {
        if(!props.disabledView) open();
        if(onClick) onClick();
    };
    return <div {...allOther}>
        {!!(props.uri ?? props.minUri) && !disabledView ?<ModalViewImage close={close} name={name} visible={visible} localUri={props.uri ?? props.minUri } />:null}
        {!error || imageError? <img onClick={_onClick} onError={_onError} src={error && imageError?imageError:props.minUri ?? props.uri} className="image-view-min"/>: <ImageNotFound />}
    </div>
};

/**
 * @description permite ver una imgen por la key
 * @version 1.0
 */
export const ImageViwerByKeys = React.memo((props:propsImage) => {
    /**Destructuring de la props */
    const { uri, ...allRest} = props;

    const [_uri,setUri] = React.useState<string>();

    /**Query que pide la uri fimada para alguna key  */
    const query = `query getSignedUrlFileDownload($key:String){
        getSignedUrlFileDownload(key:$key){
            id
            url
            key
        }  
    }
    `;
    const [loadData, { data, loading}] = useLazyQueryGranada({query});

    React.useEffect(() => {
        //Se valida la existencia de una uri en este caso una key y se hace la peticon
        if(uri)loadData({variables:{key:uri}});
    },[uri]);

    React.useEffect(() => {
        // console.log(data?.getSignedUrlFileDownload?.url)
        if(data?.getSignedUrlFileDownload?.url){
            fetch(data?.getSignedUrlFileDownload?.url).then((res:any) => res.blob()).then((res:any) => {
                let urlCreator = window.URL || window.webkitURL;
                let imageUrl = urlCreator.createObjectURL(res);
                setUri(imageUrl);
            })
        }
    },[data?.getSignedUrlFileDownload?.url]);
    return <ImageViwer uri={_uri} {...allRest} name={uri}/>
});