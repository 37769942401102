import React from 'react';
import { COLORS } from "../../../utils/colors";
import { Chart, BarController, BarElement, Title, registerables } from "chart.js";
Chart.register(...registerables);

interface propsChartBar {
    data?:Array<{label:string,data:number,labelDataSet:string,id?:number|string}>;
    width?:string|number;
    indexAxis?:"x"|"y";
    type:"bar"|"pie";
    title?:string;
    subtitle?:string;
}

const handleHover = (evt:any, item:any, legend:any) => {
    legend.chart.data.datasets[0].backgroundColor.forEach((color:any, index:any, colors:any) => {
      colors[index] = index === item.index || color.length === 9 ? color : color + '4D';
    });
    legend.chart.update();
  }

const handleLeave = (evt:any, item:any, legend:any) => {
    legend.chart.data.datasets[0].backgroundColor.forEach((color:any, index:any, colors:any) => {
        colors[index] = color.length === 9 ? color.slice(0, -2) : color;
    });
    legend.chart.update();
}

export const BasicChart = React.memo((props:propsChartBar) => {
    
    const {width,data,indexAxis,type,title,subtitle} = props;

    const el = React.useRef(null);
    const [chart,setChart] = React.useState<any>(null);
    
    React.useEffect(() => {
        let _el:any = el?.current;
        if(_el){
            if(chart) chart.destroy();
            if(!data?.length) return;

            let labels:Array<string> = [],_data:Array<number> = [],labelDataSet="";

            data.forEach((item:any) => {
                labels.push(item.label);
                _data.push(item.data);
                labelDataSet = item?.labelDataSet;
            });

            let _chart = new Chart(_el,{
                type,
                data: {
                    labels: labels,
                    datasets: [{
                        label:labelDataSet,
                        data: _data,
                        backgroundColor: COLORS.map((item:any) => item.hex),
                        borderWidth: 0
                    }]
                },
                options: {
                    indexAxis,
                    plugins:{
                        legend: {
                            display:false,
                            // onHover: handleHover,
                            // onLeave: handleLeave
                        },
                        title: {
                            display: !!title,
                            text: title,
                            font: {
                                size: 15
                            }
                        },
                        // subtitle: {
                        //     display: true,
                        //     text: 'Chart Subtitle',
                        //     color: 'blue',
                        //     font: {
                        //       size: 12,
                        //       family: 'tahoma',
                        //       weight: 'normal',
                        //       style: 'italic'
                        //     },
                        //     padding: {
                        //       bottom: 10
                        //     }
                        //   }
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
            setChart(_chart);
        }
    },[data,indexAxis,type,width]);
    return <div style={{background:"white",width}}><canvas ref={el}></canvas></div>
});
