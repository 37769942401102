import React from "react";
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import { position, latlng } from "./../../../utils/interface";
import "./Distance.scss";

interface propsMap {
    origin?:position;
    destination?:position;
    currentPosition?:position;
}

export const Distance = React.memo((props:propsMap) => {

    let [ percentage, setPercentage ] = React.useState<number>(0);

    let [ distanceOD, setDistanceOD ] = React.useState<number>();

    let [ distanceOC, setDistanceOC ] = React.useState<number>();

    const [ map, setMap ] = React.useState<any>();

    React.useEffect(() => {
        if(map) return;
        const _map = L.map("distance");
        _map.setView([3.713739, -73.091899], 6);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        // maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(_map);
        setMap(_map);
      },[]);

    React.useEffect(() => {
        let setDistance = async () => {
            let res;
            if(props.origin?.position && props.currentPosition?.position ) {
                res = await getDistance(
                   typeof(props.origin.position) === "string"? props.origin.position: latLngParce(props.origin.position),
                   typeof(props.currentPosition.position) === "string"? props.currentPosition.position: latLngParce(props.currentPosition.position))
                   .catch(console.error);
                   if(res) {
                        setDistanceOC(res);
                   }
            }
        }
        if(props.origin?.position && props.currentPosition?.position ) setDistance();
    }, [
        JSON.stringify( props.origin ), 
        JSON.stringify( props.currentPosition )
    ]);

    React.useEffect(() => {
        let setDistance = async () => {
            let res;
            if(props.origin?.position && props.destination?.position ) {
                res = await getDistance(
                    typeof(props.origin.position) === "string"? props.origin.position: latLngParce(props.origin.position),
                    typeof(props.destination.position) === "string"? props.destination.position: latLngParce(props.destination.position))
                    .catch(console.error);
                    if(res) {
                        setDistanceOD(res);
                    }
                }
            }
            if(props.origin?.position && props.destination?.position ) setDistance();
    }, [
        JSON.stringify( props.origin ), 
        JSON.stringify( props.destination )
    ]);

    React.useEffect(() => {
        if( distanceOD && distanceOC ) {
            let _percentage = +((distanceOC / distanceOD)*100).toString().split(".").slice(0,1);
            if(_percentage > 100) setPercentage(100);
            else if(_percentage < 0 ) setPercentage(0);
            else setPercentage(_percentage);
        }
        else {
            setPercentage(0);
        }
    }, [distanceOC,distanceOD]);

    const latLngParce = (v: latlng) => L.latLng(v.lat, v.lng);

    const getDistance = (origin:any,destination:any) => new Promise((resolve:(v:any)=>any, reject:(v?:any)=>any) => {
        if(!map) reject();
        const waypoints = [origin,destination];
        let route:any =  L.Routing.control({waypoints,routeWhileDragging: true,show: false})
        .addTo(map)
        .on('routesfound', function (e:any) {
            let temp = +(e?.routes?.length&&(e?.routes[0]?.summary?.totalDistance??0));
            resolve(temp/1000);
        });
    });

    return (
        <div className="distance">
            <div id="distance" className="display-none"/>
            <div className="distance__indicator" style={{width:`${percentage}%`}}>
                <label className="distance__percentage">{percentage}%</label>
            </div>
        </div>     
    )
});
