import React from 'react'
import './Forms-report.scss'
import {
  GeneralInfo,
  Observations,
  ControlButtons,
  UploadFile,
  GET_INFO,
  LocationForm,
  GET_TIMES,
  REPORT_MUTATION,
  DatePicker
} from './Form.general-info'
import { useMutationServer } from '../../../Componets/hook/mutation-hook'
import { objectKV, action, error } from '../../../utils/interface'
import { useLazyQueryToSever } from '../../../Componets/hook/query-hook'
import { useUser } from '../../../Componets/hook/user-hook'
import TextView from '../../../Componets/Basic/Text/TextView'
import {
  formatterTimeZoneDate,
  getDateServer,
  reportFormatter,
  validatorTimeDate
} from '../../../utils/utils'
import { uploadImage } from '../../../Componets/General/UploadImage/UploadImage'
import { queryTablesRemitances } from '../Travel/Travel.config'
import { InputSelect } from '../../../Componets/Basic/Input/InputSelect'
import { useInputMultiSelect } from '../../../Componets/hook/input-hook'
import { InputSelectMultiple } from '../../../Componets/Basic/Input/InputSelectMultiple'

export const FormRem = (props: {
  id?: number | string
  close: () => any
  sysEvent?: objectKV
}) => {
  const [sendData, { data }] = useMutationServer({ mutation: REPORT_MUTATION })
  const [loadData, { data: dataFill }] = useLazyQueryToSever({
    query: GET_INFO,
    fetchPolicy: 'cache-first'
  })
  const [
    loadDataRemittances,
    {
      data: dataRemittances,
      loading: loadinRemittances,
      error: errorRemittances
    }
  ] = useLazyQueryToSever({
    query: queryTablesRemitances,
    fetchPolicy: 'cache-first'
  })
  const {
    user: { id }
  } = useUser()
  const { value: valueRem, bind, setValue } = useInputMultiSelect()

  const init = {
    fecha: '',
    hora: '',
    idevento: null,
    idremesas: [],
    idsysevento: null,
    idusuario: null,
    idviaje: null,
    imagenes: [],
    lugar: '',
    novedad: '',
    retraso: null
  }

  const reducer = (state: objectKV, action: action) => {
    switch (action.type) {
      case 'SET_DATA':
        return Object.assign({}, state, action.value)
      default:
        return state
    }
  }

  const [errorNovedad, setErrorNovedad] = React.useState<error>()
  const [errorDate, setErrorDate] = React.useState<error>()
  const [errorTime, setErrorTime] = React.useState<error>()
  const [errorRem, setErrorRem] = React.useState<error>()
  const [date, setDate] = React.useState<number>()
  const [messageErrorDatePicker, setMessageErrorDatePicker] = React.useState<
    string
  >()
  const [rem, setRem] = React.useState<objectKV>()
  const [dateServer, setDateServer] = React.useState<string>()
  const [keyLocalDate, setKeyLocalDate] = React.useState<string>()

  const [uploading, setUploading] = React.useState<boolean>()

  const [state, dispatch] = React.useReducer(reducer, init)

  const _onClickSend = async () => {
    if (!(await validator(state))) return
    // let date = new Date();
    // let _date = { fecha: date.toLocaleDateString(),hora: date.toLocaleTimeString().split(":").slice(0,2).join(":")}
    setUploading(true)
    sendReport(Object.assign({}, state)).finally(() => {
      setUploading(false)
    })
  }

  const sendReport = async (v: any) => {
    let { images, report } = await reportFormatter(Object.assign({}, v))
    let resReport = await sendData({ variables: { report: [report] } }).catch(
      (err: any) => ({ error: true })
    )
    if (
      resReport?.data?.createReporteV2?.length &&
      resReport?.data?.createReporteV2[0]?.uploadLinks?.length
    ) {
      await Promise.all(
        resReport.data.createReporteV2[0].uploadLinks.map(
          async (item: objectKV) => {
            let file = images.find((f: File) => f.name === item.key)
            return item.url && file
              ? await uploadImage(item.url, file, file.type)
              : null
          }
        )
      )
    }
  }

  const _onClickCancel = () => {
    props.close()
  }

  const validator = async (v: objectKV) => {
    let currentDate = (await getDateServer(!!keyLocalDate)).split('##')[0]

    if (!currentDate || typeof currentDate !== 'string') {
      setMessageErrorDatePicker('No se pudo obtener la hora del servidor')
      return false
    }
    setErrorDate({ error: !v.fecha, message: 'Requerido' })
    setErrorTime({ error: !v.hora, message: 'Requerido' })
    let isnotvalidDate = validatorTimeDate(
      v.hora ?? '',
      v.fecha ?? '',
      formatterTimeZoneDate(currentDate).timeCurrentZone,
      formatterTimeZoneDate(+(dataFill?.viaje?.fechahoraultimoevento ?? 0))
        .timeCurrentZone
    )
    setMessageErrorDatePicker(isnotvalidDate)
    setErrorNovedad({ error: !v.novedad, message: 'Requerido' })
    setErrorRem({ error: !v.idremesas?.length, message: 'requerido' })
    if (!v.novedad || !v.idremesas?.length || isnotvalidDate) return false
    return true
  }

  React.useEffect(() => {
    getDateServer().then((res: string) => {
      let _resDate = res.split('##')
      setKeyLocalDate(_resDate[1])
      setDateServer(_resDate[0])
      setDate(formatterTimeZoneDate(_resDate[0]).timeCurrentZone ?? 0)
    })
  }, [])

  React.useEffect(() => {
    let _value = dataRemittances?.remesasInfo?.data
    if (_value?.length && props.sysEvent?.id) {
      let _rem = _value.filter((item: objectKV) =>
        valueRem?.includes(+(item.id ?? 0))
      )
      setRem(_rem)
      let tempIds = _rem?.map((i: any) => i.id)
      _rem = _rem[0] ?? {
        direccionremitente: 'Sistema',
        direcciondestinatario: 'Sistema'
      }
      dispatch({
        type: 'SET_DATA',
        value: {
          idremesas: tempIds ?? null,
          lugar: [3, 5].includes(props.sysEvent?.id)
            ? _rem?.direccionremitente
            : [7, 8].includes(props.sysEvent?.id)
            ? _rem?.direcciondestinatario
            : 'Sistema'
        }
      })
    }
  }, [valueRem, props.sysEvent])

  React.useEffect(() => {
    if (props.id) {
      loadData({ variables: { id: props.id } })
      loadDataRemittances({ variables: { id: props.id } })
      dispatch({
        type: 'SET_DATA',
        value: {
          idviaje: props.id
        }
      })
    }
  }, [props.id])

  // React.useEffect(() => {
  // //    console.log("******",valueRem)
  // }, [valueRem]);

  React.useEffect(() => {
    if (props.sysEvent?.id) {
      dispatch({
        type: 'SET_DATA',
        value: {
          idsysevento: props.sysEvent?.id
        }
      })
    }
  }, [JSON.stringify(props.sysEvent)])

  const remCurrent = React.useMemo(() => {
    let value = dataRemittances?.remesasInfo?.data
    if (value?.length) {
      if ([7, 8].includes(props.sysEvent?.id ?? 0))
        return value.filter((item: objectKV) =>
          [13, 8, 9].includes(item.idsysestado)
        )
      else if ([3, 5].includes(props.sysEvent?.id ?? 0))
        return value.filter((item: objectKV) =>
          [3, 6].includes(item.idsysestado)
        )
      else if ([36].includes(props.sysEvent?.id ?? 0))
        return value.filter((item: objectKV) => [8].includes(item.idsysestado))
      else return value
    }
    return []
  }, [JSON.stringify(dataRemittances), props.sysEvent?.id])

  React.useEffect(() => {
    let value = remCurrent
    let remReport = Array.isArray(value)
      ? value.filter((item: objectKV) => [3, 8].includes(item.idsysestado))
      : null
    if (remReport?.length) {
      setValue([...remReport.map((item: objectKV) => item.id)])
    } else if (value?.length) {
      setValue([value[0].id])
    }
  }, [remCurrent])

  React.useEffect(() => {
    dispatch({ type: 'SET_DATA', value: { idusuario: id } })
  }, [id])

  React.useEffect(() => {
    if (data?.createReporteV2?.length && !uploading) {
      props.close()
    }
  }, [data, uploading])

  return (
    <div className='form-rem general-form'>
      {/* <div className="form-rem__name-report name-report">
                { props.sysEvent?.descripcion?? "Reporte"}
            </div> */}
      <div className='form-rem__general-info'>
        <GeneralInfo
          {...props}
          title={props.sysEvent?.descripcion ?? 'Reporte'}
          formato={2}
        />
      </div>
      <div className='form-rem__select'>
        <InputSelectMultiple
          label='Remesa y Lugar*'
          style={{ width: 400 }}
          error={errorRem}
          {...bind}
          value={valueRem}
          data={remCurrent ?? []}
          // disabled={loadinRemittances || !remCurrent?.length || [8, 5].includes(props.sysEvent?.id)}
          config={{
            formatter: (data: any) => ({
              key: data.id,
              decription: `${data.numeroremesa}, ${
                data.clienteempresatransportadora
              }, ${
                [7, 8].includes(props.sysEvent?.id ?? 0)
                  ? data.direcciondestinatario
                  : data.direccionremitente
              }`,
              valueShow: data.numeroremesa
            })
          }}
          // config={{ pathEmit: "id", tokenJoin: ", ", multiPathOption: ["numeroremesa",  "clienteempresatransportadora",[7, 8].includes(props.sysEvent?.id ?? 0)?"direcciondestinatario":"direccionremitente"] }}
        />
      </div>
      <div className='form-rem__location'>
        <TextView
          label={'Dirreccion'}
          styleText={{ fontWeight: 900, fontSize: 16 }}
          text={
            [7, 8].includes(props.sysEvent?.id ?? 0)
              ? rem?.length
                ? rem[0]?.direcciondestinatario ?? 'NA'
                : 'NA'
              : rem?.length
              ? rem[0]?.direccionremitente ?? 'NA'
              : 'NA'
          }
        />
      </div>
      <div className='form-rem__upload-file'>
        <UploadFile onChange={dispatch} />
      </div>
      <div className={'form-rem__time'}>
        <DatePicker
          dateString={dateServer}
          messageGeneralError={messageErrorDatePicker}
          onChange={dispatch}
          date={date}
          errorDate={errorDate}
          errorTime={errorTime}
        />
      </div>
      <div className='form-rem__observation'>
        <Observations
          error={errorNovedad}
          onChange={dispatch}
          value='Sin Novedad'
        />
      </div>
      <div className='form-rem__buttons'>
        <ControlButtons
          buttonCancel={{ onClick: _onClickCancel, disabled: uploading }}
          buttonSend={{
            onClick: _onClickSend,
            loading: uploading,
            disabled: uploading
          }}
        />
      </div>
    </div>
  )
}
