import { fields } from "../../Componets/Helpers/Form/Form-context";
import { confTable } from "../../utils/interface";

export const dataInfoUser = `
  id
  nombre
  correo
  telefono
  indicadoractivo
  sysperfil {
    id
    descripcion
  }
`;

export const mutationCreateUser = `
mutation createPuestoControlUsuario($puestoControlUsuario: puestoControlUsuarioInput) {  
  createPuestoControlUsuario(puestoControlUsuario: $puestoControlUsuario) {
    id
  }
}
`;

export const mutationTraficControllerCreate = `
mutation createControladorTrafico($controladorTrafico: controladorTraficoInput) {  
  createControladorTrafico(controladorTrafico: $controladorTrafico) {    
    id
  }
}
`;

export const mutationRemoveUser = `
mutation deleteUser($ids: [Int]) {
  deleteUser(ids: $ids) {
    id
  }
}
`;

export const mutationUpdateUser = `
mutation updateControladorTrafico($idUser: Int, $controladorTrafico: controladorTraficoInput) {
  updateControladorTrafico(idUser: $idUser, controladorTrafico: $controladorTrafico) {
    id
  }
}
`;

export const queryInfoUsuario = `
{
    id
    idsysperfil
    nombre
    correo
    telefono
    indicadoractivo 
    sysperfil{
      id
      descripcion
    }
  }
`;

export const getCheckPoint = `
query getCheckPoints($filters:[FilterInput],$fields:Int!, $page:Int!, $orders: [OrderInput]){
  puestosControlUsuariosInfo(filters : $filters,pagination: { fields:$fields, page: $page},orders: $orders){
    data{
      id
      idsysperfil
      nombre
      correo
      telefono
      indicadoractivo
      puestocontrol
      telefonopuestocontrol
    }
    totalreg
  }  
}`;

export const queryGetTrafic = `
query getTrafic($filters:[FilterInput],$fields:Int!, $page:Int!, $orders: [OrderInput]){
  controladoresTraficoInfo(filters : $filters,pagination: { fields:$fields, page: $page},orders: $orders){
    data{
      id
      idempresa
      idusuario
      nombre
      correo
      telefono
      idsysperfil
      indicadoractivo
    }
    totalreg
  }
}
`;

export const querygetTraficOne = `
query getTrafic($id:Int!){
	controladorTraficoInfo(id:$id){
    id
    idempresa
    idusuario
    nombre
    correo
    telefono
    idsysperfil
    indicadoractivo
  }
}
`;

export const getDrivers = `
query getDrivers($filters:[FilterInput],$fields:Int!, $page:Int!, $orders: [OrderInput]){
  usuariosConductoresInfo(filters : $filters,pagination: { fields:$fields, page: $page},orders: $orders) {
    data{
      id
      idsysperfil
      nombre
      correo
      telefono
      indicadoractivo
      numerodocumento
      contactos
      foto
      fotominiatura
      placa
      idempresa
      idusuario
    }
    totalreg
  }
}`;


export const getDriversOne = `
query getDrivers($id:Int!){
  usuarioConductoreInfo(id:$id){
      id
      idsysperfil
      nombre
      correo
      telefono
      indicadoractivo
      numerodocumento
      contactos
      foto
      fotominiatura
      placa
      idempresa
      idusuario
    }
}`;

export const resetPassword = `
mutation resetPassword($id:Int!){
  resetPassword(id:$id){
    id
    succesful
  }
}
`;

export const fieldsTableTrafic : Array <confTable>  = [
    {
      field:"indicadoractivo",
      orderable:true,
      filterable:true,
      label:"Activo",
      type:"BOOLEAN",
      path:"indicadoractivo",
      textAling:"CENTER",
      width:60
    },  
    {
        field:"nombre",
        orderable:true,
        filterable:true,
        label:"Nombre",
        type:"TEXT",
        path:"nombre"
    },
    {
        field:"correo",
        orderable:true,
        filterable:true,
        label:"Correo",
        type:"TEXT",
        path:"correo"
    },
    {
        field:"telefono",
        orderable:true,
        filterable:false,
        type:"PHONE",
        label:"Teléfono",
        path:"telefono",
        stopClick:true
    }
];
export const fieldsTableCheck : Array <confTable>  = [
    {
      field:"indicadoractivo",
      orderable:true,
      filterable:true,
      label:"Activo",
      width:75,
      type:"BOOLEAN",
      path:"indicadoractivo",
      textAling:"CENTER"
    },  
    {
        field:"nombre",
        orderable:true,
        filterable:true,
        label:"Nombre",
        type:"TEXT",
        path:"nombre"
    },
    {
        field:"correo",
        orderable:true,
        filterable:true,
        label:"Correo",
        type:"TEXT",
        path:"correo"
    },
    {
        field:"telefono",
        orderable:true,
        filterable:false,
        label:"Teléfono",
        path:"telefono",
        type:"PHONE",
        stopClick:true
    },    
    {
        field:"telefonopuestocontrol",
        orderable:true,
        filterable:false,
        label:"Telefono puestocontrol",
        path:"telefonopuestocontrol",
        stopClick:true
    },    
    {
        field:"puestocontrol",
        orderable:true,
        filterable:true,
        label:"Puesto control",
        type:"TEXT",
        path:"puestocontrol"
    }
];
export const fieldsTableDriver : Array <confTable>  = [
    {
      field:"indicadoractivo",
      orderable:true,
      filterable:true,
      label:"Activo",
      type:"BOOLEAN",
      width:75,
      path:"indicadoractivo",
      textAling:"CENTER"
    },
    {
        field:"numerodocumento",
        orderable:true,
        filterable:true,
        label:"Documento",
        type:"TEXT",
        path:"numerodocumento"
    },
    {
        field:"nombre",
        orderable:true,
        filterable:true,
        label:"Nombre",
        type:"TEXT",
        path:"nombre"
    },
    {
        field:"correo",
        orderable:true,
        filterable:true,
        label:"Correo",
        type:"TEXT",
        path:"correo"
    },
    {
        field:"telefono",
        orderable:true,
        filterable:false,
        label:"Teléfono",
        path:"telefono",
        type:"PHONE",
        stopClick:true
    }
    // {
    //     field:"indicadoractivo",
    //     orderable:true,
    //     filterable:true,
    //     label:"Activo",
    //     type:"BOOLEAN",
    //     path:"indicadoractivo",
    //     textAling:"CENTER"
    // }
];

export const filedsForm:Array<fields> = [
  {
    name:"indicadoractivo",
    path:"indicadoractivo",
  },
  {
    name:"nombre",
    path:"nombre",
    validator:[true]
  },
  {
    name:"correo",
    path:"correo",
    validator:[true]
  },
  {
    name:"telefono",
    path:"telefono",
    validator:[true]
  },
  {
    name:"idusuario",
    path:"idusuario"
  }
];
