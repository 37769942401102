import React from "react";
import DayPicker from "react-day-picker";
import { ____values } from ".";
import { DateRange } from "../../Componets/Basic/Input/DateRange";
import { InputSelectMultiple } from "../../Componets/Basic/Input/InputSelectMultiple";
import InputText from "../../Componets/Basic/Input/InputText";
// import icon from "./../../utils/css/icons/common/pin_red.png";
/* You can add global styles to this file, and also import other style files */


let test = [
    {
        "id": "19169a",
        "numero": "999-0000002",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 19197,
        "numero": "999-0000003",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 19248,
        "numero": "999-0000004",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 19311,
        "numero": "999-0000005",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 19605,
        "numero": "999-00000006",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 19624,
        "numero": "999-00000007",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 19666,
        "numero": "999-00000008",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 19668,
        "numero": "999-00000009",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 20102,
        "numero": "001-40000134",
        "placa": "ZOG996    ",
        "cliente": "CLIENTES GRANADA.IO S.A.S.,CUELLAR JORGE ALBERTO",
        "__typename": "ViajesInfo"
      },
      {
        "id": 20127,
        "numero": "001-40000135",
        "placa": "ZOG996    ",
        "cliente": "CLIENTES GRANADA.IO S.A.S.,CUELLAR JORGE ALBERTO",
        "__typename": "ViajesInfo"
      },
      {
        "id": 20128,
        "numero": "999-00000010",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 20133,
        "numero": "999-00000011",
        "placa": "BBB222",
        "cliente": "nombre-cliente-test-77",
        "__typename": "ViajesInfo"
      },
      {
        "id": 20134,
        "numero": "001-40000136",
        "placa": "ZOG996    ",
        "cliente": "CLIENTES GRANADA.IO S.A.S.,CUELLAR JORGE ALBERTO",
        "__typename": "ViajesInfo"
      }
]

const onChange = async (files: any) => {
    // let file = files?.target;
    // let myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzdjYWYzYzhiNThhYzU2MWI5ZTk0Y2ZmYTI4YWRlZWE0YTkzMjMyNjA4MGVmZjU4NjFkMjYwNzQwMGU2ZGU4Y2NmM2FmZTE5MjM5NWM3YWYiLCJpYXQiOjE2MTcyMjQzODksIm5iZiI6MTYxNzIyNDM4OSwiZXhwIjoxNjQ4NzYwMzg5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.tNQZvwmcIyWlSPG0EtxELqUhNADn2afSGcJw_mac0vFL_ZVwYVYzJGqRURQalUQk-O1fxd3p8eRFZKuMDWT9c6s2UxMYoV_LGM7RmJGTv_Tq6yZ2dlG2oQr_BZPlnAGjnW4DNHmPy5aLALeLsRIJI9JKwsOlXEes3S5-jXPb0gEkanYaYvLPqjzcve85z7BJqBam9TlfoJwHnt9oYxj3YBsfKSwgs1iyidJGNGtmQUErnzdDYfFk_syc6XNnFQrWRNJlceNYhvsSE5p1BiMG5FAJicP2eIQ7x24s07Iqb1hE6mkAL9CUN1AdES9Bhfd7NC6dGTtrqga1ADjCAMMJ1XL1H6hyGSnpNjR4xipIs5pZ7AMRY0eHW8HnQqyoLIiqL1T3NJb8Oejzi8yy5aSlOr52zkFjA9ldNkElU_zjS4qdkwDBD6aJB-w9gneRP7LGvyoMqB8RYDs1XRFDG2ZWrCDDymxjeXiGRG_LK3KEa1i_DATyX0iPeMdiA4kmQWOs2CcG6yRqWsH01LCkBwPKGpfIFiNH41pPAUtCnC9VdnO8zSFv07uSmCsvJhEadCO8xOEFeuBFg2-8eBO1vWme2mKJEvGwZGPUP22K4cwoqN_Wef6mVTzEnG5l9qcH_f8De4YlZRM_5VCmxppxBNpsSsh7P8DiSj4ydSWR50pNgaY");
    // myHeaders.append("Content-Type", "application/json");
    
    // let raw = JSON.stringify({
    //   "idaplicacion": 9,
    //   "idempresa": 1,
    //   "key": "test/robin7.jpg"
    // });
    
    // let requestOptions = {
    //   method: 'PUT',
    //   headers: myHeaders,
    //   body: raw
    // };
    
    // let res = await fetch("https://u4qjp3g1c4.execute-api.us-west-1.amazonaws.com/default/protecting-files-helloFromLambdaD5E16765-1O4VJWKFLHF0B", requestOptions)
    //   .then(response => response.json())
    //     .catch(error => console.log('error', error));
    
    // let _headers = new Headers();
    // _headers.append("Accept", "application/json, text/plain, */*");
    // _headers.append("Access-Control-Allow-Origin", "*");
    // _headers.append("Content-Type",file.files[0].type);
    // let body = file.files[0];
    // res = await fetch(res?.data?.url,{
    //     method:"PUT",
    //     headers:_headers,
    //     body
    // })
    //     .then((_res: any) => {
    //         return _res;
    //     })
    //     .catch((err: any) => {
    //     return ({error:true});
    //     });
          
    //     requestOptions = {
    //     method: 'GET',
    //     headers: myHeaders,
    //     body: raw
    //     };

    //         res = await fetch("https://u4qjp3g1c4.execute-api.us-west-1.amazonaws.com/default/protecting-files-helloFromLambdaD5E16765-1O4VJWKFLHF0B", requestOptions)
    //         .then(response => response.json())
    //         .catch(error => console.log('error', error));
    
    // console.log("res",res)

 };

const PlayGround = () => {

    // const _onClick = React.useCallback((v:any) => {setMarkers([{...v.latlng,icon,tooltip:"que paooooooo"}])},[]);

    // React.useEffect(() => {

    // let headers = new Headers();
    // headers.append("Content-Type", "text/xml; charset=utf-8");
    // let raw = "<soap:Envelope xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:xsd=\"http://www.w3.org/2001/XMLSchema\" xmlns:soap=\"http://schemas.xmlsoap.org/soap/envelope/\">\r\n  <soap:Body>\r\n    <GetAlarms xmlns=\"http://tempuri.org/\">\r\n      <UserName>string</UserName>\r\n      <Password>string</Password>\r\n      <AttentionType>string</AttentionType>\r\n    </GetAlarms>\r\n  </soap:Body>\r\n</soap:Envelope>";
    // fetch("http://webcloud.satrack.com/WebServiceEventos/getEvents.asmx",{
    //     method: 'POST',
    //     headers,
    //     body: raw,
    //     redirect: 'follow'
    // }).then(console.log).catch(console.log);
    // },[]);

    // React.useEffect(() => {
    //     console.log(test);
    // }, []);
  
  // const [value, setValue] = React.useState<Array<string | number>>([19666,"19169a"]);
  const [value2, setValue2] = React.useState<Array<string | number>>([]);
  const [value, onChange] = React.useState([new Date(), new Date()]);
  
  React.useEffect(() => {
    // let values = {};
    // ____values.clientes.forEach((item:any) => {values={...values,[item.codigo]:item}})
    // console.log("values",____values?.clientes.length,Object.values(values).filter(item => item.frecuenciacorreo !== 120)
    // )
  },[]);

  return (<div id="map" style={{ margin: 10, alignItems: "flex-start", display: "flex", height: 500, width: 500, overflow: "hidden" }}>
    <DateRange />
     {/* <DayPicker
          className="Range"
          numberOfMonths={2}
          fromMonth={from}
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
        /> */}
       {/* <DateRangePicker
        onChange={onChange}
        value={value}
      /> */}
        {/* <input type="file" onChange={ onChange}/> */}
      {/* <InputSelectMultiple value={ value} onChange={ _onChnage} label="clie" data={ test} error={{ error: true, message: "que hasy" }} config={{pathEmit:"id",pathKey:"cliente",pathShowInfo:"numero"}}/>
      <InputSelectMultiple value={ value2} onChange={ _onChnage2} label="cliente" data={ test} config={{formatter: (v:any) => ({ key: v.id, decription:`${v.numero}-${v.cliente}`, valueShow: v.numero })}}/> */}
    </div>);
};

export default PlayGround;