import React from "react";
import { useUser } from "../../Componets/hook/user-hook";
import { Modal } from "../../Componets/Basic/Modal/Modal";
import { ModalAdapter, ModalConfirmationRemove } from "../../Componets/General/FormsUtils/FormModal";
import { 
    queryEvent as GET_EVENT,
    mutationUpdateEvent as UPDATE_EVENT,
    mutationCreateEvent as CREATE_EVENT,
    mutationDelEvent as DEL_EVENT,
     filedsForm
 } from "./Event.conf";
import { ButtonRemove } from "../../Componets/General/FormsUtils/Buttons.form";
import { useNotification } from "../../Componets/hook/notification-hook";
import { useMutationServer } from "../../Componets/hook/mutation-hook";
import { NotificationSucces, NotificationError } from "../../Componets/General/FormsUtils/Notification.fom";
import { objectKV } from "../../utils/interface";
import { Form, FormInputSelect, FormInputText, FormSwitch } from "../../Componets/Basic/Form/Form";
import { BooleanView } from "../../Componets/Basic/Text/BooleanView";
import "./Event.scss";
import TextView from "../../Componets/Basic/Text/TextView";
import { useLazyQueryToSever,  } from "../../Componets/hook/query-hook";
import { fields } from "../../Componets/Helpers/Form/Form-context";
import { destructor } from "../../utils/utils";

const priority = [
    {value:"1",description:"BAJA"},
    {value:2,description:"MEDIA"},
    {value:3,description:"ALTA"} ];

export const ModalOptionsEvent  = (props:{id?:string | number, visible:boolean, close:(v?:any) => any,refresh?:(v?:any) => any, onClickSave?:(v?:any) => any}) => {

    const { user } = useUser();
    
    const [isEditableOnly, setEditableOnly] = React.useState<boolean>(false);
    const [validate, setValidate] = React.useState<boolean>(false);
    const [isValid, setIsvalid] = React.useState<boolean>(false);
    const [dataForm, setDataForm] = React.useState<objectKV>();

    const {addNotification} = useNotification();

    const onCompleted = () => {
        if(props.refresh) props.refresh();
    };

    const [loadData, {data:eventData,loading:loadingEvent}] = useLazyQueryToSever({query:GET_EVENT,fetchPolicy:"cache-first"});

    const [createEvent, {loading:loadingCrate, data:dataCreate, error:errorCreate}] = useMutationServer({mutation:CREATE_EVENT,onCompleted});

    const [updateEvent, {loading:loadingUpdate, data:dataUpdate, error:errorUpdate}] = useMutationServer({mutation:UPDATE_EVENT});

    React.useEffect(() => {
    }, []);

    React.useEffect(() => {
        let _id = +(props.id ?? 0);
        if(_id && user.idcompany && props.visible) {
            loadData({variables:{id:_id}});
            setEditableOnly(false);
        } else {
            setEditableOnly(true);
        }
    }, [props.id, user,props.visible]);

    React.useEffect(() => {
        if(dataUpdate || dataCreate) {
            if(!loadingCrate || !loadingUpdate) {
                props.close();
                addNotification({element:<NotificationSucces message="procesos exitoso" />,delay:3000});
            }
        }
    }, [dataCreate, dataUpdate, loadingUpdate,loadingCrate]);

    React.useEffect(() => {
        if(errorUpdate || errorUpdate){
            addNotification({element:<NotificationError message="Ocurrio un error" />,delay:3000});
        }
    }, [errorCreate,errorUpdate]);

    const _clickOutside = () => {
        props.close();
    };

    const assingDataForm = (v?:objectKV) => {
        setDataForm(v);
    };

    const assingValid = (v:boolean) => {
        setIsvalid(v);
    };

    const _clickSave = () => {
        setValidate(true)
        // console.log(isValid,dataForm);
        if(!isValid || !dataForm){
            setValidate(true);
            return;
        }
        let evento = {
            descripcion: dataForm.descripcion,
            nivelprioridad: +dataForm.nivelprioridad,
            indicadorvisiblecliente: !!dataForm.indicadorvisiblecliente,
            indicadorpuestocontrol:true,
            indicadorconductor:dataForm.indicadorconductor
        };
        if(props.id) {
            updateEvent({variables:{evento,id:+props.id}});
        } else {
            createEvent({variables:{evento}});
        }
    };

    return (
        <Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
            <ModalAdapter title="Evento" editableOnly={isEditableOnly} close={props.close} clickSave={_clickSave} loading={loadingCrate || loadingUpdate}  >
                <ContentModal key="VIEW-EVENT" data={eventData?.eventoV2} loading={loadingEvent} />
                <ContentModal getValue={assingDataForm} getIsvalid={assingValid} key="EDIT-EVENT" validate={validate} data={isEditableOnly?null:eventData?.eventoV2} isEditable/>
            </ModalAdapter>
        </Modal>
    );
};

export const ContentModal = React.memo((props:{
    isEditable?:boolean;
    data?:objectKV;
    validate?:boolean;
    getIsvalid?:(v:boolean) => any; 
    getValue?:(v?:objectKV) => any;
    loading?:boolean;
}) => {

    const el  = React.useRef(null);

    const { data,isEditable,validate, getIsvalid, getValue,loading } = props;

    React.useEffect(() => {
        filedsForm.forEach((item:fields) => {
            let _el:any = el?.current;
            if(_el?.assingValue && item.path) {
                let _value = destructor(item.path,data ?? {});
                _el.assingValue(_value,item.name);
                item.value= _value;
            }
        });
    },[data]);

    return (<Form ref={el} fields={filedsForm} validate={validate} getIsValid={getIsvalid} getOnchangeValues={getValue}>
        <div className="events-view" >
            <div className="events-view__description">
            {isEditable?
            <FormInputText style={{width:450}} label="Descripción" name="descripcion"/>
            :<TextView label="Descripción" text={data?.descripcion}/>}
            </div>
            <div className="events-view__priority">
                {isEditable?
                <FormInputSelect 
                style={{width:450}} 
                name={"nivelprioridad"} 
                label="Prioridad"
                data={priority}
                confSelect={{pathEmit:"value",pathOption:"description"}}
                />
                :<TextView label="Prioridad" text={["","BAJA","MEDIA","ALTA"][data?.nivelprioridad ?? 0]}/>}
            </div>
            <div className="events-view__show-client">
                {isEditable?
                <FormSwitch  label="Mostrar al cliente" name="indicadorvisiblecliente"/>
                :!loading && <BooleanView
                value={data?.indicadorvisiblecliente} 
                messageTrue="Permite visualizar este evento al cliente" 
                messageFalse="No permite visualizar este evento al cliente" 
                iconFalse="icon-hidden" 
                iconTrue="icon-eye" 
                label="Mostrar al cliente" />}
            </div>
            <div className="events-view__driver">
                {isEditable?
                <FormSwitch label="Conductor" name="indicadorconductor"/>
                :!loading && <BooleanView
                value={data?.indicadorconductor} 
                messageTrue="Permite selecionar este evento al app del conductor" 
                messageFalse="No permite selecionar este evento al app del conductor" 
                label="Conductor" />}
            </div>
            {/* <div className="events-view__check-point">
                {isEditable?
                <FormSwitch label="Puesto de control" name="indicadorpuestocontrol"/>
                :!loading && <BooleanView
                value={data?.indicadorpuestocontrol} 
                messageTrue="Permitir al puesto de control" 
                messageFalse="No permitir al puesto de control"
                label="Puesto de control" />}
            </div> */}
        </div>
    </Form>
    );
});


export const RemoveEvent = (props:{ids:Array<number | string>;refresh:() => void}) => {

    const [visible, setVisible ] = React.useState<boolean>(false);

    const onCompleted = () => {
        if(props.refresh) props.refresh();
    };

    const [removeData, {loading, data,error}] = useMutationServer({mutation:DEL_EVENT,onCompleted});

    React.useEffect(() => {
        if(data) setVisible(false);
    }, [data]);

    const _onClick = () => {
        setVisible(true);
    };

    const _close = () => {
        setVisible(false);
    };

    const _accept = () => {
        let tempId = props.ids.map((item) => +item? +item:null).filter(Boolean);
        if(tempId.length) removeData({variables:{ids:tempId}});
    };

    return (
    <React.Fragment>
        <ModalConfirmationRemove loading={loading} visible={visible} close={_close} numberReg={props.ids?.length ?? 0} accept={_accept} />
        <ButtonRemove onClick={_onClick} />
    </React.Fragment>
    );
};