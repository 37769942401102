import { pathConfig } from "./../../../utils/interface";

/**
 * @description Configuracion sidebar
 * En este documento se oueden configurar las rutas que aparecen en el sidebar y a que ligar auntan,
 * pero en el caos que se decee alterar un ade estas, se debe realizar el cambio en este lugar, a la vez en el 
 * documento src/Views/Navigation.tsx ya que en este se crean las rutas.
 * mas especificamente en la funcion que las crea que es la funcion Views.
 */
export const paths:Array<pathConfig> = [
    {
        name:"Seguimiento",
        icon:"icon-tabla-archivo",
        path:"/home"
    },
    // {
    //     name:"Contenedores",
    //     icon:"icon-contenedor-recogido",
    //     path:"/home/ctns"
    // },
    {
        name:"Historial",
        icon:"icon-history",
        path:"/home/history"
    },
    {
        name:"Estadisticas",
        icon:"icon-pie-chart",
        path:"/home/statistics"
    },
    {
        name:"Gestion",
        icon:"icon-proceso",
        path:"",
        add:[{roll:3}],
        children:[
            {
                name:"Eliminar viaje",
                path:"/home/TrvlDelete",
                drop:[{idcompany:3}]
            },
            {
                name:"Clientes",
                path:"/home/Client"
            }
        ]
    },
    {
        name:"Maestras",
        icon:"icon-listas",
        path:"",
        children:[
            {
                name:"Puestos de Control",
                path:"/home/PuestoControl"
            },
            {
                name:"Plan de Viajes",
                path:"/home/PlanViaje"
            },
            {
                name:"Rutas",
                path:"/home/Rutas"
            },
            {
                name:"Usuarios",
                path:"/home/Usuarios"
            },
            {
                name:"Eventos",
                path:"/home/Eventos"
            }

        ]
    }
];