import React from "react";
import "./Layout.scss"
import Button from "./../../Basic/Button/Button";
import Icon from "./../../Basic/Icon/Icon.tooltip";
import { action } from "../../../utils/interface";
import { useUser } from "./../../hook/user-hook";
import usePortal from "./../../hook/portal-hook";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { useSideBar } from "../../hook/side-bar-hook";
import { ImageViwer } from "./../../Basic/Image/ImageViwer";
import logo from "./../../../utils/img/icon/logo.svg";


interface propsLayout {
    /**
     * 
     */
    children?: JSX.Element[] | JSX.Element;

    /**
     * este es el titulo del layout
     */
    title?:string;

    /**
     * este es el componente que se renderizara en el centro del div
     */
    center?: JSX.Element[] | JSX.Element;

    /**
     * este es el componente que se renderizara en el lado derech del div
     */
    right?: JSX.Element[] | JSX.Element;

    /**
     * este es el componente que se renderiza en la parte inferior derecha de la vista
     */
    optionMore?: JSX.Element[] | JSX.Element;

    /**
     * Es el numero de registros que se mostraran por defecto es 20
     */
    numReg?:number;

    /**
     * Es el total actual de registros
     */
    total?:number;
    /**
     * Es el total actual de registros
     */
    desabledNumReg?:boolean;
    /**
     * Es la pagina actual lo normal es que sea un numero mayor a 0.
     */
    pageCurrent?:number;

    /**
     * Es el total actual de registros
     */
    goToPage?:(v:action) => any;

    /**Este Componente se va a ver en la izquierda baja del layout*/
    leftBottom?: JSX.Element[] | JSX.Element;

    /**Permite usar 500 reg en la paginacion */
    use500?:boolean;

}

export const Options = React.memo((props: {setHover: (v:boolean) => any, hover:boolean}) => {

    const el = usePortal("select-root");

    let history = useHistory();
    
    const { logout } = useUser();

    const _onMouseOver = () => {
        if(!props.hover) props.setHover(true);
    };

    const _onMouseLeave = () => {
        if(props.hover) props.setHover(false);
    };

    const _onClick = () => {
        logout();
    };

    const _onClickChangePass = () => {
        history.push("/resetPass");
    };

    return ReactDOM.createPortal(
        <div className="options-button__values" style={{height:!props.hover?0:""}} onMouseOver={_onMouseOver} onMouseLeave={_onMouseLeave}>
            <div className="options-button-item" onClick={_onClickChangePass}><i className="icon-key options-button-icon"/>Cambiar contraseña</div>
            <div onClick={_onClick} className="options-button-item"><i className="icon-cerrar-sesion options-button-icon"/>Cerrar sesión</div>
        </div>, el);
});

export const OptionsButton = React.memo(() => {
    const { user } = useUser();

    const [hover, setHover ] = React.useState<boolean>(false);

    const _onMouseOver = () => {
        if(!hover) setHover(true);
    };

    const _onMouseLeave = () => {
        if(hover) setHover(false);
    };

    return (
        <div className="options-button text-titles" onMouseOverCapture={_onMouseOver} onMouseLeave={_onMouseLeave}>
            {user?.name ?? "Usuario"}
            {user?.logo?<ImageViwer imageError={logo} uri={user?.logo} className="logo-user-options"/>:null}
            <div>
                <i className="icon-arrow_drop_down"/>
            </div>
            <Options setHover={setHover} hover={hover}/>
        </div>
    );
});

export const NumReg = React.memo((props:{value?:string;onChange?:(v:action) => any;use500?:boolean}) => {

    const _onChange = (v:any) => {
        if(props.onChange) props.onChange({value:v?.target?.value ?? 10,type:"SET_NUM_REG"});
    };

    return (<div className="select-container">
        Registros por página
        <select onChange={_onChange} value={props.value} className="select-input">
            <option>1</option>
            <option>5</option>
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            {props.use500?<option>500</option>:null}
        </select>
    </div>);
});

export const Layout = React.memo((props:propsLayout) => {

    const [total ,setTotal] = React.useState<number>();
    const [totalPages ,setTotalPages] = React.useState<number>();
    const [numReg, setNumReg] =  React.useState<number>(20);
    // const [pageCurrent ,setPageCurrent] = React.useState<number>(1);

    React.useEffect(() => {
        if(props.numReg !== numReg) setNumReg(props.numReg ?? 20);
        if(props.total !== total) setTotal(props.total ?? 0);
    }, [props.numReg,props.total]);

    React.useEffect(() => {
        setTotalPages(total? (+(+total/numReg).toString().split(".")[0])+(numReg === 1?0:1): 0);
    }, [numReg,total]);
    
    const setCurrenPage = (pageCurrent:number) => {
        if(props.goToPage) props.goToPage({type:"ADD_CURRENT_PAGE", value:pageCurrent});
    }
    const _nextPage = () => {
        let next = (props.pageCurrent ?? 0) + 1
        if(next <= (totalPages ?? 0)) setCurrenPage(next);
    }
    const _lastPage = () => {
        setCurrenPage(totalPages ?? 0);
    }
    const _backPage = () => {
        let back = (props.pageCurrent ?? 0) - 1;
        if(back >= 1 ) setCurrenPage(back);
    }
    const _firstPage = () => {
        setCurrenPage(1);
    }

    return (
        <div className="layout">
            <div className="layout__header">
                <div className="layout__header__title">
                    <OpenSideBar />
                    <div className="button-options-header text-options">
                        <OptionsButton />
                    </div>
                </div>
                <div className="layout__header__options">
                    <div className="layout__header__options__clm">
                        <div className="text-titles">
                            {props.title ?? ""}
                        </div>
                    </div>
                    <div className="layout__header__options__center">
                        {props.center}
                    </div>
                    <div className="layout__header__options__clm">
                        {props.right}
                    </div>
                </div>
            </div>
            <div className="layout__body">
                {props.children}
            </div>
            <div className="layout__option-buttons-more">
                {props.optionMore}
            </div>
            <div className="layout__footer">
                <div className="layout__footer__side justify-content-left">
                    {props.leftBottom}
                </div>
                <div className="layout__footer__center">
                    <div className="buttons-container">
                        <Button className="buttons" onClick={_firstPage}>
                            <Icon stabilizer={40} className="icon-keyboard_arrow_left buttons__icons" message="Ir a primera pagina" />
                            <Icon stabilizer={40} className="icon-keyboard_arrow_left buttons__icons" message="Ir a primera pagina" />
                        </Button>
                        <Button className="buttons" onClick={_backPage}>
                            <Icon stabilizer={40} className="icon-keyboard_arrow_left"  message="Ir a anterior pagina"/>
                        </Button>
                        {`${props.pageCurrent ?? 0} de ${totalPages}`}
                        <Button className="buttons" onClick={_nextPage}>
                            <Icon stabilizer={40} className="icon-keyboard_arrow_right"  message="Ir a siguiente pagina" />
                        </Button>
                        <Button className="buttons" onClick={_lastPage}>
                            <Icon stabilizer={40} className="icon-keyboard_arrow_right buttons__icons"  message="Ir a ultima pagina" />
                            <Icon stabilizer={40} className="icon-keyboard_arrow_right buttons__icons"  message="Ir a ultima pagina" />
                        </Button>
                    </div>

                </div>
                <div className="layout__footer__side">
                   {props.desabledNumReg?null:<NumReg onChange={props.goToPage} use500={props.use500} value={(props.numReg ?? "")?.toString()}/>}
                </div>
            </div>
        </div>
    )
});

export const OpenSideBar = React.memo(() => {
    const {state,setStateSideBar} = useSideBar();

    const _onClick = () => {
        if(!state?.isOpen) setStateSideBar({isOpen:true}); 
    };

    return (<Button className={`button-open-sidebar`} onClick={_onClick}>
        <i className="icon-burger-menu"/>
    </Button>)
});
