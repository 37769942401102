import React from 'react';
import client from "./apollo/apollo.client";
import Navigation from './Views/Navigation';
import { NotificationProvider } from "./Componets/Helpers/Notification/notification-context";
import './App.scss';
import { ApolloProvider } from '@apollo/client/react';
import { ConfTableProvider } from './Componets/Helpers/Conf-table/Conf-table-context';
import { GenaralStoreProvider } from './Componets/Helpers/GenaralStore/General-stores-context';

const welcome = () => {
  console.log("%cBienvenido a Granada software!", "color: #3597ec; font-family: sans-serif; font-size: 4.5em; font-weight: bolder;");
  console.log("%cEsta opcion es unicamente para desarrolladores, si se le indico copiar y pegar algo aqui haga caso omiso, ya que podria comprometer su informacion", "color: red; font-family: sans-serif; font-size: 2em; font-weight: bolder;");
};

//
function App() {
  welcome();
  return (
      <ApolloProvider client={client}>
        <GenaralStoreProvider>
          <ConfTableProvider>
            <NotificationProvider>
              <Navigation />
            </NotificationProvider>
          </ConfTableProvider>
        </GenaralStoreProvider>
      </ApolloProvider>
  );
}

export default App;
