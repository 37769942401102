import  React from "react";
import "./Buttons.form.scss";
import Button from "../../Basic/Button/Button";
import LoadCircular from "../../Basic/LoadIndicator/LoadCircular";
import Icon from "../../Basic/Icon/Icon.tooltip";

export const ButtonsFooterForm = (props:{noEdit?:boolean,loading?:boolean,btnClose:{[v:string]:any},btnSave:{[v:string]:any}}) => {

    const {loading,btnClose,btnSave} = props;

    const _disabled = React.useMemo(() => {
        return loading || btnSave.disabled;
    },[loading,btnSave.disabled]);

    return (
        <div className="buttons-form-container">
            <div>
                <Button className="buttons-form-container__cancel" {...btnClose}/>
            </div>
            {props.noEdit?null: <div>
                <Button disabled={_disabled} className="buttons-form-container__send" {...btnSave}>
                    {!loading? <label>{btnSave?.label ?? ""}</label>:<LoadCircular />}
                </Button>
            </div> }
        </div>
    );
};

export const Close = (props:{[v:string]:any}) => {
    return <Button {...props} className="button-close"><Icon message="Cerrar" className="icon-close"/></Button>
};
export const ButtonAdd = (props:{[v:string]:any}) => {
    return <Button {...props} className="button-add"><Icon message="Agregar" className="icon-add"/></Button>
};

export const ButtonDownLoad = (props:{[v:string]:any}) => {
    return <Button {...props} className="button-add">{props.message?<label>{props.message}</label>:<Icon message="Descargar" className="icon-descargar"/>}</Button>
};

export const ButtonRemove = (props:{[v:string]:any}) => {
    return <Button {...props} className="button-remove"><Icon message="Eliminar" className="icon-basura-negra"/></Button>
};
