import React from "react";
import "./Forms-report.scss";
import { objectKV, action, confTable } from "../../../utils/interface";

import { TableDetails } from "../../../Componets/Basic/Table/Table-details";
import { detail, keySave } from "../../../Componets/Helpers/Table-details/Table-details-context";
import { fields } from "../../../Componets/Helpers/Form/Form-context";
import { FormInputText } from "../../../Componets/Basic/Form/Form";
import { useMutationServer } from "../../../Componets/hook/mutation-hook";
import { useLazyQueryToSever } from "../../../Componets/hook/query-hook";
import { useNotification } from "../../../Componets/hook/notification-hook";
import { NotificationError, NotificationSucces } from "../../../Componets/General/FormsUtils/Notification.fom";
import TextView from "../../../Componets/Basic/Text/TextView";
import { Modal } from "../../../Componets/Basic/Modal/Modal";
import Button from "../../../Componets/Basic/Button/Button";
import Icon from "../../../Componets/Basic/Icon/Icon.tooltip";
import InputTextArea from "../../../Componets/Basic/Input/InputTextArea";
import { ControlButtons } from "./Form.general-info";
import { useInput } from "../../../Componets/hook/input-hook";


const mutationAlert = `
mutation updateAlert($id: Int,$alerta: String){
    updateAlert(id:$id,alerta:$alerta){
        id
        alerta
    }
}
`;

export const AlertForm = (props: any) => {

    const { addNotification } = useNotification();

    const onCompleted = (res: any) => {
        if (res?.updateAlert?.id) {
            addNotification({ element: <NotificationSucces message={`Proceso exitoso`} />, delay: 2000 });
            if (typeof props.update == "function") props.update();
        } else {
            addNotification({ element: <NotificationError message={`Ocurrio un error`} />, delay: 2000 });
        }
        _onClickCancel();
    };

    const { value, bind } = useInput(props?.alerta);
    const [updateAlert, { loading: loading, data: data, error: error }] = useMutationServer({ mutation: mutationAlert, onCompleted });

    const _onClickSend = async () => {
        updateAlert({ variables: { id: props.id, alerta: value ?? "" } });
    };

    const _onClickCancel = () => {
        props.close();
    };

    return (
        <div className="general-form general-info" style={{ display: "block", }}>
            <div className=" name-report" style={{ marginBottom: 15 }}>
                Alerta
            </div>
            <div className="general-info__number" style={{ marginBottom: 15 }}>
                <InputTextArea {...bind} className="obsertvation-input" />
            </div>
            <div>
                <ControlButtons
                    labelCancel="Cerrar"
                    labelSend="Enviar"
                    buttonCancel={{ onClick: _onClickCancel, disabled: loading }}
                    buttonSend={{
                        onClick: _onClickSend,
                        loading: loading,
                        disabled: loading
                    }} />
            </div>
        </div>
    );
};

/**
 * Botton de alerta
 * @param props 
 */
export const AlertModal = React.memo((props: any) => {
    const [visible, setVisible] = React.useState<boolean>(false);

    const close = () => {
        if(typeof props.isVisible == "function") props.isVisible(false);
        setVisible(false);
    }
    const open = () => {
        if(typeof props.isVisible == "function") props.isVisible(true);
        if(!props.disableModal) setVisible(true);
    }

    if (props.disableModal && !props.indicadoralerta) return <div></div>;
    return (
        <React.Fragment>
            <Modal visible={visible} backGroundColor="#00000000" clickOutside={close}>
                <AlertForm {...props} close={close} />
            </Modal>
            <Button className="button-report"  onClick={open} style={{ backgroundColor: props?.alerta ? "red" : "",...props.style }}>
                <Icon message={props?.alerta} className="icon-alerta" />
            </Button>
        </React.Fragment>
    );
});

