import React from "react";
import Button from "../../Basic/Button/Button";
import Icon from "../../Basic/Icon/Icon.tooltip";
import "./Utils-view.scss";
import { objectKV, excelField, confTable } from "../../../utils/interface";
import usePortal from "../../hook/portal-hook";
import ReactDOM from "react-dom";
import { useLazyQueryToSever } from "../../hook/query-hook";
import { destructor } from "../../../utils/utils";
import { ClickAwayListener } from "../../Basic/ClickAwayListener/Click.away-listener";
import InputText from "../../Basic/Input/InputText";
import { useUser } from "../../hook/user-hook";
import { useInput, useInputMultiSelect } from "../../hook/input-hook";
import { InputSelectMultiple } from "../../Basic/Input/InputSelectMultiple";


interface propsFilterOption {
    /**
     * este parametro dice si los filtros esta ocultos o visibles ne le momento
     */
    hidden: boolean;
    /**
     * Este es el llamado a la funcion que resetea los filtros
     */
    reset: () => any;
    /**
     * este es el llamdo a la funcion que oculta los filtros o los muestra 
     */
    hiddenFilters: (v: boolean) => any;
    /**
     * son los filtros actuales.
     */
    filters?: objectKV;

    /**
     * So otros posibles componentes a incluir en la vista 
     */
    others?: JSX.Element[] | JSX.Element;

    /**
     * Equivale a un posible ultimo button, pensado para el de processo o descargas 
     */
    lastButtom?: JSX.Element[] | JSX.Element;
}

export const OptionsDownload = (props: { data: Array<objectKV>, path: string, query: string, variables?: objectKV, fields: Array<excelField>, name: string }) => {

    const el = usePortal("select-root");

    const [visible, setVisible] = React.useState<boolean>(false);

    const _onMouseMoveCapture = () => {
        setVisible(true);
    };

    const _onMouseLeave = () => {
        setVisible(false);
    };

    return (<Button onMouseMoveCapture={_onMouseMoveCapture} onMouseOutCapture={_onMouseLeave} className="button download-option-button">
        {ReactDOM.createPortal(
            <div onMouseMoveCapture={_onMouseMoveCapture} onMouseOutCapture={_onMouseLeave} className="modal-options-download" style={{ width: visible ? "" : 0 }}>
                {/* <ExportExcel path={props.path} data={props.data} query={props.query} variables={props.variables} name={props.name} fields={props.fields}/> */}
                {/* <Button className="button-download">
                    <Icon className="icon-PDF" message="Descargar pdf"/>
                    <PdfDownload />
                </Button> */}
            </div>
            , el)}
        <Icon className="icon-descargar" />
    </Button>
    );
};

interface ExportExcelOptions {
    visible: boolean;
    fields: Array<string>;
    name: string;
    queryId: number;
    close?: (v: any) => any;
    open?: (v: any) => any;
    getLimits: () => any;
    pagination: { fields?: number, page?: number };
    filters: Array<confTable>;
    order?: any
}

export const ExportExcelOptions = React.memo((props: ExportExcelOptions) => {
    const { visible, fields, filters, name, queryId, getLimits, pagination,order } = props;
    const [downloadingScreen, setDownloadingScreen] = React.useState<boolean>(false);
    const [downloadingAll, setDownloadingAll] = React.useState<boolean>(false);
    const { user } = useUser();

    const download = React.useCallback(async (filters: Array<confTable>, pagination?: { fields?: number, page?: number },order?:any) => {
        if (!user.idcompany) return;
        let headers = new Headers();
        headers.append("Authorization", user?.token ?? "");
        headers.append("idapp", "12");
        headers.append("idempresa", "" + (user.idcompany ?? ""));
        headers.append("Content-Type", "application/json");
        if(order?.order)order.type = order.order;
        let body = JSON.stringify({ filters, fields, idquery: queryId, pagination,order });
        let uri = `${process.env.REACT_APP_URL}/api/v1/filesDoc`;
        await fetch(uri, { method: "POST", headers, body }).then((res: any) => res.blob())
            .then((res) => res)
            .then((blob) => URL.createObjectURL(blob))
            .then((href) => {
                Object.assign(document.createElement('a'), {
                    href, download: `${name}${new Date().toLocaleString().replace(/(\s)|(,)|(:)/ig, "_").split("/").join("_")}.xlsx`,
                }).click();
            }).catch(console.log)
            .finally(() => {
            });
    }, [JSON.stringify(user), fields, name]);

    const donwloadAll = React.useCallback(() => {
        setDownloadingAll(true)
        download(filters,undefined,order).finally(() => {
            setDownloadingAll(false);
            if (props.close) props.close(true);
        });
    }, [download, filters,order]);

    const donwloadScreen = React.useCallback(() => {
        setDownloadingScreen(true);
        download(filters, pagination,order).finally(() => {
            setDownloadingScreen(false);
            if (props.close) props.close(true);
        });
    }, [download, pagination, filters,order]);

    const limits = React.useMemo(() => {
        if (!visible) return {};
        let temp: any = getLimits();
        temp = temp ? JSON.parse(temp) : null;
        let w = window.innerWidth;
        return { top: temp.bottom, left: (temp?.left + 190) > (w - 50) ? w - 220 : undefined };
    }, [visible]);

    return visible ?
        <div className="container-excel-options" style={limits}>
            <ClickAwayListener clickOutside={props.close}>
                <div className="options-container">
                    <div className="basic-button-regs">
                        <Button onClick={!(downloadingAll || downloadingScreen) ? donwloadAll : console.log} className="white-space-nowrap button-options" label={downloadingAll ? "Descargando..." : "Exportar todo"} />
                    </div>
                    <div className="basic-button-regs">
                        <Button onClick={!(downloadingAll || downloadingScreen) ? donwloadScreen : console.log} className="white-space-nowrap button-options" label={downloadingScreen ? "Descargando..." : "Exportar en pantalla"} />
                    </div>
                    {/* <div className="number-regs-export">
                        <InputText style={{ width: 40 }} />
                        <Button className="white-space-nowrap button-options" label="Registros"/>
                    </div> */}
                </div>
            </ClickAwayListener>
        </div>
        : null;
});

export const ExportExcel = (props: { fields: Array<string>, order?: any,name: string, queryId: number, pagination: { fields?: number, page?: number }, filters: Array<confTable> }) => {
    const [visible, setVisible] = React.useState<boolean>(false);

    const el = React.useRef(null);

    const open = React.useCallback(() => {
        setVisible(true);
    }, []);

    const close = React.useCallback(() => {
        setVisible(false);
    }, []);

    const getLimit = () => {
        let _el: any = el?.current;
        _el = _el?.getBoundingClientRect ? _el?.getBoundingClientRect() : null;
        _el = _el ? JSON.stringify(_el) : null;
        return _el;
    };

    return (<React.Fragment>
        <Button ref={el} onClick={open} className="button" style={{ fontSize: 17 }} >
            <Icon className="icon-Excel" message={"Exportar a excel"} />
        </Button>
        <ExportExcelOptions getLimits={getLimit} visible={visible} close={close}  {...props} />
    </React.Fragment>);
};

export const FiltersOptions = (props: propsFilterOption) => {

    const [isFiltered, setIsFiltered] = React.useState<boolean>(false);

    React.useEffect(() => {
        let filters = Object.values(props.filters ?? {});
        setIsFiltered(!!filters.filter(Boolean).length)
    }, [props.filters]);

    return (
        <div className="filters-options">
            {props.others ?? <div />}
            <div className="filters-options__container">
                {props.lastButtom}
                <Button onClick={() => props.hiddenFilters(!props.hidden)} className="button" style={{ color: isFiltered ? "#2192df" : "" }}>
                    <Icon className="icon-filter" message={props.hidden ? "Mostrar filtros" : "Ocultar filtros"} />
                    <Icon className={props.hidden ? "icon-eye" : "icon-hidden"} message={props.hidden ? "Mostrar filtros" : "Ocultar filtros"} />
                </Button>
                <Button onClick={props.reset} className="button">
                    <Icon className="icon-filter" message="Limpiar" />
                    <Icon className="icon-slash" message="Limpiar" />
                </Button>
            </div>
        </div>
    );
};

export const TotalRegs = React.memo((props: { text?: string }) => {
    return (<div className="total-regs">{props.text ?? ""}</div>);
});

export const ButtonResize = React.memo((props: { style?: any, [k: string]: any }) => {
    const _onClick = React.useCallback(() => {
        if (!(+props.value ?? 0)) props.onChange({ target: { value: props.openedSize } });
        else props.onChange({ target: { value: "0" } });
    }, [props.value, props.onChange, props.openedSize]);

    if (!props.vertical) return (<div onClick={_onClick} className={`button-resize `} style={props.style}>
        <div className={`button-resize__text ${!(+props.value ?? 0) ? "button-resize__text--rotate" : ""}`}>▲</div></div>);

    return (<div onClick={_onClick} className={`button-resize button-resize--h `} style={props.style}>
        <div className={`button-resize__text ${!!(+props.value ?? 0) ? "button-resize__text--rotate" : ""}`}>►</div></div>);
});

export const CtnResize = React.memo((props: { style?: any, [k: string]: any }) => {

    const { children, vertical } = props;

    const { value: valueInput, setValue: setValueInput, valueKV, bind } = useInput(window.innerWidth > 600 ? "1" : "");

    React.useEffect(() => {
        if (typeof props.onChanges === "function") props.onChanges(!!(+(valueInput ?? 0)));
    }, [valueInput]);

    return (<div className={`ctn-resize `} style={{ display: vertical ? "flex" : "block", ...(vertical ? { width: "fit-content" } : { height: "fit-content" }) }}>
        <div className={`ctn-resize__child `} style={{ ...(vertical ? { width: !(+(valueInput ?? 0)) ? 0 : "fit-content" } : { height: !(+(valueInput ?? 0)) ? 0 : "fit-content" }) }}>
            {children}
        </div>
        <div className={`ctn-resize__button `} style={{ ...(vertical ? { width: "fit-content", marginRight: !(+(valueInput ?? 0)) ? 17 : 15, marginLeft: !(+(valueInput ?? 0)) ? 0 : -10 } : { width: "100%", height: "fit-content", marginTop: !(+(valueInput ?? 0)) ? 0 : -10 }) }}>
            <ButtonResize style={{ left: "50%" }} {...bind} openedSize="1" vertical={vertical} />
        </div>
    </div>);
});



/**
 * @description creamos la estructura de los filtros, ya que el state almacena valores diferentes, es como sanitizar
 * @param v 
 */
export const createFilters = (v: objectKV) => {
    let filters = Object.keys(v).map((item: string, index: number) => {
        let value = v[item];
        if (!value) return false;
        return { field: item, value }
    })
    return filters.filter(Boolean);
};

/**
 * @description transforma los valores del state, en valores que reciba graphql 
 * @param v 
 */
export const parceOrders = (v: objectKV) => {
    if (!v) return;
    let order = { field: v.field, type: v.order }
    return order;
};

export const queryClientesViajes = (ids: Array<number>) => `
query clienteViajesInfo{
  clienteViajesInfo(
    orders:[
    {field:"nombre",type:ASC}
    ],
    filters:[${ids.map((item: number) => `{field:"idsysestado",value:"${item}"}`).join(",")}]
  ){
    data{
      id
      idempresa
      nombre
    }
  }
}`;

export const ClientFilter = React.memo((props: { idsysevents: Array<number>, style?: any, name?: string, disabled?: boolean, [k: string]: any }) => {
    const { style, ...all } = props;
    const [getData, { data, loading, error }] = useLazyQueryToSever({ query: queryClientesViajes(props.idsysevents),fetchPolicy:"cache-first" });
    const { value: valueRem, bind, setValue } = useInputMultiSelect([], props.name);
    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        let temp = JSON.parse(all?.value ? all?.value : "{}");
        if (JSON.stringify(temp?.values) === JSON.stringify(valueRem)) return;
        setValue(temp?.values ?? []);
    }, [all?.value]);

    React.useEffect(() => {
        let temp = valueRem?.length ? JSON.stringify({ values: valueRem }) : "";
        if (JSON.stringify(temp) === JSON.stringify(all?.value)) return;
        if (all.onChange) {
            all.onChange({ target: { name: props.name, value: temp } });
        }
    }, [valueRem]);

    return (
        <InputSelectMultiple
            style={style}
            {...bind}
            value={valueRem}
            data={data?.clienteViajesInfo?.data ?? []}
            config={{ formatter: (i: any) => ({ key: i.id, decription: i.nombre, valueShow: i.nombre }) }}
        />
    );
});