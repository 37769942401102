import React from "react";
import { useUser } from "./../../Componets/hook/user-hook";
import { Modal } from "./../../Componets/Basic/Modal/Modal";

import { ModalAdapter, ModalConfirmationRemove } from "../../Componets/General/FormsUtils/FormModal";

import {
    getCheckPoint as GET_CHECK_POINT_ID,
    queryUpdateCheckPoint as UPDATE_CHECKPOINT,
    queryCreateCheckPoint as CREATE_CHECKPOINT,
    queryRemoveCheckPoint as DEL_CHECKPOINT,
    advancedSearchPoplation as GET_POBLATION,
    filedsForm
} from "./CheckPoint.conf";
import { useNotification } from "../../Componets/hook/notification-hook";
import { useMutationServer } from "../../Componets/hook/mutation-hook";
import { NotificationSucces, NotificationError } from "../../Componets/General/FormsUtils/Notification.fom";
import { objectKV, factorFilter, error } from "../../utils/interface";
import { ButtonRemove } from "../../Componets/General/FormsUtils/Buttons.form";
import { InputSelect } from "../../Componets/Basic/Input/InputSelect";
import "./CheckPoint.scss";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import TextView from "../../Componets/Basic/Text/TextView";
import Icon from "../../Componets/Basic/Icon/Icon.tooltip";
// import MapSelect from "../../Componets/General/Map/MapSelect";
import { FormInputText, Form, FormInputAdvanced, FormSwitch } from "../../Componets/Basic/Form/Form";
import Button from "../../Componets/Basic/Button/Button";
import { fields } from "../../Componets/Helpers/Form/Form-context";
import { destructor, getGeolocation, getAdress, geoToAdrs } from "../../utils/utils";
import { MapGranada, markerInterface } from "../../Componets/General/Map/Map";
import { BooleanView } from "../../Componets/Basic/Text/BooleanView";

/**
 * @deprecated
 * @param props 
 */
export const FactoryFilters = (props: factorFilter) => {
    if (props.confField.field === "indicadoraltoriesgo") {
        return (<InputSelect style={{ width: 75 }} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}
            data={[{ value: "false", description: "NO" }, { value: "true", description: "SI" }]}
            confSelect={{ pathEmit: "value", pathOption: "description", textAling: "CENTER" }}
        />);
    }
    if (props.confField.field === "indicadorpernoctar") {
        return (<InputSelect style={{ width: 75 }} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}
            data={[{ value: "false", description: "NO" }, { value: "true", description: "SI" }]}
            confSelect={{ pathEmit: "value", pathOption: "description", textAling: "CENTER" }}
        />);
    }
    if (props.confField.field === "indicadorfisico") {
        return (<InputSelect style={{ width: 75 }} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}
            data={[{ value: "false", description: "Virtual" }, { value: "true", description: "Físico" }]}
            confSelect={{ pathEmit: "value", pathOption: "description", textAling: "CENTER" }}
        />);
    }
    return null;
};

// updatePuestoControl($id: Int, $puestoControl: PuestoControlInput) {↵  updatePuestoControl(id: $id, puestoControl: $puestoControl) 
export const ModalOptionsCheckPoint = (props: { id?: string | number, refresh?: () => any; visible: boolean, close: (v?: any) => any, onClickSave?: (v?: any) => any }) => {

    const { user } = useUser();

    const [isEditableOnly, setEditableOnly] = React.useState<boolean>(false);
    const [validate, setValidate] = React.useState<boolean>(false);
    const [isValid, setIsvalid] = React.useState<boolean>(false);

    const [_data, setData] = React.useState<objectKV>();
    const [dataForm, setDataForm] = React.useState<objectKV>();

    const { addNotification } = useNotification();

    const [loadData, { data }] = useLazyQueryToSever({ query: GET_CHECK_POINT_ID, fetchPolicy: "cache-first" });

    React.useEffect(() => {
        let id = +(props.id ?? 0);
        if (id) loadData({ variables: { id } });
    }, [props.id]);

    React.useEffect(() => {
        setData(props.visible && props.id ? data?.puestocontrolInfo : {});
        if (!props.visible) {
            setValidate(false)
        }
    }, [data, props.visible]);

    const onCompleted = () => {
        if (props.refresh) props.refresh();
    };

    const [createCheckPoint, { loading: loadingCrate, data: dataCreate, error: errorCreate }] = useMutationServer({ mutation: CREATE_CHECKPOINT, onCompleted });

    const [updateCheckPoint, { loading: loadingUpdate, data: dataUpdate, error: errorUpdate }] = useMutationServer({ mutation: UPDATE_CHECKPOINT });

    React.useEffect(() => {
        // dispatch(fetchConfigTable(dataConfig,"puecon",true));
    }, []);

    React.useEffect(() => {
        if (props.id && user.idcompany && props.visible) {
            setEditableOnly(false);
            // dispatch(queryItem("puecon","puestocontrol",generateQueryString("puestocontrol",queryInfoCheckPoint,`id: ${props.id}`),user.idcompany));
        } else {
            // dispatch(assignRegister("puecon",{}));
            setEditableOnly(true);
        }
    }, [props.id, props.visible, user]);

    React.useEffect(() => {
        if (dataUpdate || dataCreate) {
            if (!loadingCrate && !loadingUpdate) {
                props.close();
                addNotification({ element: <NotificationSucces message="procesos exitoso" />, delay: 3000 });
            }
        }
    }, [dataCreate, dataUpdate, loadingUpdate, loadingCrate]);

    React.useEffect(() => {
        if (errorCreate) {
            addNotification({ element: <NotificationError message={errorCreate?.graphQLErrors[0]?.message ?? `Ocurrio un error`} />, delay: 3000 });
        }
    }, [errorCreate]);

    React.useEffect(() => {
        if (errorUpdate) {
            addNotification({ element: <NotificationError message={errorUpdate?.graphQLErrors[0]?.message ?? `Ocurrio un error`} />, delay: 3000 });
        }
    }, [errorUpdate]);

    const _clickOutside = () => {
        props.close();
    };

    const assingDataForm = (v?: objectKV) => {
        setDataForm(v);
    };

    const assingValid = (v: boolean) => {
        setIsvalid(v);
    };

    const _clickSave = () => {
        let latitud = dataForm?.geolocalizacion?.latitud;
        let longitud = dataForm?.geolocalizacion?.longitud;
        if (!isValid || !dataForm || (!latitud && latitud !== 0) || (!longitud && longitud !== 0)) {
            setValidate(true);
            return;
        }
        // console.log("")
        let controlPintInput = {
            descripcion: dataForm.description,
            direccion: dataForm.adress,
            idsyspoblacion: +dataForm.poblation,
            indicadorfisico: false,
            indicadoraltoriesgo:dataForm.indicadoraltoriesgo,
            indicadorpernoctar:dataForm.indicadorpernoctar,
            geolocalizacion: {
                latitud,
                longitud
            }
        };
        if (props.id) {
            updateCheckPoint({ variables: { puestoControlVirtual: controlPintInput, id: +props.id } });
        } else {
            createCheckPoint({ variables: { puestoControlVirtual: controlPintInput } });
        }
    };

    return (
        <Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#1b1b1b30">
            <ModalAdapter noEdit={props.id === undefined ? false : _data?.indicadorfisico !== undefined ? _data?.indicadorfisico : true} close={props.close} title="Puesto de control" editableOnly={isEditableOnly} clickSave={_clickSave} loading={loadingCrate || loadingUpdate} >
                <Visualization data={_data} />
                <Edit getValue={assingDataForm} getIsvalid={assingValid} validate={validate} data={_data} />
            </ModalAdapter>
        </Modal>
    );
};

export const Edit = React.memo((props: { data?: objectKV, validate?: boolean, getIsvalid: (v: boolean) => any, getValue: (v?: objectKV) => any }) => {

    const { data: dataInfo } = props;

    const el = React.useRef(null);

    const [valuesForm, setValuesForm] = React.useState<objectKV>();

    const [errorSearch, setErrorSearch] = React.useState<error>();

    React.useEffect(() => {
        if (dataInfo) {
            const { geolocalizacion } = dataInfo;
            let temp = dataInfo;
            let _fields = filedsForm.map((item: fields) => {
                let tempItem = { ...item };
                let _el: any = el?.current;
                if (_el?.assingValue && tempItem.path) {
                    let _value = destructor(tempItem.path, temp);
                    _el.assingValue(_value, item.name);
                    tempItem.value = _value;
                }
                return tempItem;
            });
            setValuesForm(Object.assign({}, { geolocalizacion }, ..._fields.map((item: fields) => ({ [item.name]: item.value }))));
        };
    }, [dataInfo]);

    React.useEffect(() => {
        props.getValue(valuesForm);
    }, [valuesForm]);

    const _getOnchangeValues = (v: objectKV) => {
        setValuesForm((_values: objectKV) => {
            const { geolocalizacion, ...all } = _values ?? {};
            return Object.assign({}, v, { geolocalizacion });
        });
    };

    const _onClickSearch = () => {
        let temp = `${valuesForm?.adress}`.split(",").map((i: string) => (+i) ? +i : 0).filter((i: number) => !!i);
        if (temp.length !== 2) {
            return;
        }
        let latitud = temp[0];
        let longitud = temp[1];
        setValuesForm((_values: objectKV) => {
            return Object.assign({}, _values, { geolocalizacion: { latitud, longitud } });
        });
        // getGeolocation(valuesForm?.adress).then((res:any) => {
        //     setErrorSearch({error:!res,message:"Dirección desconocida"});
        //     if(!res)return;
        //     setValuesForm((_values:objectKV) => {
        //         return Object.assign({},_values,{geolocalizacion:res});
        //     });
        // }).catch(console.warn);
    };

    const _onClickMap = (v: any) => {
        if (v?.latlng?.lng && v?.latlng?.lat) {
            let latitud = v?.latlng?.lat;
            let longitud = v?.latlng?.lng;
            let _el: any = el?.current;
            if (_el?.assingValue) {
                _el.assingValue(`${latitud},${longitud}`, "adress");
            }
            setValuesForm((_values: objectKV) => {
                return Object.assign({}, _values, { geolocalizacion: { latitud, longitud } });
            });
            // geoToAdrs(latitud, longitud).then((res:any) => {
            //     console.log(res)
            //     if(!res) return;
            //     let _el:any = el?.current;
            //     setErrorSearch({error:false,message:"Dirección desconocida"});
            //     if(_el?.assingValue) _el.assingValue(`${res.label??""},${res.county??""},${res.region??""}`,"adress");

            // }).catch(console.warn);
        }
    };

    const _getIsValid = (v: boolean) => {
        props.getIsvalid(v);
    };

    return (
        <Form ref={el} validate={props.validate} fields={filedsForm} getIsValid={_getIsValid} getOnchangeValues={_getOnchangeValues}>
            <div className="modal-check-point">
                <div className="modal-check-point__type">
                    <div style={{ display: "grid", gap: 20, gridTemplateColumns: "76px 76px 41px" }}>
                        <FormSwitch name="indicadorpernoctar" label="Pernotar" />
                        <FormSwitch name="indicadoraltoriesgo" label="Alto riesgo" />
                        <TextView height={40} label="Tipo">
                            <Icon message="Virtual" className="icon-gps icon-type-style" />
                        </TextView>
                    </div>
                </div>
                <div className="modal-check-point__description">
                    <FormInputText style={{ width: 450 }} label="Descripción*" name="description" />
                </div>
                <div className="modal-check-point__poblation">
                    <FormInputAdvanced valuesDescription={dataInfo?.poblacion} style={{ width: 453 }} name={"poblation"} label="Población*" pathTotalReg="sysPoblaciones.totalreg" fieldFilter="nombrenacional" descripcion query={GET_POBLATION} config={{ pathEmit: "id", pathOptions: "descripcion" }} pathData="sysPoblaciones.data" />
                </div>
                <div className="modal-check-point__addres">
                    <FormInputText style={{ width: 450 }} label="Dirección*" name="adress" />
                    <Button className="button_addres" type="button" onClick={_onClickSearch}>
                        <Icon message="Buscar" className="icon-gps" />
                    </Button>
                </div>
                {errorSearch?.error ? <div style={{ color: "red", fontSize: 13 }}>{errorSearch?.message}</div> : null}
                <div className="modal-check-point__map">
                    {props.validate && (valuesForm?.geolocalizacion?.latitud === undefined || valuesForm?.geolocalizacion?.longitud === undefined) ?
                        <div style={{ fontSize: 13, fontWeight: 400, color: "red" }}>Falta geolocalizacion</div> : null}
                    <MapGranada
                        id="map-check-select"
                        onClick={_onClickMap}
                        markers={[{ lat: valuesForm?.geolocalizacion?.latitud, lng: valuesForm?.geolocalizacion?.longitud }]}
                        style={{ width: 600, height: 400 }} />
                </div>
            </div>
        </Form>
    );
});
export const Visualization = React.memo((props: { data?: objectKV }) => {

    const { data: dataInfo } = props;

    const [markers, setMarkers] = React.useState<Array<markerInterface>>([]);

    React.useEffect(() => {
        return () => {
            setMarkers([]);
        }
    }, []);

    React.useEffect(() => {
        setMarkers(dataInfo?.geolocalizacion?.latitud && dataInfo?.geolocalizacion?.longitud ?
            [{ lat: dataInfo?.geolocalizacion?.latitud, lng: dataInfo?.geolocalizacion?.longitud }] : []);
    }, [dataInfo]);

    return (
        <div className="modal-check-point">
            <div className="modal-check-point__type" style={{ display: "grid", gap: 20, gridTemplateColumns: "76px 76px 41px" }}>
                <BooleanView label="Pernotar" value={dataInfo?.indicadorpernoctar} />
                <BooleanView iconFalse="icon-alerta" iconTrue="icon-alerta" label="Alto riesgo" value={!dataInfo?.indicadoraltoriesgo} />
                <TextView height={40} label="Tipo">
                    {dataInfo?.indicadorfisico === undefined ? undefined : !dataInfo?.indicadorfisico ? <Icon message="Virtual" className="icon-gps icon-type-style" /> :
                        <Icon message="Fisico" className="icon-puesto-de-control icon-type-style" />}
                </TextView>
            </div>
            <div className="modal-check-point__description">
                <TextView label="Descripción" text={dataInfo?.descripcion} />
            </div>
            {dataInfo?.indicadorfisico ? <div className="modal-check-point__info">
                <div className="modal-check-point__info__v1">
                    <TextView label="Nombre Contacto" text={dataInfo?.contacto} />
                    <TextView label="Correo Contacto" text={dataInfo?.correocontacto} />
                </div>
                <div className="modal-check-point__info__v2">
                    <TextView label="Teléfono" text={dataInfo?.telefono} />
                    <TextView label="Propietario" text={dataInfo?.nombrepropietario} />
                    <TextView label="Disponibilidad" text={dataInfo?.disponibilidad} />
                </div>
            </div> : null}
            <div className="modal-check-point__poblation">
                <TextView label="Población" text={dataInfo?.poblacion} />
            </div>
            <div className="modal-check-point__addres">
                <TextView label="Dirección" text={dataInfo?.direccion} />
            </div>
            <div className="modal-check-point__map">
                <MapGranada
                    id="map-check"
                    markers={markers}
                    style={{ width: 600, height: 400 }} />
            </div>
        </div>
    );
});

export const RemoveCheckPoint = (props: { ids: Array<number | string>, refresh?: () => any }) => {

    const [visible, setVisible] = React.useState<boolean>(false);

    const { addNotification } = useNotification();

    const onCompleted = () => {
        if (props.refresh) props.refresh();
    };

    const [removeData, { loading, data, error }] = useMutationServer({ mutation: DEL_CHECKPOINT, onCompleted });

    React.useEffect(() => {
        if (error) {
            addNotification({ element: <NotificationError message={error?.graphQLErrors[0]?.message ?? `Ocurrio un error`} />, delay: 3000 });
        }
    }, [error]);

    React.useEffect(() => {
        if (data) setVisible(false);
    }, [data]);

    const _onClick = () => {
        setVisible(true);
    };

    const _close = () => {
        setVisible(false);
    };

    const _accept = () => {
        let tempId = props.ids.map((item) => +item ? +item : null).filter(Boolean);
        if (tempId.length) removeData({ variables: { ids: tempId } });
    };

    return (
        <React.Fragment>
            <ModalConfirmationRemove loading={loading} visible={visible} close={_close} numberReg={props.ids?.length ?? 0} accept={_accept} />
            <ButtonRemove onClick={_onClick} />
        </React.Fragment>
    );
};

