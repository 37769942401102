import React from "react";
import "./Input.scss";
import { error } from "./../../../utils/interface";

/**
 * @description esta funcion preenta el componente de Input para los archicos
 * funciona igual que el componente de input de los "<"input type="file" multiple accept="image/*" />"
 * @version 1.0
 *   */
export const InputFile = (props:{onChange?:(v:any) => any,  error?:error}) => {
    
    /**maneja los cambios en el componente */
    const _onChange = (e:any) => {
        const { target: { validity, files } } = e;
        if (validity.valid && props.onChange) props.onChange(files);
      };
      
    return (
        <div className="input-file" style={{background:props.error?.error?"#d60038":""}}>
            <i className="icon-subir-archivo"/>
            <input onChange={_onChange} className="input-file__input" type="file" multiple accept="image/*"/>
        </div>
    );
}